import React from 'react';
import { useLocation } from 'react-router-dom';

const PageViewTracker = () => {
    const location = useLocation();

    React.useEffect(() => {
        if (window?.dataLayer?.push) {
            window.dataLayer.push({
                event: 'pageview',
                page: `${location?.pathname || ''}${location?.search || ''}`
            });
        }
    }, [location]);

    return null;
};

export default PageViewTracker;
