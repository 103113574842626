import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as companyContactsActions } from './CompanyContacts.reducer';

const companyContactsListingIncludeString = 'include=company';

/**
 * Delete Single Company Contact
 * @param  {Object} Iri of a Company Contact
 * @return {Object} Response from API
 */
export function* deleteCompanyContact({ promise, iri, companyIri }) {
    try {
        const deleteSingleCompanyAPI = yield restClient.delete(iri);
        yield put({
            type: companyContactsActions.DELETE_COMPANY_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCompanyAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'companyContacts' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'COMPANY_CONTACTS',
            name: 'companyContacts',
            endpoint: `api/contacts?filters[company][equals]=${companyIri}&${companyContactsListingIncludeString}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyContactsActions.DELETE_COMPANY_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Company Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createCompanyContact({ promise, formData, companyIri }) {
    try {
        const createCompanyContactAPI = yield restClient.post(`/api/contacts`, formData);
        yield put({
            type: companyContactsActions.CREATE_COMPANY_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCompanyContactAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'companyContacts' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'COMPANY_CONTACTS',
            name: 'companyContacts',
            endpoint: `api/contacts?filters[company][equals]=${companyIri}&${companyContactsListingIncludeString}`
        });
        yield call(promise.resolve, createCompanyContactAPI?.data);
    } catch (error) {
        yield put({
            type: companyContactsActions.CREATE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Company Contact
 * @param  {Object} iri of company Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateCompanyContact({ promise, formData, iri, companyIri }) {
    try {
        const updateCompanyContactAPI = yield restClient.put(iri, formData);
        yield put({
            type: companyContactsActions.UPDATE_COMPANY_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateCompanyContactAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'companyContacts' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'COMPANY_CONTACTS',
            name: 'companyContacts',
            endpoint: `api/contacts?filters[company][equals]=${companyIri}&${companyContactsListingIncludeString}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyContactsActions.UPDATE_SINGLE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const companyContactsSaga = [
    takeLatest(companyContactsActions.START_DELETE_COMPANY_CONTACT, deleteCompanyContact),
    takeLatest(companyContactsActions.START_CREATE_COMPANY_CONTACT, createCompanyContact),
    takeLatest(companyContactsActions.START_UPDATE_COMPANY_CONTACT, updateCompanyContact)
];
