export const actions = {
    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
    SET_PAGE_NOTIFICATION: 'SET_PAGE_NOTIFICATION',
    ADD_PAGE_NOTIFICATION: 'ADD_PAGE_NOTIFICATION',
    REMOVE_PAGE_NOTIFICATION: 'REMOVE_PAGE_NOTIFICATION',
    REMOVE_PAGE_NOTIFICATIONS: 'REMOVE_PAGE_NOTIFICATIONS',
    SET_PERMANENT_PAGE_NOTIFICATION: 'SET_PERMANENT_PAGE_NOTIFICATION',
    ADD_PERMANENT_PAGE_NOTIFICATION: 'ADD_PERMANENT_PAGE_NOTIFICATION',
    REMOVE_PERMANENT_PAGE_NOTIFICATION: 'REMOVE_PERMANENT_PAGE_NOTIFICATION',
    REMOVE_PERMANENT_PAGE_NOTIFICATIONS: 'REMOVE_PERMANENT_PAGE_NOTIFICATIONS',
    SET_FLOATING_NOTIFICATION: 'SET_FLOATING_NOTIFICATION',
    ADD_FLOATING_NOTIFICATION: 'ADD_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATION: 'REMOVE_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATIONS: 'REMOVE_FLOATING_NOTIFICATIONS'
};

export const initialState = {
    pageNotifications: [],
    permanentPageNotifications: [],
    floatingNotifications: []
};

const obtainNotificationKey = position => {
    /*
    let key = response?.code;

    if (response?.errors?.code) {
        key = `${response.code}-${response.errors.code}`;
    } else if (response?.data?.id) {
        key = `${response.code}-${response.data.id}`;
        // eslint-disable-next-line camelcase
    } else if (response?.id) {
        key = `${response.code}-${response.id}`;
        // eslint-disable-next-line camelcase
    } else if (response?.error_key) {
        // eslint-disable-next-line camelcase
        key = `${response.code}-${response.error_key}`;
    }
    */
    return `${position}-${+new Date()}`; // response is always non-circular object without references, safe for JSON.stringify() operation
};

const formatNotificationData = (response, position) => {
    return { ...response, identifier: obtainNotificationKey(position) };
};

export default (state = initialState, { type, response, identifier, initiator }) => {
    switch (type) {
        // PAGE NOTIFICATIONS
        case actions.SET_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [formatNotificationData(response, 'page')]
            };
        }
        case actions.ADD_PAGE_NOTIFICATION: {
            const pageNotifications = [...state.pageNotifications];
            pageNotifications.unshift(formatNotificationData(response, 'page'));
            return {
                ...state,
                pageNotifications
            };
        }
        case actions.REMOVE_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [...state.pageNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_PAGE_NOTIFICATIONS: {
            if (initiator && initiator === 'router') {
                return {
                    ...state,
                    pageNotifications: [...state.pageNotifications].filter(item => {
                        return item.skipNotificationClearOnRouteChange;
                    })
                };
            }
            return {
                ...state,
                pageNotifications: []
            };
        }

        // PERMANENT PAGE NOTIFICATIONS

        case actions.SET_PERMANENT_PAGE_NOTIFICATION: {
            return {
                ...state,
                permanentPageNotifications: [formatNotificationData(response, 'permanentPage')]
            };
        }
        case actions.ADD_PERMANENT_PAGE_NOTIFICATION: {
            const permanentPageNotifications = [...state.permanentPageNotifications];
            permanentPageNotifications.unshift(formatNotificationData(response, 'permanentPage'));
            return {
                ...state,
                permanentPageNotifications
            };
        }
        case actions.REMOVE_PERMANENT_PAGE_NOTIFICATION: {
            return {
                ...state,
                permanentPageNotifications: [...state.permanentPageNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_PERMANENT_PAGE_NOTIFICATIONS: {
            return {
                ...state,
                permanentPageNotifications: []
            };
        }

        // FLOATING NOTIFICATIONS

        case actions.SET_FLOATING_NOTIFICATION: {
            return {
                ...state,
                floatingNotifications: [formatNotificationData(response, 'floating')]
            };
        }
        case actions.ADD_FLOATING_NOTIFICATION: {
            const floatingNotifications = [...state.floatingNotifications];
            floatingNotifications.unshift(formatNotificationData(response, 'floating'));
            return {
                ...state,
                floatingNotifications
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATION: {
            return {
                ...state,
                floatingNotifications: [...state.floatingNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATIONS: {
            return {
                ...state,
                floatingNotifications: []
            };
        }

        // GENERAL
        case actions.REMOVE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [...state.pageNotifications].filter(item => {
                    return item.identifier !== identifier;
                }),
                permanentPageNotifications: [...state.permanentPageNotifications].filter(item => {
                    return item.identifier !== identifier;
                }),
                floatingNotifications: [...state.floatingNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        default:
            return state;
    }
};
