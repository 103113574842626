export const actions = {
    START_SIGN_IN: 'START_SIGN_IN',
    START_SIGN_IN_WITH_TOKEN: 'START_SIGN_IN_WITH_TOKEN',
    SIGN_IN_SUCCESSFUL: 'SIGN_IN_SUCCESSFUL',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    START_SIGN_OUT: 'START_SIGN_OUT',
    SIGN_OUT_SUCCESSFUL: 'SIGN_OUT_SUCCESSFUL',
    STORE_USER_DATA: 'STORE_USER_DATA',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    START_FETCHING_ME: 'START_FETCHING_ME',
    FETCHING_ME_SUCCESSFULL: 'FETCHING_ME_SUCCESSFULL',
    FETCHING_ME_FAILED: 'FETCHING_ME_FAILED',
    START_FETCHING_ME_CLIENT: 'START_FETCHING_ME_CLIENT',
    FETCHING_ME_CLIENT_SUCCESSFULL: 'FETCHING_ME_CLIENT_SUCCESSFULL',
    FETCHING_ME_CLIENT_FAILED: 'FETCHING_ME_CLIENT_FAILED',
    START_FETCHING_ME_PERMISSIONS: 'START_FETCHING_ME_PERMISSIONS',
    FETCHING_ME_PERMISSIONS_SUCCESSFULL: 'FETCHING_ME_PERMISSIONS_SUCCESSFULL',
    FETCHING_ME_PERMISSIONS_FAILED: 'FETCHING_ME_PERMISSIONS_FAILED',
    START_IMPERSONATE: 'START_IMPERSONATE',
    IMPERSONATE_SAVE_ID: 'IMPERSONATE_SAVE_ID',
    IMPERSONATE_STOP: 'IMPERSONATE_STOP',
    IMPERSONATE_FAILED: 'IMPERSONATE_FAILED',

    START_FETCHING_TABLE_FILTERS: 'START_FETCHING_TABLE_FILTERS',
    FETCHING_TABLE_FILTERS_SUCCESSFULL: 'FETCHING_TABLE_FILTERS_SUCCESSFULL',
    FETCHING_TABLE_FILTERS_ERROR: 'FETCHING_TABLE_FILTERS_ERROR',

    START_CREATE_TABLE_FILTERS: 'START_CREATE_TABLE_FILTERS',
    REMOVE_TABLE_FILTER: 'REMOVE_TABLE_FILTER',

    START_UPDATE_TABLE_FILTERS: 'START_UPDATE_TABLE_FILTERS',
    STORE_DATA_TABLE_FILTERS: 'STORE_DATA_TABLE_FILTERS',

    STORE_USER_ORGANIZATION: 'STORE_USER_ORGANIZATION'
};

export const initialState = {
    token: '',
    user: null,
    userClientData: null,
    isSignedIn: false,
    fetchingMe: false,
    impersonateUserId: null,
    savedListingFilter: {}
};

export default (state = initialState, { type, response, impersonateUserId, id, name }) => {
    switch (type) {
        case actions.START_FETCHING_ME: {
            return {
                ...state,
                fetchingMe: true
            };
        }
        case actions.START_FETCHING_ME_CLIENT: {
            return {
                ...state,
                fetchingMe: true
            };
        }
        case actions.FETCHING_ME_SUCCESSFULL:
        case actions.FETCHING_ME_FAILED: {
            return {
                ...state,
                fetchingMe: false
            };
        }
        case actions.FETCHING_ME_CLIENT_SUCCESSFULL: {
            return {
                ...state,
                fetchingMe: false,
                userClientData: {
                    ...response,
                    account_manager: {
                        ...(response?.account_manager?.data?.attributes || {}),
                        image: response?.account_manager?.data?.relationships?.image?.data
                            ? response?.account_manager?.data?.relationships?.image?.data?.id
                            : null
                    },
                    company_logo: {
                        id: response?.company_logo?.data?.id,
                        ...(response?.company_logo?.data?.attributes || {})
                    }
                }
            };
        }
        case actions.FETCHING_ME_CLIENT_FAILED: {
            return {
                ...state,
                fetchingMe: false
            };
        }
        case actions.START_SIGN_IN: {
            return {
                ...state
            };
        }
        case actions.SIGN_IN_SUCCESSFUL: {
            return {
                ...state,
                token: response
            };
        }
        case actions.SIGN_IN_FAILED: {
            return {
                ...state,
                token: '',
                isSignedIn: false
            };
        }
        case actions.STORE_USER_DATA: {
            return {
                ...state,
                user: response?.data,
                isSignedIn: true
            };
        }
        case actions.UPDATE_USER_DATA: {
            const { data: responseData } = { ...response };
            // If response.user doesn't contain country/city/state properties, create them and set them to null so that they can overwrite old state properties.
            // We do this because we are merging old user and new user state.
            // We don't do this on other places because on other places we overwrite state instead of merging.
            // Merging is done here because of permissions...
            if (!('country' in responseData)) {
                responseData.country = null;
            }
            if (!('city' in responseData)) {
                responseData.city = null;
            }
            if (!('state' in responseData)) {
                responseData.state = null;
            }
            if (!('image' in responseData)) {
                responseData.image = null;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    ...responseData
                }
            };
        }
        case actions.IMPERSONATE_SAVE_ID: {
            return {
                ...state,
                impersonateUserId
                // token is only in localStorage
            };
        }
        case actions.IMPERSONATE_STOP: {
            return {
                ...state,
                impersonateUserId: null
            };
        }
        case actions.FETCHING_TABLE_FILTERS_SUCCESSFULL: {
            if (!response.length || !Object.keys(response).length) {
                const currentListing = Object.keys(state?.savedListingFilter || {}).find(
                    savedListing => state?.savedListingFilter[savedListing].listing === name
                );

                if (currentListing) {
                    delete state.savedListingFilter[currentListing];
                }

                return {
                    ...state,
                    savedListingFilter: { ...state.savedListingFilter }
                };
            }

            const listings = {};
            response.forEach(tableFiltersData => {
                listings[tableFiltersData.id] = tableFiltersData;
            });

            return { ...state, savedListingFilter: { ...state.savedListingFilter, ...listings } };
        }
        case actions.REMOVE_TABLE_FILTER: {
            const updatedListingFilters = { ...state?.savedListingFilter };
            delete updatedListingFilters[id];

            return {
                ...state,
                savedListingFilter: {
                    ...updatedListingFilters
                }
            };
        }
        case actions.STORE_DATA_TABLE_FILTERS: {
            return {
                ...state,
                savedListingFilter: {
                    ...state.savedListingFilter,
                    [response.id]: { ...response }
                }
            };
        }
        case actions.STORE_USER_ORGANIZATION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    userOrganization: response
                }
            };
        }
        default:
            return state;
    }
};
