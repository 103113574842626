import React, { memo, useEffect, useState } from 'react';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';
import PropTypes from 'prop-types';

const Dropdown = ({ opened, children, id, style, renderOnOpen }) => {
    const [reference, setReference] = useState(null);

    useEffect(() => {
        if (opened && reference) {
            const input = reference.querySelector('input') || reference.querySelector('textarea');

            if (input) input.focus();
        }
    }, [opened, reference]);

    return (
        <div
            id={id}
            ref={(ref) => ref && setReference(ref)}
            data-opened={opened}
            className={`${styles['time-tracking__dropdown']} time-tracking__dropdown`}
            style={style}
        >
            {renderOnOpen && opened && children}
            {!renderOnOpen && children}
        </div>
    );
};

Dropdown.defaultProps = {
    opened: false,
    children: null,
    style: null,
    renderOnOpen: false
};

Dropdown.propTypes = {
    opened: PropTypes.bool,
    children: PropTypes.element,
    style: PropTypes.oneOfType([PropTypes.object]),
    id: PropTypes.string.isRequired,
    renderOnOpen: PropTypes.bool
};

export default memo(Dropdown);
