import React, { memo, useEffect, useCallback } from 'react';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSubmittedTimelogs,
    getSubmittedTimelogsFetching,
    getSubmittedTimelogsMeta
} from 'erpcore/components/TimeTracking/TimeTracking.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import PropTypes from 'prop-types';
import NotificationManager from 'erpcore/utils/NotificationManager';
import { Form as FinalForm } from 'react-final-form';
import TimeTrackingForm from 'erpcore/components/TimeTracking/components/TimeTrackingForm';
import moment from 'moment-timezone';
import { Pagination } from 'erpcore/components/Listing';
import { dtoIgnoreTimezone, isUrl } from 'erpcore/utils/utils';
import { Link } from 'react-router-dom';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import base64 from 'base-64';
import qs from 'qs';
import { hasPermission } from 'erpcore/utils/RolesManager';

const SubmittedTimelogs = ({ workTypeOptions, preventFetching, onTimelogSubmitCallback }) => {
    const dispatch = useDispatch();
    const submittedTimelogs = useSelector(getSubmittedTimelogs);
    const fetching = useSelector(getSubmittedTimelogsFetching);
    const meta = useSelector(getSubmittedTimelogsMeta);
    const meData = useSelector(getMeData) || {};
    const canManageProjects = hasPermission('CAN_MANAGE_COMMON_PROJECT', meData);

    const fetchSubmittedTimelogs = useCallback(() => {
        dispatch({
            type: timeTrackingActions.START_FETCHING_SUBMITTED_TIMELOGS
        });
    }, []);

    const onDeleteTimelog = useCallback(
        (iri) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_DELETE_SUBMITTED_TIMELOG,
                    iri,
                    promise: { resolve, reject }
                });
            }).then(() => {
                const itemData = submittedTimelogs?.find((item) => item?.iri === iri);
                onTimelogSubmitCallback({ newData: itemData, oldData: itemData, method: 'delete' });
            });
        },
        [submittedTimelogs, onTimelogSubmitCallback]
    );

    const onTimelogSubmit = useCallback(() => {}, []);

    const getTimeSpentOrBillable = (timelogData, timeSpent) => {
        if (timelogData?.should_be_billable && !timelogData?.time_spent_billable_changed) {
            return {
                time_spent: timeSpent,
                time_spent_billable: timeSpent
            };
        }
        return {
            time_spent: timeSpent
        };
    };

    const onTimelogUpdate = useCallback(
        (id, timeSpent, timelogData) => {
            const timeSpentOrBillable = getTimeSpentOrBillable(timelogData, timeSpent);

            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri: id,
                    promise: { resolve, reject },
                    formData: {
                        ...timeSpentOrBillable
                    }
                });
            }).then((response) => {
                const oldData = submittedTimelogs?.find((item) => item?.iri === id);
                onTimelogSubmitCallback({ newData: response, oldData });

                return response;
            });
        },
        [submittedTimelogs, onTimelogSubmitCallback]
    );

    const onDuplicateTimelog = useCallback(
        (iri) => {
            const timelog = submittedTimelogs.find((item) => item?.iri === iri);

            const formData = {
                stage: timelog?.stage?.iri,
                work_type: timelog?.work_type?.iri,
                project: timelog?.project?.iri,
                comment: timelog?.comment || undefined,
                task_url: timelog?.task_url || undefined,
                status: 'paused',
                timeSpent: 0,
                date: moment().format('YYYY-MM-DD')
            };

            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_CREATE_DRAFT_TIMELOG,
                    formData,
                    promise: { resolve, reject }
                });
            });
        },
        [submittedTimelogs]
    );

    const onTimelogChange = useCallback(
        (key, value, timelogIri) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri: timelogIri,
                    promise: { resolve, reject },
                    formData: {
                        [key]: value
                    }
                });
            }).then((response) => {
                if (key === 'date') {
                    const oldData = submittedTimelogs?.find((item) => item?.iri === timelogIri);

                    onTimelogSubmitCallback({ newData: response, oldData });

                    return response;
                }

                return response;
            });
        },
        [submittedTimelogs, onTimelogSubmitCallback]
    );

    const loadMore = useCallback(
        ({ page }) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_FETCHING_SUBMITTED_TIMELOGS,
                    page,
                    promise: { resolve, reject }
                });
            });
        },
        [meta]
    );

    useEffect(() => {
        if (!preventFetching && !submittedTimelogs.length) fetchSubmittedTimelogs();
    }, [preventFetching]);

    return (
        <div className={styles['time-tracking__submitted']}>
            <div className={styles['time-tracking__submitted-top']}>
                <h5>RECENT LOGS</h5>
                <Link
                    rel="noopener noreferrer"
                    to={
                        canManageProjects
                            ? `/time-logs?timeLogs[filter]=${base64.encode(
                                  qs.stringify({
                                      created_by: [{ value: meData?.iri }]
                                  })
                              )}`
                            : '/time-logs'
                    }
                >
                    View all logs
                </Link>
            </div>

            <div className={styles['time-tracking__draft-list']}>
                {fetching && <ElementLoader overlay />}

                {!fetching && !submittedTimelogs?.length && (
                    <div className={styles['time-tracking__draft-list--no-data']}>
                        <NotificationManager code="listingNoData" />
                    </div>
                )}

                {!!submittedTimelogs?.length && meta && (
                    <>
                        {submittedTimelogs.map((timelog) => {
                            const initialValues = {
                                iri: timelog?.iri,
                                work_type: timelog?.work_type?.iri,
                                time_spent: timelog?.time_spent,
                                status: timelog?.status,
                                started_at: timelog?.started_at,
                                task_url: timelog?.task_url,
                                comment: timelog?.comment,
                                date: dtoIgnoreTimezone(timelog?.date),
                                stage: timelog?.stage?.iri
                            };

                            return (
                                <FinalForm
                                    key={timelog?.iri}
                                    onSubmit={onTimelogSubmit}
                                    initialValues={initialValues}
                                    validate={(values) => {
                                        const errors = {};

                                        if (
                                            values?.task_url?.length > 0 &&
                                            !isUrl(values?.task_url)
                                        ) {
                                            errors.task_url = 'Task URL has an invalid URL format';
                                        }

                                        return errors;
                                    }}
                                    render={({ handleSubmit, values, form, errors }) => {
                                        return (
                                            <TimeTrackingForm
                                                type="submitted_logs"
                                                workTypeOptions={workTypeOptions}
                                                data={timelog}
                                                onDuplicateTimelog={onDuplicateTimelog}
                                                onTimelogUpdate={onTimelogUpdate}
                                                onTimelogChange={onTimelogChange}
                                                onDeleteTimelog={onDeleteTimelog}
                                                handleSubmit={handleSubmit}
                                                form={form}
                                                values={values}
                                                errors={errors}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}

                        {meta?.last_page > 1 && (
                            <div className={styles['time-tracking__submitted--load-more']}>
                                <Pagination meta={meta} onChangePagination={loadMore} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

SubmittedTimelogs.defaultProps = {
    workTypeOptions: [],
    preventFetching: false,
    onTimelogSubmitCallback: () => {}
};

SubmittedTimelogs.propTypes = {
    workTypeOptions: PropTypes.oneOfType([PropTypes.array]),
    preventFetching: PropTypes.bool,
    onTimelogSubmitCallback: PropTypes.func
};

export default memo(SubmittedTimelogs);
