import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as bundleTemplatesActions } from 'erpcore/screens/Settings/BundleTemplates/BundleTemplates.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Create bundle templates
 * @param promise
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createBundleTemplate({ promise, formData }) {
    try {
        const createBundleTemplateAPI = yield restClient.post('api/bundle-templates', formData);
        yield put({
            type: bundleTemplatesActions.CREATE_BUNDLE_TEMPLATE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBundleTemplateAPI?.data
        });

        yield call(promise.resolve, createBundleTemplateAPI?.data);
    } catch (error) {
        yield put({
            type: bundleTemplatesActions.CREATE_BUNDLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch single bundle template
 * @param  {object} promise - promise
 * @param  {string} iri - iri
 * @param params
 * @return {Object} Response from API
 */
export function* fetchSingleBundleTemplate({ promise, iri, params }) {
    try {
        const fetchSingleBundleTemplateAPI = yield restClient.get(iri, { params });
        const fetchSingleBundleTemplateAPIDto = dto(fetchSingleBundleTemplateAPI?.data)?.data;
        yield put({
            type: bundleTemplatesActions.FETCHING_SUCCESSFUL_SINGLE_BUNDLE_TEMPLATE
        });
        yield put({
            type: bundleTemplatesActions.STORE_SINGLE_BUNDLE_TEMPLATE_DATA,
            response: fetchSingleBundleTemplateAPIDto,
            iri: fetchSingleBundleTemplateAPIDto?.iri
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: bundleTemplatesActions.FETCHING_SINGLE_BUNDLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update single bundle template
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @param iri
 * @return {Object} Response from API
 */
export function* updateSingleBundleTemplate({ promise, formData, iri }) {
    try {
        const updateSingleBundleTemplateAPI = yield restClient.put(iri, formData);
        yield put({
            type: bundleTemplatesActions.UPDATE_SINGLE_BUNDLE_TEMPLATE_SUCCESSFUL
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(updateSingleBundleTemplateAPI?.data)
        });

        /*
        const updateSingleBundleTemplateAPIDto = dto(updateSingleBundleTemplateAPI?.data?.data);

        yield put({
            type: bundleTemplatesActions.STORE_SINGLE_BUNDLE_TEMPLATE_DATA,
            response: updateSingleBundleTemplateAPIDto,
            iri: updateSingleBundleTemplateAPIDto?.iri
        });
        */

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: bundleTemplatesActions.UPDATE_SINGLE_BUNDLE_TEMPLATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const bundleTemplatesSaga = [
    takeLatest(bundleTemplatesActions.START_CREATE_BUNDLE_TEMPLATE, createBundleTemplate),
    takeLatest(
        bundleTemplatesActions.START_FETCHING_SINGLE_BUNDLE_TEMPLATE,
        fetchSingleBundleTemplate
    ),
    takeLatest(
        bundleTemplatesActions.START_UPDATE_SINGLE_BUNDLE_TEMPLATE,
        updateSingleBundleTemplate
    )
];

export default bundleTemplatesSaga;
