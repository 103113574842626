export const actions = {
    START_FETCHING_PROP_TYPES: 'START_FETCHING_PROP_TYPES',
    FETCHING_SUCCESSFULL_PROP_TYPES: 'FECTHING_SUCCESSFUL_PROP_TYPES',
    FETCHING_FAILED_PROP_TYPES: 'FETCHING_FAILED_PROP_TYPES',

    START_CREATE_PROP: 'START_CREATE_PROP',
    CREATE_PROP_SUCCESSFUL: 'CREATE_PROP_SUCCESSFUL',
    CREATE_PROP_FAILED: 'CREATE_PROP_FAILED'
};

export const initialState = {
    propTypes: [],
    fetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PROP_TYPES: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_SUCCESSFULL_PROP_TYPES: {
            return {
                ...state,
                propTypes: response,
                fetching: false
            };
        }
        case actions.FETCHING_FAILED_PROP_TYPES: {
            return {
                ...state,
                fetching: false
            };
        }
        default:
            return state;
    }
};
