import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as companyActions } from './Companies.reducer';

const companyListingIncludeString = 'include=country,city';

/**
 * Delete Single Company
 * @param  {Object} id ID of a Company
 * @return {Object} Response from API
 */
export function* deleteSingleCompany({ promise, iri }) {
    try {
        const deleteSingleCompanyAPI = yield restClient.delete(iri);
        yield put({
            type: companyActions.DELETE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCompanyAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'companies' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'COMPANIES',
            name: 'companies',
            endpoint: `api/companies?${companyListingIncludeString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyActions.DELETE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Company
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createCompany({ promise, formData }) {
    try {
        const createCompanyAPI = yield restClient.post(`/api/companies`, formData);
        yield put({
            type: companyActions.CREATE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCompanyAPI?.data
        });
        yield call(promise.resolve, createCompanyAPI?.data);
    } catch (error) {
        yield put({
            type: companyActions.CREATE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Company
 * @param  {Object} promise
 * @param {string} company Iri
 * @return {Object} response from API
 */
export function* fetchSingleCompany({ promise, iri, params }) {
    try {
        const fetchSingleCompanyAPI = yield restClient.get(iri, { params });
        yield put({
            type: companyActions.FETCH_COMPANY_SUCCESSFUL
        });
        yield put({
            type: companyActions.STORE_COMPANY_DATA,
            iri,
            response: dto(fetchSingleCompanyAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyActions.FETCH_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Company data
 * @param  {Object} id ID of an User
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleCompany({ promise, formData, iri }) {
    try {
        const updateSingleCompanyAPI = yield restClient.put(iri, formData);
        yield put({
            type: companyActions.UPDATE_COMPANY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleCompanyAPI?.data
        });
        yield put({
            type: companyActions.STORE_COMPANY_DATA,
            iri,
            response: dto(updateSingleCompanyAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyActions.UPDATE_COMPANY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Bulk Actions - Delete Company
 * @param  {Object} iri of the companies
 * @return {Object} Response from API
 */
export function* deleteCompaniesBulkAction({ promise, formData }) {
    try {
        formData.method = 'DELETE';
        yield restClient.post('api/batch-requests', formData);
        yield put({
            type: companyActions.BULK_ACTIONS_DELETE_COMPANIES_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'companies.itemSuccessfullyRemoved' }
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'companies'
        });

        const params = yield select(getQueryParams, { name: 'companies' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'COMPANIES',
            name: 'companies',
            endpoint: `api/companies?${companyListingIncludeString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: companyActions.BULK_ACTIONS_DELETE_COMPANIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const companiesSaga = [
    takeLatest(companyActions.START_DELETE_COMPANY, deleteSingleCompany),
    takeLatest(companyActions.START_CREATE_COMPANY, createCompany),
    takeLatest(companyActions.START_FETCHING_COMPANY, fetchSingleCompany),
    takeLatest(companyActions.START_UPDATE_COMPANY, updateSingleCompany),
    takeLatest(companyActions.START_BULK_ACTIONS_DELETE_COMPANIES, deleteCompaniesBulkAction)
];
