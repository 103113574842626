import React from 'react';
import PropTypes from 'prop-types';
import { generateKey } from 'erpcore/utils/utils';
import Radio from '../Radio';

import styles from './RadioGroup.module.scss';

const RadioGroup = ({ input, meta, fieldProps, fieldAttr, variation }) => {
    if ('options' in fieldProps && Array.isArray(fieldProps.options)) {
        const { options } = fieldProps;
        const { id, disabled, required } = fieldAttr;

        return (
            <div
                className={`${styles['input-radio-group']}${
                    variation ? ` ${styles[`input-radio-group--${variation}-label`]}` : ''
                }${required === true ? ` ${styles[`input-radio-group--required`]}` : ''}`}
            >
                {fieldProps.label && (
                    <label htmlFor={id} className={`${styles['input-radio-group__label']}`}>
                        <span className={`${styles['input-radio-group__label-text']}`}>
                            {fieldProps.label}
                        </span>
                    </label>
                )}
                <div className={`${styles['input-radio-group__content']}`}>
                    {options.map(item => (
                        <Radio
                            input={input}
                            meta={meta}
                            fieldProps={item}
                            fieldAttr={{
                                id,
                                disabled: item?.disabled || disabled
                            }}
                            key={generateKey(input.name + item.value)}
                            isGroup
                        />
                    ))}
                </div>
                {fieldProps.helperText && (
                    <span className={`${styles['input-radio-group__helper-text']}`}>
                        {fieldProps.helperText}
                    </span>
                )}
                {meta.touched && meta.error && (
                    <span className={`${styles['input-radio-group__helper-text--error']}`}>
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </div>
        );
    }

    return null;
};

RadioGroup.defaultProps = {
    fieldProps: {
        label: '',
        options: []
    },
    fieldAttr: {
        disabled: false
    },
    input: {},
    meta: {},
    variation: 'default'
};

RadioGroup.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    variation: PropTypes.oneOf(['default', 'inline', 'block'])
};

export default RadioGroup;
