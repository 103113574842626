import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import style from './DateRangePresets.module.scss';

const DateRangePresets = ({ activePreset, onPresetChange }) => {
    const handlePresetChange = useCallback(
        (event) => {
            onPresetChange(event?.target?.value);
        },
        [onPresetChange]
    );

    return (
        <div className={`${style.parent} datepicker-presets`}>
            <select onChange={handlePresetChange} className value={activePreset}>
                <option value="">Date range preset</option>
                {/* <option value="last365Days">Last 365 Days</option> */}
                <option value="currentWeek">This week</option>
                <option value="currentMonth">This month</option>
                {/* <option value="currentYear">Current Year</option> */}
                {/* <option value="startOfWeek">From Start of the Week</option> */}
                {/* <option value="startOfMonth">From Start of the Month</option> */}
                {/* <option value="startOfYear">From Start of the Year</option> */}
                {/* <option value="endOfWeek">Till End of the Week</option> */}
                {/* <option value="endOfMonth">Till End of the Month</option> */}
                {/* <option value="endOfYear">Till End of the Year</option> */}
                <option value="previousWeek">Last week</option>
                <option value="previousMonth">Last month</option>
                {/* <option value="previousYear">Previous Year</option> */}
                <option value="last7Days">Last 7 days</option>
                <option value="last30Days">Last 30 days</option>
                {/* <option value="allTime">All time</option> */}
            </select>
        </div>
    );
};
DateRangePresets.defaultProps = {
    activePreset: '',
    onPresetChange: () => {}
};
DateRangePresets.propTypes = {
    activePreset: PropTypes.string,
    onPresetChange: PropTypes.func
};

export default memo(DateRangePresets);
