import React from 'react';
import PropTypes from 'prop-types';
import { dtoForm } from 'erpcore/utils/dto';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Form, { GeneratePassword, Hidden } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

let ChangePasswordForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <GeneratePassword
                name="new_password"
                initialPasswordVisible={false}
                isRequired
                isConfirmationRequired
                displayOldPassword
            />
            <Form.Row>
                <Field name="id" id="id" component={Hidden} />
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Update password"
                />
            </Form.Row>
        </Form>
    );
};

ChangePasswordForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {}
};

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

// Order of this steps is mandatory to have initialValues working
ChangePasswordForm = reduxForm({
    form: 'ChangePasswordForm',
    enableReinitialize: true
})(ChangePasswordForm);

//  Getting initial value populated in the form from the store
const mapStateToProps = (state) => ({
    initialValues: dtoForm(getMeData(state))
});

export default connect(mapStateToProps)(ChangePasswordForm);
