const userActiveStatusForm = [
    {
        label: 'Active',
        value: true
    },
    {
        label: 'Inactive',
        value: false
    }
];

/*  We can't reuse the above active status when using filters because 
    BE returns base64 encoded values as "true" and "false"  (not proper booleans, but strings)
*/
const userActiveStatusFilters = [
    {
        label: 'Active',
        value: 'true'
    },
    {
        label: 'Inactive',
        value: 'false'
    }
];

const statusBadges = {
    active: {
        type: 'apple',
        label: 'Active'
    },
    inactive: {
        type: 'tomato',
        label: 'Inactive'
    }
};

const userType = [
    {
        label: 'Staff',
        value: 'staff',
        id: 'staff'
    }
];

const gender = [
    {
        label: 'Male',
        value: 'male',
        id: 'male'
    },
    {
        label: 'Female',
        value: 'female',
        id: 'female'
    }
];

const tShirtSize = [
    {
        label: 'XS',
        value: 'XS'
    },
    {
        label: 'S',
        value: 'S'
    },
    {
        label: 'M',
        value: 'M'
    },
    {
        label: 'L',
        value: 'L'
    },
    {
        label: 'XL',
        value: 'XL'
    },
    {
        label: 'XXL',
        value: 'XXL'
    },
    {
        label: 'XXXL',
        value: 'XXXL'
    }
];

const employmentType = [
    {
        label: 'Full-time',
        value: 'Full-time'
    },
    {
        label: 'Part-time',
        value: 'Part-time'
    },
    {
        label: 'Contractor',
        value: 'Contractor'
    }
];

export {
    userActiveStatusFilters,
    userActiveStatusForm,
    statusBadges,
    userType,
    gender,
    tShirtSize,
    employmentType
};
