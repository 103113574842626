export const actions = {
    START_FETCHING_REPORT_TIME_LOGS: 'START_FETCHING_REPORT_TIME_LOGS',
    FETCHING_SUCCESSFUL_REPORT_TIME_LOGS: 'FETCHING_SUCCESSFUL_REPORT_TIME_LOGS',
    FETCHING_FAILED_REPORT_TIME_LOGS: 'FETCHING_FAILED_REPORT_TIME_LOGS',
    STORE_REPORT_TIME_LOGS_DATA: 'STORE_REPORT_TIME_LOGS_DATA',
    START_FETCHING_REPORT_TIME_LOGS_LISTING: 'START_FETCHING_REPORT_TIME_LOGS_LISTING',
    FETCHING_SUCCESSFUL_REPORT_TIME_LOGS_LISTING: 'FETCHING_SUCCESSFUL_REPORT_TIME_LOGS_LISTING',
    FETCHING_FAILED_REPORT_TIME_LOGS_LISTING: 'FETCHING_FAILED_REPORT_TIME_LOGS_LISTING'
};

export default (state = {}, { type, response, by }) => {
    switch (type) {
        case actions.START_FETCHING_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: true } } }
            };
        }
        case actions.FETCHING_SUCCESSFUL_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false } } }
            };
        }
        case actions.FETCHING_FAILED_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false, error: response } } }
            };
        }
        case actions.STORE_REPORT_TIME_LOGS_DATA: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ data: response } } }
            };
        }
        case actions.START_FETCHING_REPORT_TIME_LOGS_LISTING: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_REPORT_TIME_LOGS_LISTING: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_REPORT_TIME_LOGS_LISTING: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
