import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { put, takeEvery } from 'redux-saga/effects';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as entityLabelActions } from './EntityLabel.reducer';

/**
 * Fetch Entity data
 * @param endpoint
 */
export function* fetchEntityData({ endpoint }) {
    try {
        const entityDataApi = yield restClient.get(endpoint, {
            headers: {
                checkForConcurrentRequests: true,
                concurrentIdentifier: endpoint
            }
        });

        yield put({
            type: entityLabelActions.STORE_ENTITY_DATA,
            endpoint,
            response: dto(entityDataApi?.data)?.data
        });

        yield put({
            type: entityLabelActions.FETCHING_SUCCESSFUL_ENTITY_DATA,
            endpoint
        });
    } catch (error) {
        if (error?.message !== 'requestCanceledForPendingRequest') {
            yield put({
                type: entityLabelActions.FETCHING_FAILED_ENTITY_DATA,
                endpoint
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
        }
    }
}

const EntityLabelSaga = [takeEvery(entityLabelActions.START_FETCHING_ENTITY_DATA, fetchEntityData)];

export default EntityLabelSaga;
