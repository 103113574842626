import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Input from '../Input';
import './ColorPicker.scss';

const ColorPicker = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const [colorValues, setColorValue] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const { value } = { ...input };
    const { noLightColors } = { ...fieldProps };

    const isTooLight = color => {
        if (!color) {
            return false;
        }

        // Check the format of the color, HEX or RGB?

        // If HEX --> store the red, green, blue values in separate variables
        const something = color
            .replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
            )
            .substring(1)
            .match(/.{2}/g)
            .map(x => parseInt(x, 16));

        // eslint-disable-next-line prefer-destructuring
        const r = something[0];
        // eslint-disable-next-line prefer-destructuring
        const g = something[1];
        // eslint-disable-next-line prefer-destructuring
        const b = something[2];

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        // Using the HSP value, determine whether the color is light or dark
        return hsp > 200;
    };

    useEffect(() => {
        if (value && value !== colorValues) {
            setColorValue(value);
        }
    }, [value]);

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <div className="color-picker">
                <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className="color-picker__trigger"
                >
                    <div
                        className="color-picker__selected-color"
                        style={{ backgroundColor: value }}
                    >
                        {value}
                    </div>
                </button>
                {!!open && (
                    <div className="color-picker__picker">
                        <button
                            type="button"
                            className="color-picker__cover"
                            onClick={() => setOpen(!open)}
                        />{' '}
                        <ChromePicker
                            disableAlpha
                            color={colorValues}
                            style={{ paddingBottom: '15px' }}
                            onChangeComplete={color => {
                                if (noLightColors && isTooLight(color?.hex)) {
                                    setError('Color is too light, try a darker one.');
                                } else {
                                    setError(null);
                                    input.onChange(color?.hex);
                                }

                                setColorValue(color?.hex);
                            }}
                        />
                        {error && <p className="color--red chrome-picker--error">{error}</p>}
                    </div>
                )}
            </div>
        </Input>
    );
};

ColorPicker.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

ColorPicker.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ColorPicker;
