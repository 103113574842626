import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - project milestones Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const projectApprovals = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'approval.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project approval successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'approval.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project approval successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'approval.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project approval successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'approvals.accepted':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Approval successfully accepted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'approvals.changeRequested':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Change request sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'approvalsTransitions.canNot.to_change_requested':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Waiting for an admin to submit changes!"
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

projectApprovals.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

projectApprovals.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default projectApprovals;
