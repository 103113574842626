export const actions = {
    START_FETCHING_ROLES: 'START_FETCHING_ROLES',
    FETCHING_SUCCESSFUL_ROLES: 'FETCHING_SUCCESSFUL_ROLES',
    FETCHING_FAILED_ROLES: 'FETCHING_FAILED_ROLES',

    START_CREATE_ROLE: 'START_CREATE_ROLE',
    CREATE_ROLE_SUCCESFUL: 'CREATE_ROLE_SUCCESFUL',
    CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',
    START_UPDATE_ROLE: 'START_UPDATE_ROLE',
    UPDATE_ROLE_SUCCESSFUL: 'UPDATE_ROLE_SUCCESSFUL',
    UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',
    START_FETCHING_ROLE: 'START_FETCHING_ROLE',
    FETCHING_ROLE_SUCCESSFUL: 'FETCHING_ROLE_SUCCESSFUL',
    FETCHING_ROLE_FAILED: 'FETCHING_ROLE_FAILED',
    STORE_ROLE_DATA: 'STORE_ROLE_DATA',
    START_DELETE_ROLE: 'START_DELETE_ROLE',
    DELETE_ROLE_SUCCESSFUL: 'DELETE_ROLE_SUCCESSFUL',
    DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',
    // Users
    START_ADD_ROLE_TO_USERS: 'START_ADD_ROLE_TO_USERS',
    ADD_ROLE_TO_USERS_SUCCESSFUL: 'ADD_ROLE_TO_USERS_SUCCESSFUL',
    ADD_ROLE_TO_USERS_FAILED: 'ADD_ROLE_TO_USERS_FAILED',
    START_REMOVE_ROLE_FROM_USER: 'START_REMOVE_ROLE_FROM_USER',
    REMOVE_ROLE_FROM_USER_SUCCESSFUL: 'REMOVE_ROLE_FROM_USER_SUCCESSFUL',
    REMOVE_ROLE_FROM_USER_FAILED: 'REMOVE_ROLE_FROM_USER_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_ROLES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ROLES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_ROLES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_ROLE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_ROLE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_ROLE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_ROLE_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
