import Sidebar from 'erpcore/components/Layout/Sidebar';
import Topbar from 'erpcore/components/Layout/Topbar';
import BottomBar from 'erpcore/components/Layout/BottomBar';
import MainNavigation from 'erpcore/components/Layout/MainNavigation';
import Main from 'erpcore/components/Layout/Main';
import TimeTrackingWidgetDropdown from 'erpcore/components/TimeTracking/components/TimeTrackingWidgetDropdown';
import PropsWidget from 'erpcore/components/PropsWidget';
import MultiDashboardsWidget from 'erpcore/components/MultiDashboardsWidget';

const defaultLayout = {
    data: [
        {
            slotName: 'topBar',
            component: Topbar,
            position: 0,
            content: {
                data: [
                    {
                        slotName: 'multiDashboards',
                        component: MultiDashboardsWidget,
                        position: 3
                    },
                    {
                        slotName: 'props',
                        component: PropsWidget,
                        position: 2
                    },
                    {
                        slotName: 'timeLog',
                        component: TimeTrackingWidgetDropdown,
                        position: 1
                    }
                ]
            }
        },
        {
            slotName: 'leftSidebar',
            component: Sidebar,
            position: 0,
            content: {
                data: [
                    {
                        slotName: 'mainNavigation',
                        component: MainNavigation,
                        position: 1
                    }
                ]
            }
        },
        {
            slotName: 'main',
            component: Main,
            position: 0
        },
        {
            slotName: 'bottomBar',
            component: BottomBar,
            position: 0,
            content: {
                data: [
                    {
                        slotName: 'multiDashboards',
                        component: MultiDashboardsWidget,
                        position: 1
                    },
                    {
                        slotName: 'props',
                        component: PropsWidget,
                        position: 2
                    },
                    {
                        slotName: 'timeLog',
                        component: TimeTrackingWidgetDropdown,
                        position: 3
                    }
                ]
            }
        }
    ]
};

export default defaultLayout;
