import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationManager from 'erpcore/utils/NotificationManager';
import {
    getPageNotifications,
    getPermanentPageNotifications
} from 'erpcore/utils/NotificationManager/NotificationManager.selectors';
import './PageNotifications.scss';

const PageNotifications = ({ className = '' }) => {
    const permanentPageNotifications = useSelector(getPermanentPageNotifications);
    const pageNotifications = useSelector(getPageNotifications);

    if (
        (permanentPageNotifications && permanentPageNotifications.length) ||
        (pageNotifications && pageNotifications.length)
    ) {
        return (
            <div className={`page-notifications ${className || ''}`}>
                {[...permanentPageNotifications, ...pageNotifications].map(notification => {
                    return (
                        <NotificationManager
                            code={notification.code}
                            key={notification.identifier}
                            identifier={notification.identifier}
                            detail={notification.detail}
                            notificationData={notification.notificationData}
                        />
                    );
                })}
            </div>
        );
    }

    return null;
};

PageNotifications.defaultProps = {
    className: ''
};

PageNotifications.propTypes = {
    className: PropTypes.string
};

export default PageNotifications;
