export const actions = {
    START_CREATE_DRAFT_TIMELOG: 'START_CREATE_DRAFT_TIMELOG',
    CREATE_DRAFT_TIMELOG_SUCCESSFUL: 'CREATE_DRAFT_TIMELOG_SUCCESSFUL',
    CREATE_DRAFT_TIMELOG_FAILED: 'CREATE_DRAFT_TIMELOG_FAILED',

    START_FETCHING_DRAFT_TIMELOGS: 'START_FETCHING_DRAFT_TIMELOGS',
    FETCHING_DRAFT_TIMELOGS_SUCCESSFUL: 'FETCHING_DRAFT_TIMELOGS_SUCCESSFUL',
    FETCHING_DRAFT_TIMELOGS_FAILED: 'FETCHING_DRAFT_TIMELOGS_FAILED',

    START_FETCHING_SUBMITTED_TIMELOGS: 'START_FETCHING_SUBMITTED_TIMELOGS',
    FETCHING_SUBMITTED_TIMELOGS_SUCCESSFUL: 'FETCHING_SUBMITTED_TIMELOGS_SUCCESSFUL',
    FETCHING_SUBMITTED_TIMELOGS_FAILED: 'FETCHING_SUBMITTED_TIMELOGS_FAILED',

    START_UPDATE_TIMELOG: 'START_UPDATE_TIMELOG',
    UPDATE_SUCCESSFUL_TIMELOG: 'UPDATE_SUCCESSFUL_TIMELOG',
    UPDATE_FAILED_TIMELOG: 'UPDATE_FAILED_TIMELOG',

    START_DELETE_DRAFT_TIMELOG: 'START_DELETE_DRAFT_TIMELOG',
    DELETE_DRAFT_TIMELOG_SUCCESSFUL: 'DELETE_DRAFT_TIMELOG_SUCCESSFUL',
    DELETE_DRAFT_TIMELOG_FAILED: 'DELETE_DRAFT_TIMELOG_FAILED',

    START_DELETE_SUBMITTED_TIMELOG: 'START_DELETE_SUBMITTED_TIMELOG',
    DELETE_SUBMITTED_TIMELOG_SUCCESSFUL: 'DELETE_SUBMITTED_TIMELOG_SUCCESSFUL',
    DELETE_SUBMITTED_TIMELOG_FAILED: 'DELETE_SUBMITTED_TIMELOG_FAILED',

    START_SUBMIT_DRAFT_TIMELOG: 'START_SUBMIT_DRAFT_TIMELOG',
    SUBMIT_DRAFT_TIMELOG_SUCCESSFUL: 'SUBMIT_DRAFT_TIMELOG_SUCCESSFUL',
    SUBMIT_DRAFT_TIMELOG_FAILED: 'SUBMIT_DRAFT_TIMELOG_FAILED',

    START_UPDATE_AVAILABLE_TIME: 'START_UPDATE_AVAILABLE_TIME',
    UPDATE_AVAILABLE_TIME_SUCCESSFUL: 'UPDATE_AVAILABLE_TIME_SUCCESSFUL',
    UPDATE_AVAILABLE_TIME_FAILED: 'UPDATE_AVAILABLE_TIME_FAILED',

    OPEN_TIME_TRACKING_WIDGET_DROPDOWN: 'OPEN_TIME_TRACKING_WIDGET_DROPDOWN'
};

export const initialState = {
    draftTimelogs: [],
    submittedTimelogs: [],
    fetchingDraftTimelogs: false,
    fetchingSubmittedTimelogs: false,
    meta: null,
    isTimeTrackingWidgetDropdownOpen: false
};

export default (state = initialState, { type, response, iri, meta }) => {
    switch (type) {
        case actions.SUBMIT_DRAFT_TIMELOG_SUCCESSFUL: {
            return {
                ...state,
                draftTimelogs: state?.draftTimelogs?.filter((item) => item?.iri !== iri),
                submittedTimelogs: [response, ...state?.submittedTimelogs]
            };
        }

        case actions.START_FETCHING_SUBMITTED_TIMELOGS: {
            return {
                ...state,
                fetchingSubmittedTimelogs: true
            };
        }

        case actions.FETCHING_SUBMITTED_TIMELOGS_SUCCESSFUL: {
            return {
                ...state,
                fetchingSubmittedTimelogs: false,
                submittedTimelogs: response,
                meta
            };
        }

        case actions.FETCHING_SUBMITTED_TIMELOGS_FAILED: {
            return {
                ...state,
                fetchingSubmittedTimelogs: false
            };
        }

        case actions.UPDATE_SUCCESSFUL_TIMELOG: {
            return {
                ...state,
                draftTimelogs: state?.draftTimelogs?.reduce((acc, curr) => {
                    if (curr?.iri === response?.iri) {
                        acc.push(response);
                    } else {
                        acc.push(curr);
                    }

                    return acc;
                }, []),
                submittedTimelogs: state?.submittedTimelogs?.reduce((acc, curr) => {
                    if (curr?.iri === response?.iri) {
                        acc.push(response);
                    } else {
                        acc.push(curr);
                    }

                    return acc;
                }, [])
            };
        }
        case actions.CREATE_DRAFT_TIMELOG_SUCCESSFUL: {
            return {
                ...state,
                draftTimelogs: [response, ...state?.draftTimelogs]
            };
        }
        case actions.START_FETCHING_DRAFT_TIMELOGS: {
            return {
                ...state,
                fetchingDraftTimelogs: true
            };
        }

        case actions.DELETE_DRAFT_TIMELOG_SUCCESSFUL: {
            return {
                ...state,
                draftTimelogs: state?.draftTimelogs?.filter((item) => item?.iri !== iri)
            };
        }

        case actions.DELETE_SUBMITTED_TIMELOG_SUCCESSFUL: {
            return {
                ...state,
                submittedTimelogs: state?.submittedTimelogs?.filter((item) => item?.iri !== iri)
            };
        }

        case actions.FETCHING_DRAFT_TIMELOGS_SUCCESSFUL: {
            return {
                ...state,
                fetchingDraftTimelogs: false,
                draftTimelogs: response
            };
        }

        case actions.FETCHING_DRAFT_TIMELOGS_FAILED: {
            return {
                ...state,
                fetchingDraftTimelogs: false
            };
        }

        case actions.UPDATE_AVAILABLE_TIME_SUCCESSFUL: {
            const timeAvailables = response?.timeAvailable?.reduce((acc, item) => {
                acc[item?.workType] = item;

                return acc;
            }, {});

            return {
                ...state,
                draftTimelogs:
                    state?.draftTimelogs?.map((item) => {
                        if (
                            timeAvailables[item?.work_type?.iri] &&
                            response?.iri === item?.stage?.iri
                        ) {
                            return {
                                ...item,
                                time_available: timeAvailables[item?.work_type?.iri]?.timeAvailable
                            };
                        }

                        return item;
                    }) || [],
                submittedTimelogs:
                    state?.submittedTimelogs?.map((item) => {
                        if (
                            timeAvailables[item?.work_type?.iri] &&
                            response?.iri === item?.stage?.iri
                        ) {
                            return {
                                ...item,
                                time_available: timeAvailables[item?.work_type?.iri]?.timeAvailable
                            };
                        }

                        return item;
                    }) || []
            };
        }
        case actions.OPEN_TIME_TRACKING_WIDGET_DROPDOWN: {
            return {
                ...state,
                isTimeTrackingWidgetDropdownOpen: response
            };
        }

        default:
            return state;
    }
};
