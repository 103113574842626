import React from 'react';
import Svg from 'erpcore/components/Svg';
import PropTypes from 'prop-types';
import { Field, blur, focus, touch } from 'redux-form';
import { Text } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { days, parseDecimal } from 'erpcore/utils/utils';
import style from './WorkingDays.module.scss';

const WorkingDays = ({ input, meta, children }) => {
    const { value } = { ...input };
    return (
        <div className={style['working-days']}>
            <div className={style['working-days__fields']}>
                {days.map((day) => (
                    <div className={style['working-days__day']} key={day}>
                        <Button
                            size="small"
                            label={day}
                            variation={!value[day] ? 'secondary' : 'primary'}
                            onClick={() => {
                                if (!value[day]) {
                                    meta.dispatch(blur(meta?.form, `${input?.name}.${day}`, 8));
                                } else {
                                    return meta.dispatch(
                                        blur(meta?.form, `${input?.name}.${day}`, 0)
                                    );
                                }
                                meta.dispatch(focus(meta?.form, `${input?.name}.${day}`));
                                setTimeout(() => {
                                    const inputElement = document.getElementById(
                                        `${input?.name}.${day}`
                                    );
                                    if (inputElement) {
                                        inputElement.setAttribute('autocomplete', 'off');
                                        inputElement.focus();
                                    }
                                }, 50);
                                return true;
                            }}
                        />
                        {(value[day] || value[day] === '' || meta?.[day]?.active) && (
                            <Field
                                name={`${input?.name}.${day}`}
                                fieldAttr={{ id: `${input?.name}.${day}` }}
                                fieldProps={{
                                    clearable: true,
                                    errorAsTooltip: true
                                }}
                                parse={(fieldValue) => {
                                    if (fieldValue === '0') return 0;
                                    return parseDecimal(fieldValue);
                                }}
                                component={Text}
                                validate={valueValidation([
                                    { validator: 'required' },
                                    {
                                        validator: 'numericality',
                                        args: {
                                            '>': 0,
                                            '<': 25,
                                            msg: 'Limited to numbers 0-24, with intervals of .5 allowed'
                                        }
                                    },
                                    {
                                        validator: 'format',
                                        args: {
                                            with: /^((([01]?[0-9]|2[0-3]))(\.[05])?)$/i,
                                            message:
                                                'Limited to numbers 0-24, with intervals of .5 allowed'
                                        }
                                    }
                                ])}
                                onBlur={(event, newValue, previousValue, name) => {
                                    if (newValue === '') {
                                        event.preventDefault();
                                        meta.dispatch(blur(meta?.form, name, meta?.initial?.[day]));
                                    }
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue === '') {
                                        meta.dispatch(touch(meta?.form, `${input?.name}.${day}`));
                                    }
                                }}
                            />
                        )}
                        {value[day] === 0 && !meta?.[day]?.active && (
                            <div className={style['working-days__non-working-day']}>
                                <Svg icon="close" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {children && <div className={style['working-days__children']}>{children}</div>}
        </div>
    );
};

WorkingDays.defaultProps = {
    input: {},
    meta: {},
    children: null
};

WorkingDays.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
};

export default WorkingDays;
