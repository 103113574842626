import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import './ImpersonatedUserCard.scss';
import PropTypes from 'prop-types';

const ImpersonatedUserCard = ({ isTooltip }) => {
    const user = useSelector(getMeData);

    return (
        <div className={`impersonated-user ${isTooltip ? 'impersonated-user--is-tooltip' : ''}`}>
            <NavLink to="/my-account" className="impersonated-user__info">
                <div className="impersonated-user__avatar">
                    <Image
                        className="impersonated-user__image"
                        iri={user?.image?.iri}
                        version="small"
                        width={32}
                        height={32}
                        alt="Profile"
                        fallback={<Svg className="impersonated-user__svg" icon="userMirrorIcon" />}
                    />
                </div>
                <div className="impersonated-user__text">
                    <p className="impersonated-user__subtitle">Impersonated as</p>
                    <p className="impersonated-user__name">
                        {user.first_name} {user.last_name}
                    </p>
                </div>
            </NavLink>
            <Button
                className="impersonated-user__button"
                variation="secondary"
                href="/impersonate-stop"
                label="Stop Impersonating"
            />
        </div>
    );
};

ImpersonatedUserCard.defaultProps = {
    isTooltip: false
};

ImpersonatedUserCard.propTypes = {
    isTooltip: PropTypes.bool
};

export default ImpersonatedUserCard;
