import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as forgotPasswordActions } from './ForgotPassword.reducer';

/**
 * Forgot Password Saga
 * @param  {Object} formData (Email)
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* forgotPassword({ formData, promise }) {
    try {
        const forgotPasswordAPI = yield restClient.post('/password-reset', formData);
        yield put({
            type: forgotPasswordActions.FORGOT_PASSWORD_SUCCESSFUL,
            response: { forgotPasswordAPI, ...formData }
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: forgotPasswordAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: forgotPasswordActions.FORGOT_PASSWORD_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Resend Password Email Saga
 * @param  {Object} Email TBD
 * @return {Object} Response from API
 */
export function* resendResetPassword({ email, promise }) {
    try {
        const resendResetPasswordAPI = yield restClient.post('/password-reset', {
            email
        });
        yield put({ type: forgotPasswordActions.RESEND_EMAIL_RESET_PASSWORD_SUCCESSFUL });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: resendResetPasswordAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: forgotPasswordActions.RESEND_EMAIL_RESET_PASSWORD_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Reset Payload Saga
 * @param  {Object} formData
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* resetPassword({ formData, promise }) {
    try {
        const resetPasswordAPI = yield restClient.post('/password-reset-confirmation', formData);
        yield put({
            type: forgotPasswordActions.RESET_PASSWORD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.SET_PERMANENT_PAGE_NOTIFICATION,
            response: resetPasswordAPI.data
        });
        yield call(promise.resolve, resetPasswordAPI.data);
    } catch (error) {
        yield put({
            type: forgotPasswordActions.RESET_PASSWORD_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const forgotPasswordSaga = [
    takeLatest(forgotPasswordActions.START_FORGOT_PASSWORD, forgotPassword),
    takeLatest(forgotPasswordActions.START_RESEND_EMAIL_RESET_PASSWORD, resendResetPassword),
    takeLatest(forgotPasswordActions.START_RESET_PASSWORD, resetPassword)
];
