export const actions = {
    // Listing
    START_FETCHING_TAGS: 'START_FETCHING_TAGS',
    FETCHING_SUCCESSFUL_TAGS: 'FETCHING_SUCCESSFUL_TAGS',
    FETCHING_FAILED_TAGS: 'FETCHING_FAILED_TAGS',
    // Single
    START_FETCHING_TAG: 'START_FETCHING_TAG',
    FETCH_TAG_SUCCESSFUL: 'FETCH_TAG_SUCCESSFUL',
    FETCH_TAG_FAILED: 'FETCH_TAG_FAILED',
    STORE_TAG_DATA: 'STORE_TAG_DATA',
    // Create
    START_CREATE_TAG: 'START_CREATE_TAG',
    CREATE_TAG_SUCCESSFUL: 'CREATE_TAG_SUCCESSFUL',
    CREATE_TAG_FAILED: 'CREATE_TAG_FAILED',
    // Edit
    START_UPDATE_TAG: 'START_UPDATE_TAG',
    UPDATE_TAG_SUCCESSFUL: 'UPDATE_TAG_SUCCESSFUL',
    UPDATE_TAG_FAILED: 'UPDATE_TAG_FAILED',
    // Delete
    START_DELETE_TAG: 'START_DELETE_TAG',
    DELETE_TAG_SUCCESSFUL: 'DELETE_TAG_SUCCESSFUL',
    DELETE_TAG_FAILED: 'DELETE_TAG_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_TAGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TAGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TAGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.STORE_TAG_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        default:
            return state;
    }
};
