import React from 'react';
import PropTypes from 'prop-types';

import styles from './SwitchCard.module.scss';

const SwitchCard = ({ input, meta, fieldProps, fieldAttr, children }) => {
    const { name, value = false, onChange } = { ...input };
    const { label, description } = { ...fieldProps };
    const { id, disabled } = { ...fieldAttr };
    const { touched, error } = { ...meta };
    const cleanChildren = React.Children.toArray(children).filter(o => o) || []; // clean out nullish values

    // console.log(disabled, name);

    return (
        <div className={`${styles['switch-card']} ${disabled && styles['switch-card--disabled']}`}>
            <button
                type="button"
                onClick={() => !disabled && onChange(!value)}
                className={`${styles['switch-card__content']}`}
            >
                <h3 className={`${styles['switch-card__title']}`}>{label}</h3>
                <div className={`${styles['switch-card__action']}`}>
                    <div className={`${styles['switch-card__input']}`}>
                        <span
                            role="button"
                            aria-label={`${label} switch`}
                            tabIndex={0}
                            onClick={() => !disabled && onChange(!value)}
                            onKeyPress={() => !disabled && onChange(!value)}
                            className={`${styles['switch-card__input-toggle']} ${
                                value
                                    ? `${styles['switch-card__input-toggle--active']} ${styles['switch-card__input-toggle--green']}`
                                    : ''
                            }`}
                        />
                        <input
                            id={id}
                            type="checkbox"
                            className={`${styles['switch-card__input-checkbox']}`}
                            aria-label={`${name} field`}
                            defaultChecked={value}
                            name={name}
                            {...fieldAttr}
                        />
                    </div>
                </div>
                {description && (
                    <div className={`${styles['switch-card__description']}`}>{description}</div>
                )}
            </button>
            {value && cleanChildren?.length > 0 && (
                <div className={`${styles['switch-card__children']}`}>{children}</div>
            )}
            {touched && error && (
                <span className={`${styles['switch-card__input-error']}`}>
                    {error.message ? error.message : error}
                </span>
            )}
        </div>
    );
};

SwitchCard.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {
        label: ' ',
        description: null,
        onValue: true,
        offValue: false
    },
    fieldAttr: {},
    children: null
};

SwitchCard.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
        offValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.array
    ])
};
export default SwitchCard;
