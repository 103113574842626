import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - WorkTypes Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const TimeOffTypes = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'timeoff/type.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off type successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timeoff/type.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off type successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timeoff/type.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off type successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

TimeOffTypes.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

TimeOffTypes.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default TimeOffTypes;
