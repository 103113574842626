import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Password } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';

const ResetPasswordForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="new_password"
                    id="new_password"
                    fieldProps={{
                        label: 'New password',
                        clearable: true
                    }}
                    component={Password}
                    validate={valueValidation([
                        { validator: 'length', args: { min: 8 } },
                        { validator: 'required' }
                    ])}
                />
            </Form.Row>
            <Form.Row verticalCenter>
                <Form.RowColResponsive>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label="Confirm"
                    />
                </Form.RowColResponsive>
            </Form.Row>
        </Form>
    );
};

ResetPasswordForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'ResetPasswordForm',
    enableReinitialize: true
})(ResetPasswordForm);
