import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Auth Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const AuthNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'expiredJWT':
            return (
                <Notification
                    identifier={identifier}
                    title="Please sign in!"
                    text="Sorry, your session expired."
                    type="warning"
                    expire={10000}
                />
            );
        case 'invalidJWT':
            return (
                <Notification
                    identifier={identifier}
                    title="Please sign in!"
                    text="Sorry, your session expired."
                    type="warning"
                    expire={10000}
                />
            );
        case 'missingJWT':
            return true;

        // Sign In
        case 'authenticationFailure':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Invalid username or wrong password. Please try again."
                    type="error"
                />
            );
        case 'userNotFound':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="User cannot be found using provided email address!"
                    type="error"
                />
            );

        // Forgot Password
        case 'resetPassword.emailSent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Email with further instructions was sent to your inbox."
                    type="success"
                />
            );
        case 'resetPassword.userNotFound':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="Sorry, we don't have a user with that email address. Have you made a mistake?"
                    type="warning"
                />
            );
        case 'resetPassword.emailSendError':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Something went wrong, please refresh the page and try again."
                    type="error"
                />
            );
        case 'confirmResetPassword.passwordChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Password has been successfully changed."
                    type="success"
                    expire={10000}
                />
            );
        case 'confirmResetPassword.notFound':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="This token has been already used. Please start the process of resetting password from the start."
                    type="error"
                />
            );
        case 'confirmResetPassword.expiredResetToken':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Reset token has expired! Please start the process of resetting password from the start."
                    type="error"
                />
            );

        default:
            return null;
    }
};

AuthNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

AuthNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default AuthNotifications;
