import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Input from '../Input';

const Email = ({ initialPasswordVisible, input, meta, fieldProps, fieldAttr, field }) => {
    const [passwordVisible, setPasswordVisible] = useState(initialPasswordVisible);

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <input
                id={input.name}
                {...input}
                {...fieldAttr}
                aria-label={`${input.name}`}
                type={passwordVisible ? 'text' : 'password'}
                className="input__field"
            />
            <span
                role="button"
                tabIndex={0}
                className="input__action"
                aria-label={`Toggle show ${input.name} field`}
                onKeyPress={() => setPasswordVisible(!passwordVisible)}
                onClick={() => setPasswordVisible(!passwordVisible)}
            >
                <Svg icon={passwordVisible ? 'passHide' : 'passShow'} />
            </span>
        </Input>
    );
};
Email.defaultProps = {
    initialPasswordVisible: false,
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Email.propTypes = {
    initialPasswordVisible: PropTypes.bool,
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Email;
