export const actions = {
    START_FETCHING_PERMISSIONS: 'START_FETCHING_PERMISSIONS',
    FETCHING_SUCCESSFUL_PERMISSIONS: 'FETCHING_SUCCESSFUL_PERMISSIONS',
    FETCHING_FAILED_PERMISSIONS: 'FETCHING_FAILED_PERMISSIONS',

    START_UPDATE_PERMISSION: 'START_UPDATE_PERMISSION',
    UPDATE_PERMISSION_SUCCESSFUL: 'UPDATE_PERMISSION_SUCCESSFUL',
    UPDATE_PERMISSION_FAILED: 'UPDATE_PERMISSION_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PERMISSIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PERMISSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PERMISSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
