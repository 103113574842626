import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as permissionsActions } from 'erpcore/screens/Settings/Permissions/Permissions.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Update Permission data
 * @param  {Object} permissionIri iri of Permission
 * @return {Object} Response from API
 */
export function* updatePermission({ promise, permissionIri, formData }) {
    try {
        yield restClient.patch(permissionIri, formData);
        yield put({
            type: permissionsActions.UPDATE_PERMISSION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'permission.itemSuccesfullyUpdated' }
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: permissionsActions.UPDATE_PERMISSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const permissionsSaga = [takeEvery(permissionsActions.START_UPDATE_PERMISSION, updatePermission)];

export default permissionsSaga;
