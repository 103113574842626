import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as paymentTermsActions } from 'erpcore/screens/Settings/PaymentTerms/PaymentTerms.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Payment Term
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createPaymentTerm({ promise, formData }) {
    try {
        const createPaymentTermAPI = yield restClient.post('api/payment-terms', formData);
        yield put({
            type: paymentTermsActions.CREATE_PAYMENT_TERM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createPaymentTermAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'paymentTerms' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_TERMS',
            name: 'paymentTerms',
            endpoint: 'api/payment-terms?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentTermsActions.CREATE_PAYMENT_TERM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Payment Term data
 * @param  {Object} iri of PaymentTerm
 * @return {Object} Response from API
 */
export function* updatePaymentTerm({ promise, iri, formData }) {
    try {
        const updatePaymentTermAPI = yield restClient.patch(iri, formData);
        yield put({
            type: paymentTermsActions.UPDATE_PAYMENT_TERM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePaymentTermAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'paymentTerms' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_TERMS',
            name: 'paymentTerms',
            endpoint: 'api/payment-terms?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentTermsActions.UPDATE_PAYMENT_TERM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Payment Term
 * @param  {Object} iri of Payment Term
 * @return {Object} Response from API
 */
export function* deletePaymentTerm({ promise, iri }) {
    try {
        const deletePaymentTermAPI = yield restClient.delete(iri);
        yield put({
            type: paymentTermsActions.DELETE_PAYMENT_TERM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deletePaymentTermAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'payment-terms' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_TERMS',
            name: 'paymentTerms',
            endpoint: 'api/payment-terms?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentTermsActions.DELETE_PAYMENT_TERM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const paymentTermsSaga = [
    takeLatest(paymentTermsActions.START_CREATE_PAYMENT_TERM, createPaymentTerm),
    takeLatest(paymentTermsActions.START_UPDATE_PAYMENT_TERM, updatePaymentTerm),
    takeLatest(paymentTermsActions.START_DELETE_PAYMENT_TERM, deletePaymentTerm)
];

export default paymentTermsSaga;
