import restClient from 'erpcore/api/restClient';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { actions as dashboardsListingActions } from 'erpcore/screens/Dashboards/DashboardsListing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Delete Dashboard
 * @param  {Object} id ID of an Dashboard
 */
export function* deleteDashboard({ promise, iri }) {
    try {
        yield restClient.delete(iri);
        yield put({
            type: dashboardsListingActions.DELETE_DASHBOARD_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: 'dashboardsListing' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DASHBOARDS_LISTING',
            name: 'dashboardsListing',
            endpoint: 'api/dashboards?include=owner,users.user,departments.department'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsListingActions.DELETE_DASHBOARD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export default [takeEvery(dashboardsListingActions.START_DELETE_DASHBOARD, deleteDashboard)];
