export const actions = {
    START_FETCHING_TIMELINE_TEMPLATES: 'START_FETCHING_TIMELINE_TEMPLATES',
    FETCHING_SUCCESSFUL_TIMELINE_TEMPLATES: 'FETCHING_SUCCESSFUL_TIMELINE_TEMPLATES',
    FETCHING_FAILED_TIMELINE_TEMPLATES: 'FETCHING_FAILED_TIMELINE_TEMPLATES'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_TIMELINE_TEMPLATES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TIMELINE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TIMELINE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
