import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from 'erpcore/store';
import 'erpcore/assets/scss/global.scss';
import RouterManager from 'erpcore/utils/RouterManager';

const root = ReactDOM.createRoot(document.getElementById('wrapper'));
root.render(
    <PersistGate persistor={persistor}>
        <Provider store={store}>
            <Router>
                <RouterManager />
            </Router>
        </Provider>
    </PersistGate>
);
