import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const ViewImg = ({ input, meta, fieldProps, fieldAttr, field }) => (
    <Input fieldProps={fieldProps} fieldAttr={fieldAttr} field={field} input={input} meta={meta}>
        {input.value ? (
            <div className="input__field input__field--view-image">
                <img src={input.value} className="input__field-image" id={input.name} alt="" />
            </div>
        ) : (
            <p className="input__field input__field--empty" id={input.name}>
                -
            </p>
        )}
    </Input>
);

ViewImg.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

ViewImg.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ViewImg;
