import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import { SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ children, isFeatured, isImage }) => {
    return (
        <div
            className={`gallery__item${isFeatured ? ' gallery__item--featured' : ''} ${
                isImage ? ' gallery__item--is-image' : 'gallery__item--not-image'
            }`}
        >
            {children}
            <div className="gallery__item-move">
                <Svg className="gallery__item-move-icon" icon="move" />
            </div>
        </div>
    );
});

SortableItem.defaultProps = {
    children: null,
    isImage: false,
    isFeatured: false
};
SortableItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    isImage: PropTypes.bool,
    isFeatured: PropTypes.bool
};

export default SortableItem;
