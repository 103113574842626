import React from 'react';
import PropTypes from 'prop-types';
import { generateKey } from 'erpcore/utils/utils';
import { Field, reduxForm } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Form, {
    Text,
    Textarea,
    TextEditor,
    ImageUpload,
    Hidden,
    NumberField,
    Email,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    CurrencyRange,
    Password,
    Switch,
    Select,
    MultiSelect,
    DateTime,
    DateRange,
    Location,
    Autocomplete,
    MultiAutocomplete,
    MultiDatePicker,
    SelectNew
} from 'erpcore/components/Form';

//  List of fields that can be used in Form Generator
export const stringToFormFieldComponent = {
    text: Text,
    email: Email,
    password: Password,
    number: NumberField,
    select: Select,
    multiSelect: MultiSelect,
    radio: Radio,
    radioGroup: RadioGroup,
    checkbox: Checkbox,
    checkboxGroup: CheckboxGroup,
    currencyRange: CurrencyRange,
    switch: Switch,
    hidden: Hidden,
    textarea: Textarea,
    textEditor: TextEditor,
    imageUpload: ImageUpload,
    dateTime: DateTime,
    dateRange: DateRange,
    location: Location,
    autocomplete: Autocomplete,
    multiautocomplete: MultiAutocomplete,
    multiDatePicker: MultiDatePicker,
    SelectNew
};

const FormGenerator = ({ fields, handleSubmit, onSubmit }) => (
    <form onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(fields.properties).map(key => {
            const field = fields.properties[key];
            return (
                <Form.Row key={generateKey(field.component + key)}>
                    <Field
                        name={key}
                        field={field}
                        fieldProps={field.fieldProps}
                        fieldAttr={field.fieldAttr}
                        component={stringToFormFieldComponent[field.component]}
                        validate={valueValidation(field.fieldValidation)}
                    />
                </Form.Row>
            );
        })}
    </form>
);

FormGenerator.defaultProps = {
    fields: {}
};

FormGenerator.propTypes = {
    fields: PropTypes.oneOfType([PropTypes.object]),
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    enableReinitialize: true
})(FormGenerator);
