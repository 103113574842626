import React from 'react';
import './MultiDatePicker.scss';
import 'react-dates/initialize';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import PropTypes from 'prop-types';
import Input from 'erpcore/components/Form/components/Input';
import { formatDate } from 'erpcore/utils/utils';

class MultiDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dates: [],
            calendarFocused: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextValue = !Array.isArray(nextProps.input.value) ? [] : nextProps.input.value;

        // This is used for edit initial values purpose, links props to state and if props passed to state is bigger then previous state, means that initialValues are sent //
        if (nextValue.length > prevState.dates.length + 1) {
            return {
                dates: nextValue
            };
        }

        // state is always one step ahead of props in this method, this condition is used to to reset field after form submittion ( set state to empty array when ) //
        if (nextValue.length < prevState.dates.length - 1) {
            return {
                dates: []
            };
        }
        return false;
    }

    handleDateChange = date => {
        const { dates } = this.state;

        // Check if Date exists in date array //
        const isPresent = dates.some(
            el => formatDate(el, 'YYYY-MM-DD') === formatDate(date, 'YYYY-MM-DD')
        );
        const newDates = isPresent
            ? dates.filter(d => {
                  return formatDate(date, 'YYYY-MM-DD') !== formatDate(d, 'YYYY-MM-DD');
              })
            : [...dates, date];

        this.setState(
            {
                dates: newDates
            },
            () => {
                this.setValues();
            }
        );
    };

    // Push values to Redux form Input value //
    setValues = () => {
        const { input, fieldProps } = this.props;
        const { dates } = this.state;

        if (fieldProps.format) {
            const formatedDates = dates.map(el => formatDate(el, fieldProps.format));
            return input.onChange(formatedDates);
        }

        return input.onChange(dates);
    };

    render() {
        const { fieldProps, fieldAttr, field, input, meta } = this.props;
        const { dates, calendarFocused } = this.state;

        return (
            <div className="multiday-picker">
                <Input
                    fieldProps={fieldProps}
                    fieldAttr={fieldAttr}
                    field={field}
                    input={input}
                    meta={meta}
                >
                    <SingleDatePicker
                        hideKeyboardShortcutsPanel
                        numberOfMonths={2}
                        {...fieldProps}
                        readOnly
                        withFullScreenPortal
                        placeholder=""
                        block
                        onDateChange={date => {
                            this.handleDateChange(date);
                        }}
                        focused={calendarFocused}
                        onFocusChange={() => {
                            this.setState({ calendarFocused: !calendarFocused });
                        }}
                        keepOpenOnDateSelect // highly important
                        isDayHighlighted={d1 => dates.some(d2 => formatDate(d1) === formatDate(d2))}
                    />
                    {dates instanceof Array && dates.length > 0 && (
                        <ul className="multiday-picker__tags">
                            {dates.map(d => (
                                <div key={d} className="multiday-picker__tags__item">
                                    {formatDate(d, 'DD/MM/YYYY')}
                                </div>
                            ))}
                        </ul>
                    )}
                </Input>
            </div>
        );
    }
}

MultiDatePicker.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
MultiDatePicker.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default MultiDatePicker;
