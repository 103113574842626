import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMeData, isUserClient } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import colors from 'erpcore/assets/scss/exports/colors.module.scss';
import { getBrandingSettings } from 'erpcore/utils/BrandingManager/BrandingManager.selectors';
import { getCompanyData } from 'erpcore/screens/Companies/Companies.selectors';
import { useLocation } from 'react-router-dom';
import Favicon from 'erpcore/components/Favicon';
import tinycolor from 'tinycolor2';

const BrandingManager = () => {
    const location = useLocation();
    const meData = useSelector(getMeData);
    const branding = useSelector(getBrandingSettings);

    const companyID = useMemo(() => {
        const locationData = location.pathname.split('/');
        if (locationData[1] === 'client') return locationData[2];
        return null;
    }, [location.pathname]);

    const companyData = useSelector((state) =>
        getCompanyData(state, `/api/companies/${companyID}`)
    );
    const isClient = useSelector(isUserClient) || companyID;

    const formatedCompanyData = useMemo(() => {
        return {
            color: meData?.company?.meta?.color || companyData?.meta?.color
        };
    }, [meData, companyData]);

    const minifyCssString = useCallback((css) => {
        return css.replace(/\n/g, '').replace(/\s\s+/g, ' ');
    }, []);

    const getAlphaShades = useCallback((color, alphaShades = []) => {
        return alphaShades.map((alpha) => {
            return {
                alphaName: `${alpha}`.replace('.', ''),
                color: tinycolor(color).setAlpha(alpha).toRgbString()
            };
        });
    }, []);

    const primaryColorAlphaShadesCss = useMemo(() => {
        return getAlphaShades(
            formatedCompanyData?.color || branding?.settings?.primary_color || colors.bordo,
            [0.13, 0.25]
        ).reduce((accumulator, { alphaName, color }) => {
            return `${accumulator} --primary-color-alpha-${alphaName}: ${color};`;
        }, '');
    }, [formatedCompanyData?.color, branding?.settings?.primary_color]);

    return (
        <>
            <Favicon iri={branding?.settings?.favicon} />
            <style
                dangerouslySetInnerHTML={{
                    __html: minifyCssString(`
                        body {                         
                            --primary-color: ${
                                formatedCompanyData?.color ||
                                (!isClient && branding?.settings?.primary_color) ||
                                colors.purple
                            };
                            --hover-color:  ${
                                (!isClient && branding?.settings?.hover_color) || colors.cyan
                            };
                            --primary-color-darker: ${
                                formatedCompanyData?.color || branding?.settings?.primary_color
                                    ? tinycolor(
                                          formatedCompanyData?.color ||
                                              branding?.settings?.primary_color
                                      )
                                          .darken(30)
                                          .toString()
                                    : colors.cyan
                            };
                             --primary-color-lighter: ${
                                 formatedCompanyData?.color || branding?.settings?.primary_color
                                     ? tinycolor(
                                           formatedCompanyData?.color ||
                                               branding?.settings?.primary_color
                                       )
                                           .lighten(30)
                                           .toString()
                                     : colors.cyan
                             };
                             ${primaryColorAlphaShadesCss}
                            --sidebar-color: ${
                                formatedCompanyData?.color ||
                                (!isClient && branding?.settings?.sidebar_color) ||
                                (isClient ? colors['mid-grey'] : colors.purple)
                            };
                            --chart-color: ${
                                formatedCompanyData?.color ||
                                (!isClient && branding?.settings?.sidebar_color) ||
                                (isClient ? colors['mid-grey'] : colors.purple)
                            };
                            --chart-color-lighter: ${
                                formatedCompanyData?.color ||
                                (!isClient &&
                                    tinycolor(
                                        formatedCompanyData?.color ||
                                            branding?.settings?.sidebar_color
                                    )
                                        .lighten(20)
                                        .toString()) ||
                                (isClient ? colors['mid-grey'] : colors.purple)
                            };
                    };
                        }                   
                    `)
                }}
            />
        </>
    );
};

export default React.memo(BrandingManager);
