import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as propsWidgetActions } from 'erpcore/components/PropsWidget/PropsWidget.reducer';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 *  Fetch prop types
 * @param promise
 * @param iri
 * @returns Response from API
 */
export function* fetchPropTypes({ promise, params }) {
    try {
        const fetchTimeLogEntryAPI = yield restClient.get('api/prop/types', { params });
        yield put({
            type: propsWidgetActions.FETCHING_SUCCESSFULL_PROP_TYPES,
            response: dto(fetchTimeLogEntryAPI?.data)?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsWidgetActions.FETCHING_FAILED_PROP_TYPES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Prop
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProp({ promise, formData }) {
    try {
        const createPropAPI = yield restClient.post(`/api/props`, formData);
        yield put({
            type: propsWidgetActions.CREATE_PROP_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createPropAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsWidgetActions.CREATE_PROP_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const propsWidgetSaga = [
    takeLatest(propsWidgetActions.START_FETCHING_PROP_TYPES, fetchPropTypes),
    takeLatest(propsWidgetActions.START_CREATE_PROP, createProp)
];

export default propsWidgetSaga;
