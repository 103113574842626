import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { generateKey, getResponsive } from 'erpcore/utils/utils';
import Svg from 'erpcore/components/Svg';
import NotificationManager from 'erpcore/utils/NotificationManager';
import {
    TableHeaderBulkAction,
    TableRowBulkAction
} from 'erpcore/components/Listing/components/BulkActions';
import './Table.scss';
import Tooltip from 'erpcore/components/Tooltip';
import classnames from 'classnames';

const Table = ({ data, loading, reducerName, queryParams, onSortTable }) => {
    const { isMobile } = getResponsive();
    const [mobileOpened, setMobileOpened] = useState([]);
    const [mobileSortDropdown, setMobileSortDropdown] = useState(false);

    //  Removes initial flick of notification on listing screens
    const [initialRender, setInitialRender] = useState(true);
    useEffect(() => {
        setInitialRender(false);
    }, []);

    const prepareSortData = (item) => {
        const { sortable } = item;
        const { defaultSort } = data;
        let activeSortClassName = '';
        if (queryParams.order_by) {
            const sortKey = Object.keys(queryParams.order_by)[0];
            if (sortKey === sortable) {
                activeSortClassName = ' table__sortable--asc';
                if (queryParams.order_by[sortable] === 'DESC') {
                    activeSortClassName = ' table__sortable--desc';
                }
            }
        }
        //  Action triggered when clicked on table heading sorter
        const sortFunction = () => {
            const orderByValue = 'ASC';
            let sortObject = {
                order_by: { [sortable]: orderByValue }
            };
            //  If is filtered by other field toggle it to ASC
            let isSortedByNewField = false;
            if (queryParams.order_by) {
                isSortedByNewField = Object.keys(queryParams.order_by)[0] !== sortable;
                if (
                    (!queryParams.order_by[sortable] || isSortedByNewField) &&
                    orderByValue !== 'ASC'
                ) {
                    sortObject = {
                        order_by: { [sortable]: 'ASC' }
                    };
                } else if (queryParams.order_by[sortable] === 'ASC' && orderByValue !== 'DESC') {
                    sortObject = {
                        order_by: { [sortable]: 'DESC' }
                    };
                } else if (queryParams.order_by[sortable] === 'DESC') {
                    if (defaultSort?.sortable === sortable) {
                        sortObject = {
                            order_by: {
                                [sortable]:
                                    queryParams.order_by[sortable] === 'DESC' ? 'ASC' : 'DESC'
                            }
                        };
                    } else {
                        sortObject = {
                            order_by: ''
                        };
                    }
                }
            }
            onSortTable(sortObject);
        };
        return {
            sortFunction,
            activeSortClassName
        };
    };

    // eslint-disable-next-line react/prop-types
    const getHeaderTooltip = ({ text, icon = 'help', isMobileView = false }) => {
        if (text) {
            return (
                <Tooltip
                    content={text}
                    className={classnames({
                        'table__header-tooltip': true,
                        'table__header-tooltip--mobile': isMobileView
                    })}
                >
                    <Svg className="table__header-tooltip-icon" icon={icon} />
                </Tooltip>
            );
        }

        return null;
    };

    const renderDesktopHeader = () => {
        const { schema, bulkActions } = data;
        if (!schema) return null;
        return (
            <thead className="table__header">
                <tr>
                    {bulkActions && (
                        <th key="bulkActions">
                            <TableHeaderBulkAction reducerName={reducerName} tableData={data} />
                        </th>
                    )}
                    {schema.length &&
                        schema.map(item => {
                            const {
                                title,
                                tooltipText,
                                tooltipIcon,
                                sortable,
                                align,
                                columnSubtitle
                            } = item;
                            const sortData = prepareSortData(item);
                            const tooltip = getHeaderTooltip({
                                text: tooltipText,
                                icon: tooltipIcon
                            });
                            return (
                                <th
                                    className={`table__header-cell${
                                        align ? ` table__header-cell--${align}` : ''
                                    }`}
                                    key={generateKey(title)}
                                >
                                    {sortable ? (
                                        <button
                                            type="button"
                                            className={`table__sortable${sortData.activeSortClassName}`}
                                            onClick={sortData.sortFunction}
                                        >
                                            <span className="table__sortable-label">
                                                {title}
                                                {tooltip}
                                            </span>
                                            <Svg
                                                icon="arrowDown"
                                                className="table__sortable-icon"
                                            />
                                        </button>
                                    ) : (
                                        <>
                                            {title}
                                            {tooltip}
                                        </>
                                    )}
                                    {columnSubtitle ? (
                                        <div className="table__header-cell-desktop-subtitle">
                                            {columnSubtitle}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </th>
                            );
                        })}
                </tr>
            </thead>
        );
    };

    const renderDesktopBody = () => {
        const { schema, data: list, bulkActions } = data;
        if (!list) return null;
        return (
            <tbody className="table__body">
                {list.map((item) => {
                    return (
                        <tr className="table__body-row" key={item.iri}>
                            {bulkActions && (
                                <td
                                    className="table__body-data"
                                    key={generateKey(`${item.iri}bulkActions`)}
                                >
                                    <TableRowBulkAction
                                        reducerName={reducerName}
                                        key={item.iri}
                                        iri={item.iri}
                                        bulkActionsData={bulkActions}
                                    />
                                </td>
                            )}
                            {schema.length &&
                                schema.map((schemaItem) => {
                                    return (
                                        <td
                                            className={`${
                                                schemaItem.align
                                                    ? `table__body-data table__body-data--${schemaItem.align}`
                                                    : ''
                                            }${item?.className ? ` ${item?.className}` : ''}`}
                                            key={generateKey(item.iri + schemaItem.field)}
                                        >
                                            {item[schemaItem.field]}
                                        </td>
                                    );
                                })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const filterSchemaMobile = (schema) => {
        // init schema
        const initSchema = {
            title: [],
            status: [],
            subtitle: [],
            table: [],
            hidden: []
        };

        const pushToSchema = (item, type) => {
            switch (type) {
                // push to title
                case 'title':
                    initSchema.title.push(item);
                    break;
                // push to status
                case 'status':
                    initSchema.status.push(item);
                    break;
                // push to subtitle
                case 'subtitle':
                    initSchema.subtitle.push(item);
                    break;
                // push to hidden
                case 'hidden':
                    initSchema.hidden.push(item);
                    break;
                default:
                    if (item.field !== 'actions') initSchema.table.push(item);
            }
        };

        if (schema) {
            // push data to schema object
            schema.forEach((item) => {
                const mobileType = item.mobile ? item.mobile.constructor : '';
                switch (mobileType) {
                    case Array:
                        item.mobile.forEach((subitem) => {
                            pushToSchema(item, subitem);
                        });
                        break;
                    case String:
                        pushToSchema(item, item.mobile);
                        break;
                    default:
                        pushToSchema(item, item.mobile);
                }
            });

            // check if title exists
            // if not add first that doesn't have key: mobile
            if (initSchema.title.length === 0) {
                const firstTitle = schema.find((item) => {
                    return !Object.prototype.hasOwnProperty.call(item, 'mobile') ? item : false;
                });
                initSchema.title.push(firstTitle);
            }
        }
        return initSchema;
    };

    const handleMobileOpened = (id) => {
        setMobileOpened((existingActiveMobileItems) => {
            if (existingActiveMobileItems?.includes(id)) {
                existingActiveMobileItems = existingActiveMobileItems.filter((item) => item !== id);
            } else {
                existingActiveMobileItems.push(id);
            }
            return existingActiveMobileItems.slice();
        });
    };

    const renderMobileHeader = () => {
        const { bulkActions, schema } = data;
        let sortedBy = '';
        let sortedOrder = '';
        if (queryParams.order_by) {
            const sortKey = Object.keys(queryParams.order_by)[0];
            sortedBy = schema.filter((item) => item.sortable === sortKey)[0]?.title || sortKey;
            sortedOrder = queryParams.order_by[sortKey];
        }

        let hasSortable = false;
        const sortableDropdown = schema.map((item) => {
            const { title, sortable } = item;
            const sortData = prepareSortData(item);
            if (sortable) {
                hasSortable = true;
                return (
                    <button
                        type="button"
                        className={`table__sortable${sortData.activeSortClassName}`}
                        onClick={() => {
                            sortData.sortFunction();
                            setMobileSortDropdown(!mobileSortDropdown);
                        }}
                        key={generateKey(title)}
                    >
                        <span className="table__sortable-label">{title}</span>
                        <Svg icon="arrowDown" className="table__sortable-icon" />
                    </button>
                );
            }
            return null;
        });

        return (
            <>
                {bulkActions && (
                    <div className="table-mobile__bulk">
                        <TableHeaderBulkAction reducerName={reducerName} tableData={data} />
                    </div>
                )}
                {hasSortable ? (
                    <div
                        className={`table-mobile__sorting ${
                            mobileSortDropdown ? 'table-mobile__sorting--active' : ''
                        } table-mobile__sorting--${sortedOrder}`}
                    >
                        <button
                            type="button"
                            className="table-mobile__sorting-btn"
                            onClick={() => setMobileSortDropdown(!mobileSortDropdown)}
                        >
                            <Svg icon="sorting" className="table-mobile__sorting-icon" />
                            {sortedBy ? (
                                <span className="table-mobile__sorting-label">
                                    Sorted by : <strong>{sortedBy}</strong>
                                </span>
                            ) : (
                                <span className="table-mobile__sorting-label">Sort by</span>
                            )}
                        </button>
                        <div className="table-mobile__sorting-dropdown">{sortableDropdown}</div>
                    </div>
                ) : null}
            </>
        );
    };

    const renderMobileBody = () => {
        const { schema, data: list, bulkActions } = data;
        if (!list) return null;

        const mobileSchema = filterSchemaMobile(schema);

        return list.map((item) => {
            return (
                <div
                    key={generateKey(item.iri)}
                    className={`table-mobile__list-item ${
                        mobileOpened.includes(item.iri) ? 'table-mobile__list-item--active' : ''
                    }`}
                >
                    <ul className="table-mobile__list-header">
                        {bulkActions && (
                            <li className="table-mobile__list-checkbox">
                                <TableRowBulkAction
                                    reducerName={reducerName}
                                    key={item.iri}
                                    iri={item.iri}
                                    bulkActionsData={bulkActions}
                                />
                            </li>
                        )}
                        <li className="table-mobile__list-title">
                            {item[mobileSchema.title[0].field]}
                        </li>
                        {mobileSchema.status.length > 0 && (
                            <li className="table-mobile__list-status">
                                {item[mobileSchema.status[0].field]}
                            </li>
                        )}
                        {(mobileSchema.table.length > 0 || item.actions) && (
                            <li className="table-mobile__list-arrow">
                                <Svg icon="arrowRight" />
                                <button
                                    type="button"
                                    className="table-mobile__btn"
                                    onClick={() => handleMobileOpened(item.iri)}
                                    onKeyPress={() => handleMobileOpened(item.iri)}
                                />
                            </li>
                        )}
                    </ul>
                    {mobileSchema.subtitle.length > 0 && (
                        <ul className="table-mobile__list-subtitle">
                            {mobileSchema.subtitle.map((subtitleItem) => {
                                return (
                                    <li
                                        key={generateKey(subtitleItem.field)}
                                        className="table-mobile__list-subtitle-item"
                                    >
                                        {item[subtitleItem.field]}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <div className="table-mobile__additional">
                        {mobileSchema.table.length > 0 && (
                            <table className="table-mobile__table">
                                <tbody>
                                    {mobileSchema.table.map((tableItem) => {
                                        if (item?.hiddenOnMobile?.includes(tableItem.field))
                                            return null;
                                        return (
                                            <tr
                                                key={generateKey(tableItem.field)}
                                                className="table-mobile__table-row"
                                            >
                                                <td
                                                    className={`table-mobile__table-col-left${
                                                        tableItem?.columnSubtitle
                                                            ? ' table-mobile__table-col-left--has-subtitle'
                                                            : ''
                                                    }`}
                                                >
                                                    {tableItem?.columnSubtitle && (
                                                        <div className="table-mobile__table-col-subtitle">
                                                            {tableItem?.columnSubtitle}
                                                        </div>
                                                    )}
                                                    {getHeaderTooltip({
                                                        text: tableItem.tooltipText,
                                                        icon: tableItem.tooltipIcon,
                                                        isMobileView: true
                                                    })}
                                                    {tableItem.title}:
                                                </td>
                                                <td
                                                    className={`table-mobile__table-col-right${
                                                        tableItem?.columnSubtitle
                                                            ? ' table-mobile__table-col-right--has-subtitle'
                                                            : ''
                                                    }`}
                                                >
                                                    {item[tableItem.field]}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                        {item.actions && (
                            <div className="table-mobile__actions">{item.actions}</div>
                        )}
                    </div>
                </div>
            );
        });
    };

    const { data: list } = data;

    return isMobile ? (
        <div className="table-mobile">
            <div className="table-mobile__inner">
                {renderMobileHeader()}
                <div className="table-mobile__list">{renderMobileBody()}</div>
                {(!list || list.length === 0) && !loading && !initialRender && (
                    <NotificationManager code="listingNoData" />
                )}
            </div>
        </div>
    ) : (
        <div className="table">
            <div className="table__inner">
                <table className="table__list">
                    {renderDesktopHeader()}
                    {renderDesktopBody()}
                </table>
                {(!list || list.length === 0) && !loading && !initialRender && (
                    <NotificationManager code="listingNoData" />
                )}
            </div>
        </div>
    );
};

Table.defaultProps = {
    data: {},
    onSortTable: () => {},
    queryParams: {},
    loading: false
};

Table.propTypes = {
    reducerName: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]),
    onSortTable: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object]),
    loading: PropTypes.bool
};

Table.Truncate = function Actions({ children }) {
    return <div className="table__truncate">{children}</div>;
};
Table.Truncate.defaultProps = {
    children: null
};

Table.Truncate.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node])
};

export default Table;
