const momentToDateFnsMap = [
    // Month
    ['MMMM', 'MMMM'],
    ['MMM', 'MMM'],
    ['MM', 'MM'],
    ['Mo', 'Mo'],
    ['M', 'M'],
    // Quarter
    ['Q', 'Q'],
    ['Qo', 'Qo'],
    // Day of Month
    ['DD', 'dd'],
    ['Do', 'do'],
    ['D', 'd'],
    // Day of Year
    ['DDDD', 'DDD'],
    ['DDDo', 'Do'],
    ['DDD', 'D'],
    // Day of Week
    ['dddd', 'EEEE'],
    ['ddd', 'EEE'], // TODO: antonio, check
    ['dd', 'EEEEEE'],
    ['do', 'eo'], // not precise, but close, will depend on locale
    ['d', 'e'], // not precise, but close, will depend on locale
    // Day of Week (Locale)
    ['e', 'e'],
    // Day of Week (ISO)
    ['E', 'i'],
    // Week of Year
    ['ww', 'ww'], // not precise, but close, will depend on locale
    ['wo', 'wo'], // not precise, but close, will depend on locale
    ['w', 'w'], // not precise, but close, will depend on locale
    // Week of Year (ISO)
    ['WW', 'II'],
    ['Wo', 'Io'],
    ['W', 'I'],
    // Year
    ['YYYYYY', 'yyyy'],
    ['YYYY', 'yyyy'],
    ['YY', 'yy'],
    ['Y', 'yyyy'],
    // Era Year
    ['y', 'yyyy'], // not precise, but close
    // Era
    ['NNNNN', 'GGG'],
    ['NNNN', 'GGGG'],
    ['NNN', 'GGG'],
    ['NN', 'GG'],
    ['N', 'G'],
    // Week Year
    ['gggg', 'Y'],
    ['gg', 'YY'],
    // Week Year (ISO)
    ['GGGG', 'RR'], // not precise
    ['GG', 'RR'],
    // AM/PM
    ['A', 'a'],
    ['a', 'aaa'],
    // Hour
    ['HH', 'HH'],
    ['H', 'H'],
    ['hh', 'hh'],
    ['h', 'h'],
    ['kk', 'kk'],
    ['k', 'k'],
    // Minute
    ['mm', 'mm'],
    ['m', 'm'],
    // Second
    ['ss', 'ss'],
    ['s', 's'],
    // Fractional Second
    ['SSSSSSSSS', 'SSSSSSSSS'],
    ['SSSSSSSS', 'SSSSSSSS'],
    ['SSSSSSS', 'SSSSSSS'],
    ['SSSSSS', 'SSSSSS'],
    ['SSSSS', 'SSSSS'],
    ['SSSS', 'SSSS'],
    ['SSS', 'SSS'],
    ['SS', 'SS'],
    ['S', 'S'],
    // Time Zone
    ['zz', 'XX'],
    ['z', 'XXX'],
    ['ZZ', 'xx'],
    ['Z', 'xxx'],
    // Unix Timestamp
    ['X', 't'],
    // Unix Millisecond Timestamp
    ['x', 'T']
];

const DIVIDER = '<DIVIDER>'
const addDividersToString = string => `${string.split('').join(DIVIDER)}${DIVIDER}`;

const convertMomentJsToDateFnsFormat = (momentFormat) => {
    if (!momentFormat) {
        return momentFormat;
    }

    const momentFormatWithDividers = addDividersToString(momentFormat);

    let output = momentToDateFnsMap.reduce((accumulator, current) => {
        const regex = new RegExp(addDividersToString(current[0]), 'g');
        return accumulator.replace(regex, current[1]);
    }, momentFormatWithDividers);

    const removalRegex = new RegExp(DIVIDER, 'g');
    output = output.replace(removalRegex, '');

    return output;
};

export default convertMomentJsToDateFnsFormat;
