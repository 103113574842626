import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as timeOffTypesActions } from 'erpcore/screens/Settings/TimeOffTypes/TimeOffTypes.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import dto from 'erpcore/utils/dto';

/**
 * Create TimeOffType
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createTimeOffType({ promise, formData }) {
    try {
        const createTimeOffTypeAPI = yield restClient.post('api/time-off/types', formData);
        yield put({
            type: timeOffTypesActions.CREATE_TIME_OFF_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createTimeOffTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'timeOffTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TIME_OFF_TYPES',
            name: 'timeOffTypes',
            endpoint: 'api/time-off/types'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffTypesActions.CREATE_TIME_OFF_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch TimeOffType
 * @param  {Object} promise
 * @return {string} iri TimeOffType iri
 */
export function* fetchTimeOffType({ promise, iri }) {
    try {
        const fetchTimeOffTypeAPI = yield restClient.get(`${iri}`);
        yield put({
            type: timeOffTypesActions.FETCH_TIME_OFF_TYPE_SUCCESSFUL
        });

        yield put({
            type: timeOffTypesActions.STORE_TIME_OFF_TYPE_DATA,
            iri,
            response: dto(fetchTimeOffTypeAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffTypesActions.FETCH_TIME_OFF_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update TimeOffType data
 * @param  {Object} iri of TimeOffType
 * @return {Object} Response from API
 */
export function* updateTimeOffType({ promise, iri, formData }) {
    try {
        const updateTimeOffTypeAPI = yield restClient.patch(iri, formData);
        yield put({
            type: timeOffTypesActions.UPDATE_TIME_OFF_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateTimeOffTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'timeOffTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TIME_OFF_TYPES',
            name: 'timeOffTypes',
            endpoint: 'api/time-off/types'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffTypesActions.UPDATE_TIME_OFF_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete TimeOffType
 * @param  {Object} iri of TimeOffType
 * @return {Object} Response from API
 */
export function* deleteTimeOffType({ promise, iri }) {
    try {
        const deleteTimeOffTypeAPI = yield restClient.delete(iri);
        yield put({
            type: timeOffTypesActions.DELETE_TIME_OFF_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteTimeOffTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'timeOffTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TIME_OFF_TYPES',
            name: 'timeOffTypes',
            endpoint: 'api/timeOffTypes?include=salesOrderSubscribers'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffTypesActions.DELETE_TIME_OFF_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const timeOffTypesSaga = [
    takeLatest(timeOffTypesActions.START_CREATE_TIME_OFF_TYPE, createTimeOffType),
    takeLatest(timeOffTypesActions.START_FETCHING_TIME_OFF_TYPE, fetchTimeOffType),
    takeLatest(timeOffTypesActions.START_UPDATE_TIME_OFF_TYPE, updateTimeOffType),
    takeLatest(timeOffTypesActions.START_DELETE_TIME_OFF_TYPE, deleteTimeOffType)
];

export default timeOffTypesSaga;
