import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as paymentMethodsActions } from 'erpcore/screens/Settings/PaymentMethods/PaymentMethods.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Payment Method
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createPaymentMethod({ promise, formData }) {
    try {
        const createPaymentMethodAPI = yield restClient.post('api/payment-methods', formData);
        yield put({
            type: paymentMethodsActions.CREATE_PAYMENT_METHOD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createPaymentMethodAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'paymentMethods' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_METHODS',
            name: 'paymentMethods',
            endpoint: 'api/payment-methods?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentMethodsActions.CREATE_PAYMENT_METHOD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Payment Method data
 * @param  {Object} iri of PaymentMethod
 * @return {Object} Response from API
 */
export function* updatePaymentMethod({ promise, iri, formData }) {
    try {
        const updatePaymentMethodAPI = yield restClient.patch(iri, formData);
        yield put({
            type: paymentMethodsActions.UPDATE_PAYMENT_METHOD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePaymentMethodAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'paymentMethods' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_METHODS',
            name: 'paymentMethods',
            endpoint: 'api/payment-methods?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentMethodsActions.UPDATE_PAYMENT_METHOD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Payment Method
 * @param  {Object} iri of Payment Method
 * @return {Object} Response from API
 */
export function* deletePaymentMethod({ promise, iri }) {
    try {
        const deletePaymentMethodAPI = yield restClient.delete(iri);
        yield put({
            type: paymentMethodsActions.DELETE_PAYMENT_METHOD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deletePaymentMethodAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'payment-methods' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PAYMENT_METHODS',
            name: 'paymentMethods',
            endpoint: 'api/payment-methods?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: paymentMethodsActions.DELETE_PAYMENT_METHOD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const paymentMethodsSaga = [
    takeLatest(paymentMethodsActions.START_CREATE_PAYMENT_METHOD, createPaymentMethod),
    takeLatest(paymentMethodsActions.START_UPDATE_PAYMENT_METHOD, updatePaymentMethod),
    takeLatest(paymentMethodsActions.START_DELETE_PAYMENT_METHOD, deletePaymentMethod)
];

export default paymentMethodsSaga;
