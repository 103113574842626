export const actions = {
    START_FETCHING_COMMENTS: 'START_FETCHING_COMMENTS',
    FETCH_SUCCESSFUL_COMMENTS: 'FETCH_SUCCESSFUL_COMMENTS',
    FETCH_FAILED_COMMENTS: 'FETCH_FAILED_COMMENTS',
    STORE_COMMENTS_DATA: 'STORE_COMMENTS_DATA',
    START_POSTING_SINGLE_COMMENT: 'START_POSTING_SINGLE_COMMENT',
    POST_SINGLE_COMMENT_SUCCESSFUL: 'POST_SINGLE_COMMENT_SUCCESSFUL',
    POST_SINGLE_COMMENT_FAILED: 'POST_SINGLE_COMMENT_FAILED',
    START_DELETING_SINGLE_COMMENT: 'START_DELETING_SINGLE_COMMENT',
    DELETE_SINGLE_COMMENT_SUCCESSFUL: 'DELETE_SINGLE_COMMENT_SUCCESSFUL',
    DELETE_SINGLE_COMMENT_FAILED: 'DELETE_SINGLE_COMMENT_FAILED'
};

export const initialState = {
    fetching: false
};

export default (state = initialState, { type, id, entity, response }) => {
    switch (type) {
        case actions.START_FETCHING_COMMENTS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_SUCCESSFUL_COMMENTS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCH_FAILED_COMMENTS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.STORE_COMMENTS_DATA: {
            entity = entity.toLowerCase();
            // Updating the store
            return Object.assign({}, state, {
                [entity]: { [id]: response }
            });
        }
        default:
            return state;
    }
};
