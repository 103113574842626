import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Textarea } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ProjectApprovalChangeRequestForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    onCancel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    id="change_request_description"
                    name="change_request_description"
                    fieldProps={{
                        label: 'Your description here...'
                    }}
                    fieldAttr={{ required: true }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <div>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label="Send"
                    />
                    {onCancel && typeof onCancel === 'function' && (
                        <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />
                    )}
                </div>
            </Form.Row>
        </Form>
    );
};

ProjectApprovalChangeRequestForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    onCancel: null
};

ProjectApprovalChangeRequestForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onCancel: PropTypes.func
};

export default reduxForm({
    form: 'ProjectApprovalChangeRequestForm',
    enableReinitialize: true
})(ProjectApprovalChangeRequestForm);
