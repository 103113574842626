import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Accounting Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const AccountingNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'category.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Accounting category successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'category.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Accounting category successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'category.itemSuccessfullyDeleted':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Accounting category successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'accounting.noMoveCategoryForNonEmptyCateogry':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="Can not delete a category that has subcategories with items."
                    type="warning"
                />
            );
        case 'accounting.moveCategoryIsSameOrSubcategoryOfCategoryToDelete':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="Category to move items to is the same or a subcategory of a category you are trying to delete."
                    type="warning"
                />
            );
        case 'invoice.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'invoice.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'invoice.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'invoiceitem.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice item successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'invoiceitem.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice item successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'invoiceitem.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice item successfully created!"
                    type="success"
                    expire="3500"
                />
            );

        case 'expense.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Expense successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'expense.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Expense successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'expense.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Expense successfully updated!"
                    type="success"
                    expire="3500"
                />
            );

        case 'revenue.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Revenue successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'revenue.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Revenue successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'revenue.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Revenue successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'accounting.invalidItemAddedToTheCategory':
            return (
                <Notification
                    identifier={identifier}
                    title="Info"
                    text="Can not add item of this type!"
                    type="info"
                    expire="3500"
                />
            );
        case 'invoice.statusSuccessfullyChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice status updated successfully!"
                    type="success"
                    expire="3500"
                />
            );
        case 'accountingItem.insert.failed':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={detail}
                    type="error"
                    expire="3500"
                />
            );
        case 'payment.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invoice successfully paid!"
                    type="success"
                    expire="3500"
                />
            );
        case 'payment.paid.invalidPaymentConstraint':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Sum of all invoice payments cannot be less than 0."
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

AccountingNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

AccountingNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default AccountingNotifications;
