export const actions = {
    // Listing
    START_FETCHING_BUNDLE_TEMPLATES: 'START_FETCHING_BUNDLE_TEMPLATES',
    FETCHING_SUCCESSFUL_BUNDLE_TEMPLATES: 'FETCHING_SUCCESSFUL_BUNDLE_TEMPLATES',
    FETCHING_FAILED_BUNDLE_TEMPLATES: 'FETCHING_FAILED_BUNDLE_TEMPLATES',

    START_CREATE_BUNDLE_TEMPLATE: 'START_CREATE_BUNDLE_TEMPLATE',
    CREATE_BUNDLE_TEMPLATE_SUCCESSFUL: 'CREATE_BUNDLE_TEMPLATE_SUCCESSFUL',
    CREATE_BUNDLE_TEMPLATE_FAILED: 'CREATE_BUNDLE_TEMPLATE_FAILED',

    START_FETCHING_SINGLE_BUNDLE_TEMPLATE: 'START_FETCHING_SINGLE_BUNDLE_TEMPLATE',
    FETCHING_SUCCESSFUL_SINGLE_BUNDLE_TEMPLATE: 'FETCHING_SUCCESSFUL_SINGLE_BUNDLE_TEMPLATE',
    FETCHING_SINGLE_BUNDLE_TEMPLATE_FAILED: 'FETCHING_SINGLE_BUNDLE_TEMPLATE_FAILED',
    STORE_SINGLE_BUNDLE_TEMPLATE_DATA: 'STORE_SINGLE_BUNDLE_TEMPLATE_DATA',

    START_UPDATE_SINGLE_BUNDLE_TEMPLATE: 'START_UPDATE_SINGLE_BUNDLE_TEMPLATE',
    UPDATE_SINGLE_BUNDLE_TEMPLATE_SUCCESSFUL: 'UPDATE_SINGLE_BUNDLE_TEMPLATE_SUCCESSFUL',
    UPDATE_SINGLE_BUNDLE_TEMPLATE_FAILED: 'UPDATE_SINGLE_BUNDLE_TEMPLATE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_BUNDLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_BUNDLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_BUNDLE_TEMPLATES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_BUNDLE_TEMPLATE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.STORE_SINGLE_BUNDLE_TEMPLATE_DATA: {
            return {
                ...state,
                singleFetching: false,
                [iri]: response
            };
        }
        case actions.FETCHING_SINGLE_BUNDLE_TEMPLATE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        default:
            return state;
    }
};
