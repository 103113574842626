import React, { useEffect, useState } from 'react';
import { ifMobile } from 'erpcore/utils/utils';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import './BottomBar.scss';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const BottomBar = ({ layout }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    useEffect(() => {
        window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    }, []);

    if (!hasPermissionsAccessForEntities.timeLogs && !hasPermissionsAccessForEntities.props) {
        return null;
    }

    if (ifMobile(windowWidth)) {
        return (
            <div className="bottom-bar">
                <Helmet
                    bodyAttributes={{
                        'has-bottom-bar': true
                    }}
                />
                <LayoutManager layout={layout} />
            </div>
        );
    }

    return null;
};

BottomBar.defaultProps = {
    layout: null
};

BottomBar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object])
};

export default BottomBar;
