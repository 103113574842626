export const actions = {
    START_FETCHING_OPTIONS: 'START_FETCHING_OPTIONS',
    FETCH_OPTIONS_SUCCESSFUL: 'FETCH_OPTIONS_SUCCESSFUL',
    FETCH_OPTIONS_FAILED: 'FETCH_OPTIONS_FAILED',

    START_POSTING_OPTIONS: 'START_POSTING_OPTIONS',
    POST_OPTIONS_SUCCESSFUL: 'POST_OPTIONS_SUCCESSFUL',
    POST_OPTIONS_FAILED: 'POST_OPTIONS_FAILED',

    START_UPDATING_OPTIONS: 'START_UPDATING_OPTIONS',
    UPDATE_OPTIONS_SUCCESSFUL: 'UPDATE_OPTIONS_SUCCESSFUL',
    UPDATE_OPTIONS_FAILED: 'UPDATE_OPTIONS_FAILED',

    STORE_OPTIONS_DATA: 'STORE_OPTIONS_DATA'
};

export const initialState = {
    data: {},
    fetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_OPTIONS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_OPTIONS_SUCCESSFUL: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCH_OPTIONS_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.STORE_OPTIONS_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [response && response?.name]: {
                        ...response
                    }
                }
            };
        }
        default:
            return state;
    }
};
