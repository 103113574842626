import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as dashboardsActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import {
    getCurrentDashboardIri,
    getDashboards
} from 'erpcore/screens/Dashboard/Dashboard.selectors';
import Button from 'erpcore/components/Button';
import { useDropdown } from 'erpcore/utils/utils.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { getResponsive } from 'erpcore/utils/utils';
import FavoriteDashboard from 'erpcore/screens/Dashboards/components/FavoriteDashboard';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import style from './MultiDashboard.module.scss';

const MultiDashboardsWidget = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const listOfDashboards = useSelector((state) => getDashboards(state));
    const { setClassNameSelector, dropdownOpenStatus, toggleDropdown } = useDropdown();
    const currentDashboard = useSelector(getCurrentDashboardIri);
    const navigate = useNavigate();
    const { isMobile } = getResponsive();
    const meData = useSelector(getMeData) || {};

    const fetchDashboards = () => {
        new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.START_FETCHING_DASHBOARDS
            });
        }).catch((error) => {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
        });
    };

    const setActiveDashboard = (dashboard) => {
        navigate(`/dashboard`);
        const newActiveDashboard = listOfDashboards[dashboard]?.iri;

        toggleDropdown({});
        if (newActiveDashboard === currentDashboard) return null;

        localStorage.setItem('currentDashboard', newActiveDashboard);
        dispatch({
            type: dashboardsActions.SET_CURRENT_DASHBOARD,
            iri: newActiveDashboard
        });

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.SET_ACTIVE_DASHBOARD_TO_USER,
                iri: meData?.iri,
                data: { active_dashboard: newActiveDashboard }
            });
        });
    };

    const setActivePersonalDashboard = () => {
        navigate(`/analytics/personal-dashboard`);
        const newActiveDashboard = listOfDashboards.personal_dashboard?.iri;

        toggleDropdown({});
        if (newActiveDashboard === currentDashboard) return null;

        localStorage.setItem('currentDashboard', 'personal_dashboard');
        dispatch({
            type: dashboardsActions.SET_CURRENT_DASHBOARD,
            iri: 'personal_dashboard'
        });

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.SET_ACTIVE_DASHBOARD_TO_USER,
                iri: meData?.iri,
                data: { active_dashboard: null }
            });
        });
    };

    useEffect(() => {
        if (location.pathname === '/analytics/personal-dashboard') {
            setActivePersonalDashboard();
        }
    }, [location.pathname]);

    const personalDashboard = (
        <li className={style['multi-dashboards__dropdown-list__item']}>
            <Button
                label={`${meData?.first_name}'s Dashboard`}
                className={`${style['multi-dashboards__dropdown-list__item-name']}`}
                variation="tertiary"
                onClick={() => setActivePersonalDashboard()}
            />
        </li>
    );

    const dropdownOptions = useMemo(() => {
        if (
            Object.keys(listOfDashboards).find((dashboard) => listOfDashboards[dashboard].favorite)
        ) {
            return (
                <ul className={style['multi-dashboards__dropdown-list']}>
                    {Object.keys(listOfDashboards)
                        .filter((dashboard) => listOfDashboards[dashboard].favorite)
                        .sort((a, b) => {
                            if (listOfDashboards[a]?.name < listOfDashboards[b]?.name) {
                                return -1;
                            }
                            if (listOfDashboards[a]?.name > listOfDashboards[b]?.name) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((dashboard) => {
                            const isActiveDashboard =
                                listOfDashboards[dashboard]?.iri === currentDashboard;
                            const isNameToLong = listOfDashboards[dashboard]?.name?.length >= 40;
                            return (
                                <li
                                    className={style['multi-dashboards__dropdown-list__item']}
                                    key={dashboard}
                                >
                                    {isNameToLong && !isMobile ? (
                                        <Tooltip content={listOfDashboards[dashboard]?.name}>
                                            <Button
                                                label={listOfDashboards[dashboard]?.name}
                                                className={`${
                                                    style[
                                                        'multi-dashboards__dropdown-list__item-name'
                                                    ]
                                                } ${
                                                    isActiveDashboard
                                                        ? style[
                                                              'multi-dashboards__dropdown-list__item--active'
                                                          ]
                                                        : ''
                                                } ${
                                                    isNameToLong
                                                        ? style['multi-dashboards__button-truncate']
                                                        : ''
                                                }`}
                                                variation="tertiary"
                                                onClick={() => setActiveDashboard(dashboard)}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            label={listOfDashboards[dashboard]?.name}
                                            className={`${
                                                style['multi-dashboards__dropdown-list__item-name']
                                            } ${
                                                isActiveDashboard
                                                    ? style[
                                                          'multi-dashboards__dropdown-list__item--active'
                                                      ]
                                                    : ''
                                            }`}
                                            variation="tertiary"
                                            onClick={() => setActiveDashboard(dashboard)}
                                        />
                                    )}
                                    <FavoriteDashboard
                                        dashboardData={listOfDashboards[dashboard]}
                                        isListingAction
                                    />
                                </li>
                            );
                        })}
                </ul>
            );
        }
        return (
            <Button variation="tertiary" href="/analytics/dashboards" label="View all dashboards" />
        );
    }, [listOfDashboards, currentDashboard]);

    useEffect(() => {
        toggleDropdown({});
    }, [currentDashboard]);

    useEffect(() => {
        if (!Object.keys(listOfDashboards).length) fetchDashboards();
    }, []);

    return (
        <div className={style['multi-dashboards']}>
            <button
                type="button"
                onClick={() => {
                    setClassNameSelector(['button-multiDashboard', 'dropdown']);
                    toggleDropdown('selectDashboard');
                }}
                className={`button button-multiDashboard button--tertiary ${
                    style['multi-dashboards__button']
                } ${
                    dropdownOpenStatus?.selectDashboard
                        ? style['multi-dashboards__button--active']
                        : ''
                }`}
            >
                <Svg icon="clientDashboard" />
                <span>Favorite Dashboards</span>
                <Svg icon="arrowDown" />
            </button>
            <div
                className={`dropdown ${style['multi-dashboards__dropdown']}`}
                data-opened={dropdownOpenStatus?.selectDashboard}
            >
                {dropdownOptions}
                {personalDashboard}
            </div>
        </div>
    );
};

MultiDashboardsWidget.defaultProps = {};

MultiDashboardsWidget.propTypes = {};

export default MultiDashboardsWidget;
