import React, { useCallback, useEffect, useMemo, useState, useRef, forwardRef } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { getOptionByValue, getOptionsByValues } from 'erpcore/components/Form/Form.utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions as selectActions } from 'erpcore/components/Form/components/SelectNew/Select.reducer';
import {
    getSelectDataByKey,
    getSelectFetchingByKey,
    getSelectInitialFetchDoneByKey,
    getSelectMissingOptionsFetching
} from 'erpcore/components/Form/components/SelectNew/Select.selectors';
import Svg from 'erpcore/components/Svg';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import startsWith from 'lodash/startsWith';
import { sortSelectOptions } from 'erpcore/utils/utils';
import dto, { getIdFromIri } from 'erpcore/utils/dto';
import restClient from 'erpcore/api/restClient';
import nextId from 'react-id-generator';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import classnames from 'classnames';
import Input from '../Input';
import styles from './SelectNew.styles';
import './SelectNew.scss';

export const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Svg icon="arrowDown" />
            </components.DropdownIndicator>
        )
    );
};

export const ClearIndicator = (props) => {
    return (
        components.ClearIndicator && (
            <components.ClearIndicator {...props}>
                <Svg icon="close" />
            </components.ClearIndicator>
        )
    );
};

export const selectPropsMapper = (fieldProps, fieldAttr) => {
    if ('disabled' in fieldAttr) {
        fieldProps.isDisabled = fieldAttr.disabled;
    }
    return { fieldProps, fieldAttr };
};

const creatableLocationExceptionHandlingList = {
    '/api/countries': true,
    '/api/cities': true,
    '/api/states': true
};

// eslint-disable-next-line react/prop-types
const ThirdPartyComponent = forwardRef(({ isCreatable = false, ...rest }, ref) => {
    if (isCreatable) {
        return <CreatableSelect ref={ref} {...rest} />;
    }

    return <Select ref={ref} {...rest} />;
});

let abortControllers = [];

const SelectNew = ({
    input,
    fieldAttr,
    fieldProps,
    meta,
    field,
    isMulti,
    options,
    additionalOptions,
    actionButton,
    apiData,
    isClearable,
    isCreatable,
    usePortal
}) => {
    const instanceData = useMemo(() => {
        // instanceData must change when apiData.endpoint changes
        return {
            endpoint: apiData?.endpoint,
            identifier: nextId(`${input?.name}-%-`) // identifier just needs to be unique across all SelectNew components. input?.name is here only for inspection purposes, but is not necessary
        };
    }, [apiData?.endpoint]);
    isCreatable = !!(isCreatable && apiData?.endpoint);
    const [labelActive, setLabelActive] = useState(false);
    const [localInputFieldValue, setLocalInputFieldValue] = useState('');
    const [isFetchingSearchResults, setIsFetchingSearchResults] = useState(false);
    const loadingNonExistingValues = useSelector((state) =>
        getSelectMissingOptionsFetching(state, apiData?.endpoint)
    );
    const dispatch = useDispatch();
    const initialOptionsList =
        useSelector((state) => getSelectDataByKey(state, apiData?.endpoint)) || [];
    const isFetchingInitialOptions = useSelector((state) =>
        getSelectFetchingByKey(state, apiData?.endpoint)
    );
    const isInitialFetchDone = useSelector((state) =>
        getSelectInitialFetchDoneByKey(state, apiData?.endpoint)
    );
    const [searchResults, setSearchResults] = useState(null);

    // menu is controlled only for isMulti type
    const [menuIsOpen, setMenuVisibility] = useState(false);
    const openMenu = useCallback(() => {
        setMenuVisibility(true);
    }, []);
    const closeMenu = useCallback(() => {
        setMenuVisibility(false);
    }, []);

    const thirdPartyRef = useRef(null);

    const endpointRef = useRef(apiData?.endpoint);
    const isInitialFetchDoneRef = useRef(isInitialFetchDone);

    const inputValueRef = useRef(input?.value);

    const apiValueKey = apiData?.mapData?.value || 'iri';

    const getParamsSelectedValue = (value) => {
        const prependKey = apiValueKey === 'iri' ? '' : `${apiValueKey}:`;
        const formatValue = (item) => (item ? `${prependKey}${item}` : undefined);

        return isArray(value) ? value.map((item) => formatValue(item)) : formatValue(value);
    };

    const fetchSelectOptions = () => {
        dispatch({
            type: selectActions.START_FETCHING_SELECT_OPTIONS,
            endpoint: apiData?.endpoint,
            params: { selected: getParamsSelectedValue(inputValueRef.current) }
        });
    };

    const loadOptions = async (searchQuery) => {
        if (apiData?.endpoint) {
            let { params } = { ...apiData };

            if (searchQuery) {
                params = { q: searchQuery, ...apiData.params };
            }

            if (input?.value) {
                params = { selected: getParamsSelectedValue(input?.value), ...params };
            }

            // temporary fix until backend fixes search rank
            if (params) {
                params.limit = 20;
            } else {
                params = { limit: 20 };
            }
            try {
                abortControllers.forEach((controller) => controller?.abort());
                abortControllers.push(new AbortController());
                const optionsFromApi = await restClient.get(apiData?.endpoint, {
                    signal: abortControllers[abortControllers.length - 1]?.signal,
                    params
                });
                const optionsDto = dto(optionsFromApi?.data);
                setSearchResults(optionsDto?.data);
                setIsFetchingSearchResults(false);
                abortControllers = [];
            } catch (e) {
                return e;
            }
        }
        return null;
    };

    const delayedSearch = debounce(loadOptions, 350);

    const getLabelTemplateMatch = useCallback((labelTemplate = '') => {
        return labelTemplate?.match(/[^{]+(?=})/g);
    }, []);

    const formatApiValue = (targetOptions) => {
        if (!targetOptions) return null;
        const formattedOptions = [];

        targetOptions.forEach((option) => {
            const labelTemplateMatch = getLabelTemplateMatch(apiData?.mapData?.label);
            let labelTemplate = apiData?.mapData?.label;
            if (labelTemplate && labelTemplateMatch?.length) {
                labelTemplateMatch.forEach((labelItem) => {
                    labelTemplate = labelTemplate.replace(
                        `{${labelItem}}`,
                        String(option?.[labelItem]) || ''
                    );
                });
            }

            const value = option?.[apiValueKey];

            const getLabel = () => {
                if (option?.forwardOptionData?.forceDisplayValue) {
                    return value;
                }

                if (labelTemplateMatch?.length) {
                    return labelTemplate;
                }

                if ('label' in { ...apiData?.mapData }) {
                    return option?.[apiData.mapData.label];
                }

                return value;
            };

            formattedOptions.push({
                value,
                label: getLabel(),
                ...(option?.forwardOptionData ? { ...option?.forwardOptionData } : null),
                ...(option?.forwardOptionData?.isDisabled ? { isDisabled: true } : null),
                ...(option?.forwardOptionData?.isError
                    ? { isError: option?.forwardOptionData?.isError }
                    : null)
            });
        });

        return formattedOptions;
    };

    const selectOptions = useMemo(() => {
        if (isFetchingSearchResults) {
            return [];
        }

        if (searchResults) {
            return formatApiValue(searchResults);
        }

        return [...formatApiValue(initialOptionsList), ...(additionalOptions || [])];
    }, [initialOptionsList, additionalOptions, searchResults, isFetchingSearchResults]);

    const fieldValue = useMemo(() => {
        const getMultiFieldValue = () => {
            return getOptionsByValues(
                input?.value,
                options || formatApiValue(initialOptionsList)
            ).map((item) => {
                if (loadingNonExistingValues?.[item?.label]) {
                    return {
                        ...item,
                        label: <ElementLoader monochromatic />
                    };
                }

                return item;
            });
        };

        const getSingleFieldValue = () => {
            let data = getOptionByValue(
                input?.value,
                options || formatApiValue(initialOptionsList)
            );
            if (data?.label && loadingNonExistingValues?.[data.label]) {
                data = {
                    ...data,
                    label: <ElementLoader monochromatic />
                };
            }
            return data;
        };

        return isMulti ? getMultiFieldValue() : getSingleFieldValue();
    }, [initialOptionsList, input.value, loadingNonExistingValues]);

    fieldProps.forceLabelActive = labelActive;
    const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

    const renderActionButton = (val, actionButtonData = null) => {
        if (!actionButtonData) return null;

        const { edit, create } = actionButtonData;
        const { value, label } = val;
        let buttonLabel = `Edit ${label}` || 'Edit';
        let buttonIconName = 'edit';
        let buttonUrl = null;
        let buttonDisabled = !value;

        // Edit
        if (value && edit) {
            // If component provided
            if (edit?.component) {
                return React.cloneElement(edit?.component, { value });
            }
            // If url object provided
            if (edit?.url) {
                const { prefix, suffix } = edit?.url;
                let id = null;

                if (value) {
                    id = getIdFromIri(value);
                }

                buttonUrl = `${prefix}${id}${suffix}`;
            }

            // If entity archived, missing label
            if (!label) {
                buttonDisabled = true;
            }

            return (
                <Button
                    disabled={buttonDisabled}
                    variation="action"
                    iconName={buttonIconName}
                    href={buttonUrl}
                    label={buttonLabel}
                    labelOnlyAria
                />
            );
        }

        // No value in the field
        if (!value) {
            // By defining buttonDisabled var we set it to disable
            // If create object provided
            if (create) {
                // If component provided
                if (create.component) {
                    return create.component;
                }
                // If url is provided
                if (create.url) {
                    buttonIconName = null;
                    buttonUrl = create.url;
                    buttonDisabled = false;
                    buttonIconName = 'plus';
                    buttonLabel = 'Create';
                }
            }

            return (
                <Button
                    disabled={buttonDisabled}
                    variation="action"
                    iconName={buttonIconName}
                    href={buttonUrl}
                    label={buttonLabel}
                    labelOnlyAria
                />
            );
        }

        return null;
    };

    const getSelectedValues = useCallback((collection = []) => {
        if (!collection?.length) {
            return [];
        }
        return collection.map((item) => item?.value);
    }, []);

    useEffect(() => {
        if (instanceData.endpoint) {
            dispatch({
                type: selectActions.REGISTER_SELECT_INSTANCE,
                endpoint: instanceData.endpoint,
                response: instanceData.identifier
            });
        }

        return () => {
            if (instanceData.endpoint) {
                dispatch({
                    type: selectActions.UNREGISTER_SELECT_INSTANCE,
                    endpoint: instanceData.endpoint,
                    response: instanceData.identifier
                });
            }
        };
    }, [instanceData]);

    useEffect(() => {
        endpointRef.current = apiData?.endpoint;
    }, [apiData?.endpoint]);

    useEffect(() => {
        isInitialFetchDoneRef.current = isInitialFetchDone;
    }, [isInitialFetchDone]);

    useEffect(() => {
        inputValueRef.current = input?.value;
    }, [input?.value]);

    /**
     * This useEffect handles triggering initial fetch for endpoint.
     *
     * This useEffect doesn't handle concurrent request logic.
     * Concurrent requests for the same endpoint are handled within axios instance.
     */
    useEffect(() => {
        if (apiData?.endpoint && !isInitialFetchDone) {
            fetchSelectOptions();
        }
    }, [apiData?.endpoint, isInitialFetchDone]);

    const getItemFromList = useCallback((iri, list = []) => {
        return list?.find((item) => item?.[apiValueKey] === iri);
    }, []);

    const maybeAppendSelectedSearchItemToOptions = useCallback(
        (selectedOption, searchList) => {
            const searchResultsItem = getItemFromList(
                isMulti
                    ? selectedOption?.[selectedOption?.length - 1]?.value
                    : selectedOption?.value,
                searchList
            );
            if (searchResultsItem) {
                dispatch({
                    type: selectActions.APPEND_SELECT_OPTIONS,
                    endpoint: apiData?.endpoint,
                    response: searchResultsItem,
                    valueKey: apiValueKey
                });
            }
        },
        [apiData?.endpoint, isMulti]
    );

    const setInputValue = useCallback(
        (selectedOption) => {
            if (selectedOption && !isMulti) {
                input.onChange(selectedOption?.value);
            } else if (selectedOption?.length && isMulti) {
                input.onChange(getSelectedValues(selectedOption));
                setSearchResults(null);
            } else {
                input.onChange(null);
            }
        },
        [isMulti, getSelectedValues]
    );

    const createOption = useCallback(
        (label) => {
            const creatableKeyName = apiData?.creatableKeyName || 'name';
            restClient
                .post(apiData?.endpoint, {
                    [creatableKeyName]: label
                })
                .then((response) => {
                    const createdItem = dto(response?.data)?.data;

                    if (createdItem?.[apiValueKey]) {
                        if (isMulti) {
                            input.onChange([
                                ...(inputValueRef.current || []),
                                createdItem[apiValueKey]
                            ]);
                        } else {
                            input.onChange(createdItem[apiValueKey]);
                        }

                        dispatch({
                            type: selectActions.APPEND_SELECT_OPTIONS,
                            endpoint: apiData?.endpoint,
                            response: createdItem,
                            valueKey: apiValueKey
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                        response: error?.response?.data || error
                    });
                });
        },
        [apiData?.endpoint, apiData?.creatableKeyName, isMulti, setInputValue]
    );

    const searchLocationEntitiesByLabel = useCallback((label, endpoint) => {
        return restClient.get(endpoint, {
            params: {
                q: label
            }
        });
    }, []);

    const getNonExistingValues = useCallback(
        (inputValue, availableOptions) => {
            if (
                !isArray(inputValue) &&
                !availableOptions?.some((item) => item?.[apiValueKey] === inputValue)
            ) {
                /**
                 * input value is SINGLE value AND input value is missing in options
                 */
                if (
                    creatableLocationExceptionHandlingList[apiData?.endpoint] &&
                    !startsWith(inputValue, apiData?.endpoint)
                ) {
                    /**
                     * input value is LABEL in LOCATION field
                     */
                    searchLocationEntitiesByLabel(inputValue, apiData?.endpoint)
                        .then((response) => {
                            const newEntityIri = response?.data?.data?.[0]?.id;
                            if (newEntityIri) {
                                /**
                                 * Entity with this label EXISTS in db
                                 */
                                getNonExistingValues(newEntityIri, availableOptions);
                                input.onChange(newEntityIri);
                            } else {
                                /**
                                 * Entity with this label does NOT exist in db
                                 * Create new.
                                 */
                                createOption(inputValue);
                            }
                        })
                        .catch((error) => {
                            dispatch({
                                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                response: error?.response?.data || error
                            });
                        });
                } else {
                    /**
                     * is NOT location field
                     * OR
                     * input value is NOT label in location field
                     */
                    dispatch({
                        type: selectActions.START_FETCHING_MISSING_SELECT_OPTIONS,
                        endpoint: apiData?.endpoint,
                        iri: inputValue,
                        mapData: {
                            value: apiValueKey,
                            label:
                                getLabelTemplateMatch(apiData?.mapData?.label)?.[0] ||
                                apiData?.mapData?.label
                        },
                        valueKey: apiValueKey
                    });
                }
            } else if (isArray(inputValue)) {
                /**
                 * input value is MULTI value
                 */
                // TODO: antonio: support LOCATION field (still not necessary, because there are no multi location fields)
                const arrayOfNonExistingValues = inputValue.filter(
                    (value) => !availableOptions.some((item) => item?.[apiValueKey] === value)
                );
                if (arrayOfNonExistingValues?.length) {
                    dispatch({
                        type: selectActions.START_FETCHING_MISSING_SELECT_OPTIONS,
                        endpoint: apiData?.endpoint,
                        iri: arrayOfNonExistingValues,
                        mapData: {
                            value: apiValueKey,
                            label:
                                getLabelTemplateMatch(apiData?.mapData?.label)?.[0] ||
                                apiData?.mapData?.label
                        },
                        valueKey: apiValueKey
                    });
                }
            }
        },
        [apiData?.endpoint, initialOptionsList, isCreatable]
    );

    useEffect(() => {
        if (apiData?.endpoint && initialOptionsList?.length && input.value?.length) {
            getNonExistingValues(input.value, initialOptionsList);
        }
    }, [input.value, initialOptionsList, apiData?.endpoint]);

    const optionsProp = apiData?.endpoint ? selectOptions || [] : options || [];

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            isSelect
        >
            <ThirdPartyComponent
                ref={thirdPartyRef}
                id={input.name}
                name={input.name}
                styles={{
                    ...styles,
                    ...(usePortal
                        ? {
                              menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
                              menu: () => ({ top: 0 })
                          }
                        : null)
                }}
                components={{ DropdownIndicator, ClearIndicator }}
                isMulti={isMulti}
                {...mappedConf.fieldAttr}
                {...mappedConf.fieldProps}
                classNamePrefix="react-select"
                isClearable={isClearable}
                isLoading={isFetchingSearchResults}
                onFocus={() => {
                    input.onBlur();
                    setLabelActive(true);
                }}
                value={fieldValue}
                // on search input change
                onInputChange={(value) => {
                    setLocalInputFieldValue(value);
                    setLabelActive(value !== '');
                    if (apiData?.endpoint) {
                        if (value) {
                            setIsFetchingSearchResults(true);
                            delayedSearch(value);
                        } else {
                            setSearchResults(null);
                        }
                    }
                }}
                isCreatable={isCreatable}
                // this will be called when a new option is created, and onChange will not be called.
                onCreateOption={(label) => {
                    createOption(label);
                }}
                // on local field value change
                onChange={(selectedOption) => {
                    if (apiData?.endpoint && selectedOption) {
                        maybeAppendSelectedSearchItemToOptions(selectedOption, searchResults);
                    }
                    setInputValue(selectedOption);
                    openMenu();
                }}
                className={classnames({
                    'react-select--menu-top': fieldProps.menuPlacement === 'top',
                    'react-select--multi': !!isMulti,
                    'react-select--initial-loading':
                        !!isFetchingInitialOptions && !isInitialFetchDone,
                    // 'react-select--search-loading': !!isFetchingSearchResults,
                    'react-select--search-loading': true,
                    'react-select--use-portal': !!usePortal,
                    'react-select--action':
                        (fieldValue.value && actionButton?.edit) ||
                        (!fieldValue.value && actionButton?.create)
                })}
                options={
                    fieldProps?.sortOptionsByLabel
                        ? optionsProp.sort(sortSelectOptions)
                        : optionsProp
                }
                {...(isMulti
                    ? {
                          menuIsOpen,
                          onMenuOpen: openMenu,
                          onMenuClose: closeMenu
                      }
                    : null)}
                {...(usePortal ? { menuPortalTarget: document.body } : null)}
                placeholder={fieldAttr?.placeholder || ''}
                //  open dropown only if input is not empty
                {...(fieldProps?.typeToShowDropdown
                    ? {
                          menuIsOpen: !!localInputFieldValue
                      }
                    : null)}
            />
            {!!actionButton && renderActionButton(fieldValue, actionButton)}
        </Input>
    );
};

SelectNew.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    isMulti: false,
    options: null,
    additionalOptions: null,
    actionButton: null,
    apiData: null,
    isClearable: true,
    isCreatable: false,
    usePortal: false
};

SelectNew.propTypes = {
    fieldProps: PropTypes.shape({
        forceLabelActive: PropTypes.bool,
        menuPlacement: PropTypes.string,
        additionalOptions: PropTypes.array,
        sortOptionsByLabel: PropTypes.bool,
        typeToShowDropdown: PropTypes.bool
    }),
    fieldAttr: PropTypes.object,
    field: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    isMulti: PropTypes.bool,
    options: PropTypes.array,
    additionalOptions: PropTypes.array,
    actionButton: PropTypes.object,
    apiData: PropTypes.shape({
        endpoint: PropTypes.string,
        mapData: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        }),
        creatableKeyName: PropTypes.string,
        params: PropTypes.object
    }),
    isClearable: PropTypes.bool,
    isCreatable: PropTypes.bool,
    usePortal: PropTypes.bool
};

export default SelectNew;
