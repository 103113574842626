export const getOrganizationData = (state, iri) => state.organization?.[iri];

export const getMineOrganizationData = (state) =>
    state.organization?.[state?.authManager?.userOrganization?.iri] ||
    state?.authManager?.user?.userOrganization;

export const getOrganizationFetching = (state) => state.organization?.singleFetching;

export const getOrganizationCurrencyData = (state, organizationIri = null) =>
    organizationIri
        ? state.organization?.[organizationIri]?.currency || null
        : state.organization?.[state?.authManager?.user?.organization?.iri]?.currency || null;

export default {
    getOrganizationData,
    getOrganizationFetching,
    getOrganizationCurrencyData
};
