export const actions = {
    RESET_IMAGE_EDITOR_STATE: 'RESET_IMAGE_EDITOR_STATE',
    START_CREATE_IMAGE: 'START_CREATE_IMAGE',
    CREATE_IMAGE_SUCCESSFUL: 'CREATE_IMAGE_SUCCESSFUL',
    CREATE_IMAGE_FAILED: 'CREATE_IMAGE_FAILED',
    START_FETCHING_IMAGE: 'START_FETCHING_IMAGE',
    FETCH_IMAGE_SUCCESSFUL: 'FETCH_IMAGE_SUCCESSFUL',
    FETCH_IMAGE_FAILED: 'FETCH_IMAGE_FAILED',
    START_UPDATE_IMAGE: 'START_UPDATE_IMAGE',
    UPDATE_IMAGE_SUCCESSFUL: 'UPDATE_IMAGE_SUCCESSFUL',
    UPDATE_IMAGE_FAILED: 'UPDATE_IMAGE_FAILED',
    START_DELETE_IMAGE: 'START_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESSFUL: 'DELETE_IMAGE_SUCCESSFUL',
    DELETE_IMAGE_FAILED: 'DELETE_IMAGE_FAILED',
    START_DELETE_IMAGE_VERSION: 'START_DELETE_IMAGE_VERSION',
    DELETE_IMAGE_VERSION_SUCCESSFUL: 'DELETE_IMAGE_VERSION_SUCCESSFUL',
    DELETE_IMAGE_VERSION_FAILED: 'DELETE_IMAGE_VERSION_FAILED',
    START_CREATE_IMAGE_VERSION: 'START_CREATE_IMAGE_VERSION',
    CREATE_IMAGE_VERSION_SUCCESSFUL: 'CREATE_IMAGE_VERSION_SUCCESSFUL',
    CREATE_IMAGE_VERSION_FAILED: 'CREATE_IMAGE_VERSION_FAILED'
};

export const initialState = {
    collection: null,
    error: [],
    fetching: false,
    fetchingData: {},
    creating: false,
    deleting: false,
    updating: false
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.RESET_IMAGE_EDITOR_STATE: {
            return {
                ...initialState
            };
        }
        case actions.START_FETCHING_IMAGE: {
            return {
                ...state,
                // collection: null,
                fetching: true,
                fetchingData: {
                    ...state.fetchingData,
                    [iri]: true
                }
            };
        }
        case actions.FETCH_IMAGE_SUCCESSFUL: {
            return {
                ...state,
                collection: {
                    ...state.collection,
                    [iri]: response
                },
                fetching: false,
                fetchingData: {
                    ...state.fetchingData,
                    [iri]: false
                },
                error: false
            };
        }
        case actions.FETCH_IMAGE_FAILED: {
            return {
                ...state,
                fetching: false,
                fetchingData: {
                    ...state.fetchingData,
                    [iri]: false
                },
                error: response
            };
        }
        case actions.START_UPDATE_IMAGE: {
            return {
                ...state,
                updating: true
            };
        }
        case actions.UPDATE_IMAGE_SUCCESSFUL: {
            return {
                ...state,
                collection: {
                    ...state.collection,
                    [iri]: response
                },
                updating: false,
                error: false
            };
        }
        case actions.UPDATE_IMAGE_FAILED: {
            return {
                ...state,
                updating: false,
                error: response
            };
        }
        case actions.START_DELETE_IMAGE: {
            return {
                ...state,
                deleting: true
            };
        }
        case actions.DELETE_IMAGE_SUCCESSFUL: {
            return {
                ...state,
                deleting: false,
                error: false
            };
        }
        case actions.DELETE_IMAGE_FAILED: {
            return {
                ...state,
                deleting: false,
                error: response
            };
        }
        case actions.START_DELETE_IMAGE_VERSION: {
            return {
                ...state,
                updating: true
            };
        }
        case actions.DELETE_IMAGE_VERSION_SUCCESSFUL: {
            return {
                ...state,
                updating: false,
                error: false
            };
        }
        case actions.DELETE_IMAGE_VERSION_FAILED: {
            return {
                ...state,
                updating: false,
                error: response
            };
        }
        case actions.START_CREATE_IMAGE_VERSION: {
            return {
                ...state,
                updating: true
            };
        }
        case actions.CREATE_IMAGE_VERSION_SUCCESSFUL: {
            return {
                ...state,
                updating: false,
                error: false
            };
        }
        case actions.CREATE_IMAGE_VERSION_FAILED: {
            return {
                ...state,
                updating: false,
                error: response
            };
        }
        default:
            return state;
    }
};
