import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as timeOffActions } from './TimeOff.reducer';

const includeString = 'include=workflowTransitionLogs,workflowTransitionLogs.user,requestedBy,type';

/**
 * Create Time Off
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createTimeOff({ promise, formData }) {
    try {
        const createTimeOffAPI = yield restClient.post(`/api/time-offs`, formData);
        yield put({
            type: timeOffActions.CREATE_TIME_OFF_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createTimeOffAPI?.data
        });
        yield call(promise.resolve, createTimeOffAPI?.data);
    } catch (error) {
        yield put({
            type: timeOffActions.CREATE_TIME_OFF_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Time Off
 * @param  {Object} promise
 * @return {string} id Time Off id
 */
export function* fetchTimeOff({ promise, iri }) {
    try {
        const fetchTimeOffAPI = yield restClient.get(`${iri}?${includeString}`);
        yield put({
            type: timeOffActions.FETCH_TIME_OFF_SUCCESSFUL
        });
        yield put({
            type: timeOffActions.STORE_TIME_OFF_DATA,
            iri,
            response: dto(fetchTimeOffAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffActions.FETCH_TIME_OFF_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Time Off single data
 * @param  {Object} id ID of an Time Off
 * @return {Object} Response from API
 */
export function* updateSingleTimeOff({ promise, formData, iri }) {
    try {
        const updateSingleTimeOffAPI = yield restClient.put(`${iri}?${includeString}`, formData);

        yield put({
            type: timeOffActions.UPDATE_TIME_OFF_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleTimeOffAPI?.data
        });
        yield put({
            type: timeOffActions.STORE_TIME_OFF_DATA,
            iri,
            response: dto(updateSingleTimeOffAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffActions.UPDATE_TIME_OFF_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Status Time Off single data
 * @param  {Object} id ID of an Time Off
 * @return {Object} Response from API
 */
export function* changeStatusTimeOff({ promise, formData, iri }) {
    try {
        const changeStatusTimeOffAPI = yield restClient.patch(
            `${iri}/change-status?${includeString}`,
            formData
        );
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changeStatusTimeOffAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffActions.TIME_OFF_CHANGE_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const timeOffSaga = [
    takeLatest(timeOffActions.START_CREATE_TIME_OFF, createTimeOff),
    takeLatest(timeOffActions.START_FETCHING_TIME_OFF, fetchTimeOff),
    takeLatest(timeOffActions.START_UPDATE_TIME_OFF, updateSingleTimeOff),
    takeLatest(timeOffActions.START_TIME_OFF_CHANGE_STATUS, changeStatusTimeOff)
];

export default timeOffSaga;
