import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Bundle template Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ProjectBudgetsNotification = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'projectbudget.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project budget successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectbudget.project.projectBudget.invalidSalesOrder':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    type="error"
                    text="All Budgets must be set to Hourly billing method in order to automatically generate Sales Orders based on hours worked."
                    expire="10000"
                />
            );
        case 'projectbudget.budgetSuccessfullyCreatedFromTimeline':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    type="success"
                    text="Budgets is successfully created from timeline."
                    expire="5000"
                />
            );
        case 'projectbudget.unknownError':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={
                        <>
                            Something went wrong while updating budget! Data displayed may not be in
                            sync
                            <br />
                            <br />
                            Please{' '}
                            <button
                                type="button"
                                style={{
                                    display: 'inline',
                                    margin: 0,
                                    padding: 0,
                                    border: 'none',
                                    borderRadius: 0,
                                    font: 'inherit',
                                    textDecoration: 'underline',
                                    fontWeight: 700,
                                    textTransform: 'none',
                                    color: '#fff',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    window.location.reload();
                                    return false;
                                }}
                            >
                                refresh
                            </button>{' '}
                            the page.
                        </>
                    }
                    type="error"
                />
            );
        case 'period.invalid':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    type="error"
                    text="Invalid period"
                    expire="5000"
                />
            );
        case 'period.invalid.toSmall':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    type="error"
                    text="Duplication period too small"
                    expire="5000"
                />
            );

        default:
            return null;
    }
};

ProjectBudgetsNotification.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ProjectBudgetsNotification.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ProjectBudgetsNotification;
