import React from 'react';
import PropTypes from 'prop-types';
import styles from 'erpcore/components/Form/components/Input/Input.scss';
import Input from '../Input';

const ViewText = ({ input, meta, fieldProps, fieldAttr, field }) => (
    <Input fieldProps={fieldProps} fieldAttr={fieldAttr} field={field} input={input} meta={meta}>
        {input.value ? (
            <p className={`${styles.input__field}`} id={input.name}>
                {input.value}
            </p>
        ) : (
            <p className="input__field input__field--empty" id={input.name}>
                -
            </p>
        )}
    </Input>
);

ViewText.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

ViewText.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ViewText;
