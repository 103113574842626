export const getResourceAllocationsData = (state, id) =>
    state.scheduling?.[id]?.resourceAllocationData || null;

export const getResourceAllocationsFetching = (state, id) =>
    state.scheduling?.[id] ? state.scheduling?.[id].resourceAllocationFetching : true;

export const getResourceAllocationsUsers = (state, id) =>
    state.scheduling?.[id]?.resourceAllocationUsers || null;

export const getCapacityManagementData = (state, id) =>
    state.scheduling?.[id]?.capacityManagementUsers?.data || null;

export const getCapacityManagementMeta = (state, id) =>
    state.scheduling?.[id]?.capacityManagementUsers?.meta || null;

export const getCapacityManagementFetching = (state, id) =>
    state.scheduling?.[id] ? state.scheduling?.[id].capacityManagementFetching : true;

export const getCapacityManagementSimulationFetching = (state, id) =>
    state.scheduling?.[id] ? state.scheduling?.[id].capacityManagementSimulationFetching : false;

export const getCapacityManagementSimulationData = (state, id) =>
    state.scheduling?.[id]?.capacityManagementSimulationData || null;
