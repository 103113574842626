import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as tagsActions } from 'erpcore/screens/Settings/Tags/Tags.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import dto from 'erpcore/utils/dto';

/**
 * Create Tag
 * @param promise
 * @param  {Object} formData
 * @param endpoint
 * @return {Object} Response from API
 */
export function* createTag({ promise, formData, endpoint }) {
    try {
        const createTagAPI = yield restClient.post(endpoint, formData);
        yield put({
            type: tagsActions.CREATE_TAG_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createTagAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'tags' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TAGS',
            name: 'tags',
            endpoint
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tagsActions.CREATE_TAG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Tag
 * @param  {Object} promise
 * @param iri
 * @return {string} iri Tag iri
 */
export function* fetchTag({ promise, iri }) {
    try {
        const fetchTagAPI = yield restClient.get(iri);
        yield put({
            type: tagsActions.FETCH_TAG_SUCCESSFUL
        });

        yield put({
            type: tagsActions.STORE_TAG_DATA,
            iri,
            response: dto(fetchTagAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tagsActions.FETCH_TAG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Tag data
 * @param promise
 * @param  {Object} iri of Tag
 * @param formData
 * @param endpoint
 * @return {Object} Response from API
 */
export function* updateTag({ promise, iri, formData, endpoint }) {
    try {
        const updateTagAPI = yield restClient.patch(iri, formData);
        yield put({
            type: tagsActions.UPDATE_TAG_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateTagAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'tags' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TAGS',
            name: 'tags',
            endpoint
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tagsActions.UPDATE_TAG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Tag
 * @param promise
 * @param  {Object} iri of Tag
 * @param endpoint
 * @return {Object} Response from API
 */
export function* deleteTag({ promise, iri, endpoint }) {
    try {
        const deleteTagAPI = yield restClient.delete(iri);
        yield put({
            type: tagsActions.DELETE_TAG_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteTagAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'tags' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'TAGS',
            name: 'tags',
            endpoint
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: tagsActions.DELETE_TAG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const tagsSaga = [
    takeLatest(tagsActions.START_CREATE_TAG, createTag),
    takeLatest(tagsActions.START_FETCHING_TAG, fetchTag),
    takeLatest(tagsActions.START_UPDATE_TAG, updateTag),
    takeLatest(tagsActions.START_DELETE_TAG, deleteTag)
];

export default tagsSaga;
