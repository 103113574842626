/**
 * @returns object containing all Location fields data
 * @param {object} data
 */
const getLocationData = data => {
    /*
    location: {
            full_address: address,
            street,
            country,
            state,
            city,
            zip,
            latitude,
            longitude
        }
        */
    console.error(data);
    return data;
};

/**
 * @returns 'data' object containing with rearranged Location fields data under 'fieldName'
 * @param {string} fieldName
 * @param {object} data
 */
const setLocationData = (fieldName, fdata) => {
    if (!fdata) {
        return null;
    }

    if (!fieldName) {
        return fdata;
    }

    const data = { ...fdata };

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'full_address')) {
        data.full_address =
            data[fieldName].full_address !== '' ? data[fieldName].full_address : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'street')) {
        data.street = data[fieldName].street !== '' ? data[fieldName].street : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'country')) {
        data.country = data[fieldName].country !== '' ? data[fieldName].country : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'state')) {
        data.state = data[fieldName].state !== '' ? data[fieldName].state : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'city')) {
        data.city = data[fieldName].city !== '' ? data[fieldName].city : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'zip')) {
        data.zip = data[fieldName].zip !== '' ? data[fieldName].zip : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'latitude')) {
        data.latitude = data[fieldName].latitude !== '' ? data[fieldName].latitude : null;
    }

    if (Object.prototype.hasOwnProperty.call(data[fieldName], 'longitude')) {
        data.longitude = data[fieldName].longitude !== '' ? data[fieldName].longitude : null;
    }

    delete data[fieldName];
    delete data.included;

    return data;
};

export { getLocationData, setLocationData };
