import React from 'react';
import PropTypes from 'prop-types';

import './StatusManagement.scss';

const StatusManagement = ({ title, headerRight, log, actions, children, footer, className }) => {
    return (
        <div className={`status-management ${className || ''}`}>
            <div className="status-management__header">
                {title && <h2 className="status-management__title">{title}</h2>}
                {headerRight && (
                    <div className="status-management__header-right">{headerRight}</div>
                )}
            </div>
            {log && <div className="status-management__logs">{log}</div>}
            {actions && <div className="status-management__actions">{actions}</div>}
            {children && <div className="status-management__content">{children}</div>}
            {footer && <div className="status-management__footer">{footer}</div>}
        </div>
    );
};

StatusManagement.defaultProps = {
    title: null,
    headerRight: null,
    log: null,
    children: null,
    actions: null,
    footer: null,
    className: ''
};

StatusManagement.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    headerRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    log: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

/**
 * Status Management Logs Line Item
 */
StatusManagement.Log = function Action({ className, children }) {
    return <div className={`status-management__log ${className || ''}`}>{children}</div>;
};

StatusManagement.Log.defaultProps = {
    children: null,
    className: ''
};

StatusManagement.Log.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

/**
 * Status Management Log Item
 */
StatusManagement.LogItem = function Action({ className, children, isWide }) {
    return (
        <div
            className={`status-management__log-item ${
                isWide ? `status-management__log-item--wide` : ''
            } ${className || ''}`}
        >
            {children}
        </div>
    );
};

StatusManagement.LogItem.defaultProps = {
    children: null,
    isWide: false,
    className: ''
};

StatusManagement.LogItem.propTypes = {
    children: PropTypes.node,
    isWide: PropTypes.bool,
    className: PropTypes.string
};

export default StatusManagement;
