import { combineReducers } from 'redux';

import teamActivityWidget from './components/TeamActivityWidget/TeamActivityWidget.reducer';
import timeOffWidget from './components/TimeOffWidget/TimeOffWidget.reducer';

export const actions = {
    START_FETCHING_WIDGET_DATA: 'START_FETCHING_WIDGET_DATA',
    FETCHING_WIDGET_DATA_SUCCESSFUL: 'FETCHING_WIDGET_DATA_SUCCESSFUL',
    FETCHING_WIDGET_DATA_FAILED: 'FETCHING_WIDGET_DATA_FAILED',
    STORE_WIDGET_DATA: 'STORE_WIDGET_DATA'
};

export const widgetsData = (state = {}, { type, response, widgetId }) => {
    switch (type) {
        case actions.START_FETCHING_WIDGET_DATA: {
            return {
                ...state,
                [widgetId]: {
                    ...state?.[widgetId],
                    fetching: true
                }
            };
        }
        case actions.FETCHING_WIDGET_DATA_SUCCESSFUL: {
            return {
                ...state,
                [widgetId]: {
                    ...state?.[widgetId],
                    fetching: false
                }
            };
        }
        case actions.FETCHING_WIDGET_DATA_FAILED: {
            return {
                ...state,
                [widgetId]: {
                    ...state?.[widgetId],
                    fetching: false
                }
            };
        }
        case actions.STORE_WIDGET_DATA: {
            return {
                ...state,
                [widgetId]: {
                    ...state?.[widgetId],
                    data: response
                }
            };
        }
        default:
            return state;
    }
};

//  Combining all accounting reducers
const dashboardWidgets = combineReducers({
    widgetsData,
    teamActivityWidget,
    timeOffWidget
});

export default dashboardWidgets;
