import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import restClient from 'erpcore/api/restClient';
import Svg from 'erpcore/components/Svg';
import { operations } from 'erpcore/data/filterOperations';
import { getFilterTagOperatorLabel, getOptionByValue } from 'erpcore/components/Form/Form.utils';
import { generateKey } from 'erpcore/utils/utils';
import moment from 'moment-timezone';
import './FilterTags.scss';

const FilterTags = ({ onChangeFilterTag, queryParams, filterSchema, entity }) => {
    const [apiLabels, setApiLabels] = useState([]);

    const getSchemaForFilter = (filterName) => {
        const filterSchemaItem = find(
            filterSchema,
            (item) => item.name === filterName.replace(/>/g, '.')
        );

        const props = filterSchemaItem?.filterFields?.value;

        const isSelectNew = (props?.component || '').toLowerCase() === 'selectnew';

        const checkIfHasAPIOptions = () => {
            if (isSelectNew) {
                return !!(props?.apiData?.endpoint && props?.apiData?.mapData);
            }

            if (!isSelectNew) {
                return !!(
                    props?.fieldProps?.options?.endpoint && props?.fieldProps?.options?.mapData
                );
            }

            return false;
        };

        const hasAPIOptions = checkIfHasAPIOptions();

        let mapData = false;
        if (hasAPIOptions) {
            mapData = isSelectNew ? props?.apiData?.mapData : props?.fieldProps?.options?.mapData;
        }

        const hasOptions = isSelectNew
            ? !!(props?.options && isArray(props?.options))
            : !!(props?.fieldProps?.options && isArray(props?.fieldProps?.options));

        let filterSchemaOptions = [];
        if (hasOptions) {
            filterSchemaOptions = props?.fieldProps?.options;
        }

        return {
            filterSchemaItem,
            hasAPIOptions,
            mapData,
            hasOptions,
            filterSchemaOptions,
            defaultOperator: filterSchemaItem?.defaultOperator,
            formatCurrency: props?.fieldProps?.formatCurrency || false
        };
    };

    // This effect fetches filter labels from the API
    useEffect(() => {
        map(queryParams.filter, (queryParamsItem, queryParamsItemKey) => {
            const filterSchemaData = getSchemaForFilter(queryParamsItemKey);
            if (filterSchemaData.hasAPIOptions) {
                map(queryParamsItem, (queryParamValue) => {
                    map(queryParamValue, (valueItem, key) => {
                        if (key !== 'operator') {
                            if (!isArray(valueItem)) valueItem = [valueItem];
                            map(valueItem, (valueItemItem) => {
                                if (getOptionByValue(valueItemItem, apiLabels)?.labelEqualsValue) {
                                    restClient
                                        .get(valueItemItem)
                                        .then((response) => {
                                            let labelTemplate = filterSchemaData.mapData?.label;
                                            const labelTemplateMatch =
                                                labelTemplate?.match(/[^{]+(?=})/g);
                                            if (labelTemplateMatch) {
                                                labelTemplateMatch.forEach((labelItem) => {
                                                    labelTemplate = labelTemplate.replace(
                                                        `{${labelItem}}`,
                                                        response?.data?.data?.attributes?.[
                                                            labelItem
                                                        ] || ''
                                                    );
                                                });
                                            }
                                            const apiLabel = labelTemplateMatch
                                                ? labelTemplate
                                                : response?.data?.data?.attributes?.[labelTemplate];
                                            setApiLabels((oldApiLabels) => [
                                                ...oldApiLabels,
                                                { value: valueItemItem, label: apiLabel }
                                            ]);
                                        })
                                        .catch((error) => error);
                                }
                            });
                        }
                    });
                });
            }
        });
    }, [queryParams]);

    const getFilterValue = (value, key, filterSchemaData, values) => {
        if (key !== 'operator') {
            if (!isArray(value)) {
                value = [value];
            }

            value.forEach((valueItem) => {
                const result =
                    getOptionByValue(valueItem, [
                        ...apiLabels,
                        ...filterSchemaData.filterSchemaOptions
                    ])?.label || '...';

                if (
                    filterSchemaData?.filterSchemaItem?.filterFields?.value?.fieldProps?.dateFormat
                ) {
                    values.push(moment(result).format('M/D/YYYY'));
                } else if (filterSchemaData?.formatCurrency) {
                    values.push((parseFloat(result, 10) / 100).toFixed(2));
                } else {
                    values.push(result);
                }
            });
        }
    };

    const getFilterString = (label, operator, values, filterName) => {
        if (filterName === 'archived') {
            return `${operator} ${label ? ` ${label.toLowerCase()}` : ''} ${entity}`;
        }

        return `${label} ${operator ? ` ${operator.toLowerCase()}` : ''} ${values && ` ${values}`}`;
    };

    const tags = [];

    const processFilter = (filterData, index, filterSchemaData, filterName) => {
        const values = [];

        Object.entries(filterData).forEach(([key, value]) =>
            getFilterValue(value, key, filterSchemaData, values)
        );

        const operator = getFilterTagOperatorLabel(
            filterName,
            filterData?.operator || filterSchemaData.defaultOperator,
            operations
        );

        const delimiter = filterSchemaData?.filterSchemaItem?.defaultDelimiter || 'or';
        const displayString = getFilterString(
            filterSchemaData?.filterSchemaItem?.label,
            operator,
            values.join(` ${delimiter} `),
            filterName
        );

        const tag = {
            key: filterName,
            index,
            displayString
        };

        tags.push(tag);
    };

    const getTagFromFilter = (filter, filterName) => {
        const filterSchemaData = getSchemaForFilter(filterName);

        filter.forEach((filterValue, index) =>
            processFilter(filterValue, index, filterSchemaData, filterName)
        );
    };

    if (!queryParams.filter) {
        return null;
    }

    Object.entries(queryParams.filter).forEach(([filterName, filter]) =>
        getTagFromFilter(filter, filterName)
    );

    const removeTag = (item) => {
        queryParams.filter[item.key].splice(item.index, 1);
        onChangeFilterTag(queryParams);
    };

    return tags.length > 0 ? (
        <ul className="filtertags">
            {tags.map((tag) => (
                <li className="filtertags__item" key={generateKey(`${tag.key}${tag.index}`)}>
                    <span className="filtertags__label">{tag.displayString}</span>
                    <button
                        type="button"
                        className="filtertags__btn"
                        key={`${tag.index}${tag.displayString}`}
                        onClick={() => removeTag(tag)}
                    >
                        <Svg icon="close" />
                    </button>
                </li>
            ))}
            <li className="filtertags__clear">
                <button
                    type="button"
                    className="filtertags__btn-clear"
                    onClick={() => {
                        queryParams.filter = {};
                        onChangeFilterTag(queryParams);
                    }}
                >
                    Clear All
                </button>
            </li>
        </ul>
    ) : null;
};

FilterTags.defaultProps = {
    fieldProps: {},
    options: {},
    onChangeFilterTag: () => {},
    queryParams: {},
    filterSchema: [],
    apiData: {},
    component: '',
    entity: ''
};

FilterTags.propTypes = {
    fieldProps: PropTypes.shape({
        options: PropTypes.shape({
            endpoint: PropTypes.string,
            mapData: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string
            })
        }),
        endpoint: PropTypes.string,
        formatCurrency: PropTypes.bool
    }),
    options: PropTypes.object,
    onChangeFilterTag: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object]),
    filterSchema: PropTypes.oneOfType([PropTypes.array]),
    apiData: PropTypes.shape({
        endpoint: PropTypes.string,
        mapData: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    }),
    component: PropTypes.string,
    entity: PropTypes.string
};

export default FilterTags;
