export const actions = {
    START_FETCHING_REVENUE_DATA: 'START_FETCHING_REVENUE_DATA',
    FETCHING_SUCCESSFUL_REVENUE_DATA: 'FETCHING_SUCCESSFUL_REVENUE_DATA',
    FETCHING_FAILED_REVENUE_DATA: 'FETCHING_FAILED_REVENUE_DATA',
    STORE_REVENUE_DATA: 'STORE_REVENUE_DATA',

    START_FETCHING_EXPENSES_DATA: 'START_FETCHING_EXPENSES_DATA',
    FETCHING_SUCCESSFUL_EXPENSES_DATA: 'FETCHING_SUCCESSFUL_EXPENSES_DATA',
    FETCHING_FAILED_EXPENSES_DATA: 'FETCHING_FAILED_EXPENSES_DATA',
    STORE_EXPENSES_DATA: 'STORE_EXPENSES_DATA'
};

export default (state = {}, { type, data, by, chartType }) => {
    switch (type) {
        case actions.START_FETCHING_REVENUE_DATA:
            return {
                ...state,
                revenue: {
                    ...state.revenue,
                    [chartType]: {
                        ...state.revenue?.[chartType],
                        [by]: { ...state.revenue?.[by], fetching: true }
                    }
                }
            };

        case actions.FETCHING_SUCCESSFUL_REVENUE_DATA:
        case actions.FETCHING_FAILED_REVENUE_DATA:
            return {
                ...state,
                revenue: {
                    ...state.revenue,
                    [chartType]: {
                        ...state.revenue?.[chartType],
                        [by]: { ...state.revenue?.[by], fetching: false }
                    }
                }
            };

        case actions.STORE_REVENUE_DATA:
            return {
                ...state,
                revenue: {
                    ...state.revenue,
                    [chartType]: {
                        ...state.revenue?.[chartType],
                        [by]: { ...state.revenue?.[by], data }
                    }
                }
            };
        case actions.START_FETCHING_EXPENSES_DATA:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    [chartType]: {
                        ...state.expenses?.[chartType],
                        [by]: { ...state.expenses?.[by], fetching: true }
                    }
                }
            };

        case actions.FETCHING_SUCCESSFUL_EXPENSES_DATA:
        case actions.FETCHING_FAILED_EXPENSES_DATA:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    [chartType]: {
                        ...state.expenses?.[chartType],
                        [by]: { ...state.expenses?.[by], fetching: false }
                    }
                }
            };

        case actions.STORE_EXPENSES_DATA:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    [chartType]: {
                        ...state.expenses?.[chartType],
                        [by]: { ...state.expenses?.[by], data }
                    }
                }
            };

        default:
            return state;
    }
};
