import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - ProjectStages Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ProjectStages = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'stage.dependsOn.invalidStageConstraint':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Circular reference detected. Two stages cant have circular dependencies."
                    type="error"
                    expire="4500"
                />
            );
        case 'worktypeallocations.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Work type allocations successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'stage.unknownRealTimeError':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={
                        <>
                            Something went wrong! Data displayed may not be in sync.
                            <br />
                            <br />
                            Please{' '}
                            <button
                                type="button"
                                style={{
                                    display: 'inline',
                                    margin: 0,
                                    padding: 0,
                                    border: 'none',
                                    borderRadius: 0,
                                    font: 'inherit',
                                    textDecoration: 'underline',
                                    fontWeight: 700,
                                    textTransform: 'none',
                                    color: '#fff',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    window.location.reload();
                                    return false;
                                }}
                            >
                                refresh
                            </button>{' '}
                            the page.
                        </>
                    }
                    type="error"
                />
            );
        case 'projectstages.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Timeline successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectstages.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Timeline successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectstages.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Timeline successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectstagetemplate.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Timeline template successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectstagetemplateitem.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Item successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'noTimelineData':
            return (
                <Notification
                    identifier={identifier}
                    title="No data to display"
                    text="There is no data to display, start creating your timeline by clicking 'Add new'"
                    type="info"
                />
            );
        default:
            return null;
    }
};

ProjectStages.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ProjectStages.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ProjectStages;
