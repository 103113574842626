export const actions = {
    START_FETCHING_BILLING_REQUESTS: 'START_FETCHING_BILLING_REQUESTS',
    FETCHING_SUCCESSFUL_BILLING_REQUESTS: 'FETCHING_SUCCESSFUL_BILLING_REQUESTS',
    FETCHING_FAILED_BILLING_REQUESTS: 'FETCHING_FAILED_BILLING_REQUESTS',

    START_CREATE_BILLING_REQUEST: 'START_CREATE_BILLING_REQUEST',
    CREATE_BILLING_REQUEST_SUCCESSFUL: 'CREATE_BILLING_REQUEST_SUCCESSFUL',
    CREATE_BILLING_REQUEST_FAILED: 'CREATE_BILLING_REQUEST_FAILED',
    START_FETCHING_BILLING_REQUEST: 'START_FETCHING_BILLING_REQUEST',
    FETCH_BILLING_REQUEST_SUCCESSFUL: 'FETCH_BILLING_REQUEST_SUCCESSFUL',
    FETCH_BILLING_REQUEST_FAILED: 'FETCH_BILLING_REQUEST_FAILED',
    START_UPDATE_BILLING_REQUEST: 'START_UPDATE_BILLING_REQUEST',
    UPDATE_BILLING_REQUEST_SUCCESSFUL: 'UPDATE_BILLING_REQUEST_SUCCESSFUL',
    UPDATE_BILLING_REQUEST_FAILED: 'UPDATE_BILLING_REQUEST_FAILED',
    START_DELETE_BILLING_REQUEST: 'START_DELETE_BILLING_REQUEST',
    DELETE_BILLING_REQUEST_SUCCESSFUL: 'DELETE_BILLING_REQUEST_SUCCESSFUL',
    DELETE_BILLING_REQUEST_FAILED: 'DELETE_BILLING_REQUEST_FAILED',
    STORE_BILLING_REQUEST_DATA: 'STORE_BILLING_REQUEST_DATA',
    // Change Status
    START_BILLING_REQUEST_CHANGE_STATUS: 'START_BILLING_REQUEST_CHANGE_STATUS',
    BILLING_REQUEST_CHANGE_STATUS_SUCCESSFUL: 'BILLING_REQUEST_CHANGE_STATUS_SUCCESSFUL',
    BILLING_REQUEST_CHANGE_STATUS_FAILED: 'BILLING_REQUEST_CHANGE_STATUS_FAILED',
    // Time logs
    START_FETCHING_BILLING_REQUEST_TIME_LOGS: 'START_FETCHING_BILLING_REQUEST_TIME_LOGS',
    FETCHING_SUCCESSFUL_BILLING_REQUEST_TIME_LOGS: 'FETCHING_SUCCESSFUL_BILLING_REQUEST_TIME_LOGS',
    FETCHING_FAILED_BILLING_REQUEST_TIME_LOGS: 'FETCHING_FAILED_BILLING_REQUEST_TIME_LOGS',

    CLEAR_BILLING_REQUEST_STATE: 'CLEAR_BILLING_REQUEST_STATE',

    START_REFRESHING_TIMELOGS: 'START_REFRESHING_TIMELOGS',
    REFRESHING_TIMELOGS_SUCCESSFUL: 'REFRESHING_TIMELOGS_SUCCESSFUL',
    REFRESHING_TIMELOGS_FAILED: 'REFRESHING_TIMELOGS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    refreshingTimelogs: false
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_BILLING_REQUESTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_BILLING_REQUESTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_BILLING_REQUESTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_BILLING_REQUEST: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_BILLING_REQUEST_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_BILLING_REQUEST_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_BILLING_REQUEST_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_BILLING_REQUEST_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.START_FETCHING_BILLING_REQUEST_TIME_LOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_BILLING_REQUEST_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_BILLING_REQUEST_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.CLEAR_BILLING_REQUEST_STATE: {
            return {
                ...initialState
            };
        }
        case actions.START_REFRESHING_TIMELOGS: {
            return {
                ...state,
                refreshingTimelogs: true
            };
        }
        case actions.REFRESHING_TIMELOGS_SUCCESSFUL: {
            return {
                ...state,
                refreshingTimelogs: false
            };
        }
        case actions.REFRESHING_TIMELOGS_FAILED: {
            return {
                ...state,
                refreshingTimelogs: false
            };
        }
        default:
            return state;
    }
};
