import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as divisionsActions } from 'erpcore/screens/Settings/Divisions/Divisions.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Division
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createDivision({ promise, formData }) {
    try {
        const createDivisionAPI = yield restClient.post('api/divisions', formData);
        yield put({
            type: divisionsActions.CREATE_DIVISION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createDivisionAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'divisions' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DIVISIONS',
            name: 'divisions',
            endpoint: 'api/divisions'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: divisionsActions.CREATE_DIVISION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Division data
 * @param  {Object} iri of Division
 * @return {Object} Response from API
 */
export function* updateDivision({ promise, iri, formData }) {
    try {
        const updateDivisionAPI = yield restClient.patch(iri, formData);
        yield put({
            type: divisionsActions.UPDATE_DIVISION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateDivisionAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'divisions' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DIVISIONS',
            name: 'divisions',
            endpoint: 'api/divisions'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: divisionsActions.UPDATE_DIVISION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Division
 * @param  {Object} iri of Division
 * @return {Object} Response from API
 */
export function* deleteDivision({ promise, iri }) {
    try {
        const deleteDivisionAPI = yield restClient.delete(iri);
        yield put({
            type: divisionsActions.DELETE_DIVISION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteDivisionAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'divisions' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DIVISIONS',
            name: 'divisions',
            endpoint: 'api/divisions'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: divisionsActions.DELETE_DIVISION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const divisionsSaga = [
    takeLatest(divisionsActions.START_CREATE_DIVISION, createDivision),
    takeLatest(divisionsActions.START_UPDATE_DIVISION, updateDivision),
    takeLatest(divisionsActions.START_DELETE_DIVISION, deleteDivision)
];

export default divisionsSaga;
