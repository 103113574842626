import React, { useState, Fragment } from 'react';
import Svg from 'erpcore/components/Svg';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useLoadScript } from '@react-google-maps/api';

import ElementLoader from 'erpcore/components/ElementLoader';
import Form from 'erpcore/components/Form';
import GooglePlacesSuggest from 'react-google-places-suggest';
import Input from 'erpcore/components/Form/components/Input';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import './FullAddress.scss';

const libraries = ['places', 'geometry'];

/**
 * Subcomponent for the FullAddress
 * FullAddress is at the bottom of this file
 */
const FullAddressInput = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const [results, setResults] = useState([]);
    const [typing, setTyping] = useState(false);
    const [cursor, setCursor] = useState(0);
    const { name, value, onChange, onBlur } = input;
    const { onSelect, onFullAddressClear } = fieldProps;
    const hasValue = value.length > 0 || value === 0 || false;

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: enviromentVariables.GOOGLE_MAPS_API_KEY,
        libraries
    });

    const googleMaps = window?.google?.maps;

    /**
     *
     * @param ev
     * @param place
     */
    const onSuggestionSelect = (ev, place) => {
        const { place_id: placeId } = { ...place };
        ev.preventDefault();
        setTyping(false);

        return onSelect(placeId);
    };

    const renderInputField = () => {
        return (
            <Fragment>
                <input
                    type="text"
                    {...input}
                    {...fieldAttr}
                    id={name}
                    value={value}
                    className="input__field"
                    aria-label={`${name}`}
                    autoComplete="off"
                    onChange={ev =>
                        onChange(() => {
                            const { value: currentValue } = ev.currentTarget;
                            setTyping(true);
                            return currentValue;
                        })
                    }
                    onBlur={() => {
                        onBlur(() => {
                            setTimeout(() => {
                                setTyping(false);
                                setCursor(0);
                            }, 500);
                        });
                    }}
                    onKeyDown={ev => {
                        if (ev.keyCode === 38 && cursor > 0) {
                            setCursor(cursor - 1);
                        } else if (ev.keyCode === 40 && cursor < results.length - 1) {
                            setCursor(cursor + 1);
                        } else if (ev.keyCode === 13) {
                            onSuggestionSelect(ev, results[cursor]);
                        }
                    }}
                />
                {hasValue && (
                    <span
                        role="button"
                        aria-label={`Clear ${name}`}
                        tabIndex="-1"
                        className="input__action input__action--clear"
                        onKeyPress={onFullAddressClear}
                        onClick={onFullAddressClear}
                    >
                        <Svg icon="close" />
                    </span>
                )}
            </Fragment>
        );
    };

    const renderCustomContainer = items => {
        if (items?.length) {
            setResults(items);
        }

        if (typing && results.length) {
            return (
                <ul className="location__dropdown">
                    {results.length ? (
                        results.map((item, index) => {
                            return (
                                <li
                                    key={item.id}
                                    className={`location__dropdown-item ${
                                        cursor === index ? 'location__dropdown-item--active' : ''
                                    }`}
                                >
                                    <button
                                        type="button"
                                        className="location__dropdown-btn"
                                        onClick={ev => onSuggestionSelect(ev, item)}
                                        onKeyPress={ev => onSuggestionSelect(ev, item)}
                                    >
                                        {item.description}
                                    </button>
                                </li>
                            );
                        })
                    ) : (
                        <li key="ganoresaults" className="location__dropdown-message">
                            No Results!
                        </li>
                    )}
                </ul>
            );
        }

        return null;
    };

    const renderField = () => {
        return (
            <Form.Row>
                <Input
                    meta={meta}
                    input={input}
                    field={field}
                    fieldProps={fieldProps}
                    fieldAttr={fieldAttr}
                >
                    {googleMaps && (
                        <GooglePlacesSuggest
                            googleMaps={googleMaps}
                            displayPoweredByGoogle={false}
                            autocompletionRequest={{ input: value }}
                            customContainerRender={items => {
                                return renderCustomContainer(items);
                            }}
                        >
                            {renderInputField()}
                        </GooglePlacesSuggest>
                    )}
                </Input>
            </Form.Row>
        );
    };

    if (loadError) {
        return <div>Field cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderField() : <ElementLoader overlay />;
};

FullAddressInput.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

FullAddressInput.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

const FullAddress = ({ fieldName, label, disabled, onSelect, onFullAddressClear }) => {
    return (
        <Field
            name={`${fieldName}.full_address`}
            id={`${fieldName}FullAddress`}
            fieldProps={{
                icon: 'location',
                label: label || 'Full address',
                onSelect,
                onFullAddressClear
            }}
            component={FullAddressInput}
            fieldAttr={{ disabled }}
        />
    );
};

FullAddress.defaultProps = {
    fieldName: null,
    label: null,
    disabled: false,
    onSelect: () => {},
    onFullAddressClear: () => {}
};

FullAddress.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    onFullAddressClear: PropTypes.func
};

export default FullAddress;
