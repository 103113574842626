import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './PageLoader.scss';

/**
 *
 * @param {*} props
 */
const PageLoader = ({ className, content }) => {
    return (
        <div className={`page-loader ${content ? 'page-loader--content' : ''} ${className || ''}`}>
            <Svg icon="elementLoader" />
        </div>
    );
};

PageLoader.defaultProps = {
    className: '',
    content: false
};

PageLoader.propTypes = {
    className: PropTypes.string,
    content: PropTypes.bool
};

export default PageLoader;
