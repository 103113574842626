import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions } from 'erpcore/screens/Reports/ProfitabilityReports/ProfitabilityReports.reducer';

const BASE_GENERIC_REPORTS_URL = '/api/accounting/generic-reports';

function getGenericReportsEndpoint(uri) {
    return `${BASE_GENERIC_REPORTS_URL}${uri}`;
}
/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchRevenueData({
    promise,
    params,
    by,
    chartType,
    isAllocationsEndpoint = false
}) {
    try {
        const endpoint = getGenericReportsEndpoint(
            isAllocationsEndpoint ? '/revenue-allocations' : '/revenues'
        );

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_REVENUE_DATA,
            by,
            chartType
        });

        yield put({
            type: actions.STORE_REVENUE_DATA,
            data: dto(apiResponse?.data, {}, true),
            by,
            chartType
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_REVENUE_DATA,
            by,
            chartType
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchExpensesData({
    promise,
    params,
    by,
    chartType,
    isAllocationsEndpoint = false
}) {
    try {
        const endpoint = getGenericReportsEndpoint(
            isAllocationsEndpoint ? '/expense-allocations' : '/expenses'
        );

        const apiResponse = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: actions.FETCHING_SUCCESSFUL_EXPENSES_DATA,
            by,
            chartType
        });
        yield put({
            type: actions.STORE_EXPENSES_DATA,
            data: dto(apiResponse?.data, {}, true),
            by,
            chartType
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: actions.FETCHING_FAILED_EXPENSES_DATA,
            by,
            chartType
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(actions.START_FETCHING_REVENUE_DATA, fetchRevenueData),
    takeEvery(actions.START_FETCHING_EXPENSES_DATA, fetchExpensesData)
];
