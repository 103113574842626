import { useCallback, useEffect, useState, useRef } from 'react';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';

// eslint-disable-next-line import/prefer-default-export
export const useDropdown = () => {
    const [dropdownOpenStatus, setDropdownOpenStatus] = useState({});
    const dropdownOpenStatusRef = useRef({});

    const toggleDropdown = useCallback(
        (dropdownKey) => {
            setDropdownOpenStatus({
                [dropdownKey]: !dropdownOpenStatus[dropdownKey]
            });
        },
        [dropdownOpenStatus]
    );

    /**
     * Used to close dropdowns on outside click
     * @param e {Object} Click event
     * @returns void
     */
    const handleClick = useCallback((e) => {
        const dropdowns = document.getElementsByClassName(styles['time-tracking__dropdown']);
        const checkActiveDropDowns = Array.from(dropdowns).filter(
            (dropdown) => dropdown.dataset.opened === 'true'
        );

        const clickTargetIsDropdown =
            e?.target?.closest('.time-tracking__dropdown') ||
            e?.target?.classList.value.includes('react-datepicker__day') ||
            e?.target?.classList.contains(styles['time-tracking__create-button']) ||
            e?.target?.classList.contains(styles['time-tracking__dropdown']);

        if (!clickTargetIsDropdown || checkActiveDropDowns.length > 1) {
            setDropdownOpenStatus({});
        }
    }, []);

    const handleKeyUp = useCallback((e) => {
        if (e?.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();

            setDropdownOpenStatus({});
        }
    }, []);

    useEffect(() => {
        // Used to close dropdown on outside click
        if (Object.keys(dropdownOpenStatus)?.length) {
            window.addEventListener('click', handleClick);
            window.addEventListener('keypress', handleKeyUp);
        } else {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keypress', handleKeyUp);
        }

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keypress', handleKeyUp);
        };
    }, [dropdownOpenStatus]);

    useEffect(() => {
        dropdownOpenStatusRef.current = dropdownOpenStatus;
    }, [dropdownOpenStatus]);

    return {
        toggleDropdown,
        dropdownOpenStatus,
        setDropdownOpenStatus
    };
};
