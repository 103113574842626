export const actions = {
    START_FETCHING_USERS: 'START_FETCHING_USERS',
    FETCHING_SUCCESSFUL_USERS: 'FETCHING_SUCCESSFUL_USERS',
    FETCHING_FAILED_USERS: 'FETCHING_FAILED_USERS',

    START_CREATE_USER: 'START_CREATE_USER',
    CREATE_USER_SUCCESSFUL: 'CREATE_USER_SUCCESSFUL',
    CREATE_USER_FAILED: 'CREATE_USER_FAILED',
    START_FETCHING_SINGLE_USER: 'START_FETCHING_SINGLE_USER',
    FETCH_SINGLE_USER_SUCCESSFUL: 'FETCH_SINGLE_USER_SUCCESSFUL',
    FETCH_SINGLE_USER_FAILED: 'FETCH_SINGLE_USER_FAILED',
    START_UPDATE_SINGLE_USER: 'START_UPDATE_SINGLE_USER',
    UPDATE_SINGLE_USER_SUCCESSFUL: 'UPDATE_SINGLE_USER_SUCCESSFUL',
    UPDATE_SINGLE_USER_FAILED: 'UPDATE_SINGLE_USER_FAILED',
    START_CHANGE_PASSWORD_SINGLE_USER: 'START_CHANGE_PASSWORD_SINGLE_USER',
    CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL: 'CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL',
    CHANGE_PASSWORD_SINGLE_USER_FAILED: 'CHANGE_PASSWORD_SINGLE_USER_FAILED',
    START_DELETE_SINGLE_USER: 'START_DELETE_SINGLE_USER',
    DELETE_SINGLE_USER_SUCCESSFUL: 'DELETE_SINGLE_USER_SUCCESSFUL',
    DELETE_SINGLE_USER_FAILED: 'DELETE_SINGLE_USER_FAILED',
    STORE_SINGLE_USER_DATA: 'STORE_SINGLE_USER_DATA',
    START_FETCH_USER_ESTIMATED_HOURS_TO_LOG: 'START_FETCH_USER_ESTIMATED_HOURS_TO_LOG',
    FETCH_USER_ESTIMATED_HOURS_TO_LOG_SUCCESSFUL: 'FETCH_USER_ESTIMATED_HOURS_TO_LOG_SUCCESSFUL',
    FETCH_USER_ESTIMATED_HOURS_TO_LOG_FAILED: 'FETCH_USER_ESTIMATED_HOURS_TO_LOG_FAILED',
    STORE_USER_ESTIMATED_HOURS_TO_LOG: 'STORE_USER_ESTIMATED_HOURS_TO_LOG',

    START_UPDATE_USER_WORKING_DAYS: 'START_UPDATE_USER_WORKING_DAYS',
    UPDATE_USER_WORKING_DAYS_SUCCESSFUL: 'UPDATE_USER_WORKING_DAYS_SUCCESSFUL',
    UPDATE_USER_WORKING_DAYS_FAILED: 'UPDATE_USER_WORKING_DAYS_FAILED',
    START_FETCH_USER_CUMULATIVE_HOURS_WORKED: 'START_FETCH_USER_CUMULATIVE_HOURS_WORKED',
    FETCH_USER_CUMULATIVE_HOURS_WORKED_SUCCESSFUL: 'FETCH_USER_CUMULATIVE_HOURS_WORKED_SUCCESSFUL',
    STORE_USER_CUMULATIVE_HOURS_WORKED: 'STORE_USER_CUMULATIVE_HOURS_WORKED',
    FETCH_USER_CUMULATIVE_HOURS_WORKED_FAILED: 'FETCH_USER_CUMULATIVE_HOURS_WORKED_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_USERS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_USERS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_USERS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_USER: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_USER_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_USER_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_USER_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SINGLE_USER_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.START_FETCH_USER_ESTIMATED_HOURS_TO_LOG: {
            return {
                ...state,
                estimatedHoursToLog: { ...state.estimatedHoursToLog, fetching: true }
            };
        }
        case actions.FETCH_USER_ESTIMATED_HOURS_TO_LOG_SUCCESSFUL: {
            return {
                ...state,
                estimatedHoursToLog: { ...state.estimatedHoursToLog, ...response, fetching: false }
            };
        }
        case actions.FETCH_USER_ESTIMATED_HOURS_TO_LOG_FAILED: {
            return {
                ...state,
                estimatedHoursToLog: { ...state.estimatedHoursToLog, fetching: false }
            };
        }
        case actions.STORE_USER_ESTIMATED_HOURS_TO_LOG: {
            return {
                ...state,
                estimatedHoursToLog: { ...state.estimatedHoursToLog, [iri]: response.data }
            };
        }
        case actions.START_FETCH_USER_CUMULATIVE_HOURS_WORKED: {
            return {
                ...state,
                cumulativeHoursWorked: { ...state.cumulativeHoursWorked, fetching: true }
            };
        }
        case actions.FETCH_USER_CUMULATIVE_HOURS_WORKED_SUCCESSFUL:
        case actions.FETCH_USER_CUMULATIVE_HOURS_WORKED_FAILED: {
            return {
                ...state,
                cumulativeHoursWorked: { ...state.cumulativeHoursWorked, fetching: false }
            };
        }
        case actions.STORE_USER_CUMULATIVE_HOURS_WORKED: {
            return {
                ...state,
                cumulativeHoursWorked: { ...state.cumulativeHoursWorked, [iri]: response }
            };
        }
        default:
            return state;
    }
};
