export const getSelectState = state => state.select;

export const getSelectDataByKey = (state, selectEndpoint) => state.select?.[selectEndpoint]?.data;

export const getSelectDataTimeFetchedByKey = (state, selectEndpoint) =>
    state.select?.[selectEndpoint]?.timeFetched;

export const getSelectFetchingByKey = (state, selectEndpoint) =>
    !!state.select?.[selectEndpoint]?.fetching;

const objectRef = {};
export const getSelectMissingOptionsFetching = (state, selectEndpoint, optionIri) => {
    if (optionIri) {
        return state.select?.[selectEndpoint]?.fetchingMissingOptions?.[optionIri] || false;
    }

    return state.select?.[selectEndpoint]?.fetchingMissingOptions || objectRef;
};

export const getSelectInitialFetchDoneByKey = (state, selectEndpoint) =>
    !!state.select?.[selectEndpoint]?.initialFetchDone;
