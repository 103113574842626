import restClient from 'erpcore/api/restClient';

/**
 * setToken is filling api clients with token
 * @param  {String} token JWT Token that's been using for API
 */
export function setToken(token) {
    localStorage.setItem('token', token);
    restClient.defaults.headers.Authorization = `Bearer ${token}`;
}

//  clearToken clearing clients from token
export function clearToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('impersonateToken');
    restClient.defaults.headers.Authorization = ``;
}

export function setImpersonateToken(token) {
    localStorage.setItem('impersonateToken', token);
    restClient.defaults.headers.Authorization = `Bearer ${token}`;
}

export function clearImpersonateToken() {
    localStorage.removeItem('impersonateToken');
    const originalToken = localStorage.getItem('token');
    if (originalToken) {
        restClient.defaults.headers.Authorization = `Bearer ${originalToken}`;
    } else {
        restClient.defaults.headers.Authorization = ``;
    }
}
