export const actions = {
    START_FETCHING_PENDING_TIME_LOGS: 'START_FETCHING_PENDING_TIME_LOGS',
    FETCHING_SUCCESSFUL_PENDING_TIME_LOGS: 'FETCHING_SUCCESSFUL_PENDING_TIME_LOGS',
    FETCHING_FAILED_PENDING_TIME_LOGS: 'FETCHING_FAILED_PENDING_TIME_LOGS'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    }
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PENDING_TIME_LOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PENDING_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PENDING_TIME_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
