import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as billingRequestsActions } from './BillingRequests.reducer';

const includeString =
    'include=workflowTransitionLogs,workflowTransitionLogs.user,workflowTransitionLogs.comment,company,department,project,category,paymentMethod,paymentTerm,additionalSubscribers,attachments';

/**
 * Delete Sales Order
 * @param  {Object} iri of a Sales Order
 * @return {Object} Response from API
 */
export function* deleteBillingRequest({ promise, iri }) {
    try {
        const deleteBillingRequestAPI = yield restClient.delete(iri);
        yield put({
            type: billingRequestsActions.DELETE_BILLING_REQUEST_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteBillingRequestAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: billingRequestsActions.DELETE_BILLING_REQUEST_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Sales Order
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createBillingRequest({ promise, formData }) {
    try {
        const createBillingRequestAPI = yield restClient.post(`/api/billing-requests`, formData);
        yield put({
            type: billingRequestsActions.CREATE_BILLING_REQUEST_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBillingRequestAPI?.data
        });
        yield call(promise.resolve, createBillingRequestAPI?.data);
    } catch (error) {
        yield put({
            type: billingRequestsActions.CREATE_BILLING_REQUEST_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Sales Order
 * @param  {Object} promise
 * @return {string} iri Sales Order iri
 */
export function* fetchBillingRequest({ promise, iri }) {
    try {
        const fetchBillingRequestAPI = yield restClient.get(`${iri}?${includeString}`);
        yield put({
            type: billingRequestsActions.FETCH_BILLING_REQUEST_SUCCESSFUL
        });
        yield put({
            type: billingRequestsActions.STORE_BILLING_REQUEST_DATA,
            iri,
            response: dto(fetchBillingRequestAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: billingRequestsActions.FETCH_BILLING_REQUEST_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Sales Order single data
 * @param  {Object} iri iri of Sales Order
 * @return {Object} Response from API
 */
export function* updateSingleBillingRequest({ promise, formData, iri }) {
    try {
        const updateSingleBillingRequestAPI = yield restClient.put(
            `${iri}?${includeString}`,
            formData
        );

        yield put({
            type: billingRequestsActions.UPDATE_BILLING_REQUEST_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleBillingRequestAPI?.data
        });
        yield put({
            type: billingRequestsActions.STORE_BILLING_REQUEST_DATA,
            iri,
            response: dto(updateSingleBillingRequestAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: billingRequestsActions.UPDATE_BILLING_REQUEST_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Status Sales Order single data
 * @param  {Object} iri of an Sales Order
 * @return {Object} Response from API
 */
export function* changeStatusBillingRequest({ promise, formData, iri }) {
    try {
        const changeStatusBillingRequestAPI = yield restClient.patch(
            `${iri}/change-status?${includeString}`,
            formData
        );
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changeStatusBillingRequestAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: billingRequestsActions.BILLING_REQUEST_CHANGE_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Refresh Timelogs
 * @param {String} id - Sales order ID
 * @return {Object} Response from API
 */
export function* refreshTimeLogs({ promise, id }) {
    try {
        yield restClient.patch(`api/billing-requests/${id}/refresh-timelogs`, {});

        yield put({
            type: billingRequestsActions.REFRESHING_TIMELOGS_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: billingRequestsActions.REFRESHING_TIMELOGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const billingRequestsSaga = [
    takeLatest(billingRequestsActions.START_DELETE_BILLING_REQUEST, deleteBillingRequest),
    takeLatest(billingRequestsActions.START_CREATE_BILLING_REQUEST, createBillingRequest),
    takeLatest(billingRequestsActions.START_FETCHING_BILLING_REQUEST, fetchBillingRequest),
    takeLatest(billingRequestsActions.START_UPDATE_BILLING_REQUEST, updateSingleBillingRequest),
    takeLatest(
        billingRequestsActions.START_BILLING_REQUEST_CHANGE_STATUS,
        changeStatusBillingRequest
    ),
    takeLatest(billingRequestsActions.START_REFRESHING_TIMELOGS, refreshTimeLogs)
];

export default billingRequestsSaga;
