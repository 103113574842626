export const actions = {
    START_FETCHING_PAYROLL_REPORTS: 'START_FETCHING_PAYROLL_REPORTS',
    FETCHING_SUCCESSFUL_PAYROLL_REPORTS: 'FETCHING_SUCCESSFUL_PAYROLL_REPORTS',
    FETCHING_FAILED_PAYROLL_REPORTS: 'FETCHING_FAILED_PAYROLL_REPORTS',

    START_CREATE_PAYROLL_REPORT: 'START_CREATE_PAYROLL_REPORT',
    CREATE_PAYROLL_REPORT_SUCCESSFUL: 'CREATE_PAYROLL_REPORT_SUCCESSFUL',
    CREATE_PAYROLL_REPORT_FAILED: 'CREATE_PAYROLL_REPORT_FAILED',
    START_FETCHING_PAYROLL_REPORT: 'START_FETCHING_PAYROLL_REPORT',
    FETCH_PAYROLL_REPORT_SUCCESSFUL: 'FETCH_PAYROLL_REPORT_SUCCESSFUL',
    FETCH_PAYROLL_REPORT_FAILED: 'FETCH_PAYROLL_REPORT_FAILED',
    START_UPDATE_PAYROLL_REPORT: 'START_UPDATE_PAYROLL_REPORT',
    UPDATE_PAYROLL_REPORT_SUCCESSFUL: 'UPDATE_PAYROLL_REPORT_SUCCESSFUL',
    UPDATE_PAYROLL_REPORT_FAILED: 'UPDATE_PAYROLL_REPORT_FAILED',
    START_DELETE_PAYROLL_REPORT: 'START_DELETE_PAYROLL_REPORT',
    DELETE_PAYROLL_REPORT_SUCCESSFUL: 'DELETE_PAYROLL_REPORT_SUCCESSFUL',
    DELETE_PAYROLL_REPORT_FAILED: 'DELETE_PAYROLL_REPORT_FAILED',
    STORE_PAYROLL_REPORT_DATA: 'STORE_PAYROLL_REPORT_DATA',
    // Change Status
    START_PAYROLL_REPORT_CHANGE_STATUS: 'START_PAYROLL_REPORT_CHANGE_STATUS',
    PAYROLL_REPORT_STATUS_SUCCESSFUL: 'PAYROLL_REPORT_STATUS_SUCCESSFUL',
    PAYROLL_REPORT_CHANGE_STATUS_FAILED: 'PAYROLL_REPORT_CHANGE_STATUS_FAILED',

    START_FETCHING_PAYROLL_REPORT_CURRENCY: 'START_FETCHING_PAYROLL_REPORT_CURRENCY',
    FETCHING_SUCCESSFUL_PAYROLL_REPORT_CURRENCY: 'FETCHING_SUCCESSFUL_PAYROLL_REPORT_CURRENCY',
    FETCHING_FAILED_PAYROLL_REPORT_CURRENCY: 'FETCHING_FAILED_PAYROLL_REPORT_CURRENCY',

    START_FETCHING_PAYROLL_REPORT_USER_WORKED: 'START_FETCHING_PAYROLL_REPORT_USER_WORKED',
    FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_WORKED:
        'FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_WORKED',
    FETCHING_FAILED_PAYROLL_REPORT_USER_WORKED: 'FETCHING_FAILED_PAYROLL_REPORT_USER_WORKED',

    START_FETCHING_PAYROLL_REPORT_USER_RECORDS: 'START_FETCHING_PAYROLL_REPORT_USER_RECORDS',
    FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_RECORDS:
        'FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_RECORDS',
    FETCHING_FAILED_PAYROLL_REPORT_USER_RECORDS: 'FETCHING_FAILED_PAYROLL_REPORT_USER_RECORDS',

    CLEAR_STATE: 'CLEAR_STATE'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    currency: [],
    userWorked: [],
    userWorkedFetching: [],
    userEmployeeRecords: [],
    userEmployeeRecordsFetching: []
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_PAYROLL_REPORTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PAYROLL_REPORTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PAYROLL_REPORTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_PAYROLL_REPORT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_PAYROLL_REPORT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_PAYROLL_REPORT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_PAYROLL_REPORT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_PAYROLL_REPORT_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_CURRENCY: {
            return {
                ...state,
                currency: { ...state.currency, [iri]: response.data }
            };
        }
        case actions.START_FETCHING_PAYROLL_REPORT_USER_WORKED: {
            const newUserWorkedFetching = state.userWorkedFetching;
            newUserWorkedFetching.push(iri);
            return {
                ...state,
                userWorkedFetching: newUserWorkedFetching
            };
        }
        case actions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_WORKED: {
            return {
                ...state,
                userWorkedFetching: state.userWorkedFetching.filter(user => user !== iri),
                userWorked: { ...state.userWorked, [iri]: response.data }
            };
        }
        case actions.FETCHING_FAILED_PAYROLL_REPORT_USER_WORKED: {
            return {
                ...state,
                userWorkedFetching: state.userWorkedFetching.filter(user => user !== iri)
            };
        }
        case actions.START_FETCHING_PAYROLL_REPORT_USER_RECORDS: {
            const newUserEmployeeRecordsFetching = state.userEmployeeRecordsFetching;
            newUserEmployeeRecordsFetching.push(iri);
            return {
                ...state,
                userEmployeeRecordsFetching: newUserEmployeeRecordsFetching
            };
        }
        case actions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_RECORDS: {
            return {
                ...state,
                userEmployeeRecordsFetching: state.userEmployeeRecordsFetching.filter(
                    user => user !== iri
                ),
                userEmployeeRecords: { ...state.userEmployeeRecords, [iri]: response.data }
            };
        }
        case actions.FETCHING_FAILED_PAYROLL_REPORT_USER_RECORDS: {
            return {
                ...state,
                userEmployeeRecordsFetching: state.userEmployeeRecordsFetching.filter(
                    user => user !== iri
                )
            };
        }
        case actions.CLEAR_STATE: {
            return {
                ...state,
                userEmployeeRecords: [],
                userWorked: []
            };
        }
        default:
            return state;
    }
};
