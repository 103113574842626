import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { clearImpersonateToken, clearToken, setImpersonateToken, setToken } from 'erpcore/api';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as authActions } from './AuthManager.reducer';
import { getSignedIn } from './AuthManager.selectors';

/**
 * /me enpoint params
 */
const meParams = {
    include:
        'organization,organizations,image,image.versions,country,state,city,timezone,company,company.accountManager,company.logo,company.country,company.state,company.city'
};

/**
 * Sign In
 * @param  {Object} credentials Email and Password
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* signIn({ credentials, promise }) {
    try {
        const singIn = yield restClient.post('/login', credentials);
        // store token
        yield setToken(singIn?.data?.token);

        yield put({
            promise,
            type: authActions.START_FETCHING_ME
        });

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: singIn?.data?.token
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        // yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Into App With Token
 * @param  {string} token
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* signInWithToken({ token, promise }) {
    try {
        // store token
        yield setToken(token);

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: token
        });

        yield put({
            promise,
            type: authActions.START_FETCHING_ME
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Out Saga
 */
export function* signOut() {
    try {
        clearToken();
        // This action is handled in main reducer file where it resets the redux store
        yield put({ type: authActions.SIGN_OUT_SUCCESSFUL });
    } catch (error) {
        yield console.error(error);
    }
}

/**
 * Fetch User Data
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchMe({ promise }) {
    // Get user data
    try {
        const getMe = yield restClient.get('/api/me', { params: meParams });
        yield put({
            type: authActions.FETCHING_ME_SUCCESSFULL
        });

        // Fetch My Permissions
        yield put({
            type: authActions.START_FETCHING_ME_PERMISSIONS
        });

        try {
            const getMineOrganization = yield restClient.get(
                `${getMe?.data?.data?.relationships?.organization?.data?.id}?include=currency,timezone`
            );
            getMe.data.data.userOrganization = dto(getMineOrganization?.data)?.data;
        } catch (e) {
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: e?.response?.data || e
            });
        }

        try {
            const getMePermissions = yield restClient.get('/api/me/permissions');

            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_SUCCESSFULL
            });

            getMe.data.data.attributes.userPermissions =
                getMePermissions?.data?.data?.attributes?.permissions || [];

            getMe.data.data.attributes.userRoles =
                getMePermissions?.data?.data?.attributes?.roles || [];

            getMe.data.data.attributes.userUserTypes =
                getMePermissions?.data?.data?.attributes?.user_types || [];

            yield put({ type: authActions.STORE_USER_DATA, response: dto(getMe?.data) });

            yield call(promise.resolve);
        } catch (error) {
            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_FAILED
            });
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: error?.response?.data || error
            });
            yield call(promise.reject, error?.response?.data || error);
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.FETCHING_ME_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * impersonate
 */
export function* impersonate({ promise, id }) {
    try {
        const getImpersonateToken = yield restClient.get(`api/users/${id}/impersonate`);

        yield setImpersonateToken(getImpersonateToken?.data?.token);

        yield call(fetchMe, {
            promise: { resolve: promise?.resolve, reject: promise?.reject }
        });

        const isSignedIn = yield select(getSignedIn);

        if (isSignedIn) {
            // user is still logged in
            yield put({
                type: authActions.IMPERSONATE_SAVE_ID,
                impersonateUserId: id
            });
        } else {
            // if in the meantime user got logged out
            clearImpersonateToken();
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch User Client Data
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchMeClient({ promise }) {
    // Get user data
    try {
        const getMe = yield restClient.get('/api/client/me', { params: meParams });
        yield put({
            type: authActions.FETCHING_ME_CLIENT_SUCCESSFULL,
            response: dto(getMe.data)?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.FETCHING_ME_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Table Filters
 * @param  {Object} promise Promises
 * @param name
 */
export function* fetchTableFilters({ promise, name }) {
    try {
        const getTableFilters = yield restClient.get('/api/table-filters', {
            params: {
                'filters[listing][equals]': name
            },
            headers: { Accept: 'application/json' }
        });

        yield put({
            type: authActions.FETCHING_TABLE_FILTERS_SUCCESSFULL,
            name,
            response: getTableFilters.data
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.FETCHING_TABLE_FILTERS_ERROR,
            name
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Table Filters
 * @param  {Object} promise Promises
 * @param response
 */
export function* createTableFilters({ promise, response }) {
    try {
        const createTableFiltersApi = yield restClient.post(
            '/api/table-filters',
            {
                ...response
            },
            { headers: { Accept: 'application/json' } }
        );
        yield put({
            type: authActions.STORE_DATA_TABLE_FILTERS,
            response: createTableFiltersApi.data
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise?.reject, error?.response?.data || error);
    }
}

/**
 * Update Table Filters
 * @param  {Object} promise Promises
 * @param response
 * @param id
 */
export function* updateTableFilters({ promise, response, id }) {
    try {
        const updateTableFiltersApi = yield restClient.patch(
            `/api/table-filters/${id}`,
            {
                ...response
            },
            { headers: { Accept: 'application/json' } }
        );
        yield put({
            type: authActions.STORE_DATA_TABLE_FILTERS,
            response: updateTableFiltersApi.data
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Remove Table Filters
 * @param  {Object} promise Promises
 * @param id
 */
export function* removeTableFilters({ promise, id }) {
    try {
        if (id) {
            yield restClient.delete(`/api/table-filters/${id}?confirmDelete=DELETE`);
        }
        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const authManagerSaga = [
    takeLatest(authActions.START_SIGN_IN, signIn),
    takeLatest(authActions.START_SIGN_IN_WITH_TOKEN, signInWithToken),
    takeLatest(authActions.START_SIGN_OUT, signOut),
    takeEvery(authActions.START_FETCHING_ME, fetchMe),
    takeEvery(authActions.START_FETCHING_ME_CLIENT, fetchMeClient),
    takeEvery(authActions.START_IMPERSONATE, impersonate),
    takeEvery(authActions.START_FETCHING_TABLE_FILTERS, fetchTableFilters),
    takeEvery(authActions.START_CREATE_TABLE_FILTERS, createTableFilters),
    takeEvery(authActions.START_UPDATE_TABLE_FILTERS, updateTableFilters),
    takeEvery(authActions.REMOVE_TABLE_FILTER, removeTableFilters)
];
