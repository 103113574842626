import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Departments Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const PayrollReport = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'payrollreport.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payroll report successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'payrollreport.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payroll report successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'payrollreport.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payroll report successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'payrollReport.statusSuccessfullyChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payroll report successfully changed status!"
                    type="success"
                    expire="3500"
                />
            );
        case 'payrollReport.canNotEditPayrollReport':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Payroll report failed to change status!"
                    type="error"
                />
            );
        default:
            return null;
    }
};

PayrollReport.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

PayrollReport.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default PayrollReport;
