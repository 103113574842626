import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const Email = ({ input, meta, fieldProps, fieldAttr, field }) => {
    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <input
                id={input.name}
                {...input}
                {...fieldAttr}
                aria-label={`${input.name}`}
                type="email"
                className="input__field"
            />
        </Input>
    );
};
Email.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Email.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Email;
