import React, { useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getResponsive } from 'erpcore/utils/utils';
import Button from 'erpcore/components/Button';
import restClient from 'erpcore/api/restClient';
import styles from './ProfileSettings.module.scss';

const ProfileSettings = ({ mobile }) => {
    const routeParams = useParams();
    const isUserAdmin = !!routeParams?.companyId;
    const { isMobile } = getResponsive();
    const userData = useSelector(getMeData);
    const navigate = useNavigate();

    const handleBackToERP = useCallback(() => {
        delete restClient.defaults.headers.common[`erp-scope-to-company`];

        navigate('/companies');
    }, []);

    if (isMobile && !mobile) {
        return null;
    }

    if (isUserAdmin) {
        return (
            <>
                <Svg icon="arrowLeft" iconColor="light-blue" />{' '}
                <Button variation="link" label="Back to ERP" onClick={handleBackToERP} />
            </>
        );
    }

    return (
        <ul className={styles['profile-settings__list']}>
            <li className={styles['profile-settings__list-item']}>
                <NavLink to="/client/account">
                    <div className={styles['profile-settings__list-item-user']}>
                        <Image
                            iri={userData?.image?.iri}
                            version="small"
                            alt="Profile"
                            fallback={<Svg icon="profile" iconColor="mid-grey" />}
                        />
                    </div>
                    My account
                </NavLink>
            </li>

            <li className={styles['profile-settings__list-item']}>
                <NavLink to="/sign-out">
                    <Svg icon="signOut" iconColor="mid-grey" /> Sign out
                </NavLink>
            </li>
        </ul>
    );
};

ProfileSettings.defaultProps = {
    mobile: false
};

ProfileSettings.propTypes = {
    mobile: PropTypes.bool
};

export default React.memo(ProfileSettings);
