export const getPageNotifications = (state) => state.notificationManager.pageNotifications;

export const getPermanentPageNotifications = (state) =>
    state.notificationManager.permanentPageNotifications;

export const getFloatingNotifications = (state) => state.notificationManager.floatingNotifications;

export default {
    getPageNotifications,
    getPermanentPageNotifications,
    getFloatingNotifications
};
