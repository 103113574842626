import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './BulkActions.scss';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getBulkActionsIris,
    getBulkActionsSelectAll
} from 'erpcore/components/Listing/Listing.selectors';

const TableHeaderBulkAction = ({ tableData, reducerName }) => {
    const dispatch = useDispatch();
    const bulkActionsIris = useSelector(state => getBulkActionsIris(state, reducerName));
    const selectedAll = useSelector(state => getBulkActionsSelectAll(state, reducerName));

    const onChange = () => {
        const iris = [];
        let selectAll = false;

        if (!bulkActionsIris?.length > 0 && tableData.data) {
            selectAll = true;
            tableData.data.map(row => {
                return iris.push(row.iri);
            });
        }

        // If change happend from Redux store
        // selectedAll value changed
        if (
            !selectedAll &&
            bulkActionsIris?.length > 0 &&
            bulkActionsIris?.length - 1 === tableData.data?.length
        ) {
            selectAll = true;
            tableData.data.map(row => {
                return iris.push(row.iri);
            });
        }

        return dispatch({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: iris,
            selectAll,
            name: reducerName
        });
    };

    let isPartial = false;
    if (bulkActionsIris.length && bulkActionsIris.length < tableData.data.length) {
        isPartial = true;
    }

    return (
        <div className="bulk-actions-checkbox">
            <input
                type="checkbox"
                aria-label="Select for Bulk Action"
                name="TableHeaderBulkAction"
                id="table-header-bulk-action"
                value="TableHeaderBulkAction"
                disabled={!tableData?.data?.length}
                onChange={onChange}
                checked={selectedAll || isPartial}
            />
            <label className="bulk-actions-checkbox__label" htmlFor="table-header-bulk-action">
                <span className="bulk-actions-checkbox__icon">
                    {isPartial ? <Svg icon="minus" /> : <Svg icon="checkmark" />}
                </span>
            </label>
        </div>
    );
};

TableHeaderBulkAction.defaultProps = {
    tableData: {}
};

TableHeaderBulkAction.propTypes = {
    tableData: PropTypes.oneOfType([PropTypes.object]),
    reducerName: PropTypes.string.isRequired
};

export default TableHeaderBulkAction;
