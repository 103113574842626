import React, { useState, useEffect, useCallback } from 'react';
import Button from 'erpcore/components/Button';
import './TimeTrackingWidgetDropdown.scss';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { useDispatch, useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import TimeTracking from 'erpcore/components/TimeTracking';
import { getIsTimeTrackingWidgetDropdownOpen } from 'erpcore/components/TimeTracking/TimeTracking.selectors';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';

const TimeTrackingWidgetDropdown = () => {
    const dispatch = useDispatch();
    const [dropdownRef, setDropdownRef] = useState(null);
    const isDropdownOpen = useSelector(getIsTimeTrackingWidgetDropdownOpen);
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    const openDropdown = useCallback((nextState) => {
        dispatch({
            type: timeTrackingActions.OPEN_TIME_TRACKING_WIDGET_DROPDOWN,
            response: nextState
        });
    }, []);

    const handleClickOutside = useCallback(
        (event) => {
            if (
                dropdownRef &&
                !dropdownRef.contains(event.target) &&
                !event.target?.closest('.time-tracking-widget-dropdown') &&
                !event?.target?.classList.contains('react-tooltip-lite') &&
                !event?.target?.classList.contains('react-select__indicator') &&
                !event?.target?.classList.contains('react-select__option') &&
                !event?.target?.classList.contains(
                    styles['time-tracking__draft-list__single__actions-time-button']
                ) &&
                !(event?.target instanceof SVGElement)
            ) {
                openDropdown(false);
            }
        },
        [dropdownRef]
    );

    useEffect(() => {
        if (dropdownRef && isDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef, handleClickOutside, isDropdownOpen]);

    if (!hasPermissionsAccessForEntities.timeLogs) {
        return null;
    }

    return (
        <div
            ref={(ref) => ref && setDropdownRef(ref)}
            className={`time-tracking-widget-dropdown ${
                isDropdownOpen ? ' time-tracking-widget-dropdown--active' : ''
            }`}
        >
            <Button
                iconName="stopwatch"
                variation="tertiary"
                labelOnlyAria
                onClick={() => openDropdown(!isDropdownOpen)}
            />
            <div className="time-tracking-widget-dropdown__content">
                <TimeTracking />
            </div>
        </div>
    );
};

TimeTrackingWidgetDropdown.defaultProps = {};

TimeTrackingWidgetDropdown.propTypes = {};

export default TimeTrackingWidgetDropdown;
