import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as workTypesActions } from 'erpcore/screens/Settings/WorkTypes/WorkTypes.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create WorkType
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createWorkType({ promise, formData }) {
    try {
        const createWorkTypeAPI = yield restClient.post('api/work-types', formData);
        yield put({
            type: workTypesActions.CREATE_WORK_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createWorkTypeAPI?.data || { code: 'workType.itemSuccesfullyCreated' }
        });

        const params = yield select(getQueryParams, { name: 'workTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'WORK_TYPES',
            name: 'workTypes',
            endpoint: 'api/work-types'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: workTypesActions.CREATE_WORK_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update WorkType data
 * @param  {Object} iri of WorkType
 * @return {Object} Response from API
 */
export function* updateWorkType({ promise, iri, formData }) {
    try {
        const updateWorkTypeAPI = yield restClient.patch(iri, formData);
        yield put({
            type: workTypesActions.UPDATE_WORK_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateWorkTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'workTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'WORK_TYPES',
            name: 'workTypes',
            endpoint: 'api/work-types?order_by[id]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: workTypesActions.UPDATE_WORK_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete WorkType
 * @param  {Object} iri of WorkType
 * @return {Object} Response from API
 */
export function* deleteWorkType({ promise, iri, formData }) {
    try {
        let deleteURL = iri;
        if (formData?.moveTo) {
            deleteURL = `${iri}?move_to=${formData?.moveTo}`;
        }

        const deleteWorkTypeAPI = yield restClient.delete(deleteURL);
        yield put({
            type: workTypesActions.DELETE_WORK_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteWorkTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'workTypes' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'WORK_TYPES',
            name: 'workTypes',
            endpoint: 'api/work-types'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: workTypesActions.DELETE_WORK_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(workTypesActions.START_CREATE_WORK_TYPE, createWorkType),
    takeLatest(workTypesActions.START_UPDATE_WORK_TYPE, updateWorkType),
    takeLatest(workTypesActions.START_DELETE_WORK_TYPE, deleteWorkType)
];
