export const actions = {
    // Listing
    START_FETCHING_SERVICES: 'START_FETCHING_SERVICES',
    FETCHING_SUCCESSFUL_SERVICES: 'FETCHING_SUCCESSFUL_SERVICES',
    FETCHING_FAILED_SERVICES: 'FETCHING_FAILED_SERVICES',
    // Create
    START_CREATE_SERVICE: 'START_CREATE_SERVICE',
    CREATE_SERVICE_SUCCESSFUL: 'CREATE_SERVICE_SUCCESSFUL',
    CREATE_SERVICE_FAILED: 'CREATE_SERVICE_FAILED',
    // Edit
    START_UPDATE_SERVICE: 'START_UPDATE_SERVICE',
    UPDATE_SERVICE_SUCCESSFUL: 'UPDATE_SERVICE_SUCCESSFUL',
    UPDATE_SERVICE_FAILED: 'UPDATE_SERVICE_FAILED',
    // Delete
    START_DELETE_SERVICE: 'START_DELETE_SERVICE',
    DELETE_SERVICE_SUCCESSFUL: 'DELETE_SERVICE_SUCCESSFUL',
    DELETE_SERVICE_FAILED: 'DELETE_SERVICE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_SERVICES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SERVICES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SERVICES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
