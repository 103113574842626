import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BrandingManager from 'erpcore/utils/BrandingManager';
import './content.scss';
import defaultLayout from './defaultLayout';

class LayoutManager extends Component {
    constructor(props) {
        super(props);

        //  Binding function to this
        this.renderLayout = this.renderLayout.bind(this);
    }

    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            return false;
        }
        return false;
    }

    renderLayoutComponent = ({ layoutItem }) => {
        if (!layoutItem) {
            return null;
        }

        const { slot, className, children } = this.props;

        // If slot exists, render children in the Component
        if (slot && layoutItem.slotName && layoutItem.slotName === slot) {
            return (
                <layoutItem.component
                    layout={layoutItem.content}
                    className={className}
                    key={layoutItem.slotName}
                >
                    {children}
                </layoutItem.component>
            );
        }

        return <layoutItem.component layout={layoutItem.content} key={layoutItem.slotName} />;
    };

    renderLayout() {
        const { layout, layoutType } = this.props;

        //  Load default main layout for render
        let layoutToRender = Object.assign({}, defaultLayout);

        //  Merge default layout and layout for render
        if (layoutType && layoutType === 'merge') {
            layoutToRender = Object.assign(layoutToRender, layout);
        }

        //  Override default layout with layout for render
        if (layoutType && layoutType === 'override') {
            layoutToRender = layout;
        }

        return (
            layoutToRender &&
            layoutToRender.data &&
            layoutToRender.data
                .sort((a, b) => a.position - b.position)
                .map((layoutItem) => {
                    return this.renderLayoutComponent({ layoutItem });
                })
        );
    }

    render() {
        const { layoutType } = this.props;

        return (
            <>
                <BrandingManager />
                {layoutType === 'merge' ? (
                    <div id="content" className="content">
                        {this.renderLayout()}
                    </div>
                ) : (
                    this.renderLayout()
                )}
            </>
        );
    }
}

LayoutManager.defaultProps = {
    layout: null,
    layoutType: 'override',
    className: '',
    children: null,
    slot: null
};

LayoutManager.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    layoutType: PropTypes.oneOf(['merge', 'override']),
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number
    ]),
    slot: PropTypes.string
};
export default LayoutManager;
