import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as categoriesActions } from 'erpcore/screens/Accounting/screens/Categories/Categories.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import dtoTreeView from 'erpcore/components/TreeView/dtoTreeView';

/**
 * Create Accounting Category
 * @param  {Object} promise
 * @return {string}
 */
export function* createAccountingCategory({ promise, formData }) {
    try {
        // Create Expense Category
        const createAccountingCategoryAPI = yield restClient.post(
            `api/accounting/categories`,
            formData
        );
        yield put({
            type: categoriesActions.CREATE_ACCOUNTING_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createAccountingCategoryAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.CREATE_ACCOUNTING_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Accounting category data
 * @param  {Object} iri of accounting category
 * @return {Object} Response from API
 */
export function* updateAccountingCategory({ promise, iri, formData }) {
    try {
        const updateAccountingCategoryAPI = yield restClient.patch(iri, formData);
        yield put({
            type: categoriesActions.UPDATE_ACCOUNTING_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateAccountingCategoryAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.UPDATE_ACCOUNTING_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete accounting category
 * @param  {Object} iri of the category
 * @return {Object} Response from API
 */
export function* deleteAccountingCategory({ promise, iri, formData }) {
    try {
        yield restClient.delete(iri, { data: formData });
        yield put({
            type: categoriesActions.DELETE_ACCOUNTING_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'category.itemSuccessfulyDeleted' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.DELETE_ACCOUNTING_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *  Fetch time log entry
 * @param promise
 * @param iri
 * @returns {Generator<<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"CALL", CallEffectDescriptor>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* fetchAccountingCategory({ promise, iri }) {
    try {
        const fetchAccountingCategoryAPI = yield restClient.get(iri);
        yield put({
            type: categoriesActions.FETCH_ACCOUNTING_CATEGORY_SUCCESSFUL
        });
        yield put({
            type: categoriesActions.STORE_ACCOUNTING_CATEGORY_DATA,
            iri,
            response: dto(fetchAccountingCategoryAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.FETCH_ACCOUNTING_CATEGORY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Expense categories
 * @return {Object} Response from API
 */
export function* fetchExpenseCategories({ promise }) {
    try {
        const fetchExpenseCategoriesAPI = yield restClient.get(
            'api/accounting/categories?filters[type][equals]=expense&order_by[generalLedger]=ASC&include=subcategories&pagination=false'
        );
        yield put({
            type: categoriesActions.FETCHING_EXPENSE_CATEGORIES_SUCCESSFUL,
            response: dtoTreeView(fetchExpenseCategoriesAPI?.data, 'subcategories')
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.FETCHING_EXPENSE_CATEGORIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Revenue categories
 * @return {Object} Response from API
 */
export function* fetchRevenueCategories({ promise }) {
    try {
        const fetchRevenueCategoriesAPI = yield restClient.get(
            'api/accounting/categories?filters[type][equals]=revenue&order_by[generalLedger]=ASC&include=subcategories&pagination=false'
        );
        yield put({
            type: categoriesActions.FETCHING_REVENUE_CATEGORIES_SUCCESSFUL,
            response: dtoTreeView(fetchRevenueCategoriesAPI?.data, 'subcategories')
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: categoriesActions.FETCHING_REVENUE_CATEGORIES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export default [
    takeLatest(categoriesActions.START_CREATE_ACCOUNTING_CATEGORY, createAccountingCategory),
    takeEvery(categoriesActions.START_FETCH_ACCOUNTING_CATEGORY, fetchAccountingCategory),
    takeEvery(categoriesActions.START_UPDATE_ACCOUNTING_CATEGORY, updateAccountingCategory),
    takeLatest(categoriesActions.START_DELETE_ACCOUNTING_CATEGORY, deleteAccountingCategory),
    takeLatest(categoriesActions.START_FETCHING_EXPENSE_CATEGORIES, fetchExpenseCategories),
    takeLatest(categoriesActions.START_FETCHING_REVENUE_CATEGORIES, fetchRevenueCategories)
];
