import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as scheduledCommandsActions } from 'erpcore/screens/Settings/ScheduledCommands/ScheduledCommands.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Fetch Scheduled Command
 * @param  {Object} promise
 * @param  {Object} formData
 */
export function* fetchScheduleCommand({ promise, command = null }) {
    try {
        const fetchScheduledCommandAPI = yield restClient.get(
            `api/scheduled-commands?name=${command}`
        );
        yield put({
            type: scheduledCommandsActions.FETCH_SCHEDULED_COMMANDS_SUCCESSFUL
        });
        yield put({
            type: scheduledCommandsActions.STORE_SCHEDULED_COMMANDS_DATA,
            // always select first in array
            // as there should be only one options page
            response: dto(fetchScheduledCommandAPI?.data)?.data?.[0]
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: scheduledCommandsActions.FETCH_SCHEDULED_COMMANDS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update Scheduled Command
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} formData
 */
export function* updateScheduledCommand({ promise, iri, formData }) {
    try {
        const putScheduledCommandAPI = yield restClient.put(iri, formData);
        yield put({
            type: scheduledCommandsActions.UPDATE_SCHEDULED_COMMAND_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(putScheduledCommandAPI?.data)
        });
        yield put({
            type: scheduledCommandsActions.STORE_SCHEDULED_COMMANDS_DATA,
            response: dto(putScheduledCommandAPI?.data)?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: scheduledCommandsActions.UPDATE_SCHEDULED_COMMAND_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Create Scheduled Command
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} formData
 */
export function* createScheduledCommand({ promise, formData }) {
    try {
        const putScheduledCommandAPI = yield restClient.post('/api/scheduled-commands', formData);
        yield put({
            type: scheduledCommandsActions.CREATE_SCHEDULED_COMMAND_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(putScheduledCommandAPI?.data)
        });
        yield put({
            type: scheduledCommandsActions.STORE_SCHEDULED_COMMANDS_DATA,
            response: dto(putScheduledCommandAPI?.data)?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: scheduledCommandsActions.CREATE_SCHEDULED_COMMAND_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(scheduledCommandsActions.START_FETCHING_SCHEDULED_COMMANDS, fetchScheduleCommand),
    takeLatest(scheduledCommandsActions.START_UPDATING_SCHEDULED_COMMANDS, updateScheduledCommand),
    takeLatest(scheduledCommandsActions.START_CREATE_SCHEDULED_COMMANDS, createScheduledCommand)
];
