import { put, putResolve, call, all, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import isArray from 'lodash/isArray';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as selectActions } from './Select.reducer';

/**
 * Fetch Select Options
 * @param promise
 * @param endpoint
 * @param  {Object} params
 * @return {Object} Response from API
 */
export function* fetchSelectOptions({ promise, endpoint, params }) {
    try {
        const api = yield restClient.get(endpoint, {
            params,
            headers: {
                checkForConcurrentRequests: true,
                concurrentIdentifier: endpoint
            }
        });
        const selectedOptionsDto = yield dto(api?.data);
        yield put({
            type: selectActions.STORE_SELECT_OPTIONS_DATA,
            endpoint,
            response: selectedOptionsDto
        });
        yield put({
            type: selectActions.FETCHING_SUCCESSFUL_SELECT_OPTIONS,
            endpoint
        });
        if (promise) {
            yield call(promise.resolve, selectedOptionsDto?.data);
        }
    } catch (error) {
        if (error?.message === 'requestCanceledForPendingRequest') {
            if (promise) {
                yield call(promise.reject, error);
            }
        } else {
            yield put({
                type: selectActions.FETCHING_FAILED_SELECT_OPTIONS,
                endpoint
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
            if (promise) {
                yield call(promise.reject, error?.response?.data || error);
            }
        }
    }
}

/**
 * Fetch Additional Select Options
 * @param promise
 * @param iri {string|array} Iri or Iris to fetch
 * @param endpoint {string} For which Select endpoint is this iri fetched
 * @param  {Object} params
 * @param  {Object} mapData
 * @param  {string} valueKey
 * @return {Object} Response from API
 */
export function* fetchMissingSelectOptions({ promise, iri, endpoint, params, mapData, valueKey }) {
    try {
        const getConfig = key => {
            return {
                params,
                headers: {
                    checkForConcurrentRequests: true,
                    concurrentIdentifier: `${endpoint}-${key}`
                }
            };
        };

        let options;

        if (valueKey === 'iri') {
            /**
             * A. try to fetch data only if values are real iri
             */
            const requests = yield Promise.allSettled(
                isArray(iri)
                    ? iri.map(value => {
                          return restClient.get(value, getConfig(value));
                      })
                    : [restClient.get(iri, getConfig(iri))]
            );

            yield all(
                requests.map(request =>
                    // eslint-disable-next-line func-names
                    (function*() {
                        if (
                            request.status === 'rejected' &&
                            request.reason?.message !== 'requestCanceledForPendingRequest' &&
                            request.reason?.config?.url
                        ) {
                            const is404 = yield request.reason.response?.status === 404 &&
                                request.reason.response?.data?.code === 'generalError';
                            yield putResolve({
                                type: selectActions.APPEND_SELECT_OPTIONS,
                                endpoint,
                                iri: request.reason.config.url,
                                response: {
                                    [mapData?.value || 'iri']: request.reason?.config?.url,
                                    ...(mapData?.value !== mapData?.label // don't overwrite value property with label property
                                        ? {
                                              [mapData?.label || 'name']: `${
                                                  is404 ? 'Not found:' : 'Error:'
                                              } ${request.reason.config.url}`
                                          }
                                        : null),
                                    forwardOptionData: {
                                        ...(is404 ? { isDisabled: true } : null),
                                        isError: is404 ? 404 : true
                                    }
                                },
                                valueKey
                            });
                        }
                    })()
                )
            );

            if (requests.some(request => request?.status === 'fulfilled')) {
                options = requests.reduce((accumulator, request) => {
                    if (request.status === 'fulfilled' && request.value?.data?.data) {
                        accumulator.push(dto(request.value?.data)?.data);
                    }
                    return accumulator;
                }, []);
            }

            if (options || options?.length) {
                yield put({
                    type: selectActions.APPEND_SELECT_OPTIONS,
                    endpoint,
                    iri,
                    response: options,
                    valueKey
                });
                yield put({
                    type: selectActions.FETCHING_SUCCESSFUL_MISSING_SELECT_OPTIONS,
                    endpoint,
                    iri
                });
                if (promise) {
                    yield call(promise.resolve, options);
                }
            }
        } else {
            /**
             * B. if values are not real iri, save plain values
             */
            yield all(
                (isArray(iri) ? iri : [iri]).map(notIri =>
                    // eslint-disable-next-line func-names
                    (function*() {
                        yield putResolve({
                            type: selectActions.APPEND_SELECT_OPTIONS,
                            endpoint,
                            iri: notIri,
                            response: {
                                [mapData?.value || 'iri']: notIri,
                                forwardOptionData: {
                                    forceDisplayValue: true
                                }
                            },
                            valueKey
                        });
                    })()
                )
            );
        }
    } catch (error) {
        if (error?.message === 'requestCanceledForPendingRequest') {
            if (promise) {
                yield call(promise.reject, error);
            }
        } else {
            yield put({
                type: selectActions.FETCHING_FAILED_MISSING_SELECT_OPTIONS,
                endpoint,
                iri
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
            if (promise) {
                yield call(promise.reject, error?.response?.data || error);
            }
        }
    }
}

/**
 * Register action to watcher
 */
const SelectSaga = [
    takeEvery(selectActions.START_FETCHING_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(selectActions.START_FETCHING_MISSING_SELECT_OPTIONS, fetchMissingSelectOptions)
];

export default SelectSaga;
