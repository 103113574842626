import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Form, { Checkbox, Password } from 'erpcore/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, change, blur, clearFields, Field, Fields } from 'redux-form';
import generator from 'generate-password';
import Button from 'erpcore/components/Button';
import {
    validatePassword,
    validateOldPassword,
    validateConfirmationPassword
} from 'erpcore/components/Form/components/GeneratePassword/GeneratePassword.utils';
import './GeneratePassword.scss';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const RenderFields = fields => {
    const dispatch = useDispatch();
    const passwordField = fields[fields.nameBindings.passwordField];
    const confirmationField = fields[fields.nameBindings.confirmationField];
    const oldPasswordField = fields[fields.nameBindings.oldPasswordField];
    const manualField = fields[fields.nameBindings.manualField];
    const isManual = manualField.input.value;
    const isRequired = fields?.isRequired;
    const isConfirmationRequired = isManual && fields?.isConfirmationRequired;
    const displayOldPassword = fields?.displayOldPassword;
    const initialPasswordVisible = fields?.initialPasswordVisible;

    const passwordLabel = fields?.passwordLabel;
    const oldPasswordLabel = fields?.oldPasswordLabel;
    const confirmPasswordLabel = fields?.confirmPasswordLabel;

    const onGenerate = () => {
        const generatedPassword = generator.generate({
            length: 8,
            numbers: true,
            strict: true
        });
        dispatch(change(passwordField.meta.form, passwordField.input.name, generatedPassword));
    };

    const formValues = useSelector(state => getFormValues(passwordField?.meta?.form)(state));
    const {
        [fields?.nameBindings?.passwordField]: passwordValue,
        [fields?.nameBindings?.confirmationField]: confirmationValue,
        [fields?.nameBindings?.oldPasswordField]: oldPasswordValue,
        [fields?.nameBindings?.manualField]: manualValue
    } = { ...formValues };

    const prevValue = usePrevious({
        passwordValue,
        confirmationValue,
        oldPasswordValue,
        manualValue
    });

    useEffect(() => {
        // on input-manual change
        if (prevValue?.manualValue !== manualValue) {
            // if input-manual is on
            if (manualValue) {
                // clear confirmation password field
                dispatch(
                    clearFields(confirmationField?.meta?.form, false, true, [
                        confirmationField?.input?.name
                    ])
                );
                dispatch(blur(confirmationField?.meta?.form, confirmationField?.input?.name));
            }
            // if input-manual is off
            else {
                // clear password field
                dispatch(
                    clearFields(passwordField?.meta?.form, true, true, [passwordField?.input?.name])
                );
                // dispatch(blur(confirmationField?.meta?.form, confirmationField?.input?.name));
            }
        }

        // on password change
        if (prevValue?.passwordValue !== passwordValue) {
            // if input-manual is off
            if (confirmationField?.input?.name && !manualValue) {
                // copy password value to confirmation password field
                dispatch(
                    blur(
                        confirmationField?.meta?.form,
                        confirmationField?.input?.name,
                        passwordField?.input?.value
                    )
                );
            }
        }
    }, [passwordValue, confirmationValue, oldPasswordValue, manualValue]);

    return (
        <div className="generate-password">
            {!!displayOldPassword && (
                <Form.Row>
                    <Field
                        initialPasswordVisible={initialPasswordVisible}
                        name={oldPasswordField.input.name}
                        id={oldPasswordField.input.name}
                        fieldProps={{
                            label: oldPasswordLabel
                        }}
                        fieldAttr={{ required: isRequired }}
                        component={Password}
                        validate={(value, allValues, props, fieldName) =>
                            validateOldPassword(
                                { isRequired, passwordField },
                                { value, allValues, props, fieldName }
                            )
                        }
                    />
                </Form.Row>
            )}
            <Form.Row>
                <div className="generate-password__password">
                    {!isManual && (
                        <Button
                            variation="secondary"
                            label="Generate password"
                            onClick={() => onGenerate()}
                            className="generate-password__button"
                        />
                    )}
                    <Field
                        initialPasswordVisible={initialPasswordVisible}
                        name={passwordField.input.name}
                        id={passwordField.input.name}
                        fieldProps={{
                            label: passwordLabel
                        }}
                        fieldAttr={{
                            'aria-autocomplete': 'none',
                            autoComplete: 'off',
                            disabled: !isManual,
                            required: isRequired
                        }}
                        component={Password}
                        validate={(value, allValues, props, fieldName) =>
                            validatePassword(
                                { isRequired, oldPasswordField },
                                { value, allValues, props, fieldName }
                            )
                        }
                    />
                </div>
            </Form.Row>
            {!!isConfirmationRequired && (
                <Form.Row>
                    <Field
                        initialPasswordVisible={initialPasswordVisible}
                        name={confirmationField.input.name}
                        id={confirmationField.input.name}
                        fieldProps={{
                            label: confirmPasswordLabel
                        }}
                        fieldAttr={{ required: isRequired }}
                        component={Password}
                        validate={(value, allValues, props, fieldName) =>
                            validateConfirmationPassword(
                                { passwordField, manualField },
                                { value, allValues, props, fieldName }
                            )
                        }
                    />
                </Form.Row>
            )}
            <Form.Row>
                <Field
                    name={manualField.input.name}
                    fieldProps={{
                        id: `${manualField.input.name}-id`,
                        value: true,
                        label: 'Input password manually'
                    }}
                    component={Checkbox}
                />
            </Form.Row>
        </div>
    );
};

const GeneratePassword = ({
    initialPasswordVisible,
    name,
    oldPasswordName,
    confirmationPasswordName,
    manualName,
    passwordLabel,
    oldPasswordLabel,
    confirmPasswordLabel,
    isRequired,
    isConfirmationRequired,
    displayOldPassword
}) => {
    return (
        <Fields
            names={[name, confirmationPasswordName, oldPasswordName, manualName]}
            nameBindings={{
                passwordField: name,
                confirmationField: confirmationPasswordName,
                oldPasswordField: oldPasswordName,
                manualField: manualName
            }}
            initialPasswordVisible={initialPasswordVisible}
            passwordLabel={passwordLabel}
            oldPasswordLabel={oldPasswordLabel}
            confirmPasswordLabel={confirmPasswordLabel}
            isRequired={isRequired}
            isConfirmationRequired={isConfirmationRequired}
            displayOldPassword={displayOldPassword}
            component={RenderFields}
        />
    );
};
GeneratePassword.defaultProps = {
    initialPasswordVisible: false,
    name: 'new_password',
    oldPasswordName: 'old_password',
    confirmationPasswordName: 'password_confirmation',
    manualName: 'manual_password',
    passwordLabel: 'New password',
    oldPasswordLabel: 'Current password',
    confirmPasswordLabel: 'Confirm new password',
    isRequired: false,
    isConfirmationRequired: false,
    displayOldPassword: false
};
GeneratePassword.propTypes = {
    initialPasswordVisible: PropTypes.bool,
    name: PropTypes.string,
    oldPasswordName: PropTypes.string,
    confirmationPasswordName: PropTypes.string,
    manualName: PropTypes.string,
    passwordLabel: PropTypes.string,
    oldPasswordLabel: PropTypes.string,
    confirmPasswordLabel: PropTypes.string,
    isRequired: PropTypes.bool,
    isConfirmationRequired: PropTypes.bool,
    displayOldPassword: PropTypes.bool
};
export default GeneratePassword;
