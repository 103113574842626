import {
    required,
    email,
    length,
    numericality,
    date,
    confirmation,
    format,
    acceptance,
    inclusion,
    exclusion,
    absence,
    url,
    file
} from 'redux-form-validators';

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * valueValidation prepares validators for field
 * @param {Array} validators Description of field validation
 * @return {Array} Returns array of validation functions for field
 */
const valueValidation = (validators) => {
    const preparedValidators = [];
    //  Skip validators maps if they are empty
    if (!validators) {
        return false;
    }
    //  Fill validatiors with arguments
    validators.map((validation) => {
        const { validator, args } = validation;
        if (validator === 'required') {
            preparedValidators.push(required(args));
        } else if (validator === 'email') {
            preparedValidators.push(email(args));
        } else if (validator === 'length') {
            preparedValidators.push(length(args));
        } else if (validator === 'numericality') {
            preparedValidators.push(numericality(args));
        } else if (validator === 'date') {
            preparedValidators.push(date(args));
        } else if (validator === 'confirmation') {
            preparedValidators.push(confirmation(args));
        } else if (validator === 'format') {
            preparedValidators.push(format(args));
        } else if (validator === 'acceptance') {
            preparedValidators.push(acceptance(args));
        } else if (validator === 'inclusion') {
            preparedValidators.push(inclusion(args));
        } else if (validator === 'exclusion') {
            preparedValidators.push(exclusion(args));
        } else if (validator === 'absence') {
            preparedValidators.push(absence(args));
        } else if (validator === 'url') {
            preparedValidators.push(url(args));
        } else if (validator === 'file') {
            preparedValidators.push(file(args));
        }
        return true;
    });
    return preparedValidators;
};

/**
 * Prepares data from API for redux form
 * @param {Object} error Error response from API
 * @return {Object} Returns error object suited fror redux forms
 */
const reduxFormErrorMapper = (error, forceSnakeCase = false) => {
    const reduxError = {
        _error: error
    };
    if (error && error.errors) {
        error.errors.forEach((item) => {
            /**
             * Checking if field name is nested ex ( from_address.state ) then
             * create field name object with key of a subfield ex.
             * from_address: { state: [errorObject] }
             * */
            if (item.name && item.name.indexOf('.') !== -1) {
                //  Filling error object with subfields
                const splitedItemName = item.name.split('.');
                //  If parent field doesnt still exists create empty object
                if (!reduxError[splitedItemName[0]]) {
                    reduxError[splitedItemName[0]] = {};
                }
                reduxError[splitedItemName[0]][splitedItemName[1]] = item;
            } else {
                reduxError[forceSnakeCase ? camelToSnakeCase(item.name) : item.name] = item;
            }
        });
    }
    return reduxError;
};

/**
 * Handling Location Form Data
 * @param {object} formData containing form data
 * @returns {object} containing payload for the API and initial values to be combined with the API response in the saga
 */
const formatLocationFormData = (formData) => {
    // Set inital values
    const data = {
        payload: {},
        initialValues: formData
    };
    let venueName = {};
    const payload = Object.assign({}, formData);
    payload.location = Object.assign({}, formData.location);

    if (payload.venue_name) {
        venueName = { venue_name: payload.venue_name };
    }
    if (payload.location && payload.location.state === '') {
        payload.location.state = null;
    }

    if (payload.included) {
        delete payload.included;
    }

    if (payload.location && payload.location.locationPin) {
        delete payload.location.locationPin;
    }
    if (Object.keys(payload.location).length === 0 && payload.constructor === Object) {
        delete payload.location;
    }

    data.payload = Object.assign({}, payload.location, venueName);

    return data;
};

const getFilteredOption = (value, options) => {
    let filteredOption = null;
    // eslint-disable-next-line
    for (const item of options || []) {
        if (item.value === value) {
            filteredOption = item;
            break;
        }
        if (item.options?.length) {
            // eslint-disable-next-line
            for (const groupItem of item.options) {
                if (groupItem.value === value) {
                    filteredOption = groupItem;
                    break;
                }
            }
        }
    }
    return filteredOption;
};

const getOptionByValue = (value, options) => {
    //  Getting select required value from options
    let fieldValue = '';

    if (value || typeof value === 'boolean') {
        const filteredOption = getFilteredOption(value, options);
        // const filteredOption = options.find(o => o.value === value); // does NOT work with groups. Is replaced with getFilteredOption

        //  If option exits inside options array store it to option
        if (filteredOption) {
            fieldValue = filteredOption;
        } else {
            // If Option is not found send the value
            fieldValue = {
                value,
                label: value,
                labelEqualsValue: true
            };
        }
    }

    return fieldValue;
};

const getFilterTagOperatorLabel = (filterName, operator, operations) => {
    if (filterName === 'archived') {
        // Special case and the wording is different
        return 'include';
    }

    const operation = operations.find((o) => o.value === operator);

    if (!operation) {
        return operator;
    }

    return operation.label;
};

/**
 *
 * @param values {array|string|number}
 * @param options {array}
 * @return {Array}
 */
const getOptionsByValues = (values = [], options) => {
    const fieldValues = [];
    if (values && !Array.isArray(values)) {
        values = [values];
    }
    if (values && values.length) {
        values.forEach((value) => {
            const filteredOption = getFilteredOption(value, options);
            // const filteredOption = options.find(o => o.value === value); // does NOT work with groups. Is replaced with getFilteredOption
            //  If option exits inside options array store it to option
            if (filteredOption) {
                fieldValues.push({
                    value,
                    label: filteredOption.label,
                    ...(filteredOption?.isDisabled ? { isDisabled: true } : null),
                    ...(filteredOption?.isError ? { isError: filteredOption?.isError } : null)
                });
            } else {
                // If Option is not found send the value
                fieldValues.push({
                    value,
                    label: value
                });
            }
        });
    }
    return fieldValues;
};

export {
    valueValidation,
    reduxFormErrorMapper,
    formatLocationFormData,
    getOptionByValue,
    getOptionsByValues,
    getFilterTagOperatorLabel
};
