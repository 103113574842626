import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

/**
 * Listing Wrapper
 */
const WithListingWrapper =
    (BaseComponent) =>
    // eslint-disable-next-line
    ({ name, ...props }) => {
        const location = useLocation();
        const { search } = { ...location };
        const searchRef = useRef(search);
        const [mountIteration, setMountIteration] = useState(0);

        // Re-mount listing if URL query params are (externally) cleared (i.e. click on the main navigation link), by the means of changing the Listing key
        useEffect(() => {
            if (!search && !!searchRef.current) {
                const urlParams = qs.parse(searchRef?.current?.substr(1));
                if (urlParams?.[name]) {
                    setMountIteration((oldKey) => oldKey + 1);
                }
            }
            searchRef.current = search;
        }, [search]);

        return (
            <BaseComponent
                key={`listing-mount-iteration-${mountIteration}`}
                name={name}
                {...props}
            />
        );
    };

export default WithListingWrapper;
