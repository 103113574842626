export const actions = {
    START_FETCHING_APPROVALS: 'START_FETCHING_APPROVALS',
    FETCHING_SUCCESSFUL_APPROVALS: 'FETCHING_SUCCESSFUL_APPROVALS',
    FETCHING_FAILED_APPROVALS: 'FETCHING_FAILED_APPROVALS',

    START_DELETE_APPROVAL: 'START_DELETE_APPROVAL',
    DELETE_APPROVAL_SUCCESSFUL: 'DELETE_APPROVAL_SUCCESSFUL',
    DELETE_APPROVAL_FAILED: 'DELETE_APPROVAL_FAILED',

    START_CREATE_APPROVAL: 'START_CREATE_APPROVAL',
    CREATE_APPROVAL_SUCCESSFUL: 'CREATE_APPROVAL_SUCCESSFUL',
    CREATE_APPROVAL_FAILED: 'CREATE_APPROVAL_FAILED',

    START_FETCHING_APPROVAL: 'START_FETCHING_APPROVAL',
    FETCH_APPROVAL_SUCCESSFUL: 'FETCH_APPROVAL_SUCCESSFUL',
    FETCH_APPROVAL_FAILED: 'FETCH_APPROVAL_FAILED',
    STORE_APPROVAL_DATA: 'STORE_APPROVAL_DATA',

    START_UPDATE_APPROVAL: 'START_UPDATE_APPROVAL',
    UPDATE_APPROVAL_SUCCESSFUL: 'UPDATE_APPROVAL_SUCCESSFUL',
    UPDATE_APPROVAL_FAILED: 'UPDATE_APPROVAL_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    submittingBulkAction: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_APPROVALS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_APPROVAL: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_APPROVAL_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_APPROVAL_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response?.data
            });
        }
        case actions.FETCH_APPROVAL_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_APPROVALS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_APPROVALS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_APPROVAL_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_APPROVAL_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.START_BULK_ACTIONS_DELETE_APPROVALS: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }

        case actions.BULK_ACTIONS_DELETE_APPROVALS_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_DELETE_APPROVALS_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }

        default:
            return state;
    }
};
