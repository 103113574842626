import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';
import { dtoForm } from 'erpcore/utils/dto';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import UpdateProfileForm from 'erpcore/screens/MyAccount/components/UpdateProfileForm';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as myAccountActions } from 'erpcore/screens/MyAccount/MyAccount.reducer';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const Account = () => {
    const dispatch = useDispatch();
    const meData = dtoForm(useSelector(getMeData) || {});
    const initialValues = {
        first_name: meData.first_name,
        last_name: meData.last_name,
        email: meData.email,
        personal_email: meData.personal_email,
        phone_number: meData.phone_number,
        birthdate: dtoIgnoreTimezone(meData.birthdate),
        is_birthdate_private: meData.is_birthdate_private,
        image: meData.image,
        gender: meData.gender,
        t_shirt_size: meData.t_shirt_size,
        location: {
            full_address: meData.full_address,
            street: meData.street,
            country: meData.country,
            state: meData.state,
            city: meData.city,
            zip: meData.zip,
            latitude: meData.latitude,
            longitude: meData.longitude
        },
        timezone: meData?.timezone
    };

    const title = 'My account';

    const fetchMeData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_FETCHING_ME
            });
        }).catch((error) => ({ error }));
    };

    const onSubmitProfileForm = (formData) => {
        // send only changed data
        formData = diff(initialValues, formData);
        // Handle Location data
        const { location } = { ...formData };
        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: myAccountActions.START_UPDATE_USER,
                formData
            })
        ).catch((error) => {
            return error;
        });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchMeData();
    }, []);

    return (
        <>
            <HeadMeta title={title} />
            <PageHeader
                backButtonLink="/client/dashboard"
                backButtonLinkLabel="Back to dashboard"
                title={title}
            />
            <PageContent>
                <UpdateProfileForm
                    canChangePassword
                    onSubmit={onSubmitProfileForm}
                    initialValues={initialValues}
                />
            </PageContent>
        </>
    );
};

export default Account;
