export const actions = {
    UPDATE_SIDEBAR_DECK_STATE: 'UPDATE_SIDEBAR_DECK_STATE'
};

export const initialState = {
    showDeck: true
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.UPDATE_SIDEBAR_DECK_STATE: {
            return {
                ...state,
                showDeck: response
            };
        }
        default:
            return state;
    }
};
