export const actions = {
    // Listing
    START_FETCHING_DEPARTMENTS: 'START_FETCHING_DEPARTMENTS',
    FETCHING_SUCCESSFUL_DEPARTMENTS: 'FETCHING_SUCCESSFUL_DEPARTMENTS',
    FETCHING_FAILED_DEPARTMENTS: 'FETCHING_FAILED_DEPARTMENTS',
    // Single
    START_FETCHING_DEPARTMENT: 'START_FETCHING_DEPARTMENT',
    FETCH_DEPARTMENT_SUCCESSFUL: 'FETCH_DEPARTMENT_SUCCESSFUL',
    FETCH_DEPARTMENT_FAILED: 'FETCH_DEPARTMENT_FAILED',
    STORE_DEPARTMENT_DATA: 'STORE_DEPARTMENT_DATA',
    // Create
    START_CREATE_DEPARTMENT: 'START_CREATE_DEPARTMENT',
    CREATE_DEPARTMENT_SUCCESSFUL: 'CREATE_DEPARTMENT_SUCCESSFUL',
    CREATE_DEPARTMENT_FAILED: 'CREATE_DEPARTMENT_FAILED',
    // Edit
    START_UPDATE_DEPARTMENT: 'START_UPDATE_DEPARTMENT',
    UPDATE_DEPARTMENT_SUCCESSFUL: 'UPDATE_DEPARTMENT_SUCCESSFUL',
    UPDATE_DEPARTMENT_FAILED: 'UPDATE_DEPARTMENT_FAILED',
    // Delete
    START_DELETE_DEPARTMENT: 'START_DELETE_DEPARTMENT',
    DELETE_DEPARTMENT_SUCCESSFUL: 'DELETE_DEPARTMENT_SUCCESSFUL',
    DELETE_DEPARTMENT_FAILED: 'DELETE_DEPARTMENT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_DEPARTMENTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DEPARTMENTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_DEPARTMENTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.STORE_DEPARTMENT_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        default:
            return state;
    }
};
