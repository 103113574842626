import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import StatusManagement from 'erpcore/components/StatusManagement';

const ProjectApprovalStates = ({ title, data }) => {
    const logs = useMemo(() => {
        const { workflow_transition_logs: transitions = [], created_by: createdBy } = { ...data };
        const { first_name: creatorFirstName, last_name: creatorLastName } = { ...createdBy };
        const createdByName = `${creatorFirstName || ''} ${creatorLastName || ''}`;
        const isInternalUser = data?.assigned_to;

        const approvedBy = isInternalUser
            ? `${isInternalUser?.first_name || ''} ${isInternalUser?.last_name || ''}`
            : `${data?.contact?.first_name || ''} ${data?.contact?.last_name || ''}`;

        return transitions?.reduce((acc, item, index) => {
            let messageStatus = 'Pending';
            const { change_request_description: changeRequestDescription } = item?.data || {};
            if (item.to === 'created') messageStatus = 'Created';
            if (item.to === 'change_requested') messageStatus = 'Change requested';
            if (item.to === 'approved') messageStatus = 'Approved';
            if (index === 0) {
                messageStatus = 'Created';
            }
            return [
                ...acc,
                <StatusManagement.Log key={item.iri}>
                    <StatusManagement.LogItem>
                        {moment(item.created_at).format('MMM Do YYYY, h:mm a')}
                    </StatusManagement.LogItem>
                    <StatusManagement.LogItem isWide>
                        {messageStatus}
                        {changeRequestDescription && item.to === 'change_requested' ? (
                            <i>{`: ${changeRequestDescription}`}</i>
                        ) : (
                            ''
                        )}
                    </StatusManagement.LogItem>
                    <StatusManagement.LogItem>
                        {item.to === 'pending' ? createdByName : approvedBy}
                    </StatusManagement.LogItem>
                </StatusManagement.Log>
            ];
        }, []);
    }, [data]);

    return logs?.length > 0 ? <StatusManagement title={title} log={logs} /> : null;
};

ProjectApprovalStates.defaultProps = {
    title: 'Status management',
    data: {}
};

ProjectApprovalStates.propTypes = {
    title: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectApprovalStates;
