import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Billing orders Notifications
 * @return {Node} Returns Notification component
 */
const BillingRequest = ({ identifier, code }) => {
    switch (code) {
        case 'billingrequest.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Billing order successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'billingrequest.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Billing order successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'billingrequest.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Billing order successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'billingRequest.statusSuccessfullyChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Billing order status successfully changed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'money.addingAmountInDifferentCurrencyIsNotSupported':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Tried to add or subtract amount in CAD currency to the amount of USD currency"
                    type="error"
                />
            );
        default:
            return null;
    }
};

BillingRequest.defaultProps = {
    identifier: null,
    code: null
};

BillingRequest.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string
};

export default BillingRequest;
