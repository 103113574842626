export const actions = {
    START_FETCHING_REVENUES: 'START_FETCHING_REVENUES',
    FETCHING_SUCCESSFUL_REVENUES: 'FETCHING_SUCCESSFUL_REVENUES',
    FETCHING_FAILED_REVENUES: 'FETCHING_FAILED_REVENUES',

    START_DELETE_REVENUE: 'START_DELETE_REVENUE',
    DELETE_REVENUE_SUCCESSFUL: 'DELETE_REVENUE_SUCCESSFUL',
    DELETE_REVENUE_FAILED: 'DELETE_REVENUE_FAILED',

    START_CREATE_REVENUE: 'START_CREATE_REVENUE',
    CREATE_REVENUE_SUCCESSFUL: 'CREATE_REVENUE_SUCCESSFUL',
    CREATE_REVENUE_FAILED: 'CREATE_REVENUE_FAILED',

    START_FETCHING_SINGLE_REVENUE: 'START_FETCHING_SINGLE_REVENUE',
    FETCHING_SINGLE_REVENUE_SUCCESSFUL: 'FETCHING_SINGLE_REVENUE_SUCCESSFUL',
    FETCHING_SINGLE_REVENUE_FAILED: 'FETCHING_SINGLE_REVENUE_FAILED',
    STORE_SINGLE_REVENUE_DATA: 'STORE_SINGLE_REVENUE_DATA',

    START_UPDATE_REVENUE: 'START_UPDATE_REVENUE',
    UPDATE_REVENUE_SUCCESSFUL: 'UPDATE_REVENUE_SUCCESSFUL',
    UPDATE_REVENUE_FAILED: 'UPDATE_REVENUE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_REVENUES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_REVENUES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_REVENUES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_REVENUE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_SINGLE_REVENUE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SINGLE_REVENUE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_REVENUE_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
