import React from 'react';
import './SuspenseLoader.scss';

const SuspenseLoader = () => {
    return <div className="suspense-loader" />;
};

SuspenseLoader.defaultProps = {};

SuspenseLoader.propTypes = {};

export default SuspenseLoader;
