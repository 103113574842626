import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import LayoutManager from 'erpcore/utils/LayoutManager';
import SuspenseLoader from 'erpcore/components/SuspenseLoader';

const SignIn = React.lazy(() => import('erpcore/screens/SignIn'));
const ForgotPassword = React.lazy(() =>
    import('erpcore/screens/ForgotPassword/screens/ForgotPassword')
);
const ResetPassword = React.lazy(() =>
    import('erpcore/screens/ForgotPassword/screens/ResetPassword')
);

const FrontRoutes = () => {
    const location = useLocation();

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                    <Route exact path="/" element={<SignIn />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/reset-password/:token" element={<ResetPassword />} />
                    <Route exact path="/reset-password-login" element={<ResetPassword />} />
                    <Route path="*" element={<Navigate to="/" state={{ from: location }} />} />
                </Routes>
            </Suspense>
        </LayoutManager>
    );
};

FrontRoutes.defaultProps = {};

FrontRoutes.propTypes = {};

export default FrontRoutes;
