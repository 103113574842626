export const actions = {
    START_FETCHING_CALENDAR_EVENTS: 'START_FETCHING_CALENDAR_EVENTS',
    FETCHING_SUCCESSFUL_CALENDAR_EVENTS: 'FETCHING_SUCCESSFUL_CALENDAR_EVENTS',
    FETCHING_FAILED_CALENDAR_EVENTS: 'FETCHING_FAILED_CALENDAR_EVENTS',
    STORE_CALENDAR_EVENTS: 'STORE_CALENDAR_EVENTS',
    APPEND_CALENDAR_EVENTS: 'APPEND_CALENDAR_EVENTS',
    START_FETCHING_ADDITIONAL_CALENDAR_EVENTS: 'START_FETCHING_ADDITIONAL_CALENDAR_EVENTS'
};

export default (state = {}, { type, endpoint, response, startDate, endDate, id }) => {
    switch (type) {
        case actions.START_FETCHING_CALENDAR_EVENTS: {
            return {
                ...state,
                [id]: { ...state?.[id], fetching: true }
            };
        }
        case actions.START_FETCHING_ADDITIONAL_CALENDAR_EVENTS: {
            return {
                ...state,
                [id]: { ...state?.[id], fetching: true }
            };
        }
        case actions.FETCHING_SUCCESSFUL_CALENDAR_EVENTS: {
            return {
                ...state,
                [id]: { ...state?.[id], fetching: false }
            };
        }
        case actions.FETCHING_FAILED_CALENDAR_EVENTS: {
            return {
                ...state,
                [id]: { ...state?.[id], fetching: false }
            };
        }
        case actions.STORE_CALENDAR_EVENTS: {
            // Updating the store
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    dateRange: { startDate, endDate },
                    [endpoint]: response?.data
                }
            };
        }
        case actions.APPEND_CALENDAR_EVENTS: {
            return {
                ...state,
                [id]: {
                    ...state?.[id],
                    dateRange: { ...(state?.[id]?.dateRange || {}), startDate, endDate },
                    [endpoint]: [
                        ...(state?.[id]?.[endpoint] || []),
                        ...response?.data.filter(
                            item => !state?.[id]?.[endpoint].some(i => i.id === item.id)
                        )
                    ]
                }
            };
        }
        default:
            return state;
    }
};
