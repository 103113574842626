import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import './Phone.scss';
import Input from '../Input';

class Phone extends Component {
    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(value) {
        const { input } = this.props;
        input.onChange(value || '');
    }

    render() {
        const { input, meta, fieldProps, fieldAttr, field } = this.props;
        const inputFieldProps = Object.assign({}, fieldProps);
        inputFieldProps.iconPosition = 'left';
        if (fieldProps.forceLabelActive) {
            delete fieldProps.forceLabelActive;
        }

        return (
            <Input
                fieldProps={inputFieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <PhoneInput
                    id={input.name}
                    {...fieldAttr}
                    {...fieldProps}
                    {...input}
                    aria-label={`${input.name}`}
                    className="input__field"
                    onChange={this.handleOnChange}
                />
            </Input>
        );
    }
}

Phone.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Phone.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Phone;
