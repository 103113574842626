export const actions = {
    START_FETCHING_SINGLE_ORGANIZATION: 'START_FETCHING_SINGLE_ORGANIZATION',
    START_FETCHING_MINE_ORGANIZATION: 'START_FETCHING_MINE_ORGANIZATION',
    FETCH_SINGLE_ORGANIZATION_SUCCESSFUL: 'FETCH_SINGLE_ORGANIZATION_SUCCESSFUL',
    FETCH_SINGLE_ORGANIZATION_FAILED: 'FETCH_SINGLE_ORGANIZATION_FAILED',
    START_UPDATE_SINGLE_ORGANIZATION: 'START_UPDATE_SINGLE_ORGANIZATION',
    START_UPDATE_MINE_ORGANIZATION: 'START_UPDATE_MINE_ORGANIZATION',
    UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL: 'UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL',
    UPDATE_SINGLE_ORGANIZATION_FAILED: 'UPDATE_SINGLE_ORGANIZATION_FAILED',
    STORE_SINGLE_ORGANIZATION_DATA: 'STORE_SINGLE_ORGANIZATION_DATA',
    START_UPDATE_MINE_ORGANIZATION_WORK_DAYS: 'START_UPDATE_MINE_ORGANIZATION_WORK_DAYS'
};

export const initialState = {
    singleFetching: false,
    singleError: null
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_SINGLE_ORGANIZATION:
        case actions.START_FETCHING_MINE_ORGANIZATION: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_ORGANIZATION_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_ORGANIZATION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_ORGANIZATION_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        default:
            return state;
    }
};
