import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import CustomInput from 'erpcore/components/Form/components/DateRange/components/CustomInput';
import DateRangePresets from 'erpcore/components/Form/components/DateRange/components/DateRangePresets';
import { getFormattedDateRangePresetMap } from 'erpcore/components/Form/components/DateRange/dateRangePresetMap';
import moment from 'moment-timezone';
import { createPortal } from 'react-dom';
import { getResponsive, insertIf } from 'erpcore/utils/utils';
import convertMomentJsToDateFnsFormat from 'erpcore/utils/convertMomentJsToDateFnsFormat';
import defaultDateFormat from 'erpcore/utils/defaultDateFormat';
import { useId } from 'react-id-generator';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { Field } from 'redux-form';
import { Hidden } from 'erpcore/components/Form';
import classnames from 'classnames';
import Input from '../Input';
import 'erpcore/components/Form/components/DateTime/DateTime.scss';
import './DateRange.scss';

const PopperContainer = ({ children, containerElement }) => {
    return createPortal(children, containerElement);
};

/**
 *
 * How to use flat start and end fields:
 * <Fields
 *     // ^  NOT the <Field> but the <Fields> with an >>> s <<<
 *     names={['start_date', 'end_date']} // fieldProps.keyStart, fieldProps.keyEnd
 *     fieldProps={{
 *         keyStart: 'start_date', // must match one of the names (order is not important)
 *         keyEnd: 'end_date', // must match one of the names (order is not important)
 *     }}
 *     fieldAttr={{
 *         required: true
 *     }}
 *     component={DateRange}
 *     validate={{
 *         start_date: valueValidation([{ validator: 'required' }]), // form validation is done separately per each field name
 *         end_date: valueValidation([{ validator: 'required' }])
 *     }}
 * />
 *
 */
const DateRange = ({
    input,
    meta,
    fieldProps,
    fieldAttr,
    field,
    iconOnly,
    iconOnlyStyle,
    greedyPortal,
    dateFormat,
    presentationalDateFormat,
    constrainToSameMonth,
    filterDate,
    minDate,
    maxDate,
    includeDates,
    includeDateIntervals,
    excludeDates,
    excludeDateIntervals,
    enableDateRangePresets,
    isAllowedSelectSameDay,
    ...fields
}) => {
    const { isMobile } = getResponsive();
    const { keyStart = 'start', keyEnd = 'end', arraySupport } = fieldProps;

    const [globallyUniqueComponentId, globallyUniqueStartDateId, globallyUniqueEndDateId] = useId(
        3,
        'date-range-input-'
    );

    const [globallyUniquePopperId] = useId(1, 'date-range-portal-');
    const dateRangePortalWrapper =
        document.getElementById('daterange-portal-wrapper') || document.body;
    const popperContainerElement = useRef(dateRangePortalWrapper); // does not need to be reactive
    // create popper container node element
    if (!document.getElementById(globallyUniquePopperId)) {
        const newPopperContainerElement = document.createElement('div');
        newPopperContainerElement.setAttribute('id', globallyUniquePopperId);
        newPopperContainerElement.setAttribute(
            'class',
            `date-range-portal ${greedyPortal ? 'date-range-portal--greedy' : ''}`
        );
        popperContainerElement.current = newPopperContainerElement;
        dateRangePortalWrapper.appendChild(newPopperContainerElement);
    }

    // remove popper container node element on unMount
    useEffect(() => {
        return () => {
            const popperContainerElementForRemoval =
                document.getElementById(globallyUniquePopperId);
            if (popperContainerElementForRemoval) {
                popperContainerElementForRemoval.remove();
            }
        };
    }, []);

    const momentDateFormat = dateFormat || fieldProps?.dateFormat || defaultDateFormat;

    const dateFnsDateFormat = useMemo(() => {
        return convertMomentJsToDateFnsFormat(momentDateFormat);
    }, [momentDateFormat]);

    /**
     * If TRUE; component is rendered via <Fields> (instead of <Field>)
     */
    const isFlatFields =
        fields?.names?.length &&
        keyStart &&
        keyEnd &&
        get(fields, keyStart)?.input &&
        get(fields, keyEnd)?.input &&
        fields.names.includes(keyStart) &&
        fields.names.includes(keyEnd);

    const isFlatFieldsRef = useRef(isFlatFields);

    useEffect(() => {
        isFlatFieldsRef.current = isFlatFields;
    }, [isFlatFields]);

    // eslint-disable-next-line
    const inputStartEndValuePair = useMemo(() => {
        /**
         * <Fields />
         */
        if (isFlatFieldsRef.current) {
            return [get(fields, keyStart)?.input?.value, get(fields, keyEnd)?.input?.value];
        }

        /**
         * <Field />
         */
        if (!input?.value) {
            return [null, null];
        }

        if (isArray(input.value)) {
            return [input.value[0], input.value[1]];
        }

        if (isPlainObject(input.value)) {
            return [input.value[keyStart], input.value[keyEnd]];
        }

        return [null, null];
    }, [
        isFlatFields,
        get(fields, keyStart)?.input?.value,
        get(fields, keyEnd)?.input?.value,
        input?.value
    ]);

    const inputStartEndValuePairRef = useRef(inputStartEndValuePair);

    // const [inputStartDate, inputEndDate] = [...inputStartEndValuePair];

    const [datesBeforeOpen, setDatesBeforeOpen] = useState([...inputStartEndValuePair]);
    const [startDateBeforeOpen, endDateBeforeOpen] = [...datesBeforeOpen];

    const [localDates, setLocalDates] = useState([...inputStartEndValuePair]);
    const [localStartDate, localEndDate] = [...localDates];

    const minDateFinal = useMemo(() => {
        if (!constrainToSameMonth) {
            return minDate;
        }

        if (constrainToSameMonth && localStartDate) {
            return moment(localStartDate).startOf('month').toDate();
        }

        return undefined;
    }, [constrainToSameMonth, minDate, localStartDate]);

    const maxDateFinal = useMemo(() => {
        if (!constrainToSameMonth) {
            return maxDate;
        }

        if (constrainToSameMonth && localStartDate) {
            return moment(localStartDate).endOf('month').toDate();
        }

        return undefined;
    }, [constrainToSameMonth, maxDate, localStartDate]);

    const inputNameStart = useMemo(() => {
        if (isFlatFieldsRef.current) {
            return keyStart;
        }

        if (arraySupport) {
            return `${input?.name}[0]`;
        }

        return keyStart;
    }, [keyStart, isFlatFields, arraySupport]);

    const inputNameEnd = useMemo(() => {
        if (isFlatFieldsRef.current) {
            return keyEnd;
        }

        if (arraySupport) {
            return `${input?.name}[1]`;
        }

        return keyEnd;
    }, [keyEnd, isFlatFields, arraySupport]);

    const inputOnFocus = useCallback(() => {
        const dummyOnFocus = () => {};
        return (
            (isFlatFieldsRef.current ? get(fields, keyStart)?.input?.onFocus : input?.onFocus) ||
            dummyOnFocus
        );
    }, [isFlatFields, get(fields, keyStart)?.input?.onFocus, input?.onFocus]);

    const inputOnBlur = useCallback(() => {
        const dummyOnBlur = () => {};
        return (
            (isFlatFieldsRef.current ? get(fields, keyStart)?.input?.onBlur : input?.onBlur) ||
            dummyOnBlur
        );
    }, [isFlatFields, get(fields, keyStart)?.input?.onBlur, input?.onBlur]);

    const clearableValue = useMemo(() => {
        const fallback = arraySupport ? [] : '';
        return 'clearableValue' in { ...fieldProps } ? fieldProps.clearableValue : fallback;
    }, [fieldProps?.clearableValue, arraySupport]);

    const [inputFocused, setInputFocused] = useState('start');
    const onFocusStartInput = useCallback(() => {
        setInputFocused('start');
    }, []);

    const onFocusEndInput = useCallback(() => {
        setInputFocused('end');
    }, []);

    const handleLocalDateChange = useCallback(
        (dates = []) => {
            if (!dates?.length || (!dates?.[0] && !dates?.[1])) {
                setLocalDates([]);
                return;
            }

            const getFormattedDate = (x) => {
                return x ? moment(x).format(momentDateFormat) : null;
            };

            setLocalDates([getFormattedDate(dates?.[0]), getFormattedDate(dates?.[1])]);

            if (dates?.[0] && !dates?.[1]) {
                setInputFocused('end');
                if (document.getElementById(globallyUniqueEndDateId)) {
                    document.getElementById(globallyUniqueEndDateId).focus();
                }
            }
        },
        [momentDateFormat]
    );

    const handleInputDateChange = useCallback(
        (dates = []) => {
            /**
             * Early return
             * Change to empty
             */
            if (!dates?.length || (!dates?.[0] && !dates?.[1])) {
                if (isFlatFieldsRef.current) {
                    get(fields, keyStart)?.input?.onChange(clearableValue);
                    get(fields, keyEnd)?.input?.onChange(clearableValue);
                } else {
                    input.onChange(clearableValue);
                }
                return;
            }

            const start = dates?.[0];
            const end = dates?.[1];

            /**
             * Early return
             * Don't change because one of the start|end are missing
             */
            if ((start && !end) || (!start && end)) {
                return;
            }

            const getFormattedDate = (x) => {
                return x ? moment(x).format(momentDateFormat) : null;
            };

            /**
             * <Fields />
             */
            if (isFlatFieldsRef.current) {
                if (start) {
                    get(fields, keyStart)?.input?.onChange(getFormattedDate(start));
                }
                if (end) {
                    get(fields, keyEnd)?.input?.onChange(getFormattedDate(end));
                }
            }
            // eslint-disable-next-line
            /**
             * <Field />
             */
            else if (arraySupport) {
                input.onChange([
                    ...insertIf(start, [getFormattedDate(start)]),
                    ...insertIf(end, [getFormattedDate(end)])
                ]);
            } else if (!arraySupport) {
                input.onChange({
                    ...(start ? { [keyStart]: getFormattedDate(start) } : clearableValue),
                    ...(end ? { [keyEnd]: getFormattedDate(end) } : clearableValue)
                });
            }
        },
        [
            isFlatFields,
            arraySupport,
            get(fields, keyStart)?.input?.onChange,
            get(fields, keyEnd)?.input?.onChange,
            dateFnsDateFormat,
            momentDateFormat,
            keyStart,
            keyEnd
        ]
    );

    const handleClearForFlatFields = useCallback(() => {
        handleInputDateChange([]);
    }, [handleInputDateChange]);

    /**
     * INPUT -> LOCAL
     *
     */
    useEffect(() => {
        if (
            inputStartEndValuePair[0] !== inputStartEndValuePairRef.current[0] &&
            inputStartEndValuePair[1] !== inputStartEndValuePairRef.current[1]
        ) {
            handleLocalDateChange(inputStartEndValuePair);
        }

        inputStartEndValuePairRef.current = inputStartEndValuePair;
    }, [inputStartEndValuePair]);

    /**
     * LOCAL -> INPUT
     *
     */
    useEffect(() => {
        const [inputStartDateRef, inputEndDateRef] = [...inputStartEndValuePairRef.current];
        if (
            localStartDate &&
            localEndDate &&
            (moment(localStartDate).format('X') !== moment(inputStartDateRef).format('X') ||
                moment(localEndDate).format('X') !== moment(inputEndDateRef).format('X'))
        ) {
            handleInputDateChange([localStartDate, localEndDate]);
        }
    }, [localStartDate, localEndDate]);

    const calculateOpenDate = useCallback((start, end) => {
        // no start date
        if (!start) {
            return null;
        }

        const defaultOutput = moment(start).date(15).toDate();

        // no end date
        if (!end) {
            return defaultOutput;
        }

        // start and end are not in the same month
        if (moment(start).month() !== moment(end).month()) {
            return moment(start).add(1, 'months').date(15).toDate();
        }

        // start and end are in the same month
        return defaultOutput;
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const isOpenRef = useRef(isOpen);
    const [openToDate, setOpenToDate] = useState(calculateOpenDate(localStartDate, localEndDate));

    const onCalendarOpen = useCallback(() => {
        setDatesBeforeOpen([localStartDate, localEndDate]);
        setIsOpen(true);
    }, [localStartDate, localEndDate]);

    const onCalendarClose = useCallback(() => {
        setIsOpen(false);
        setInputFocused('start');
        inputOnBlur();
    }, [inputOnBlur]);

    const onInputEndClick = useCallback(() => {
        handleLocalDateChange([inputStartEndValuePairRef.current?.[0], null]);
    }, []);

    /**
     * Change openToDate only while dropdown is closed
     */
    useEffect(() => {
        if (!isOpen) {
            setOpenToDate(calculateOpenDate(localStartDate, localEndDate));
        }
    }, [isOpen, localStartDate, localEndDate]);

    /**
     * Handle effects after closing
     * (reset incomplete values)
     */
    useEffect(() => {
        // if effects is triggered by closing
        if (!isOpen && isOpenRef.current) {
            const emptyDates = [];

            let newValue;

            if (!localStartDate) {
                // no start date
                newValue = emptyDates;
            } else if (!localEndDate) {
                // has start date
                // no end date
                if (!endDateBeforeOpen) {
                    // did NOT have end date before opening
                    newValue = emptyDates;
                } else if (localStartDate === startDateBeforeOpen) {
                    // had end date before opening && start date did NOT change
                    newValue = [localStartDate, endDateBeforeOpen];
                } else {
                    // had end date before opening && start date did change
                    newValue = emptyDates;
                }
            }

            if (newValue) {
                handleLocalDateChange(newValue);
            }
        }
        isOpenRef.current = isOpen;
    }, [isOpen, localStartDate, localEndDate, datesBeforeOpen, handleLocalDateChange]);

    /**
     * end date should never exist without the start date
     */
    useEffect(() => {
        if (!localStartDate && localEndDate) {
            handleLocalDateChange([]);
        }
        isOpenRef.current = isOpen;
    }, [localStartDate, localEndDate, handleLocalDateChange]);

    const flatFieldsMeta = useMemo(() => {
        if (!isFlatFields) {
            return {};
        }

        const metaStart = get(fields, keyStart)?.meta || {};
        const metaEnd = get(fields, keyEnd)?.meta || {};

        const resolveValueByKey = (key) => metaStart[key] || metaEnd[key];

        const dirty = resolveValueByKey('dirty');
        const invalid = resolveValueByKey('invalid');

        return {
            ...metaStart,
            active: resolveValueByKey('active'),
            dirty,
            pristine: !dirty,
            invalid,
            valid: !invalid,
            touched: resolveValueByKey('touched'),
            visited: resolveValueByKey('visited'),
            warning: resolveValueByKey('warning')
        };
    }, [isFlatFields, get(fields, keyStart)?.meta, get(fields, keyEnd)?.meta]);

    const [activePreset, setActivePreset] = useState('');

    const dateRangePresets = useMemo(() => {
        return getFormattedDateRangePresetMap(momentDateFormat);
    }, [momentDateFormat]);

    /**
     * Set new local dates
     */
    const onPresetChange = useCallback(
        (preset) => {
            const presetRange = dateRangePresets[preset];
            if (presetRange) {
                handleLocalDateChange(presetRange);
                setOpenToDate(calculateOpenDate(presetRange[0], presetRange[1]));
            }
        },
        [handleLocalDateChange]
    );

    /**
     * Set active preset when local dates change
     */
    useEffect(() => {
        let nextPreset = '';

        if (localStartDate && localEndDate) {
            // eslint-disable-next-line no-restricted-syntax
            for (const currentPresetName of Object.keys(dateRangePresets)) {
                const currentPresetRange = dateRangePresets[currentPresetName];
                if (
                    currentPresetRange[0] === localStartDate &&
                    currentPresetRange[1] === localEndDate
                ) {
                    nextPreset = currentPresetName;
                    break;
                }
            }
        }

        setActivePreset(nextPreset);
    }, [dateRangePresets, localStartDate, localEndDate]);

    return (
        <>
            {!!isFlatFields && (
                <Field component={Hidden} input={get(fields, keyEnd)?.input} name={keyEnd} />
            )}
            <Input
                fieldProps={{
                    ...fieldProps,
                    onIconClick: () => {
                        const targetFocusInput = document.getElementById(globallyUniqueStartDateId);
                        if (targetFocusInput) {
                            targetFocusInput.click();
                        }
                    },
                    ...(isOpen ? { forceLabelActive: true } : null),
                    ...(isFlatFields ? { handleClear: handleClearForFlatFields } : null)
                }}
                fieldAttr={fieldAttr}
                field={field}
                // input={input}
                // meta={meta}
                input={isFlatFields ? get(fields, keyStart)?.input : input}
                meta={isFlatFields ? flatFieldsMeta : meta}
                className={classnames({
                    'input--datepicker': true,
                    'input--datepicker-range': true,
                    'datepicker-input-icon-only': iconOnly,
                    'datepicker-disable-select-same-day': !isAllowedSelectSameDay
                })}
            >
                <DatePicker
                    id={globallyUniqueComponentId}
                    calendarClassName="daterange-calendar"
                    className="input__field"
                    showDisabledMonthNavigation
                    showMonthDropdown={false}
                    showYearDropdown={false}
                    showTimeSelect={false}
                    popperProps={{
                        strategy: 'fixed'
                    }}
                    {...fieldAttr}
                    {...fieldProps}
                    onBlur={() => {
                        inputOnBlur();
                    }}
                    onFocus={() => {
                        inputOnFocus();
                    }}
                    dateFormat={dateFnsDateFormat}
                    selectsRange
                    showPreviousMonths={false}
                    {...(!isMobile && !isOpen ? { openToDate } : null)}
                    selected={localStartDate ? moment(localStartDate).toDate() : null}
                    startDate={localStartDate ? moment(localStartDate).toDate() : null}
                    endDate={localEndDate ? moment(localEndDate).toDate() : null}
                    monthsShown={!isMobile ? 2 : 1}
                    peekNextMonth={false}
                    allowSameDay
                    filterDate={filterDate}
                    minDate={minDateFinal}
                    maxDate={maxDateFinal}
                    includeDates={includeDates}
                    includeDateIntervals={includeDateIntervals}
                    excludeDates={excludeDates}
                    excludeDateIntervals={excludeDateIntervals}
                    onCalendarOpen={onCalendarOpen}
                    onCalendarClose={onCalendarClose}
                    disabled={!!fieldAttr?.disabled}
                    disabledKeyboardNavigation
                    onChange={handleLocalDateChange}
                    customInput={
                        <CustomInput
                            fieldAttr={fieldAttr}
                            inputNameStart={inputNameStart}
                            inputNameEnd={inputNameEnd}
                            startDate={localStartDate}
                            endDate={localEndDate}
                            startDateId={globallyUniqueStartDateId}
                            endDateId={globallyUniqueEndDateId}
                            presentationalDateFormat={presentationalDateFormat}
                            iconOnly={iconOnly}
                            iconOnlyStyle={iconOnlyStyle}
                            icon={fieldProps?.icon || 'date'}
                            isEmpty={
                                !isOpen &&
                                !inputStartEndValuePair?.[0] &&
                                !inputStartEndValuePair?.[1]
                            }
                            onInputEndClick={onInputEndClick}
                            onFocusStartInput={onFocusStartInput}
                            onFocusEndInput={onFocusEndInput}
                            inputFocused={isOpen ? inputFocused : null}
                        />
                    }
                    popperModifiers={[
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                                altAxis: true
                            }
                        }
                    ]}
                    popperContainer={({ children, ...rest }) => (
                        <PopperContainer
                            {...rest}
                            containerElement={popperContainerElement.current}
                        >
                            {children}
                        </PopperContainer>
                    )}
                >
                    {!!enableDateRangePresets && (
                        <DateRangePresets
                            activePreset={activePreset}
                            onPresetChange={onPresetChange}
                        />
                    )}
                </DatePicker>
            </Input>
        </>
    );
};

DateRange.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    iconOnly: false,
    iconOnlyStyle: null,
    greedyPortal: false,
    dateFormat: null,
    presentationalDateFormat: defaultDateFormat,
    constrainToSameMonth: false,
    filterDate: undefined,
    minDate: undefined,
    maxDate: undefined,
    includeDates: undefined,
    includeDateIntervals: undefined,
    excludeDates: undefined,
    excludeDateIntervals: undefined,
    enableDateRangePresets: true,
    isAllowedSelectSameDay: true
};

DateRange.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    iconOnly: PropTypes.bool, // renders only icon
    iconOnlyStyle: PropTypes.object,
    greedyPortal: PropTypes.bool, // renders click hijacking element outside calendar
    dateFormat: PropTypes.string, // output format, defaults to 'M/D/YYYY' (defaultDateFormat)
    presentationalDateFormat: PropTypes.string, // render format, do not rename to renderDateFormat because it will collide with library internals
    constrainToSameMonth: PropTypes.bool,
    filterDate: PropTypes.func,
    minDate: PropTypes.object, // must be native Date object
    maxDate: PropTypes.object, // must be native Date object
    includeDates: PropTypes.arrayOf(
        PropTypes.object // must be native Date object
    ),
    includeDateIntervals: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.object, // must be native Date object
            end: PropTypes.object // must be native Date object
        })
    ),
    excludeDates: PropTypes.arrayOf(
        PropTypes.object // must be native Date object
    ),
    excludeDateIntervals: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.object, // must be native Date object
            end: PropTypes.object // must be native Date object
        })
    ),
    enableDateRangePresets: PropTypes.bool,
    isAllowedSelectSameDay: PropTypes.bool
};

export default DateRange;
