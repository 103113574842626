import React, { useEffect, useState } from 'react';
import Svg from 'erpcore/components/Svg';
import Form, { Checkbox } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { change, Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions as myAccountActions } from 'erpcore/screens/MyAccount/MyAccount.reducer';
import Button from 'erpcore/components/Button';
import './CustomListingColumns.scss';
import { getMeData, getUserColumnSettings } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';

const CustomListingColumns = ({ data, handleSubmit, submitting }) => {
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const { customColumns } = { ...data };
    const { columns = [], settingsFor } = { ...customColumns };
    const userColumnSettings = useSelector(state => getUserColumnSettings(state, settingsFor));

    const fetchUserData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: authActions.START_FETCHING_ME,
                promise: { resolve, reject }
            });
        });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        if (userColumnSettings) {
            columns.map(column => {
                return dispatch(change('CustomListingColumnsForm', column.name, false));
            });

            userColumnSettings.map(column => {
                return dispatch(change('CustomListingColumnsForm', column, true));
            });
        }
    }, [userColumnSettings]);

    const updateUserSettings = formData => {
        const keys = Object.keys(formData);
        const columnSettings = keys.filter(key => formData[key]);

        const formatedData = {
            meta: {
                ...meData?.meta,
                [settingsFor]: columnSettings
            }
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: myAccountActions.START_UPDATE_USER,
                promise: { resolve, reject },
                formData: formatedData
            });
        });
    };

    const renderColumns = () => {
        return (
            <Form
                onSubmit={handleSubmit(updateUserSettings)}
                className="custom-listing-columns__form"
            >
                {columns?.length &&
                    columns.map(({ name, label }) => (
                        <Field
                            name={name}
                            fieldProps={{ label, value: true }}
                            fieldAttr={{ id: name }}
                            component={Checkbox}
                        />
                    ))}

                <Button
                    label="Submit"
                    size="small"
                    submit
                    onClick={() => setOpened(false)}
                    loading={submitting}
                />
            </Form>
        );
    };

    return (
        <div className={`custom-listing-columns ${opened ? 'custom-listing-columns--opened' : ''}`}>
            <button
                type="button"
                aria-pressed={opened}
                className="custom-listing-columns__btn"
                onClick={() => setOpened(!opened)}
                onKeyPress={() => setOpened(!opened)}
            >
                <Svg icon="columns" className="custom-listing-columns__btn-icon" />
                <span className="custom-listing-columns__btn-label">Columns</span>
            </button>

            <div className="custom-listing-columns__dropdown">
                <div className="custom-listing-columns__dropdown-header">
                    <div className="custom-listing-columns__dropdown-header-col">
                        <h4>Choose columns to be displayed</h4>
                    </div>
                    <div className="custom-listing-columns__dropdown-header-col">
                        <button
                            type="button"
                            aria-pressed={!opened}
                            className="custom-listing-columns__dropdown-close"
                            onClick={() => setOpened(!opened)}
                            onKeyPress={() => setOpened(!opened)}
                        >
                            <Svg icon="close" />
                        </button>
                    </div>
                </div>
                {renderColumns()}
            </div>
        </div>
    );
};

CustomListingColumns.defaultProps = {
    data: {},
    handleSubmit: () => {},
    submitting: false
};

CustomListingColumns.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool
};

export default reduxForm({ form: 'CustomListingColumnsForm', enableReinitialize: false })(
    CustomListingColumns
);
