import React from 'react';
import Svg from 'erpcore/components/Svg';
import './Repeater.scss';
import PropTypes from 'prop-types';
import { getFormSyncErrors, getFormInitialValues } from 'redux-form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import ElementLoader from 'erpcore/components/ElementLoader';
import { diff } from 'deep-object-diff';
import { connect } from 'react-redux';

const RepeaterSortableItem = SortableElement(({ children }) => {
    return <div className="repeater__item">{children}</div>;
});

const RepeaterSortableContainer = SortableContainer(({ children }) => {
    return <div className="repeater__list">{children}</div>;
});

/**
 * Must be wrapped by redux-form FieldArray
 */
class Repeater extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            dragging: false,
            modalIndexOpened: false
        };

        this.removeItem = this.removeItem.bind(this);
        this.changeItemMiddleware = this.changeItemMiddleware.bind(this);
    }

    getMergedRepeaterAndItemData(itemData = null) {
        const { data } = this.props;

        return { ...data, ...itemData };
    }

    setDraggingState(dragging = true) {
        this.setState({ dragging });
    }

    getNewItemsIndexes() {
        const { fields } = this.props;

        const newRepeaterItemIndexes = [];

        if (fields && fields.length) {
            fields.forEach((member, index) => {
                if (fields.get(index).isNewRepeaterItem === true) {
                    newRepeaterItemIndexes.push(index);
                }
            });
        }

        return newRepeaterItemIndexes;
    }

    getRepeaterSortableItems() {
        const { fields, RepeatableItem, uniqueIdentifier, canDelete, canSort, canSortNewItems } =
            this.props;

        let items = [];

        let newItemsCount = 0;

        if (fields && fields.getAll() && fields.getAll().length) {
            items = fields.map((member, index) => {
                const repeaterItemData = fields.get(index);

                const key =
                    repeaterItemData[uniqueIdentifier] || repeaterItemData[uniqueIdentifier] === 0
                        ? repeaterItemData[uniqueIdentifier]
                        : `new-item-${++newItemsCount}`; // eslint-disable-line no-plusplus

                // disable sort if prop.canSort is false or (if an item is a new (unsaved) item and prop.canSortNewItems is false)
                const isSortDisabled =
                    !canSort || (repeaterItemData.isNewRepeaterItem && !canSortNewItems);

                return (
                    <RepeaterSortableItem
                        key={key}
                        collection={this.determineCollectionKey(repeaterItemData)}
                        disabled={isSortDisabled}
                        index={index}
                    >
                        {canSort && (
                            <div className="repeater__item-move">
                                <Svg icon="drag" className="repeater__item-move-icon" />
                            </div>
                        )}
                        <div className="repeater__item-content">
                            <RepeatableItem
                                member={member}
                                memberIndex={index}
                                isNewRepeaterItem={repeaterItemData.isNewRepeaterItem}
                                data={this.getMergedRepeaterAndItemData(repeaterItemData)}
                                changeItemMiddleware={this.changeItemMiddleware}
                                repeaterFieldName={fields?.name}
                            />
                            {!!repeaterItemData.isNewRepeaterItem && (
                                <Button
                                    variation="tertiary"
                                    className="repeater__item-cancel"
                                    size="small"
                                    label="Cancel"
                                    labelOnlyAria
                                    iconName="close"
                                    onClick={() => this.cancelNewItem(index)}
                                />
                            )}
                            {!!canDelete && !repeaterItemData.isNewRepeaterItem && (
                                <Button
                                    variation="tertiary"
                                    className="repeater__item-remove"
                                    size="small"
                                    label="Remove"
                                    labelOnlyAria
                                    iconName="remove"
                                    onClick={() => this.handleModal(index)}
                                />
                            )}
                        </div>
                    </RepeaterSortableItem>
                );
            });
        }

        return items;
    }

    getControls() {
        const {
            canSave,
            canAddMultiple,
            saveButtonIntent,
            addNewButtonVariation,
            addNewButtonIcon,
            canSaveAllSortOrder,
            canAdd,
            RepeatableItem,
            addNewLabel,
            saveLabel,
            saveSortOrderLabel,
            fields,
            meta,
            customButtonsArea
        } = this.props;

        const renderSaveButton = canSave && (!!this.hasNewItem() || saveButtonIntent === 'all');

        const renderAddNewButton =
            !!canAdd && !!RepeatableItem && (!this.hasNewItem() || canAddMultiple);

        return (
            <React.Fragment>
                {!!renderSaveButton && (
                    <Button
                        disabled={
                            saveButtonIntent === 'all'
                                ? !this.areItemsValid(
                                      /* eslint-disable-next-line prefer-spread */
                                      Array.apply(null, { length: fields.length }).map(
                                          Number.call,
                                          Number
                                      )
                                  ) || !meta.dirty // disable button if button intent is 'all', and any of all fields is not valid or not dirty
                                : !this.areItemsValid(this.getNewItemsIndexes()) // disable button if button intent is 'new', and at least one new field is not valid
                        }
                        onClick={() => this.saveAll()}
                        label={saveLabel}
                        size="small"
                    />
                )}
                {canSaveAllSortOrder && (
                    <Button
                        onClick={() => this.saveSortOrder()}
                        label={saveSortOrderLabel}
                        size="small"
                    />
                )}
                {!!renderAddNewButton && (
                    <Button
                        className={`repeater__add-new repeater__add-new--${addNewButtonVariation}`}
                        variation={
                            addNewButtonVariation === 'right' ||
                            addNewButtonVariation === 'tertiary'
                                ? 'tertiary'
                                : 'secondary'
                        }
                        onClick={() => this.addNewItem()}
                        label={addNewLabel}
                        size="small"
                        iconName={addNewButtonIcon || null}
                    />
                )}
                {!!customButtonsArea && customButtonsArea}
            </React.Fragment>
        );
    }

    sortStart = ({ node, index }) => {
        const { onSortStart } = this.props;

        this.setDraggingState(true);

        const forwardProps = { node, index };

        const { fields } = this.props;
        forwardProps.itemData = fields.get(index);

        onSortStart(forwardProps);
    };

    sortEnd = ({ oldIndex, newIndex }) => {
        const { onSortEnd } = this.props;

        const forwardProps = { oldIndex, newIndex };

        const { fields } = this.props;

        // Here we want to reorder the items in the array
        fields.move(oldIndex, newIndex);

        /*  Using setTimeout here is not the best solution here.
            However, .move() method above is executing some async code 
            and unless we wrap the below code in setTimeout, the .move()
            logic will not be executed in time and we will end up with 
            the old (NOT reordered) array reference.
        */
        setTimeout(() => {
            const { fields: updatedFieldsReference } = this.props;
            forwardProps.itemData = updatedFieldsReference.get(newIndex);
            forwardProps.allData = updatedFieldsReference.getAll();

            onSortEnd(forwardProps);

            this.setDraggingState(false);
        }, 100);
    };

    determineCollectionKey(data) {
        const { canSort, canSortNewItems, canMixItems } = this.props;
        if (canSort && canSortNewItems && canMixItems) {
            return 'universal';
        }

        return data.isNewRepeaterItem ? 'new' : 'api';
    }

    hasNewItem() {
        const { fields } = this.props;

        let hasNewItem = false;

        if (fields && fields.length) {
            const newItems = fields.getAll().filter((member, index) => {
                return fields.get(index).isNewRepeaterItem === true;
            });

            if (newItems && newItems.length) {
                hasNewItem = true;
            }
        }

        return hasNewItem;
    }

    /**
     * Checks provided fields by indexes, and;
     * returns true if all fields are valid,
     * returns true if empty array of indexes is provided,
     * returns false if any of the fields is not valid,
     * returns undefined if indexCollection is null or if Repeater is not wrapped with FieldArray
     *
     * @param indexCollection {array}
     * @return {boolean|undefined}
     */
    areItemsValid(indexCollection = null) {
        if (indexCollection === null) return undefined;

        const { fields } = this.props;

        if (fields && fields.name) {
            const { formSyncErrors } = this.props;

            let isAllValid = true;

            indexCollection.forEach(index => {
                if (
                    formSyncErrors &&
                    fields.name in formSyncErrors &&
                    index in formSyncErrors[fields.name] &&
                    formSyncErrors[fields.name][index] &&
                    formSyncErrors[fields.name][index].constructor === Object &&
                    Object.keys(formSyncErrors[fields.name][index]).length > 0
                ) {
                    isAllValid = false;
                }
            });

            return isAllValid;
        }

        return undefined;
    }

    addNewItem() {
        const { fields, newItemInitialValues } = this.props;
        if (fields) {
            fields.push({ isNewRepeaterItem: true, ...newItemInitialValues });
        }
    }

    saveAll() {
        const { onSaveAll, onSaveNewItem, formInitialValues, fields } = this.props;
        let newItem = null;
        const oldItems = [];
        const newItems = [];
        const oldItemsForDiff = {};
        let oldItemsDiff = {};
        fields.forEach((member, index) => {
            const item = fields.get(index);
            if (item.isNewRepeaterItem && item.isNewRepeaterItem === true) {
                fields.get(index).isNewRepeaterItem = false;
                newItem = item;
                newItems.push(item);
            } else {
                oldItems.push(item);
                oldItemsForDiff[index] = item;
            }
        });

        if (newItem) {
            delete newItem.isNewRepeaterItem;
        }

        if (fields && fields.name && formInitialValues && formInitialValues[fields.name]) {
            oldItemsDiff = { ...diff(formInitialValues[fields.name], oldItemsForDiff) };
        }

        onSaveAll({ oldItems, oldItemsDiff, newItems });
        onSaveNewItem({ itemData: newItem, newItems }); // legacy onSaveNewItem hook and itemData property

        this.forceUpdate();
    }

    cancelNewItem(index) {
        const { onCancelNewItem, fields } = this.props;

        const item = fields.get(index);
        if (item.isNewRepeaterItem && item.isNewRepeaterItem === true) {
            fields.remove(index);
        }

        onCancelNewItem({ itemData: item });
    }

    removeItem(fieldIndex = false) {
        if (fieldIndex === false) return null;

        const { onRemoveItem, fields } = this.props;

        fields.remove(fieldIndex);

        const itemData = fields.get(fieldIndex);

        onRemoveItem({ itemData });

        return null;
    }

    changeItemMiddleware(data = null) {
        const { onChangeItem } = this.props;

        onChangeItem(data);
    }

    handleModal(index = false) {
        this.setState({ modalIndexOpened: index });
    }

    saveSortOrder() {
        const { onSaveSortOrder, fields } = this.props;

        const allData = fields.getAll();

        onSaveSortOrder({ allData });
    }

    render() {
        const { dragging, modalIndexOpened } = this.state;

        const {
            canSort,
            noRightPadding,
            loading,
            deleteModalTitle,
            deleteModalSubTitle,
            deleteModalButtonDelete,
            deleteModalButtonCancel
        } = this.props;

        return (
            <div
                className={`repeater${dragging ? ' repeater--dragging' : ''}${
                    loading ? ' repeater--loading' : ''
                }${!canSort ? ' repeater--no-sort' : ''}${
                    noRightPadding ? ' repeater--no-right-padding' : ''
                }`}
            >
                <RepeaterSortableContainer
                    helperClass="repeater__item--dragged"
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges
                    distance={3}
                    onSortStart={this.sortStart}
                    onSortEnd={this.sortEnd}
                    disableAutoscroll
                >
                    {this.getRepeaterSortableItems()}
                </RepeaterSortableContainer>
                {this.getControls()}
                {!!loading && (
                    <div className="repeater__loader">
                        <ElementLoader />
                    </div>
                )}
                <Modal
                    root="body"
                    variation="small"
                    opened={!!modalIndexOpened || modalIndexOpened === 0}
                    onClose={() => this.handleModal(false)}
                    title={deleteModalTitle}
                    subtitle={deleteModalSubTitle}
                >
                    <React.Fragment>
                        <Button
                            label={deleteModalButtonDelete}
                            onClick={() => {
                                this.removeItem(modalIndexOpened);
                                this.handleModal(false);
                            }}
                        />
                        <Button
                            label={deleteModalButtonCancel}
                            onClick={() => this.handleModal(false)}
                            variation="secondary"
                        />
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}

Repeater.defaultProps = {
    RepeatableItem: () => (
        <span>Unable to render item. Repeater.props.RepeatableItem is missing!</span>
    ),
    data: null,
    fields: null,
    meta: null,
    uniqueIdentifier: 'iri',
    noRightPadding: false,
    canSave: true,
    canSaveAllSortOrder: false,
    canSort: true,
    canSortNewItems: false,
    canMixItems: false,
    canAdd: true,
    canDelete: true,
    canAddMultiple: false,
    saveButtonIntent: 'new',
    addNewButtonVariation: 'default',
    addNewButtonIcon: null,
    addNewLabel: 'Add new',
    saveLabel: 'Save',
    saveSortOrderLabel: 'Save sort order',
    deleteModalTitle: 'Delete item',
    deleteModalSubTitle: 'Are you sure you wanna delete this item?',
    deleteModalButtonDelete: 'Delete',
    deleteModalButtonCancel: 'Cancel',
    loading: false,
    formSyncErrors: null,
    formInitialValues: null,
    onSortStart: () => {},
    onSortEnd: () => {},
    onSaveNewItem: () => {},
    onSaveAll: () => {},
    onCancelNewItem: () => {},
    onRemoveItem: () => {},
    onSaveSortOrder: () => {},
    onChangeItem: () => {},
    customButtonsArea: null,
    newItemInitialValues: {}
};

Repeater.propTypes = {
    RepeatableItem: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    // RepeatableItem is a Component that will be rendered for each redux-form FieldArray item. Prop passed to this RepeatableItem will be {member, isNewRepeaterItem, data, changeItemMiddleware}.
    // member {string} is a name of the current FieldArray item. Use this prop as a prefix for all Field names inside RepeatableItem.
    // isNewRepeaterItem {boolean}. Use this prop to determine if current RepeatableItem is new item.
    // data {Object} contains both current FieldArray item data, and custom data passed to this Repeater component. Use it sparingly inside RepeatableItem, use only prop data that is not controlled by the RepeatableItem Fields.
    // changeItemMiddleware {func} see prop.onChangeItem() description below.
    data: PropTypes.oneOfType([PropTypes.object]), // Custom data that will be merged with each FieldArray item's data and be forwarded to each instance of RepeatableItem.
    fields: PropTypes.oneOfType([PropTypes.object]), // Passed by redux-form FieldArray. The fields object is a "pseudo-array".
    meta: PropTypes.oneOfType([PropTypes.object]), // Passed by redux-form FieldArray. Metadata about the state of this field array.
    uniqueIdentifier: PropTypes.oneOfType([PropTypes.string]), // Each item (object) in redux-form FieldArray should have a property with which it can be uniquely identified within FieldArray. Use this prop to specify that property key. It is usually 'id' or 'iri'. Defaults to 'iri'.
    noRightPadding: PropTypes.bool,
    canSave: PropTypes.bool,
    canSaveAllSortOrder: PropTypes.bool, // Renders 'Save Sort Order' button.
    canSort: PropTypes.bool, // Control if items can be sorted
    canSortNewItems: PropTypes.bool, // Control if new items can be sorted
    canMixItems: PropTypes.bool, // Control if items and new items can be mixed sorted (if both canSort and canSortNewItems are truthy)
    canAdd: PropTypes.bool, // Control if new RepeatableItem can be added to FieldArray
    canDelete: PropTypes.bool, // Control if items can be removed from the FieldArray
    canAddMultiple: PropTypes.bool, // Control if multiple new items can be added to repeater before being saved to database
    saveButtonIntent: PropTypes.oneOf(['new', 'all']), // defines save button availability and button related validation scope
    addNewButtonVariation: PropTypes.oneOf(['default', 'right', 'tertiary']), // Defines add new button position and style
    addNewButtonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    addNewLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom button label
    saveLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom button label
    saveSortOrderLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom button label
    deleteModalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Modal title
    deleteModalSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Modal subtitle
    deleteModalButtonDelete: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Modal button label
    deleteModalButtonCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Modal button label
    loading: PropTypes.bool, // Use this prop to control Repeater's loading state
    formSyncErrors: PropTypes.oneOfType([PropTypes.object]),
    formInitialValues: PropTypes.oneOfType([PropTypes.object]),
    onSortStart: PropTypes.func,
    onSortEnd: PropTypes.func, // This prop function will be invoked after item order is changed. It can be used to send new sort index to api. Function will receive following argument: { allData, itemData, oldIndex, newIndex }
    onSaveNewItem: PropTypes.func, // This prop function will be invoked after new item is saved. It can be used to send api request to create new entity. Function will receive following argument: { itemData }. props.onSaveAll can be used instead
    onSaveAll: PropTypes.func, // This prop function will be invoked after click on save button. It can be used to send api request to create new entity or/and update already saved entities. Function will receive following argument: { oldItems, oldItemsDiff, newItems }.
    onCancelNewItem: PropTypes.func,
    onRemoveItem: PropTypes.func, // This prop function will be invoked after item is removed. It can be used to send api request to remove entity. Function will receive following argument: { itemData }
    onSaveSortOrder: PropTypes.func, // This prop function will be invoked after 'canSaveAllSortOrder' button is clicked. It can be used to send sort order off all items. Function will receive following argument: { allData }
    onChangeItem: PropTypes.func, // This prop function will be invoked after RepeatableItem's prop.changeItemMiddleware() is triggered. It can be used to send item edit changes. onChangeItem will receive data passed from RepeatableItem.prop.changeItemMiddleware.
    customButtonsArea: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]), // Prop for additional buttons that are displayed in repeater footer, fragment is added together with onClick event from the container component
    newItemInitialValues: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

//  Getting initial value populated in the form from the store
const mapStateToProps = (state, ownProps) => ({
    formSyncErrors: getFormSyncErrors(
        ownProps.meta && ownProps.meta.form ? ownProps.meta.form : null
    )(state),
    formInitialValues: getFormInitialValues(
        ownProps.meta && ownProps.meta.form ? ownProps.meta.form : null
    )(state)
});

Repeater.Title = function Title({ children }) {
    return <h4 className="repeater__item-content-title">{children}</h4>;
};

Repeater.Title.defaultProps = {
    children: null
};

Repeater.Title.propTypes = {
    children: PropTypes.node
};

export default connect(mapStateToProps)(Repeater);
