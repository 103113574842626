export const actions = {
    COMMIT_CONTRACT_BUDGET_ERROR: 'COMMIT_CONTRACT_BUDGET_ERROR',
    CLEAR_CONTRACT_BUDGET_ERRORS: 'CLEAR_CONTRACT_BUDGET_ERRORS'
};

export const initialState = {
    project: {}
};

export default (state = initialState, { type, project, timestamp, callback, response }) => {
    switch (type) {
        case actions.COMMIT_CONTRACT_BUDGET_ERROR: {
            return {
                ...state,
                project: {
                    ...state?.project,
                    [project]: {
                        timestamp,
                        callback,
                        ...response
                    }
                }
            };
        }
        case actions.CLEAR_CONTRACT_BUDGET_ERRORS: {
            const newState = {
                ...state
            };
            if (newState?.project?.[project]) {
                delete newState.project[project];
            }
            return newState;
        }
        default:
            return state;
    }
};
