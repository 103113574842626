import React, { Component } from 'react';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import ElementLoader from 'erpcore/components/ElementLoader';
//  import restClient from 'erpcore/api/restClient';
import Notification from 'erpcore/components/Notification';
import Svg from 'erpcore/components/Svg';
import PropTypes from 'prop-types';
import './FileUploader.scss';

class FileUploader extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            currentUploadingFileName: null,
            submittedFiles: [],
            uploadedFiles: [],
            uploadHidden: false
        };

        const { fieldProps } = props;
        const { allowedExtensions } = fieldProps;

        this.uploader = new FineUploaderTraditional({
            options: {
                request: {
                    method: 'POST',
                    omitDefaultParams: true,
                    forceMultipart: false,
                    requireSuccessJson: false,
                    endpoint: `${enviromentVariables.REST_API}/api/media-objects`,
                    params: {
                        meta: () => this.getUploadingFileMeta()
                    },
                    customHeaders: {
                        Authorization: `Bearer ${localStorage.token}`
                    },
                    inputName: 'file'
                },
                multiple: false,
                validation: {
                    allowedExtensions
                },
                sizeError: 'The file should be 10 MB or less.',
                typeError: 'Wrong file format. Valid format(s): {extensions}.',
                callbacks: {
                    onAllComplete: () => this.onAllComplete(),
                    onComplete: (id, name, responseJSON) => this.onComplete(id, responseJSON),
                    onSubmitted: (id, name) => this.onSubmitted(id, name),
                    onStatusChange: (id, oldStatus, newStatus) => this.onStatusChange(newStatus),
                    onError: (id, name, errorReason) => this.onError(id, name, errorReason)
                },
                cors: {
                    expected: true,
                    allowXdr: false
                },
                autoUpload: true
            }
        });

        this.onFileUploaded = this.onFileUploaded.bind(this);
    }

    // region Callbacks

    /**
     *
     */
    onStatusChange(newStatus) {
        if (newStatus === 'upload successful') {
            this.setState({ error: null });
        }
        if (newStatus === 'canceled') {
            const submittedFiles = this.uploader.methods.getUploads({
                status: ['submitting', 'uploading']
            });
            this.setState({ submittedFiles });
        }
    }

    /**
     *
     */
    onError(id, name, errorReason = null) {
        if (errorReason) {
            this.setState({ error: errorReason });
        } else {
            this.setState({ error: null });
        }
    }

    /**
     *
     */
    onSubmitted(id, name) {
        const submittedFiles = this.uploader.methods.getUploads({
            status: ['submitting', 'submitted', 'uploading']
        });
        this.setState({ submittedFiles, currentUploadingFileName: name });
    }

    /**
     *
     * @param id {number}
     * @param responseJSON {object}
     */
    onComplete(id, responseJSON) {
        // refresh submitted files
        const submittedFiles = this.uploader.methods.getUploads({
            status: ['submitting', 'submitted', 'uploading']
        });

        const { content_url: fileUrl, meta } = { ...responseJSON.data.attributes };
        const { filename } = { ...meta };

        this.setState(
            {
                submittedFiles,
                uploadedFiles: [
                    {
                        _id: id,
                        id: responseJSON.data.id,
                        filename: filename || fileUrl,
                        url: fileUrl,
                        deleting: false
                    }
                ]
            },
            () => this.onFileUploaded()
        );
    }

    /**
     *
     */
    onAllComplete() {
        const { fieldProps, hideDropZoneOnUpload } = this.props;
        const { onAllComplete } = fieldProps;
        if (hideDropZoneOnUpload) this.setState({ uploadHidden: true });
        if (onAllComplete) onAllComplete();
    }

    // endregion

    // region Actions

    onFileUploaded() {
        // Call callback props onFileUploaded function
        const { onFileUploaded } = this.props;
        const { uploadedFiles: latestUploadedFiles } = this.state;
        return onFileUploaded(latestUploadedFiles);
    }

    getUploadingFileMeta() {
        const { currentUploadingFileName } = this.state;
        return JSON.stringify({ filename: currentUploadingFileName });
    }

    /**
     *
     * @param ev {event}
     * @param id {number}
     */
    cancelItem(ev, id) {
        ev.preventDefault();
        this.uploader.methods.cancel(id);
    }

    /**
     *
     * @param ev {event}
     * @param id {number}
     */
    deleteItem(ev, id) {
        ev.preventDefault();
        const { uploadedFiles } = this.state;

        const newUploadedFiles = uploadedFiles.filter(item => {
            if (item.id === id) {
                return false;
            }
            return item;
        });

        // What is this doing? (if down you set uploadedFiles again)
        this.setState({ uploadedFiles: newUploadedFiles, uploadHidden: false }, () =>
            this.onFileUploaded()
        );
    }

    // endregion

    /**
     *
     * @returns {*}
     */
    render() {
        const { input, fieldProps } = this.props;
        const { uploadedFiles, submittedFiles, error, uploadHidden } = this.state;
        const { name } = input;
        const { label } = fieldProps;

        return (
            <div className="fileuploader">
                {!uploadHidden && (
                    <Dropzone
                        multiple
                        name={name}
                        uploader={this.uploader}
                        className="fileuploader__droparea"
                        dropActiveClassName="fileuploader__droparea--active"
                    >
                        <div className="fileuploader__droparea-inner">
                            <Svg icon="upload" className="fileuploader__icon" />
                            <p className="fileuploader__text">
                                Drag and drop file here
                                <br />
                                or
                            </p>
                            <FileInput
                                multiple
                                uploader={this.uploader}
                                className="button button--secondary button--small"
                            >
                                Browse files
                            </FileInput>
                        </div>
                    </Dropzone>
                )}
                {!!error && (
                    <div className="fileuploader__error">
                        <Notification title="Error!" text={error} type="warning" />
                    </div>
                )}
                {label && <p className="fileuploader__label">{label}</p>}
                {submittedFiles.length > 0 && (
                    <div className="fileuploader__filelist">
                        <h2 className="fileuploader__filelist-title">
                            <ElementLoader />
                            Uploading files...
                        </h2>
                        <ul className="fileuploader__filelist-items">
                            {submittedFiles.map(file => {
                                return (
                                    <li key={file.id} className="fileuploader__filelist-item">
                                        {file.url ? (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={file.url}
                                                className="fileuploader__filelist-name"
                                            >
                                                {file.filename}
                                            </a>
                                        ) : (
                                            <span className="fileuploader__filelist-name">
                                                {file.filename}
                                            </span>
                                        )}
                                        <button
                                            type="button"
                                            onClick={ev => this.cancelItem(ev, file.id)}
                                            className="fileuploader__filelist-remove"
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {uploadedFiles.length > 0 && (
                    <div className="fileuploader__filelist">
                        {!uploadHidden && (
                            <h2 className="fileuploader__filelist-title">Uploaded files</h2>
                        )}
                        <ul className="fileuploader__filelist-items">
                            {uploadedFiles.map(file => {
                                return (
                                    <li
                                        key={file.id}
                                        className={`fileuploader__filelist-item ${
                                            file.deleting
                                                ? 'fileuploader__filelist-item--deleting'
                                                : ''
                                        }`}
                                    >
                                        {file.url ? (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={file.url}
                                                className="fileuploader__filelist-name"
                                            >
                                                {file.filename}
                                            </a>
                                        ) : (
                                            <span className="fileuploader__filelist-name">
                                                {file.filename}
                                            </span>
                                        )}
                                        <button
                                            type="button"
                                            onClick={ev => this.deleteItem(ev, file.id)}
                                            className="fileuploader__filelist-remove"
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

FileUploader.defaultProps = {
    input: {},
    fieldProps: {},
    onFileUploaded: () => {},
    hideDropZoneOnUpload: false
};

FileUploader.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    onFileUploaded: PropTypes.func,
    hideDropZoneOnUpload: PropTypes.bool
};

export default FileUploader;
