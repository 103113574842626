import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import { actions as propsWidgetActions } from 'erpcore/components/PropsWidget/PropsWidget.reducer';
import { actions as propsActions } from 'erpcore/screens/Props/Props.reducers';
import PropsWidgetCreateForm from 'erpcore/components/PropsWidget/components/PropsWidgetCreateForm';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import './PropsWidget.scss';
import { hasPermission } from 'erpcore/utils/RolesManager';

const PropsWidget = () => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const meData = useSelector(getMeData);

    const handleModal = () => {
        setOpened(!opened);
    };

    const fetchProps = (page = 1) => {
        const params = {
            include: 'fromUser,toUser,type,type.image',
            page,
            'order_by[createdAt]': 'DESC'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: propsActions.START_FETCHING_PROPS,
                promise: { resolve, reject },
                params
            });
        });
    };

    const onSubmit = formData => {
        formData = {
            ...formData,
            fromUser: meData?.iri
        };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: propsWidgetActions.START_CREATE_PROP,
                formData
            })
        )
            .then(() => {
                fetchProps();
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    if (!hasPermission('CAN_MANAGE_COMMON_PROP', meData)) {
        return null;
    }

    return (
        <div className="props-widget">
            <Button
                className="props-widget__button"
                iconName="props"
                variation="tertiary"
                labelOnlyAria
                onClick={() => handleModal()}
            />

            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title="Give props to a team member"
            >
                <PropsWidgetCreateForm onSubmit={onSubmit} />
            </Modal>
        </div>
    );
};

export default PropsWidget;
