export const actions = {
    START_FETCHING_DASHBOARDS_LISTING: 'START_FETCHING_DASHBOARDS_LISTING',
    FETCHING_SUCCESSFUL_DASHBOARDS_LISTING: 'FETCHING_SUCCESSFUL_DASHBOARDS_LISTING',
    FETCHING_FAILED_DASHBOARDS_LISTING: 'FETCHING_FAILED_DASHBOARDS_LISTING',

    START_DELETE_DASHBOARD: 'START_DELETE_DASHBOARD',
    DELETE_DASHBOARD_SUCCESSFUL: 'DELETE_DASHBOARD_SUCCESSFUL',
    DELETE_DASHBOARD_FAILED: 'DELETE_DASHBOARD_FAILED',

    STORE_DASHBOARD_FAVORITE_DATA: 'STORE_DASHBOARD_FAVORITE_DATA'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_DASHBOARDS_LISTING: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DASHBOARDS_LISTING: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_DASHBOARDS_LISTING: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.STORE_DASHBOARD_FAVORITE_DATA: {
            const updatedDashboard = state?.listingResponse?.data.map(dashboard => {
                if (dashboard?.id === response?.id) dashboard.favorite = response?.favorite;
                return dashboard;
            });

            return {
                ...state,
                listingResponse: {
                    ...state?.listingResponse,
                    data: updatedDashboard
                }
            };
        }
        default:
            return state;
    }
};
