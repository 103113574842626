export const actions = {
    START_FETCHING_PROJECTS: 'START_FETCHING_PROJECTS',
    FETCHING_SUCCESSFUL_PROJECTS: 'FETCHING_SUCCESSFUL_PROJECTS',
    FETCHING_FAILED_PROJECTS: 'FETCHING_FAILED_PROJECTS',

    START_CREATE_PROJECT: 'START_CREATE_PROJECT',
    CREATE_PROJECT_SUCCESFUL: 'CREATE_PROJECT_SUCCESFUL',
    CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',

    START_UPDATE_PROJECT: 'START_UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESSFUL: 'UPDATE_PROJECT_SUCCESSFUL',
    UPDATE_PROJECT_FAILED: 'UPDATE_PROJECT_FAILED',

    START_FETCHING_PROJECT: 'START_FETCHING_PROJECT',
    FETCHING_PROJECT_SUCCESSFUL: 'FETCHING_PROJECT_SUCCESSFUL',
    FETCHING_PROJECT_FAILED: 'FETCHING_PROJECT_FAILED',

    STORE_PROJECT_DATA: 'STORE_PROJECT_DATA',

    START_DELETE_PROJECT: 'START_DELETE_PROJECT',
    DELETE_PROJECT_SUCCESSFUL: 'DELETE_PROJECT_SUCCESSFUL',
    DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',

    START_FETCHING_PROJECT_REVENUES: 'START_FETCHING_PROJECT_REVENUES',
    FETCHING_PROJECT_REVENUES_SUCCESSFUL: 'FETCHING_PROJECT_REVENUES_SUCCESSFUL',
    FETCHING_PROJECT_REVENUES_FAILED: 'FETCHING_PROJECT_REVENUES_FAILED',

    START_FETCHING_PROJECT_EXPENSES: 'START_FETCHING_PROJECT_EXPENSES',
    FETCHING_PROJECT_EXPENSES_SUCCESSFUL: 'FETCHING_PROJECT_EXPENSES_SUCCESSFUL',
    FETCHING_PROJECT_EXPENSES_FAILED: 'FETCHING_PROJECT_EXPENSES_FAILED',

    START_FETCHING_PROJECT_REPORT_TIME_LOGS: 'START_FETCHING_PROJECT_REPORT_TIME_LOGS',
    FETCHING_PROJECT_REPORT_TIME_LOGS_SUCCESSFUL: 'FETCHING_PROJECT_REPORT_TIME_LOGS_SUCCESSFUL',
    FETCHING_PROJECT_REPORT_TIME_LOGS_FAILED: 'FETCHING_PROJECT_REPORT_TIME_LOGS_FAILED',
    STORE_PROJECT_REPORT_TIME_LOGS: 'STORE_PROJECT_REPORT_TIME_LOGS',

    START_FETCHING_PROJECT_BILLING_REQUESTS: 'START_FETCHING_PROJECT_BILLING_REQUESTS',
    FETCHING_PROJECT_BILLING_REQUESTS_SUCCESSFUL: 'FETCHING_PROJECT_BILLING_REQUESTS_SUCCESSFUL',
    FETCHING_PROJECT_BILLING_REQUESTS_FAILED: 'FETCHING_PROJECT_BILLING_REQUESTS_FAILED',

    CLEAR_PROJECT_DASHBOARD_STATE: 'CLEAR_PROJECT_DASHBOARD_STATE'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    revenuesListingFetching: false,
    revenuesListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    revenuesListingError: [],
    expensesListingFetching: false,
    expensesListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    expensesListingError: [],
    billingRequestsListingFetching: false,
    billingRequestsListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    billingRequestsListingError: []
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_PROJECT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_PROJECT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_PROJECT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_PROJECT_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        case actions.START_FETCHING_PROJECT_REVENUES: {
            return {
                ...state,
                revenuesListingFetching: true
            };
        }
        case actions.FETCHING_PROJECT_REVENUES_SUCCESSFUL: {
            return {
                ...state,
                revenuesListingFetching: false,
                revenuesListingResponse: response
            };
        }
        case actions.FETCHING_PROJECT_REVENUES_FAILED: {
            return {
                ...state,
                revenuesListingFetching: false,
                revenuesListingError: response
            };
        }

        case actions.START_FETCHING_PROJECT_EXPENSES: {
            return {
                ...state,
                expensesListingFetching: true
            };
        }
        case actions.FETCHING_PROJECT_EXPENSES_SUCCESSFUL: {
            return {
                ...state,
                expensesListingFetching: false,
                expensesListingResponse: response
            };
        }
        case actions.FETCHING_PROJECT_EXPENSES_FAILED: {
            return {
                ...state,
                expensesListingFetching: false,
                expensesListingError: response
            };
        }
        case actions.START_FETCHING_PROJECT_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [iri]: { ...state[iri], ...{ fetching: true } } }
            };
        }
        case actions.FETCHING_PROJECT_REPORT_TIME_LOGS_SUCCESSFUL: {
            return {
                ...state,
                ...{ [iri]: { ...state[iri], ...{ fetching: false } } }
            };
        }
        case actions.FETCHING_PROJECT_REPORT_TIME_LOGS_FAILED: {
            return {
                ...state,
                ...{ [iri]: { ...state[iri], ...{ fetching: false, error: response } } }
            };
        }
        case actions.STORE_PROJECT_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [iri]: { ...state[iri], ...{ data: response } } }
            };
        }
        case actions.START_FETCHING_PROJECT_BILLING_REQUESTS: {
            return {
                ...state,
                billingRequestsListingFetching: true
            };
        }
        case actions.FETCHING_PROJECT_BILLING_REQUESTS_SUCCESSFUL: {
            return {
                ...state,
                billingRequestsListingFetching: false,
                billingRequestsListingResponse: response
            };
        }
        case actions.FETCHING_PROJECT_BILLING_REQUESTS_FAILED: {
            return {
                ...state,
                billingRequestsListingFetching: false,
                billingRequestsListingError: response
            };
        }
        case actions.CLEAR_PROJECT_DASHBOARD_STATE: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
