import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as propsSettingsActions } from 'erpcore/screens/Settings/Props/Props.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Create Prop Settting
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createPropSettings({ promise, formData }) {
    try {
        const createPropsSettingsAPI = yield restClient.post('api/prop/types', formData);
        yield put({
            type: propsSettingsActions.CREATE_PROPS_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createPropsSettingsAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsSettingsActions.CREATE_PROPS_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Prop
 * @param  {Object} iri of Prop
 * @return {Object} Response from API
 */
export function* deleteProp({ promise, iri }) {
    try {
        const deletePropAPI = yield restClient.delete(iri);
        yield put({
            type: propsSettingsActions.DELETE_PROPS_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deletePropAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsSettingsActions.DELETE_PROPS_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Prop data
 * @param  {Object} iri of Prop
 * @return {Object} Response from API
 */
export function* updateProp({ promise, iri, formData }) {
    try {
        const updatePropAPI = yield restClient.patch(iri, formData);
        yield put({
            type: propsSettingsActions.UPDATE_PROPS_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updatePropAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsSettingsActions.UPDATE_PROPS_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const propsSettingsSaga = [
    takeLatest(propsSettingsActions.START_CREATE_PROPS_SETTINGS, createPropSettings),
    takeLatest(propsSettingsActions.START_DELETE_PROPS_SETTINGS, deleteProp),
    takeLatest(propsSettingsActions.START_UPDATE_PROPS_SETTINGS, updateProp)
];

export default propsSettingsSaga;
