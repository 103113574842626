import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import icons from './svgManifest';
import './Svg.scss';

const Svg = ({ icon, iconColor, className, style }) => {
    if (icon && icon !== '')
        return (
            <span
                className={`isvg loaded svg${className ? ` ${className}` : ''}${
                    iconColor ? ` svg--${iconColor}` : ''
                }`}
            >
                <SVG style={style} src={icons[icon]} cacheRequests />
            </span>
        );
    return null;
};

Svg.defaultProps = {
    style: null,
    className: '',
    iconColor: null
};

Svg.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object]),
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconColor: PropTypes.string
};

export default Svg;
