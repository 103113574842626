import React from 'react';
import PropTypes from 'prop-types';
import SortableItem from 'erpcore/components/Form/components/Media/components/SortableItem';
import { Receiver } from 'react-file-uploader';

const MediaAddItem = ({ id, isReceiverOpen, setIsReceiverOpen, onImageDrop }) => {
    return (
        <SortableItem key="add-new" collection="control" disabled index={0}>
            <div className="gallery__item-inner" id={id}>
                <div
                    className={`gallery__upload${
                        isReceiverOpen ? ' gallery__upload--drag-entered' : ''
                    }`}
                >
                    <label className="gallery__upload-button">
                        <input
                            type="file"
                            multiple
                            onChange={event => onImageDrop(event, event.target.files, true)}
                        />
                        <span className="gallery__upload-button-icon">
                            {isReceiverOpen ? '⭳' : '+'}
                        </span>
                    </label>
                    <Receiver
                        wrapperId={id}
                        customClass="gallery__upload-receiver"
                        isOpen={isReceiverOpen}
                        onDragEnter={() => {
                            setIsReceiverOpen(true);
                        }}
                        onDragOver={() => {}}
                        onDragLeave={() => {
                            setIsReceiverOpen(false);
                        }}
                        onFileDrop={onImageDrop}
                    />
                </div>
            </div>
        </SortableItem>
    );
};

MediaAddItem.defaultProps = {
    id: null,
    isReceiverOpen: false,
    setIsReceiverOpen: () => {},
    onImageDrop: () => {}
};
MediaAddItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReceiverOpen: PropTypes.bool,
    setIsReceiverOpen: PropTypes.func,
    onImageDrop: PropTypes.func
};

export default MediaAddItem;
