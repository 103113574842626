import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Roles and Permissions Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const RolesAndPermissionsNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        // - Permissions
        case 'permission.itemSuccesfullyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Permission successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        // - Roles
        case 'role.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Role successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'role.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Role successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'role.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Role successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'role.canNotDeleteWhenCurrentUserHasSameRole':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Can not delete role when current user has same role."
                    type="error"
                />
            );
        case 'role.itemSuccessfulyAddedToUsers':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Role successfully added to users!"
                    type="success"
                    expire="3500"
                />
            );
        case 'role.itemSuccessfullyRemovedFromUser':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Role successfully removed from user!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

RolesAndPermissionsNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

RolesAndPermissionsNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default RolesAndPermissionsNotifications;
