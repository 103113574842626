import React from 'react';
import PropTypes from 'prop-types';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import './Main.scss';

const Main = ({ children, className }) => {
    return (
        <>
            <main className={`main ${className || ''}`}>
                <PageNotifications />
                {children}
            </main>
            <FloatingNotifications />
        </>
    );
};

Main.defaultProps = {
    children: null,
    className: ''
};

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

export default Main;
