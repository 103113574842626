export const actions = {
    SET_URL_QUERY_PARAMS: 'SET_URL_QUERY_PARAMS'
};

export const initialState = {
    urlQueryParams: ''
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.SET_URL_QUERY_PARAMS: {
            return {
                ...state,
                urlQueryParams: response
            };
        }
        default:
            return state;
    }
};
