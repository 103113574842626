const defaultWidgets = {
    userWidget: {
        id: 'userWidget',
        name: 'User widget',
        type: 'UserWidget',
        layout: {
            lg: {
                w: 6,
                h: 3,
                x: 0,
                y: 0,
                i: 'userWidget',
                minW: 4,
                maxW: 12,
                minH: 3,
                moved: false,
                static: false,
                isResizable: true
            },
            md: {
                w: 1,
                h: 3,
                x: 0,
                y: 0,
                i: 'userWidget',
                minH: 3,
                moved: false,
                static: false,
                isResizable: true
            }
        },
        draggable: true
    },
    MyAssignedWorkWidget: {
        id: 'MyAssignedWorkWidget',
        name: 'My assigned work',
        type: 'MyAssignedWorkWidget',
        layout: {
            lg: {
                w: 6,
                h: 3,
                x: 6,
                y: 0,
                i: 'MyAssignedWorkWidget',
                minW: 5,
                maxW: 12,
                minH: 3,
                moved: false,
                static: false,
                isResizable: true
            },
            md: {
                w: 1,
                h: 3,
                x: 0,
                y: 0,
                i: 'MyAssignedWorkWidget',
                minH: 3,
                moved: false,
                static: false,
                isResizable: true
            }
        },
        draggable: true
    },
    TimeLogCreateWidget: {
        id: 'TimeLogCreateWidget',
        name: 'Time tracking',
        type: 'TimeLogCreateWidget',
        layout: {
            lg: {
                w: 12,
                h: 10,
                x: 0,
                y: 4,
                i: 'TimeLogCreateWidget',
                minW: 5,
                maxW: 12,
                minH: 10,
                moved: false,
                static: false,
                isResizable: true
            },
            md: {
                w: 1,
                h: 10,
                x: 0,
                y: 9,
                i: 'TimeLogCreateWidget',
                moved: false,
                static: false,
                isResizable: true
            }
        },
        newWidget: false,
        draggable: true
    },
    StaffActivityWidget: {
        id: 'StaffActivityWidget',
        name: 'Personal activity',
        type: 'StaffActivityWidget',
        layout: {
            lg: {
                w: 12,
                h: 6,
                x: 0,
                y: 14,
                i: 'StaffActivityWidget',
                minW: 6,
                maxW: 12,
                minH: 6,
                moved: false,
                static: false,
                isResizable: true
            },
            md: {
                w: 1,
                h: 6,
                x: 0,
                y: 19,
                i: 'StaffActivityWidget',
                moved: false,
                static: false,
                isResizable: true
            }
        },
        newWidget: false,
        draggable: true
    }
};

const defaultPersonalDashboardConfig = {
    iri: 'personal_dashboard',
    config: {
        widgets: defaultWidgets
    }
};

export { defaultPersonalDashboardConfig, defaultWidgets };
