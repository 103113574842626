import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { Select } from 'erpcore/components/Form';

const ImageVersionPicker = ({ imageVersionOptions }) => {
    return (
        <Form onSubmit={() => {}}>
            <Field
                name="currentImageVersion"
                id="currentImageVersion"
                fieldProps={{
                    options: imageVersionOptions,
                    isSearchable: false,
                    isClearable: false,
                    label: 'Image version'
                }}
                component={Select}
            />
        </Form>
    );
};

ImageVersionPicker.defaultProps = {
    imageVersionOptions: []
};

ImageVersionPicker.propTypes = {
    imageVersionOptions: PropTypes.oneOfType([PropTypes.array])
};

export default reduxForm({
    form: 'ImageVersionPicker',
    enableReinitialize: true
    // enableReinitialize: false
})(ImageVersionPicker);
