import uniq from 'lodash/uniq';
import without from 'lodash/without';
import omit from 'lodash/omit';

export const actions = {
    START_FETCHING_ENTITY_DATA: 'START_FETCHING_ENTITY_DATA',
    FETCHING_SUCCESSFUL_ENTITY_DATA: 'FETCHING_SUCCESSFUL_ENTITY_DATA',
    FETCHING_FAILED_ENTITY_DATA: 'FETCHING_FAILED_ENTITY_DATA',
    STORE_ENTITY_DATA: 'STORE_ENTITY_DATA',

    REGISTER_ENTITY_INSTANCE: 'REGISTER_ENTITY_INSTANCE',
    UNREGISTER_ENTITY_INSTANCE: 'UNREGISTER_ENTITY_INSTANCE'
};

export const initialState = {};

export default (state = initialState, { type, endpoint, response }) => {
    switch (type) {
        case actions.START_FETCHING_ENTITY_DATA: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    fetching: true
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_ENTITY_DATA: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    fetching: false
                }
            };
        }
        case actions.FETCHING_FAILED_ENTITY_DATA: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    fetching: false
                }
            };
        }
        case actions.STORE_ENTITY_DATA: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    fetching: false,
                    data: response
                }
            };
        }
        case actions.REGISTER_ENTITY_INSTANCE: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    connectedInstances: uniq([
                        ...(state?.[endpoint]?.connectedInstances || []),
                        response
                    ])
                }
            };
        }
        case actions.UNREGISTER_ENTITY_INSTANCE: {
            const newInstances = without(state?.[endpoint]?.connectedInstances || [], response);

            if (!newInstances.length) {
                // remove endpoint data if there are no entity instances connected to endpoint data
                return {
                    ...omit(state, [endpoint])
                };
            }

            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    connectedInstances: newInstances
                }
            };
        }
        default:
            return state;
    }
};
