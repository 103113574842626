import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { actions as propsActions } from './Props.reducers';

/**
 *  Fetch props
 * @param promise
 * @param iri
 * @returns Response from API
 */
export function* fetchProps({ promise, params }) {
    try {
        const fetchPropsAPI = yield restClient.get('api/props', { params });

        yield put({
            type: propsActions.FETCHING_SUCCESSFUL_PROPS,
            response: dto(fetchPropsAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: propsActions.FETCHING_FAILED_PROPS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const propsSaga = [takeLatest(propsActions.START_FETCHING_PROPS, fetchProps)];

export default propsSaga;
