export const actions = {
    START_FETCHING_EXPENSES: 'START_FETCHING_EXPENSES',
    FETCHING_SUCCESSFUL_EXPENSES: 'FETCHING_SUCCESSFUL_EXPENSES',
    FETCHING_FAILED_EXPENSES: 'FETCHING_FAILED_EXPENSES',

    START_DELETE_EXPENSE: 'START_DELETE_EXPENSE',
    DELETE_EXPENSE_SUCCESSFUL: 'DELETE_EXPENSE_SUCCESSFUL',
    DELETE_EXPENSE_FAILED: 'DELETE_EXPENSE_FAILED',

    START_CREATE_EXPENSE: 'START_CREATE_EXPENSE',
    CREATE_EXPENSE_SUCCESSFUL: 'CREATE_EXPENSE_SUCCESSFUL',
    CREATE_EXPENSE_FAILED: 'CREATE_EXPENSE_FAILED',

    START_FETCHING_SINGLE_EXPENSE: 'START_FETCHING_SINGLE_EXPENSE',
    FETCHING_SINGLE_EXPENSE_SUCCESSFUL: 'FETCHING_SINGLE_EXPENSE_SUCCESSFUL',
    FETCHING_SINGLE_EXPENSE_FAILED: 'FETCHING_SINGLE_EXPENSE_FAILED',
    STORE_SINGLE_EXPENSE_DATA: 'STORE_SINGLE_EXPENSE_DATA',

    START_UPDATE_EXPENSE: 'START_UPDATE_EXPENSE',
    UPDATE_EXPENSE_SUCCESSFUL: 'UPDATE_EXPENSE_SUCCESSFUL',
    UPDATE_EXPENSE_FAILED: 'UPDATE_EXPENSE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_EXPENSES: {
            return {
                ...state,
                listingFetching: true,
                listingResponse: {
                    data: [],
                    meta: {},
                    included: []
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_EXPENSES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EXPENSES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_EXPENSE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_SINGLE_EXPENSE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SINGLE_EXPENSE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_EXPENSE_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
