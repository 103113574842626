import { put, call, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as calendarActions } from './Calendar.reducer';

/**
 * Fetch Activity Log Data
 * @param promise
 * @param iri
 * @param  {string} endpoint
 * @return {Object} Response from API
 */
export function* fetchCalendarEvents({ promise, endpoint, startDate, endDate, params, id }) {
    try {
        const fetchCalendarAPI = yield restClient.get(endpoint, { params });
        const calendarDto = yield dto(fetchCalendarAPI?.data);
        yield put({
            type: calendarActions.FETCHING_SUCCESSFUL_CALENDAR_EVENTS,
            id
        });
        yield put({
            type: calendarActions.STORE_CALENDAR_EVENTS,
            endpoint,
            response: calendarDto,
            startDate,
            endDate,
            id
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: calendarActions.FETCHING_FAILED_CALENDAR_EVENTS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* fetchAdditionalCalendarEvents({
    promise,
    endpoint,
    startDate,
    params,
    id,
    endDate
}) {
    try {
        const fetchCalendarAPI = yield restClient.get(endpoint, { params });
        const calendarDto = yield dto(fetchCalendarAPI?.data);
        yield put({
            type: calendarActions.FETCHING_SUCCESSFUL_CALENDAR_EVENTS,
            id
        });
        yield put({
            type: calendarActions.APPEND_CALENDAR_EVENTS,
            endpoint,
            response: calendarDto,
            startDate,
            endDate,
            id
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: calendarActions.FETCHING_FAILED_CALENDAR_EVENTS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const calendarSaga = [
    takeEvery(calendarActions.START_FETCHING_CALENDAR_EVENTS, fetchCalendarEvents),
    takeEvery(
        calendarActions.START_FETCHING_ADDITIONAL_CALENDAR_EVENTS,
        fetchAdditionalCalendarEvents
    )
];

export default calendarSaga;
