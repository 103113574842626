import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, isUserClient } from 'erpcore/utils/AuthManager/AuthManager.selectors';

// Screens / Pages
// Auth
import SignOut from 'erpcore/screens/SignOut';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';
import ResetPasswordSignedIn from 'erpcore/screens/ForgotPassword/screens/ResetPasswordSignedIn';
import ProjectApprovalsPrivate from 'erpcore/screens/Projects/screens/ProjectManage/screens/ProjectApprovals/screens/ProjectApprovalsPrivate';
import PageViewTracker from 'erpcore/utils/RouterManager/PageViewTracker.jsx';
import ScrollbarWidthDetector from 'erpcore/components/ScrollbarWidthDetector';

import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import BrandingManager from 'erpcore/utils/BrandingManager';
import { actions as brandingActions } from 'erpcore/utils/BrandingManager/BrandingManager.reducer';

import FrontRoutes from 'erpcore/utils/RouterManager/routeGroups/FrontRoutes';
import BackRoutes from 'erpcore/utils/RouterManager/routeGroups/BackRoutes';
import ClientBackRoutes from 'erpcore/utils/RouterManager/routeGroups/ClientBackRoutes';
import ClientRoutes from 'erpcore/utils/RouterManager/routeGroups/ClientRoutes';

/**
 * Router Manager
 */
const RouterManager = () => {
    const location = useLocation();
    const [initial, setInitial] = useState(true);
    const dispatch = useDispatch();
    const allowedToClearNotifications = useRef(false);
    const isClient = useSelector(isUserClient);
    const isSignedIn = useSelector(getSignedIn);

    const fetchUserData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: authActions.START_FETCHING_ME,
                promise: { resolve, reject }
            });
        });
    };

    const fetchClientData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: authActions.START_FETCHING_ME_CLIENT,
                promise: { resolve, reject }
            });
        });
    };

    const fetchBrandingSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: brandingActions.START_FETCHING_BRANDING_SETTINGS,
                organizationName: process.env.REACT_APP_ORGANIZATION_NAME
            });
        }).catch((error) => ({ error }));
    };

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = (params) => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Initial useEffect
     */
    useEffect(() => {
        if (initial && isSignedIn) {
            setInitial(false);
            fetchUserData().finally();
            fetchBrandingSettings().finally();
        }
    }, []);

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamically updating body className
            let cssClass;
            if (location?.pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = '';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [location.pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(location.search);
    }, [location.search]);

    useEffect(() => {
        if (isClient) fetchClientData().finally();
    }, [isClient]);

    if (isSignedIn && isClient) {
        return (
            <>
                <BrandingManager />
                <PageViewTracker />
                <ScrollbarWidthDetector />
                <Routes>
                    <Route key="SignOut" path="/sign-out" exact element={<SignOut />} />
                    <Route
                        key="ResetPasswordSignedIn"
                        exact
                        path="/reset-password"
                        element={<ResetPasswordSignedIn />}
                    />
                    <Route
                        key="ResetPasswordSignedIn"
                        exact
                        path="/reset-password/:token"
                        element={<ResetPasswordSignedIn />}
                    />
                    <Route
                        key="ResetPassword"
                        path="/reset-password-login"
                        exact
                        element={<ResetPassword />}
                    />
                    <Route path="*" element={<ClientRoutes />} />
                </Routes>
            </>
        );
    }

    // Render the private routes when the user is signed in
    if (isSignedIn && !isClient) {
        return (
            <>
                <BrandingManager />
                <PageViewTracker />
                <ScrollbarWidthDetector />
                <Routes>
                    <Route key="SignOut" path="/sign-out" exact element={<SignOut />} />
                    <Route
                        key="ResetPasswordLogin"
                        path="/reset-password-login"
                        exact
                        element={<ResetPassword />}
                    />
                    <Route exact path="/approvals/:hash" element={<ProjectApprovalsPrivate />} />
                    <Route path="/client/:companyId/*" element={<ClientBackRoutes />} />
                    <Route path="*" element={<BackRoutes />} />
                </Routes>
            </>
        );
    }

    // Render the public router when the user is not signed in
    return (
        <>
            <BrandingManager />
            <PageViewTracker />
            <ScrollbarWidthDetector />
            <Routes>
                <Route exact path="/approvals/:hash" element={<ProjectApprovalsPrivate />} />
                <Route path="*" element={<FrontRoutes />} />
            </Routes>
        </>
    );
};

RouterManager.defaultProps = {};

RouterManager.propTypes = {};

export default RouterManager;
