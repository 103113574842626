import { put, takeLatest, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as activityLogActions } from './ActivityLog.reducer';

/**
 * Fetch Activity Log Data
 * @param  {Object} params
 * @return {Object} Response from API
 */
export function* fetchActivityLog({ promise, iri, params }) {
    try {
        const fetchActivityLogAPI = yield restClient.get(`${iri}/audit`, {
            params
        });
        yield put({
            type: activityLogActions.FETCHING_SUCCESSFUL_ACTIVITY_LOG
        });
        yield put({
            type: activityLogActions.STORE_ACTIVITY_LOG_DATA,
            iri,
            response: fetchActivityLogAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityLogActions.FETCHING_FAILED_ACTIVITY_LOG
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const activityLogSaga = [
    takeLatest(activityLogActions.START_FETCHING_ACTIVITY_LOG, fetchActivityLog)
];

export default activityLogSaga;
