import restClient from 'erpcore/api/restClient';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { actions as contactsActions } from 'erpcore/screens/Contacts/Contacts.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';
import dto from 'erpcore/utils/dto';

const contactsListingIncludeString = 'include=company';

/**
 * Delete Single Contact
 * @param  {Object} id ID of an Contact
 * @return {Object} Response from API
 */
export function* deleteSingleContact({ promise, iri }) {
    try {
        const deleteSingleContactAPI = yield restClient.delete(iri);
        yield put({
            type: contactsActions.DELETE_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleContactAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'contacts' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'CONTACTS',
            name: 'contacts',
            endpoint: `api/contacts?${contactsListingIncludeString}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: contactsActions.DELETE_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createContact({ promise, formData }) {
    try {
        const createContactAPI = yield restClient.post(`/api/contacts`, formData);
        yield put({
            type: contactsActions.CREATE_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createContactAPI?.data
        });
        yield call(promise.resolve, createContactAPI?.data);
    } catch (error) {
        yield put({
            type: contactsActions.CREATE_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Contact
 * @param  {Object} id ID of a Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleContact({ promise, formData, iri, params }) {
    try {
        const updateSingleContactAPI = yield restClient.put(iri, formData, { params });
        yield put({
            type: contactsActions.UPDATE_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleContactAPI?.data
        });
        yield put({
            type: contactsActions.STORE_CONTACT_DATA,
            iri,
            response: dto(updateSingleContactAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: contactsActions.UPDATE_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Contact
 * @param  {Object} promise
 * @param {string} contact Iri
 * @return {Object} response from API
 */
export function* fetchSingleContact({ promise, iri, params }) {
    try {
        const fetchSingleContactAPI = yield restClient.get(iri, { params });
        yield put({
            type: contactsActions.FETCH_CONTACT_SUCCESSFUL
        });
        yield put({
            type: contactsActions.STORE_CONTACT_DATA,
            iri,
            response: dto(fetchSingleContactAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: contactsActions.FETCH_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* exportContacts({ promise }) {
    try {
        const params = yield select(getQueryParams, { name: 'contacts' });
        const exportContactsAPI = yield restClient.get('api/contacts/export', {
            params: parseParamsForApi(params)
        });
        yield put({
            type: contactsActions.EXPORTING_CONTACTS_SUCCESSFUL
        });
        yield call(promise.resolve, exportContactsAPI);
    } catch (error) {
        yield put({
            type: contactsActions.EXPORTING_CONTACTS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const contactsSaga = [
    takeLatest(contactsActions.START_DELETE_CONTACT, deleteSingleContact),
    takeLatest(contactsActions.START_CREATE_CONTACT, createContact),
    takeLatest(contactsActions.START_FETCHING_CONTACT, fetchSingleContact),
    takeLatest(contactsActions.START_UPDATE_CONTACT, updateSingleContact),
    takeLatest(contactsActions.START_EXPORTING_CONTACTS, exportContacts)
];
