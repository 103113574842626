export const actions = {
    // Listing
    START_FETCHING_JOB_TITLES: 'START_FETCHING_JOB_TITLES',
    FETCHING_SUCCESSFUL_JOB_TITLES: 'FETCHING_SUCCESSFUL_JOB_TITLES',
    FETCHING_FAILED_JOB_TITLES: 'FETCHING_FAILED_JOB_TITLES',
    // Create
    START_CREATE_JOB_TITLE: 'START_CREATE_JOB_TITLE',
    CREATE_JOB_TITLE_SUCCESSFUL: 'CREATE_JOB_TITLE_SUCCESSFUL',
    CREATE_JOB_TITLE_FAILED: 'CREATE_JOB_TITLE_FAILED',
    // Edit
    START_UPDATE_JOB_TITLE: 'START_UPDATE_JOB_TITLE',
    UPDATE_JOB_TITLE_SUCCESSFUL: 'UPDATE_JOB_TITLE_SUCCESSFUL',
    UPDATE_JOB_TITLE_FAILED: 'UPDATE_JOB_TITLE_FAILED',
    // Delete
    START_DELETE_JOB_TITLE: 'START_DELETE_JOB_TITLE',
    DELETE_JOB_TITLE_SUCCESSFUL: 'DELETE_JOB_TITLE_SUCCESSFUL',
    DELETE_JOB_TITLE_FAILED: 'DELETE_JOB_TITLE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_JOB_TITLES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_JOB_TITLES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_JOB_TITLES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
