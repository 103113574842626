import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import omit from 'lodash/omit';

export const actions = {
    REGISTER_SELECT_INSTANCE: 'REGISTER_SELECT_INSTANCE',
    UNREGISTER_SELECT_INSTANCE: 'UNREGISTER_SELECT_INSTANCE',
    START_FETCHING_SELECT_OPTIONS: 'START_FETCHING_SELECT_OPTIONS',
    FETCHING_SUCCESSFUL_SELECT_OPTIONS: 'FETCHING_SUCCESSFUL_SELECT_OPTIONS',
    FETCHING_FAILED_SELECT_OPTIONS: 'FETCHING_FAILED_SELECT_OPTIONS',
    STORE_SELECT_OPTIONS_DATA: 'STORE_SELECT_OPTIONS_DATA',
    START_FETCHING_MISSING_SELECT_OPTIONS: 'START_FETCHING_MISSING_SELECT_OPTIONS',
    FETCHING_SUCCESSFUL_MISSING_SELECT_OPTIONS: 'FETCHING_SUCCESSFUL_MISSING_SELECT_OPTIONS',
    FETCHING_FAILED_MISSING_SELECT_OPTIONS: 'FETCHING_FAILED_MISSING_SELECT_OPTIONS',
    APPEND_SELECT_OPTIONS: 'APPEND_SELECT_OPTIONS'
};

export const initialState = {
    //
};

const setFetchStateByEndpoint = (state, endpoint, isFetching) => {
    return {
        ...state,
        [endpoint]: {
            ...state?.[endpoint],
            fetching: isFetching,
            ...(!isFetching
                ? {
                      initialFetchDone: true
                  }
                : null)
        }
    };
};

const setFetchMissingOptionsState = (state, endpoint, iri, isFetching) => {
    return {
        ...state,
        [endpoint]: {
            ...state?.[endpoint],
            fetchingMissingOptions: {
                ...state?.[endpoint]?.fetchingMissingOptions,
                ...(isArray(iri)
                    ? iri.reduce((accumulator, currentValue) => {
                          accumulator[currentValue] = isFetching;
                          return accumulator;
                      }, {})
                    : { [iri]: isFetching })
            }
        }
    };
};

export default (state = initialState, { type, endpoint, iri, response, valueKey = 'iri' }) => {
    switch (type) {
        case actions.REGISTER_SELECT_INSTANCE: {
            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    connectedInstances: uniq([
                        ...(state?.[endpoint]?.connectedInstances || []),
                        response
                    ])
                }
            };
        }
        case actions.UNREGISTER_SELECT_INSTANCE: {
            const newInstances = without(state?.[endpoint]?.connectedInstances || [], response);

            if (!newInstances.length) {
                // remove endpoint data if there are no select instances connected to endpoint data
                return {
                    ...omit(state, [endpoint])
                };
            }

            return {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    connectedInstances: newInstances
                }
            };
        }
        case actions.START_FETCHING_SELECT_OPTIONS: {
            return setFetchStateByEndpoint(state, endpoint, true);
        }
        case actions.FETCHING_SUCCESSFUL_SELECT_OPTIONS: {
            return setFetchStateByEndpoint(state, endpoint, false);
        }
        case actions.FETCHING_FAILED_SELECT_OPTIONS: {
            return setFetchStateByEndpoint(state, endpoint, false);
        }
        case actions.STORE_SELECT_OPTIONS_DATA: {
            const newState = {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    timeFetched: +new Date(),
                    data: response?.data
                }
            };

            return setFetchStateByEndpoint(newState, endpoint, false);
        }
        case actions.START_FETCHING_MISSING_SELECT_OPTIONS: {
            return setFetchMissingOptionsState(state, endpoint, iri, true);
        }
        case actions.FETCHING_SUCCESSFUL_MISSING_SELECT_OPTIONS: {
            return setFetchMissingOptionsState(state, endpoint, iri, false);
        }
        case actions.FETCHING_FAILED_MISSING_SELECT_OPTIONS: {
            return setFetchMissingOptionsState(state, endpoint, iri, false);
        }
        case actions.APPEND_SELECT_OPTIONS: {
            // merge current state array with response, overwriting the objects with the same iris

            const normalizeResponseForProcessing = input => {
                return keyBy(isArray(input) ? input : [input], valueKey);
            };

            const existingOptionsByKey = keyBy(state?.[endpoint]?.data || [], valueKey);

            const newStateData = {
                ...state,
                [endpoint]: {
                    ...state?.[endpoint],
                    timeFetched: state?.[endpoint]?.timeFetched,
                    data: values({
                        ...existingOptionsByKey,
                        ...normalizeResponseForProcessing(response)
                    })
                }
            };

            if (iri?.length) {
                return setFetchMissingOptionsState(newStateData, endpoint, iri, false);
            }

            return newStateData;
        }
        default:
            return state;
    }
};
