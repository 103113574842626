import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from 'erpcore/components/TimeTracking/TimeTracking.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import PropTypes from 'prop-types';
import {
    getDraftTimelogs,
    getDraftTimelogsFetching
} from 'erpcore/components/TimeTracking/TimeTracking.selectors';
import TimeTrackingForm from 'erpcore/components/TimeTracking/components/TimeTrackingForm';
import moment from 'moment-timezone';
import Button from 'erpcore/components/Button';
import { Form as FinalForm } from 'react-final-form';
import ElementLoader from 'erpcore/components/ElementLoader';
import CalculateTotalTime from 'erpcore/components/TimeTracking/components/CalculateTotalTime';
import NotificationManager from 'erpcore/utils/NotificationManager';
import { dtoIgnoreTimezone, isUrl } from 'erpcore/utils/utils';
import defaultDateFormat from 'erpcore/utils/defaultDateFormat';
import Tooltip from 'erpcore/components/Tooltip';

const DraftTimeLogs = ({ workTypeOptions, preventFetching, onTimelogSubmitCallback }) => {
    const fetching = useSelector(getDraftTimelogsFetching);
    const draftTimelogs = useSelector(getDraftTimelogs);
    const dispatch = useDispatch();
    const formValues = useRef({});
    const currentTimeRef = useRef(0);
    const [submittingAll, setSubmittingAll] = useState(false);
    const [currentlyEditingTimeLog, setCurrentlyEditingTimeLog] = useState(false);

    const setCurrentTime = useCallback((time) => {
        currentTimeRef.current = time;
    });

    const currentTime = useCallback(() => {
        return currentTimeRef.current;
    }, []);

    const getTimeSpentOrBillable = (timelogData, timeSpent) => {
        if (timelogData?.should_be_billable && !timelogData?.time_spent_billable_changed) {
            return {
                time_spent: timeSpent,
                time_spent_billable: timeSpent
            };
        }
        return {
            time_spent: timeSpent
        };
    };

    const fetchDraftTimelogs = useCallback(() => {
        dispatch({
            type: timeTrackingActions.START_FETCHING_DRAFT_TIMELOGS
        });
    }, []);

    const fetchSubmittedTimelogs = useCallback((params) => {
        dispatch({
            type: timeTrackingActions.START_FETCHING_SUBMITTED_TIMELOGS,
            params
        });
    }, []);

    const onTimelogUpdate = useCallback(
        (id, timeSpent, timelogData) => {
            const timeSpentOrBillable = getTimeSpentOrBillable(timelogData, timeSpent);
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri: id,
                    promise: { resolve, reject },
                    formData: {
                        ...timeSpentOrBillable
                    }
                });
            }).then((response) => {
                const oldData = draftTimelogs?.find((item) => item?.iri === id);
                onTimelogSubmitCallback({ newData: response, oldData });

                return response;
            });
        },
        [onTimelogSubmitCallback, draftTimelogs]
    );

    const onTimelogBillableHoursUpdate = useCallback((id, timeSpent, isTimeSpentBillable) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: timeTrackingActions.START_UPDATE_TIMELOG,
                iri: id,
                promise: { resolve, reject },
                formData: {
                    time_spent_billable: isTimeSpentBillable ? timeSpent : 0
                }
            });
        });
    }, []);

    const stopActiveTimelog = useCallback((iri, values) => {
        const difference = moment.duration(moment(new Date()).diff(moment(values?.started_at)));
        return new Promise((resolve, reject) => {
            dispatch({
                type: timeTrackingActions.START_UPDATE_TIMELOG,
                iri,
                promise: { resolve, reject },
                formData: {
                    status: 'paused',
                    time_spent: Math.round((values?.time_spent * 1000 + difference) / 1000)
                }
            });
        });
    }, []);

    const onStartTimer = useCallback(
        async (iri) => {
            setCurrentlyEditingTimeLog(true);
            const hasActiveTimelog = draftTimelogs?.find(
                (timelog) => timelog?.status === 'draft' && !!timelog?.started_at
            )?.iri;

            if (hasActiveTimelog) {
                try {
                    const oldActiveTimelogData = draftTimelogs?.find(
                        (item) => item?.iri === hasActiveTimelog
                    );

                    const activeTimelog = await stopActiveTimelog(
                        hasActiveTimelog,
                        formValues.current?.[hasActiveTimelog]
                    );

                    await onTimelogSubmitCallback({
                        newData: activeTimelog,
                        oldData: oldActiveTimelogData
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri,
                    promise: { resolve, reject },
                    formData: {
                        status: 'draft',
                        started_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00')
                    }
                });
            }).then(() => {
                setCurrentlyEditingTimeLog(false);
            });
        },
        [draftTimelogs]
    );

    const onPauseTimer = useCallback(
        async (iri, timeSpent, method, timelogData) => {
            const timeSpentOrBillable = getTimeSpentOrBillable(timelogData, timeSpent);
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri,
                    promise: { resolve, reject },
                    method,
                    formData: {
                        status: 'paused',
                        ...timeSpentOrBillable
                    }
                });
            }).then((response) => {
                const oldData = draftTimelogs?.find((item) => item?.iri === iri);
                onTimelogSubmitCallback({ newData: response, oldData });

                return response;
            });
        },
        [draftTimelogs, onTimelogSubmitCallback]
    );

    const onDeleteTimelog = useCallback(
        (iri) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_DELETE_DRAFT_TIMELOG,
                    iri,
                    promise: { resolve, reject }
                });
            }).then(() => {
                const itemData = draftTimelogs?.find((item) => item?.iri === iri);
                onTimelogSubmitCallback({ newData: itemData, oldData: itemData, method: 'delete' });

                delete formValues.current?.[iri];
            });
        },
        [onTimelogSubmitCallback, draftTimelogs]
    );

    const onTimelogSubmit = useCallback(async (values) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: timeTrackingActions.START_SUBMIT_DRAFT_TIMELOG,
                promise: { resolve, reject },
                iri: values?.iri,
                formData: {
                    status: 'completed',
                    time_spent: values?.time_spent
                }
            });
        })
            .then(async (response) => {
                delete formValues.current?.[values?.iri];

                if (response?.meta?.multiday && response?.meta?.multiday?.related_time_logs) {
                    await fetchSubmittedTimelogs({
                        selected: [...response?.meta?.multiday?.related_time_logs, response?.iri]
                    });
                }
            })
            .catch((e) => {
                const notEnoughtAvailableTimeError = e?.errors?.find(
                    (error) => error?.code === 'timelog.timeAllocated.invalidTimeLog'
                );

                const errors = {};

                if (notEnoughtAvailableTimeError) {
                    errors.time_spent = notEnoughtAvailableTimeError;
                }

                return errors;
            })
            .finally(async () => {
                await fetchDraftTimelogs();
            });
    }, []);

    const onTimelogChange = useCallback(
        (key, value, timelogIri) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_UPDATE_TIMELOG,
                    iri: timelogIri,
                    promise: { resolve, reject },
                    formData: {
                        [key]: value || ''
                    }
                });
            }).then((response) => {
                if (key === 'date') {
                    const oldData = draftTimelogs?.find((item) => item?.iri === timelogIri);

                    onTimelogSubmitCallback({ newData: response, oldData });
                    return response;
                }

                return response;
            });
        },
        [onTimelogSubmitCallback, draftTimelogs]
    );

    const getCurrentTime = useCallback((time) => {
        setCurrentTime(time);
    }, []);

    const onDuplicateTimelog = useCallback(
        (iri) => {
            const timelog = draftTimelogs.find((item) => item?.iri === iri);

            const formData = {
                stage: timelog?.stage?.iri,
                work_type: timelog?.work_type?.iri,
                project: timelog?.project?.iri,
                comment: timelog?.comment || undefined,
                task_url: timelog?.task_url || undefined,
                status: 'paused',
                timeSpent: 0,
                date: moment().format('YYYY-MM-DD')
            };

            return new Promise((resolve, reject) => {
                dispatch({
                    type: timeTrackingActions.START_CREATE_DRAFT_TIMELOG,
                    formData,
                    promise: { resolve, reject }
                });
            });
        },
        [draftTimelogs]
    );

    const onSubmitAll = useCallback(async () => {
        const allForms = formValues.current;
        const keys = Object.keys(allForms);
        setSubmittingAll(true);

        const hasActiveTimelog = draftTimelogs?.find(
            (timelog) => timelog?.status === 'draft' && !!timelog?.started_at
        )?.iri;

        if (hasActiveTimelog) {
            await stopActiveTimelog(hasActiveTimelog, formValues.current?.[hasActiveTimelog]);
        }

        if (keys.length) {
            Promise.all(keys.map((key) => onTimelogSubmit({ iri: key }))).then(() => {
                setSubmittingAll(false);
            });
        }
    }, [draftTimelogs, stopActiveTimelog]);

    const isDisabledSubmitAllButton = draftTimelogs?.length
        ? !draftTimelogs.every((item) => item?.time_spent) ||
          draftTimelogs.some(
              (item) =>
                  // OVERFLOW CONDITION
                  // Older projects may not have "meta" property at all. In that case, overflow SHOULD be allowed
                  (item?.project.meta !== null &&
                      !item?.project?.meta?.allow_time_tracking_overflow &&
                      typeof item?.time_available === 'number' &&
                      item?.time_available <= 0) ||
                  // STAGE REQUIRED CONDITION
                  (!!item?.project?.meta?.stage_required && !!item?.project?.stages && !item?.stage)
          )
        : true;

    useEffect(() => {
        if (!preventFetching && !draftTimelogs.length) fetchDraftTimelogs();
    }, [preventFetching]);

    return (
        <div className={styles['time-tracking__draft']}>
            <div className={styles['time-tracking__draft-top']}>
                <h5>DRAFT TIME LOGS</h5>

                <div className={styles['time-tracking__draft-options']}>
                    <div className={styles['time-tracking__draft-options-total']}>
                        <h5>
                            TOTAL{' '}
                            <span>
                                <CalculateTotalTime
                                    formValues={formValues.current}
                                    currentTime={currentTime}
                                />
                            </span>
                        </h5>
                    </div>
                    <div className={styles['time-tracking__draft-options-submit']}>
                        {isDisabledSubmitAllButton && draftTimelogs?.length ? (
                            <Tooltip content="You cannot submit all logs while one or more draft contains no time logged.">
                                <Button disabled variation="tertiary" label="Submit all" />
                            </Tooltip>
                        ) : (
                            <Button
                                disabled={isDisabledSubmitAllButton}
                                onClick={onSubmitAll}
                                variation="tertiary"
                                label="Submit all"
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className={styles['time-tracking__draft-list']}>
                {(fetching || submittingAll) && <ElementLoader overlay />}

                {!fetching && !draftTimelogs?.length && (
                    <div className={styles['time-tracking__draft-list--no-data']}>
                        <NotificationManager code="listingNoData" />
                    </div>
                )}

                {!!draftTimelogs?.length &&
                    !fetching &&
                    draftTimelogs.map((timelog) => {
                        const initialValues = {
                            iri: timelog?.iri,
                            date:
                                timelog?.meta?.multiday?.start && timelog?.meta?.multiday?.end
                                    ? {
                                          start: moment(
                                              dtoIgnoreTimezone(timelog?.meta?.multiday?.start)
                                          ).format(defaultDateFormat),
                                          end: moment(
                                              dtoIgnoreTimezone(timelog?.meta?.multiday?.end)
                                          ).format(defaultDateFormat)
                                      }
                                    : moment(dtoIgnoreTimezone(timelog?.date)).format(
                                          defaultDateFormat
                                      ),
                            work_type: timelog?.work_type?.iri,
                            time_spent: timelog?.time_spent,
                            status: timelog?.status,
                            started_at: timelog?.started_at,
                            task_url: timelog?.task_url,
                            comment: timelog?.comment,
                            project: timelog?.project?.iri,
                            stage: timelog?.stage?.iri,
                            bulk: timelog?.bulk
                        };

                        return (
                            <FinalForm
                                key={timelog?.iri}
                                onSubmit={onTimelogSubmit}
                                initialValues={initialValues}
                                validate={(values) => {
                                    const errors = {};

                                    if (values?.task_url?.length > 0 && !isUrl(values?.task_url)) {
                                        errors.task_url = 'Task URL has an invalid URL format';
                                    }

                                    if (
                                        timelog?.project?.meta?.stage_required &&
                                        !!timelog?.project?.stages?.some(
                                            (stage) => !stage.is_completed
                                        ) &&
                                        !timelog?.stage
                                    ) {
                                        errors.stage = 'No stage selected';
                                    }

                                    return errors;
                                }}
                                mutators={{
                                    handleDateTypeChange: ([config], state, utils) => {
                                        utils.changeValue(state, 'date', () => config?.value);
                                    }
                                }}
                                render={({
                                    handleSubmit,
                                    values,
                                    form,
                                    submitting,
                                    errors,
                                    submitErrors
                                }) => {
                                    formValues.current = {
                                        ...formValues.current,
                                        [timelog?.iri]: values
                                    };

                                    return (
                                        <TimeTrackingForm
                                            onSubmit={onTimelogSubmit}
                                            workTypeOptions={workTypeOptions}
                                            data={timelog}
                                            onTimelogUpdate={onTimelogUpdate}
                                            onDuplicateTimelog={onDuplicateTimelog}
                                            onTimelogBillableHoursUpdate={
                                                onTimelogBillableHoursUpdate
                                            }
                                            onStartTimer={onStartTimer}
                                            onPauseTimer={onPauseTimer}
                                            onDeleteTimelog={onDeleteTimelog}
                                            handleDateTypeChange={
                                                form.mutators.handleDateTypeChange
                                            }
                                            handleSubmit={handleSubmit}
                                            form={form}
                                            values={values}
                                            getCurrentTime={getCurrentTime}
                                            onTimelogChange={onTimelogChange}
                                            submitting={submitting}
                                            errors={errors}
                                            submitErrors={submitErrors}
                                            isDisabledStartTimerButton={currentlyEditingTimeLog}
                                        />
                                    );
                                }}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

DraftTimeLogs.defaultProps = {
    workTypeOptions: [],
    preventFetching: false,
    onTimelogSubmitCallback: () => {}
};

DraftTimeLogs.propTypes = {
    workTypeOptions: PropTypes.oneOfType([PropTypes.array]),
    preventFetching: PropTypes.bool,
    onTimelogSubmitCallback: PropTypes.func
};

export default memo(DraftTimeLogs);
