export const actions = {
    START_FETCHING_WIDGET_TIME_OFFS: 'START_FETCHING_WIDGET_TIME_OFFS',
    FETCHING_SUCCESSFUL_WIDGET_TIME_OFFS: 'FETCHING_SUCCESSFUL_WIDGET_TIME_OFFS',
    FETCHING_FAILED_WIDGET_TIME_OFFS: 'FETCHING_FAILED_WIDGET_TIME_OFFS'
};

export default (state = {}, { type }) => {
    switch (type) {
        default:
            return state;
    }
};
