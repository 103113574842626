import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as officesActions } from 'erpcore/screens/Settings/Offices/Offices.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Office
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createOffice({ promise, formData }) {
    try {
        const createOfficeAPI = yield restClient.post('api/offices', formData);
        yield put({
            type: officesActions.CREATE_OFFICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createOfficeAPI?.data || { code: 'office.itemSuccesfullyCreated' }
        });

        const params = yield select(getQueryParams, { name: 'offices' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'OFFICES',
            name: 'offices',
            endpoint: 'api/offices'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: officesActions.CREATE_OFFICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Office data
 * @param  {Object} iri of Office
 * @return {Object} Response from API
 */
export function* updateOffice({ promise, iri, formData }) {
    try {
        const updateOfficeAPI = yield restClient.patch(iri, formData);
        yield put({
            type: officesActions.UPDATE_OFFICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateOfficeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'offices' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'OFFICES',
            name: 'offices',
            endpoint: 'api/offices'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: officesActions.UPDATE_OFFICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Office
 * @param  {Object} iri of Office
 * @return {Object} Response from API
 */
export function* deleteOffice({ promise, iri }) {
    try {
        const deleteOfficeAPI = yield restClient.delete(iri);
        yield put({
            type: officesActions.DELETE_OFFICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteOfficeAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'offices' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'OFFICES',
            name: 'offices',
            endpoint: 'api/offices'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: officesActions.DELETE_OFFICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(officesActions.START_CREATE_OFFICE, createOffice),
    takeLatest(officesActions.START_UPDATE_OFFICE, updateOffice),
    takeLatest(officesActions.START_DELETE_OFFICE, deleteOffice)
];
