import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import ElementLoader from 'erpcore/components/ElementLoader';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import './LocationMap.scss';

const libraries = ['places', 'geometry'];

const LocationMap = ({ disabled, coordinates, onChange }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: enviromentVariables.GOOGLE_MAPS_API_KEY,
        libraries
    });

    const options = {
        id: 'circle-example',
        mapContainerClassName: 'location-map__container',
        center: { lat: coordinates?.lat || 39.9636615, lng: coordinates?.lng || -100.5430035 },
        zoom: coordinates?.lat && coordinates?.lng ? 15 : 2,
        clickableIcons: false,
        onClick: onChange
    };

    const renderMap = () => {
        return (
            <div className={`location-map${disabled ? ' location-map--disabled' : ''}`}>
                <GoogleMap {...options}>
                    {coordinates?.lat && coordinates?.lng && (
                        <Marker position={coordinates} draggable={!disabled} onDragEnd={onChange} />
                    )}
                </GoogleMap>
            </div>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <ElementLoader overlay />;
};

LocationMap.defaultProps = {
    disabled: false,
    coordinates: null,
    onChange: () => {}
};

LocationMap.propTypes = {
    disabled: PropTypes.bool,
    coordinates: PropTypes.oneOfType([PropTypes.object]),
    onChange: PropTypes.func
};

export default LocationMap;
