import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Checkbox } from 'erpcore/components/Form';

const LocationManualToggle = ({ fieldName, label, disabled }) => {
    return (
        <div className="location__checkbox">
            <Field
                name={`${fieldName}.locationManualToggle`}
                id={`${fieldName}LocationManualToggle`}
                fieldProps={{
                    value: true,
                    label: label || 'Input Location Manually'
                }}
                fieldAttr={{
                    disabled
                }}
                component={Checkbox}
            />
        </div>
    );
};

LocationManualToggle.defaultProps = {
    fieldName: null,
    label: null,
    disabled: false
};

LocationManualToggle.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool
};

export default LocationManualToggle;
