import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcore/components/Button';
import './PageHeader.scss';

const PageHeader = ({
    title,
    subtitle,
    children,
    className,
    backButtonLink,
    backButtonLinkLabel
}) => {
    return (
        <header className={`page-header ${className || ''}`}>
            {(title || subtitle || backButtonLink) && (
                <div className="page-header__content">
                    {title && <h1 className="page-header__title">{title}</h1>}
                    {subtitle && <p className="page-header__subtitle">{subtitle}</p>}
                    {backButtonLink && (
                        <div className="page-header__back">
                            <Button
                                variation="link"
                                size="small"
                                className="page-header__back-button"
                                label={backButtonLinkLabel || ''}
                                iconName="arrowLeft"
                                href={backButtonLink}
                            />
                        </div>
                    )}
                </div>
            )}

            {children && (
                <div className="page-header__actions">
                    <span className="page-header__actions-dots">
                        <Svg icon="action" />
                    </span>
                    <div className="page-header__actions-items">{children}</div>
                </div>
            )}
        </header>
    );
};

PageHeader.defaultProps = {
    title: null,
    subtitle: null,
    children: null,
    className: '',
    backButtonLink: null,
    backButtonLinkLabel: null
};

PageHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    backButtonLink: PropTypes.string,
    backButtonLinkLabel: PropTypes.string
};

export default PageHeader;
