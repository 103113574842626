import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Button from 'erpcore/components/Button';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import Cropper from 'react-cropper';
import ElementLoader from 'erpcore/components/ElementLoader';
import Modal from 'erpcore/components/Modal';
import Tooltip from 'erpcore/components/Tooltip';
import './ImageManager.cropperjs.scss';
import './ImageManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getImage,
    getImageFetching,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import { getIdFromIri } from 'erpcore/utils/dto';
import ImageVersionPicker from 'erpcore/components/ImageManager/components/ImageVersionPicker';
import { getFormValues, destroy } from 'redux-form';

export const getImageVersion = ({ imageData, versionName = null, getProperty = null }) => {
    if (!imageData || !versionName) return null;

    let latestVersion = null;

    if (imageData?.versions?.length) {
        const allVersionWithTargetName = imageData.versions.filter((version) => {
            return version.name === versionName;
        });
        if (allVersionWithTargetName.length) {
            latestVersion = allVersionWithTargetName.reduce((prevItem, currentItem) => {
                const prevItemDate = new Date(prevItem.created_at);
                const currentItemDate = new Date(currentItem.created_at);
                return prevItemDate.getTime() > currentItemDate.getTime() ? prevItem : currentItem;
            });
        }
    }

    if (getProperty && latestVersion) {
        return latestVersion[getProperty];
    }

    return latestVersion;
};

export const getImageVersionsList = ({ imageData }) => {
    if (!imageData) return null;

    if (imageData.versions && imageData.versions.length) {
        return imageData.versions.reduce((accumulator, current) => {
            if (current?.name && !accumulator.includes(current?.name)) {
                accumulator.push(current?.name);
            }
            return accumulator;
        }, []);
    }

    return [];
};

export const getFileExtension = (filename = '') => {
    if (!filename) return '';
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2) || ''; // eslint-disable-line no-bitwise
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    // const k = 1024;
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getImageSrcFromMediaObject = (mediaObject = null, version = null) => {
    if (!mediaObject) return null;

    let src = mediaObject.content_url_modified || mediaObject.content_url;

    if (version) {
        const versionObject = getImageVersion({ imageData: mediaObject, versionName: version });

        if (versionObject && versionObject.content_url) {
            src = versionObject.content_url;
        }
    }

    return src;
};

export const getImageSrcFromRawImageVersions = (imageVersions = [], version = null) => {
    return (
        imageVersions?.filter(image => image?.data?.attributes?.name === version)[0]?.data
            ?.attributes?.content_url || false
    );
};

const ImageManager = ({
    type,
    localImageData,
    imageIri,
    opened,
    versionName,
    aspectRatio,
    onClose,
    onSaveImage
}) => {
    const dispatch = useDispatch();

    const [cropperRef, setCropperRef] = useState(null);
    // const openedRef = useRef(opened);
    const formValues = useSelector((state) => getFormValues('ImageVersionPicker')(state)) || {};
    const imageData =
        type === 'upload' ? localImageData : useSelector((state) => getImage(state, imageIri));
    const fetching = useSelector((state) => getImageFetching(state));
    const creating = useSelector((state) => getImageCreating(state));
    const deleting = useSelector((state) => getImageDeleting(state));
    const updating = useSelector((state) => getImageUpdating(state));

    const [isCropperReady, setIsCropperReady] = useState(false);
    const [originalDimensions, setOriginalDimensions] = useState([]);

    const loading = fetching || creating || deleting || updating;

    const destroyForm = () => {
        dispatch(destroy(['currentImageVersion']));
    };

    const fetchImageData = (iri) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_FETCHING_IMAGE,
                iri
            });
        }).catch((error) => ({ error }));
    };
    const createImageVersion = (versionData) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_CREATE_IMAGE_VERSION,
                versionData
            });
        }).catch((error) => ({ error }));
    };
    const deleteImageVersion = (versionId) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_DELETE_IMAGE_VERSION,
                versionId
            });
        }).catch((error) => ({ error }));
    };

    const writeCropBoxNaturalDimensions = () => {
        if (cropperRef) {
            const { viewBox } = cropperRef.cropper;

            if (viewBox) {
                const positionData = cropperRef.getData(true);

                const dimensionsText = `${positionData.width} x ${positionData.height}`;

                let targetElement = viewBox.querySelector('.cropper-crop-box-natural-dimensions');
                if (!targetElement) {
                    const newCropBoxNode = document.createElement('span');
                    newCropBoxNode.textContent = dimensionsText;
                    newCropBoxNode.className = 'cropper-crop-box-natural-dimensions';
                    viewBox.appendChild(newCropBoxNode);
                }
                targetElement = viewBox.querySelector('.cropper-crop-box-natural-dimensions');
                targetElement.textContent = dimensionsText;
                if (cropperRef.getCropBoxData().top < 15) {
                    targetElement.setAttribute('data-position', 'top-in');
                } else {
                    targetElement.setAttribute('data-position', 'top-out');
                }
            }
        }
    };

    const onModalClose = () => {
        setIsCropperReady(false);
        // setSelectedVersionName(null);
        destroyForm();
        onClose();
    };

    const onZoom = () => {
        setTimeout(() => {
            writeCropBoxNaturalDimensions();
        }, 100);
    };

    const onCropMove = () => {
        writeCropBoxNaturalDimensions();
    };

    const onCropStart = () => {
        writeCropBoxNaturalDimensions();
    };

    const setDragMode = (action = 'crop') => {
        cropperRef.setDragMode(action);
    };

    const transformImagePositionData = (imagePosition, transformFor = 'frontend') => {
        if (!imagePosition) return null;

        const transformedImagePosition = { ...imagePosition };

        if (transformFor === 'frontend') {
            if (transformedImagePosition.quality) {
                delete transformedImagePosition.quality;
            }
        }

        return transformedImagePosition;
    };

    const saveImageVersion = async () => {
        const canvasData = imageData?.meta?.type
            ? cropperRef.getCroppedCanvas().toDataURL(imageData.meta.type)
            : cropperRef.getCroppedCanvas().toDataURL();
        const positionData = cropperRef.getData(true);

        if (formValues.currentImageVersion) {
            const imageVersionId = getImageVersion({
                imageData,
                versionName: formValues.currentImageVersion,
                getProperty: 'id'
            });

            // DELETE existing image version (non-blocking, runs in the background)
            if (imageVersionId) {
                deleteImageVersion(imageVersionId);
            }

            const formData = new FormData();

            if (canvasData) {
                const fetchBase64 = await fetch(canvasData);
                const file = await fetchBase64.blob();
                formData.append('file', file);
            }

            if (positionData) {
                formData.append(
                    'options',
                    JSON.stringify({
                        ...transformImagePositionData(positionData, 'backend'),
                        quality: 100
                    })
                );
            }

            if (imageData?.meta?.type) {
                formData.append('meta', JSON.stringify({ type: imageData.meta.type }));
            }

            formData.append('parent', `/api/media-objects/${getIdFromIri(imageIri)}`);
            formData.append('name', formValues.currentImageVersion);

            createImageVersion(formData).finally(() => {
                fetchImageData(imageIri);
                onSaveImage({ canvasData, positionData });
            });
        }
    };

    const uploadImage = () => {
        const canvasData = imageData?.meta?.type
            ? cropperRef.getCroppedCanvas().toDataURL(imageData.meta.type)
            : cropperRef.getCroppedCanvas().toDataURL();
        const positionData = cropperRef.getData(true);

        onSaveImage({ canvasData, positionData });
    };

    const zoomIn = () => {
        cropperRef.zoom(0.1);
    };

    const zoomOut = () => {
        cropperRef.zoom(-0.1);
    };

    const scaleIn = () => {
        const cropper = cropperRef;
        cropper.scale(cropper.getData().scaleX + 0.15, cropper.getData().scaleY + 0.15);
    };

    const scaleOut = () => {
        const cropper = cropperRef;
        cropper.scale(cropper.getData().scaleX - 0.15, cropper.getData().scaleY - 0.15);
    };

    const rotateLeft = () => {
        cropperRef.rotate(-15);
    };

    const rotateRight = () => {
        cropperRef.rotate(15);
    };

    /*
    const flipHorizontal = () => {
        const cropper = cropperRef.current;
        cropper.scaleX(-cropper.getData().scaleX || -1);
    };

    const flipVertical = () => {
        const cropper = cropperRef.current;
        cropper.scaleY(-cropper.getData().scaleY || -1);
    };
    */

    const resetToOriginal = () => {
        const cropper = cropperRef;
        if (cropper) {
            cropper.reset();
            cropper.setData({
                width: originalDimensions[0],
                height: originalDimensions[1],
                x: 0,
                y: 0,
                rotate: 0,
                scaleX: 1,
                scaleY: 1
            });
            writeCropBoxNaturalDimensions();
        }
    };

    const getInitialCropData = () => {
        let initialCropData = getImageVersion({
            imageData,
            versionName: formValues.currentImageVersion,
            getProperty: 'options'
        });

        initialCropData = transformImagePositionData(initialCropData, 'frontend');

        if (
            (initialCropData && initialCropData.constructor !== Object) ||
            (initialCropData &&
                initialCropData.constructor === Object &&
                Object.keys(initialCropData).length === 0)
        ) {
            initialCropData = null;
        }

        return initialCropData;
    };

    const resetToSavedVersion = () => {
        const initialCropData = getInitialCropData();
        const cropper = cropperRef;
        cropper.setData(initialCropData);
        writeCropBoxNaturalDimensions();
    };

    const setupCropper = () => {
        const initialCropData = getInitialCropData();

        resetToOriginal();

        if (initialCropData) {
            if (cropperRef) {
                cropperRef.crop().setData(initialCropData);
            }
        }

        const cropperImageData = cropperRef?.getImageData();

        setOriginalDimensions([cropperImageData?.naturalWidth, cropperImageData?.naturalHeight]);

        writeCropBoxNaturalDimensions();
    };

    const onCropperReady = () => {
        setIsCropperReady(true);
        setupCropper();
    };

    const renderMeta = () => {
        const versionData = getImageVersion({
            imageData,
            versionName: formValues.currentImageVersion
        });

        const imageVersionsList = getImageVersionsList({ imageData });

        const imageVersionsOptions = imageVersionsList?.length
            ? imageVersionsList.map((item) => {
                  return {
                      value: item,
                      label: item
                  };
              })
            : [];

        if (versionName && !imageVersionsList.includes(versionName)) {
            imageVersionsOptions.push({
                value: versionName,
                label: versionName
            });
        }

        return (
            <div className="image-editor__meta">
                {type === 'edit' && !!imageVersionsOptions?.length && (
                    <ImageVersionPicker
                        imageVersionOptions={imageVersionsOptions}
                        initialValues={{
                            currentImageVersion: versionName || ''
                        }}
                    />
                )}
                {type === 'edit' && !imageVersionsOptions?.length && !!versionName && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Image version:</span>{' '}
                        {versionName}
                    </p>
                )}
                {!!cropperRef?.getData() && false && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Data</span>{' '}
                        <span
                            className="cropper-data-output"
                            style={{
                                display: 'block',
                                overflowWrap: 'break-word',
                                maxWidth: '300px'
                            }}
                        />
                    </p>
                )}
                {!!imageData?.meta?.filename && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File name</span>{' '}
                        {imageData.meta.filename}
                    </p>
                )}
                {!!imageData.created_at && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Uploaded on:</span>{' '}
                        <Tooltip
                            zIndex={10010}
                            content={moment(imageData.created_at).format('MMMM Do YYYY, h:mm A')}
                        >
                            {moment(imageData.created_at).format('M/D/YYYY')}
                        </Tooltip>
                    </p>
                )}
                {!!versionData && !!versionData.created_at && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Modified on:</span>{' '}
                        <Tooltip
                            zIndex={10010}
                            content={moment(versionData.created_at).format('MMMM Do YYYY, h:mm A')}
                        >
                            {moment(versionData.created_at).format('M/D/YYYY')}
                        </Tooltip>
                    </p>
                )}
                {!!imageData?.meta?.size && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File size (original):</span>{' '}
                        {formatBytes(imageData.meta.size)}
                    </p>
                )}
                {!!versionData?.meta?.size && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">
                            File size ({versionData.name}):
                        </span>{' '}
                        {formatBytes(versionData?.meta?.size)}
                    </p>
                )}
                {!!originalDimensions?.[0] && !!originalDimensions?.[1] && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Original dimensions:</span>{' '}
                        {`${originalDimensions[0]} x ${originalDimensions[1]}`}
                    </p>
                )}
            </div>
        );
    };

    const renderCropper = () => {
        if (!imageData) return null;

        const { content_url: contentUrl, content_url_modified: contentUrlModified } = imageData;

        return (
            <div className="image-editor__canvas">
                <Cropper
                    onInitialized={(instance) => {
                        setCropperRef(instance);
                    }}
                    checkCrossOrigin
                    src={contentUrlModified || contentUrl}
                    className="image-editor__canvas-cropper"
                    guides
                    rotatable
                    movable
                    aspectRatio={aspectRatio}
                    viewMode={0}
                    responsive
                    modal
                    center
                    highlight
                    background
                    dragMode="crop"
                    autoCrop={false}
                    ready={() => onCropperReady()}
                    cropstart={() => onCropStart()}
                    cropmove={() => onCropMove()}
                    zoom={() => onZoom()}
                />
                {!isCropperReady && (
                    <div className="image-editor__canvas-loader">
                        <ElementLoader />
                    </div>
                )}
            </div>
        );
    };

    const renderEditorControls = () => {
        const initialCropData = getInitialCropData();

        return (
            <>
                <div className="image-editor__controls">
                    <Button
                        onClick={() => setDragMode('move')}
                        variation="secondary"
                        label="Move"
                        iconName="move"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => setDragMode('crop')}
                        variation="secondary"
                        label="Crop"
                        iconName="crop"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => zoomIn()}
                        variation="secondary"
                        label="Zoom in"
                        iconName="plusRound"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => zoomOut()}
                        variation="secondary"
                        label="Zoom out"
                        iconName="minusRound"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => scaleIn()}
                        variation="secondary"
                        label="Scale in"
                        iconName="plusScale"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button image-editor__controls-button--large-icon"
                    />
                    <Button
                        onClick={() => scaleOut()}
                        variation="secondary"
                        label="Scale out"
                        iconName="minusScale"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button image-editor__controls-button--large-icon"
                    />
                    <Button
                        onClick={() => rotateLeft()}
                        variation="secondary"
                        label="Rotate left"
                        iconName="rotateLeft"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => rotateRight()}
                        variation="secondary"
                        label="Rotate right"
                        iconName="rotateRight"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    {/*
                    <Button
                        onClick={() => flipHorizontal()}
                        variation="secondary"
                        label="Flip horizontal"
                        iconName="flipHorizontal"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    <Button
                        onClick={() => flipVertical()}
                        variation="secondary"
                        label="Flip vertical"
                        iconName="flipVertical"
                        labelOnlyAria
                        disabled={!isCropperReady}
                        className="image-editor__controls-button"
                    />
                    */}
                    {!!initialCropData && (
                        <ButtonDropdown
                            placeholder="Reset image &nbsp;&nbsp;&nbsp;&nbsp;"
                            triggerActionOnOptionSelection
                            options={[
                                {
                                    id: 'reset-original',
                                    label: 'Reset to original',
                                    onClick: () => resetToOriginal()
                                },
                                {
                                    id: 'reset-modified',
                                    label: 'Reset to saved version',
                                    onClick: () => resetToSavedVersion()
                                }
                            ]}
                            variation="secondary"
                            disabled={!isCropperReady}
                        />
                    )}
                    {!initialCropData && (
                        <Button
                            onClick={() => resetToOriginal()}
                            variation="secondary"
                            label="Reset to original"
                            disabled={!isCropperReady}
                        />
                    )}
                </div>
            </>
        );
    };

    useEffect(() => {
        if (imageIri) {
            fetchImageData(imageIri);
        }
    }, [imageIri]);

    return (
        <Modal root="body" opened={opened} onClose={() => onModalClose()} title="Edit image">
            {!!opened && (
                <div className="image-editor">
                    {!!imageData && (
                        <React.Fragment>
                            {renderMeta()}
                            {renderCropper()}
                            {renderEditorControls()}
                            <div className="image-editor__actions">
                                {type === 'edit' && (
                                    <Button
                                        onClick={() => saveImageVersion()}
                                        label="Save"
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                )}
                                {type === 'upload' && (
                                    <Button
                                        onClick={() => uploadImage()}
                                        label="Upload"
                                        disabled={!isCropperReady}
                                        className="image-editor__controls-button"
                                    />
                                )}
                                <Button
                                    onClick={() => onModalClose()}
                                    variation="secondary"
                                    label="Cancel"
                                />
                            </div>
                        </React.Fragment>
                    )}

                    {!!(!imageData || !!loading) && (
                        <div className="image-editor__loader">
                            <ElementLoader />
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

ImageManager.defaultProps = {
    type: 'edit',
    localImageData: null,
    imageIri: null,
    opened: false,
    versionName: 'small',
    aspectRatio: null,
    // crossOrigin: 'true',
    onClose: () => {},
    onSaveImage: () => {}
};

ImageManager.propTypes = {
    type: PropTypes.oneOf(['edit', 'upload']),
    localImageData: PropTypes.oneOfType([PropTypes.object]),
    imageIri: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    opened: PropTypes.bool,
    versionName: PropTypes.string,
    aspectRatio: PropTypes.number,
    // crossOrigin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onClose: PropTypes.func,
    onSaveImage: PropTypes.func
};

export default ImageManager;
