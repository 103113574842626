export const actions = {
    START_FETCHING_COMPANIES: 'START_FETCHING_COMPANIES',
    FETCHING_SUCCESSFUL_COMPANIES: 'FETCHING_SUCCESSFUL_COMPANIES',
    FETCHING_FAILED_COMPANIES: 'FETCHING_FAILED_COMPANIES',

    START_DELETE_COMPANY: 'START_DELETE_COMPANY',
    DELETE_COMPANY_SUCCESSFUL: 'DELETE_COMPANY_SUCCESSFUL',
    DELETE_COMPANY_FAILED: 'DELETE_COMPANY_FAILED',

    START_CREATE_COMPANY: 'START_CREATE_COMPANY',
    CREATE_COMPANY_SUCCESSFUL: 'CREATE_COMPANY_SUCCESSFUL',
    CREATE_COMPANY_FAILED: 'CREATE_COMPANY_FAILED',

    START_FETCHING_COMPANY: 'START_FETCHING_COMPANY',
    FETCH_COMPANY_SUCCESSFUL: 'FETCH_COMPANY_SUCCESSFUL',
    FETCH_COMPANY_FAILED: 'FETCH_COMPANY_FAILED',
    STORE_COMPANY_DATA: 'STORE_COMPANY_DATA',

    START_UPDATE_COMPANY: 'START_UPDATE_COMPANY',
    UPDATE_COMPANY_SUCCESSFUL: 'UPDATE_COMPANY_SUCCESSFUL',
    UPDATE_COMPANY_FAILED: 'UPDATE_COMPANY_FAILED',

    START_BULK_ACTIONS_DELETE_COMPANIES: 'START_BULK_ACTIONS_DELETE_COMPANIES',
    BULK_ACTIONS_DELETE_COMPANIES_SUCCESS: 'BULK_ACTIONS_DELETE_COMPANIES_SUCCESS',
    BULK_ACTIONS_DELETE_COMPANIES_FAILED: 'BULK_ACTIONS_DELETE_COMPANIES_FAILED',

    CLEAN_UP: 'CLEAN_UP'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    submittingBulkAction: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_COMPANIES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_COMPANY: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_COMPANY_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_COMPANY_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response?.data
            });
        }
        case actions.FETCH_COMPANY_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_COMPANY_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_COMPANY_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.START_BULK_ACTIONS_DELETE_COMPANIES: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }

        case actions.BULK_ACTIONS_DELETE_COMPANIES_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_DELETE_COMPANIES_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }

        case actions.CLEAN_UP: {
            return initialState;
        }

        default:
            return state;
    }
};
