import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import Tooltip from 'erpcore/components/Tooltip';
import { getResponsive } from 'erpcore/utils/utils';
import { actions as dashboardsActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { dashboardFetching } from 'erpcore/screens/Dashboard/Dashboard.selectors';
import style from './FavoriteDashboard.module.scss';

const FavoriteDashboard = ({ dashboardData, isListingAction }) => {
    const { isMobile } = getResponsive();
    const dispatch = useDispatch();
    const isDashboardFetching = useSelector(dashboardFetching);
    const [loading, setLoading] = useState(false);

    const toggleFavoriteDashboard = () => {
        const data = {
            favorite: !dashboardData?.favorite
        };
        setLoading(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.SET_DASHBOARD_AS_FAVORITE,
                id: dashboardData?.id,
                data
            });
        })
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: error?.response?.data || error
                });
            });
    };

    return isMobile ? (
        <Button
            labelOnlyAria={isListingAction}
            iconName={!dashboardData?.favorite ? 'favorites' : 'favoritesFull'}
            variation={isListingAction ? 'tertiary' : 'secondary'}
            className={`${isListingAction ? style['favorite-listing'] : style.favorite}`}
            loading={loading}
            disabled={isDashboardFetching}
            label={
                // eslint-disable-next-line no-nested-ternary
                !isListingAction
                    ? !dashboardData?.favorite
                        ? 'Add to favourites'
                        : 'Remove from favourites'
                    : null
            }
            onClick={() => toggleFavoriteDashboard()}
        />
    ) : (
        <Tooltip
            content={!dashboardData?.favorite ? 'Add to favourites' : 'Remove from favourites'}
        >
            <Button
                labelOnlyAria
                iconName={!dashboardData?.favorite ? 'favorites' : 'favoritesFull'}
                variation={isListingAction ? 'tertiary' : 'secondary'}
                className={`${
                    isListingAction ? style['favorite-listing'] : style.favorite
                } favorite-button`}
                loading={loading}
                disabled={isDashboardFetching}
                onClick={() => toggleFavoriteDashboard()}
            />
        </Tooltip>
    );
};

FavoriteDashboard.defaultProps = {
    dashboardData: {},
    isListingAction: false
};

FavoriteDashboard.propTypes = {
    dashboardData: PropTypes.oneOfType([PropTypes.object]),
    isListingAction: PropTypes.bool
};

export default FavoriteDashboard;
