export const getProjectApprovalSingleFetching = (state) => state?.projectApprovals?.singleFetching;

export const getProjectApprovalData = (state) => state?.projectApprovals?.singleData;

export const getProjectApprovalPrivateFetching = (state) =>
    state?.projectApprovals?.privateFetching;

export const getProjectApprovalPrivateToken = (state) => state?.projectApprovals?.privateToken;

export const getProjectApprovalPrivateData = (state) => state?.projectApprovals?.privateData;

export default {
    getProjectApprovalSingleFetching,
    getProjectApprovalData,
    getProjectApprovalPrivateFetching,
    getProjectApprovalPrivateToken,
    getProjectApprovalPrivateData
};
