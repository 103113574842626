export const actions = {
    START_FETCHING_PROJECT_APPROVALS: 'START_FETCHING_PROJECT_APPROVALS',
    FETCHING_SUCCESSFUL_PROJECT_APPROVALS: 'FETCHING_SUCCESSFUL_PROJECT_APPROVALS',
    FETCHING_FAILED_PROJECT_APPROVALS: 'FETCHING_FAILED_PROJECT_APPROVALS',

    START_CREATE_PROJECT_APPROVAL: 'START_CREATE_PROJECT_APPROVAL',
    CREATE_PROJECT_APPROVAL_SUCCESS: 'CREATE_PROJECT_APPROVAL_SUCCESS',
    CREATE_PROJECT_APPROVAL_FAILED: 'CREATE_PROJECT_APPROVAL_FAILED',

    START_FETCHING_SINGLE_PROJECT_APPROVAL: 'START_FETCHING_SINGLE_PROJECT_APPROVAL',
    FETCHING_SINGLE_PROJECT_APPROVAL_SUCCESS: 'FETCHING_SINGLE_PROJECT_APPROVAL_SUCCESS',
    FETCHING_SINGLE_PROJECT_APPROVAL_FAILED: 'FETCHING_SINGLE_PROJECT_APPROVAL_FAILED',
    STORE_SINGLE_PROJECT_APPROVAL_DATA: 'STORE_SINGLE_PROJECT_APPROVAL_DATA',

    START_UPDATING_SINGLE_PROJECT_APPROVAL: 'START_UPDATING_SINGLE_PROJECT_APPROVAL',
    UPDATING_SINGLE_PROJECT_APPROVAL_SUCCESS: 'UPDATING_SINGLE_PROJECT_APPROVAL_SUCCESS',
    UPDATING_SINGLE_PROJECT_APPROVAL_FAILED: 'UPDATING_SINGLE_PROJECT_APPROVAL_FAILED',

    START_DELETING_SINGLE_PROJECT_APPROVAL: 'START_DELETING_SINGLE_PROJECT_APPROVAL',
    DELETING_SINGLE_PROJECT_APPROVAL_SUCCESS: 'DELETING_SINGLE_PROJECT_APPROVAL_SUCCESS',
    DELETING_SINGLE_PROJECT_APPROVAL_FAILED: 'DELETING_SINGLE_PROJECT_APPROVAL_FAILED',

    START_FETCHING_PRIVATE_PROJECT_APPROVAL: 'START_FETCHING_PRIVATE_PROJECT_APPROVAL',
    PRIVATE_PROJECT_APPROVAL_FETCH_SUCCESS: 'PRIVATE_PROJECT_APPROVAL_FETCH_SUCCESS',
    PRIVATE_PROJECT_APPROVAL_FETCH_FAILED: 'PRIVATE_PROJECT_APPROVAL_FETCH_FAILED',

    START_APPROVE_PROJECT_APPROVAL: 'START_APPROVE_PROJECT_APPROVAL',
    PROJECT_APPROVAL_APPROVE_SUCCESS: 'PROJECT_APPROVAL_APPROVE_SUCCESS',
    PROJECT_APPROVAL_APPROVE_FAILED: 'PROJECT_APPROVAL_APPROVE_FAILED',

    START_UPDATING_PROJECT_APPROVAL_CHANGE_REQUEST:
        'START_UPDATING_PROJECT_APPROVAL_CHANGE_REQUEST',
    PROJECT_APPROVAL_CHANGE_REQUEST_SUCCESS: 'PROJECT_APPROVAL_CHANGE_REQUEST_SUCCESS',
    PROJECT_APPROVAL_CHANGE_REQUEST_FAILED: 'PROJECT_APPROVAL_CHANGE_REQUEST_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false,
    singleData: null,
    privateFetching: false,
    privateToken: null,
    privateData: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECT_APPROVALS: {
            return {
                ...state,
                listingFetching: true,
                singleData: null
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_APPROVALS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_APPROVALS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_PROJECT_APPROVAL: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_SINGLE_PROJECT_APPROVAL_SUCCESS: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SINGLE_PROJECT_APPROVAL_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_SINGLE_PROJECT_APPROVAL_DATA: {
            return {
                ...state,
                singleData: response
            };
        }
        case actions.START_UPDATING_SINGLE_PROJECT_APPROVAL: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATING_SINGLE_PROJECT_APPROVAL_SUCCESS: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATING_SINGLE_PROJECT_APPROVAL_FAILED: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.START_DELETING_SINGLE_PROJECT_APPROVAL: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETING_SINGLE_PROJECT_APPROVAL_SUCCESS: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.DELETING_SINGLE_PROJECT_APPROVAL_FAILED: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.START_FETCHING_PRIVATE_PROJECT_APPROVAL: {
            return {
                ...state,
                privateFetching: true
            };
        }
        case actions.PRIVATE_PROJECT_APPROVAL_FETCH_SUCCESS: {
            return {
                ...state,
                privateFetching: false,
                privateToken: response.token,
                privateData: response.data
            };
        }
        case actions.PRIVATE_PROJECT_APPROVAL_FETCH_FAILED: {
            return {
                ...state,
                privateFetching: false
            };
        }
        default:
            return state;
    }
};
