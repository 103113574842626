import React from 'react';
import Notification from 'erpcore/components/Notification';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';

import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';

const ResetPasswordSignedIn = () => {
    return (
        <>
            <PageHeader title="You are already signed in!" />

            <PageNotifications />

            <Notification
                title=""
                text="You are already signed in. Please go to My account to change your password."
                type="warning"
            >
                <Button href="/my-account" label="Go to My account" />
            </Notification>
        </>
    );
};

ResetPasswordSignedIn.defaultProps = {};

ResetPasswordSignedIn.propTypes = {};

export default ResetPasswordSignedIn;
