import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as brandingActions } from 'erpcore/utils/BrandingManager/BrandingManager.reducer';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Update Branding Settings
 * @param  {Object} promise
 * @param {Object} formData
 * @param {string} organizationName Organization name
 * @return {Object} Response from API
 */
export function* updateBrandingSettings({ promise, formData, iri }) {
    try {
        const updateBrandingSettingsAPI = yield restClient.put(iri, {
            settings: { ...formData }
        });
        const updateBrandingSettingsDTO = yield dto(updateBrandingSettingsAPI?.data);
        yield put({
            type: brandingActions.UPDATE_BRANDING_SETTINGS_SUCCESSFUL,
            response: updateBrandingSettingsDTO?.data
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateBrandingSettingsDTO
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: brandingActions.UPDATE_BRANDING_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Branding Settings
 * @param  {Object} promise
 * @param {Object} formData
 * @param {string} organizationName Organization name
 * @return {Object} Response from API
 */
export function* createBrandingSettings({ promise, formData, organizationName }) {
    try {
        const createBrandingSettingsAPI = yield restClient.post('/api/organization-settings', {
            name: organizationName,
            settings: { ...formData }
        });
        const createBrandingSettingsDTO = yield dto(createBrandingSettingsAPI?.data);
        yield put({
            type: brandingActions.UPDATE_BRANDING_SETTINGS_SUCCESSFUL,
            response: createBrandingSettingsDTO?.data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBrandingSettingsDTO
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: brandingActions.UPDATE_BRANDING_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Branding Settings
 * @param  {Object} promise
 * @param {string} organizationName Organization name
 * @return {Object} Response from API
 */
export function* fetchBrandingSettings({ promise, organizationName }) {
    try {
        if (organizationName) {
            const fetchBrandingSettingsAPI = yield restClient.get(
                `/api/organization-settings?filters[name][equals]=${organizationName}`
            );
            const updateBrandingSettingsDTO = yield dto(fetchBrandingSettingsAPI?.data);

            yield put({
                type: brandingActions.FETCHING_BRANDING_SETTINGS_SUCCESSFUL,
                response: updateBrandingSettingsDTO?.data?.[0] || null
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: brandingActions.FETCHING_BRANDING_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const brandingManagerSaga = [
    takeLatest(brandingActions.START_UPDATE_BRANDING_SETTINGS, updateBrandingSettings),
    takeLatest(brandingActions.START_CREATE_BRANDING_SETTINGS, createBrandingSettings),
    takeLatest(brandingActions.START_FETCHING_BRANDING_SETTINGS, fetchBrandingSettings)
];
