// Merge included data with data //
const mergeIncludedData = (categoryItems, data, subItemsKey = 'subitems') => {
    const newArray = [];
    if (data.included) {
        categoryItems.map((arrayItem, i) => {
            // First push attributes into item array //
            newArray.push(arrayItem.attributes);

            // Separate Id from Link //
            const ID = arrayItem.id.split('/');
            Object.assign(newArray[i], {
                id: ID[ID.length - 1],
                iri: arrayItem.id
            });

            // check if current array item has relationship object keys //
            if (arrayItem.relationships) {
                // make relationship keys into array and iterate over //
                Object.keys(arrayItem.relationships).forEach(key => {
                    const relationshipsData = arrayItem.relationships[key].data;

                    // if current key values is array //
                    if (relationshipsData instanceof Array) {
                        Object.assign(newArray[i], {
                            [key]: []
                        });
                        relationshipsData.map(relationshipElement => {
                            // Find a match of relationships and included data //
                            const include = data.included.find(
                                includeElement => includeElement.id === relationshipElement.id
                            );

                            if (include) {
                                const includeID = include.id.split('/');

                                const mergeData = Object.assign({}, include.attributes, {
                                    id: includeID[includeID.length - 1],
                                    iri: include.id
                                });

                                // Check if included item has children of his own, recursive function if yes //
                                if (include.relationships && include.relationships[subItemsKey]) {
                                    const childrenTree = mergeIncludedData([{ ...include }], data);

                                    // Merge data //
                                    Object.assign(mergeData, {
                                        ...childrenTree.data[0]
                                    });
                                }

                                // push items into [key] array ( example: subitems ) //
                                newArray[i][key].push(mergeData);
                            }

                            return false;
                        });

                        // if current key values are objects //
                    } else if (relationshipsData instanceof Object) {
                        // Find a match of relationships and included data //
                        const include = data.included.find(
                            includeElement => includeElement.id === relationshipsData.id
                        );

                        // If there is a match, assign key value ( example: created_by ) //
                        if (include) {
                            // const includeID = include.id.split('/');

                            Object.assign(newArray[i], {
                                [key]: include
                                // id: includeID[includeID.length - 1]
                            });
                        } else {
                            Object.assign(newArray[i], {
                                [key]: null
                            });
                        }
                    }
                });
            }

            return true;
        });
    } else {
        categoryItems.map(el => {
            const obj = {};
            // Separate Id from Link //
            const ID = el.id.split('/');
            Object.assign(obj, {
                ...el.attributes,
                id: ID[ID.length - 1],
                iri: el.id
            });

            return newArray.push(obj);
        });
    }
    return {
        data: newArray
    };
};

// Returns only parents with children with all included data
const formTreeView = (parentCategories, data) => {
    const newArray = [];
    const dataCopy = parentCategories;
    dataCopy.forEach(el => {
        if (el.subitems && el.subitems.length > 0) {
            const children = el.subitems;
            const subitems = [];

            children.forEach(child => {
                if (!child.deleted) {
                    let match = data.data.find(
                        childWithIncludedData => childWithIncludedData.id === child.id
                    );

                    if (match.subitems) {
                        const childrensChildren = formTreeView([{ ...match }], data);

                        // eslint-disable-next-line prefer-destructuring
                        match = childrensChildren.data[0];
                    }

                    if (match) {
                        subitems.push(match);
                    }
                }
            });

            const newObject = Object.assign({}, el, {
                subitems
            });

            if (newObject.subitems.length === 0) {
                delete newObject.subitems;
            }

            newArray.push(newObject);
        } else {
            newArray.push(el);
        }
    });

    return { data: newArray };
};

// TreeViewDto //
const dtoTreeView = (data, subItemsKey) => {
    const mergeIncluded = mergeIncludedData(data?.data, data, subItemsKey);

    const parentCategories = mergeIncluded.data.filter(el => el?.parent === undefined);

    const tree = formTreeView(parentCategories, mergeIncluded);

    return tree;
};

const createFormatedData = (array, props, depth = 0) => {
    // Function for creating formated data that is required by react-treebeard, and also creating fields object key //
    const newArr = [];
    if (array?.length) {
        array.forEach(el => {
            const singleData = {};
            // Assign react-treebeard required values ( if has children it will expend if null it wont ) //

            // Empty array for responsive treebeard for arrows because each row has controls for child, null on desktop
            const noSubitems = props.isMobile ? [] : null;

            Object.assign(singleData, {
                name: el?.name,
                children: el?.subitems ? el?.subitems : noSubitems,
                id: el?.id,
                toggled: props?.expandAllOnLoad ? props.expandAllOnLoad : false,
                depth,
                hideFieldsOnExpand: props?.hideFieldsOnExpand,
                fields: el?.fields,
                expandedFields: el?.expandedFields
            });

            // Check if children has children, recursive function if yes //
            if (singleData.children !== null) {
                const nextChildren = createFormatedData(singleData.children, props, depth + 1);
                Object.assign(singleData, {
                    children: nextChildren,
                    depth
                });
            }

            return newArr.push(singleData);
        });
    }

    return newArr;
};

// Format Data neccessery for ReactTreebead //
export const formatData = (data, props) => {
    return createFormatedData(data, props);
};

export default dtoTreeView;
