import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as harvestIntegrationActions } from 'erpcore/screens/Integrations/Harvest/Harvest.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Update Jira Integration Manual Sync
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} formData
 */
export function* updateManualSync({ promise, formData }) {
    try {
        const putManualSyncAPI = yield restClient.post(
            '/api/manual-harvest-timelogs-sync',
            formData
        );
        yield put({
            type: harvestIntegrationActions.UPDATE_HARVEST_MANUAL_SYNC_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(putManualSyncAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: harvestIntegrationActions.UPDATE_HARVEST_MANUAL_SYNC_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(harvestIntegrationActions.START_UPDATING_HARVEST_MANUAL_SYNC, updateManualSync)
];
