import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as invoiceActions } from './Invoices.reducer';

const invoiceListingIncludedString = 'include=company';
const invoiceInclude =
    'invoiceItems,invoiceItems.expenses,invoiceItems.expenses.allocation,invoiceItems.expenses.allocation.department,invoiceItems.expenses.allocation.expense,invoiceItems.revenues,invoiceItems.revenues.allocation,invoiceItems.revenues.allocation.department,invoiceItems.revenues.allocation.expense,company,currency,invoiceItems.category';

/**
 * Delete Single Invoice
 * @param  {String} iri - Invoice iri
 * @param  {Object} promise - promise
 * @return {Object} Response from API
 */
export function* deleteSingleInvoice({ promise, iri }) {
    try {
        const deleteSingleInvoiceAPI = yield restClient.delete(iri);
        yield put({
            type: invoiceActions.DELETE_INVOICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleInvoiceAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'invoices' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'INVOICES',
            name: 'invoices',
            endpoint: `api/accounting/invoices?${invoiceListingIncludedString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.DELETE_INVOICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Single Invoice Item
 * @param  {String} iri - Invoice item iri
 * @param  {Object} promise - promise
 * @param invoiceIri
 * @return {Object} Response from API
 */
export function* deleteSingleInvoiceItem({ promise, iri, invoiceIri }) {
    try {
        const deleteSingleInvoiceItemAPI = yield restClient.delete(iri);
        yield put({
            type: invoiceActions.DELETE_INVOICE_ITEM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleInvoiceItemAPI?.data
        });

        const params = {
            include: 'invoiceItems,company,currency,invoiceItems.category'
        };

        yield put({
            type: invoiceActions.START_FETCHING_SINGLE_INVOICE,
            promise,
            iri: invoiceIri,
            params
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.DELETE_INVOICE_ITEM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Single Invoice
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @return {Object} Response from API
 */
export function* createSingleInvoice({ promise, formData }) {
    try {
        const createSingleInvoiceAPI = yield restClient.post('/api/accounting/invoices', formData);
        const createSingleInvoiceAPIDto = dto(createSingleInvoiceAPI?.data);
        yield put({
            type: invoiceActions.CREATE_INVOICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSingleInvoiceAPIDto
        });

        yield call(promise.resolve, createSingleInvoiceAPIDto);
    } catch (error) {
        yield put({
            type: invoiceActions.CREATE_INVOICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Single Invoice item
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @return {Object} Response from API
 */
export function* createInvoiceItem({ promise, formData }) {
    try {
        const createInvoiceItemAPI = yield restClient.post(
            '/api/accounting/invoice-items',
            formData
        );
        yield put({
            type: invoiceActions.CREATE_INVOICE_ITEM_SUCCESSFUL
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(createInvoiceItemAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.CREATE_INVOICE_ITEM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Invoice item
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @param iri
 * @return {Object} Response from API
 */
export function* updateInvoice({ promise, formData, iri }) {
    try {
        const updateInvoiceAPI = yield restClient.put(iri, formData);
        yield put({
            type: invoiceActions.UPDATE_INVOICE_SUCCESSFUL
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(updateInvoiceAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.UPDATE_INVOICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Invoice item
 * @param  {object} promise - promise
 * @param  {object} formData - formData
 * @param invoiceIri
 * @param iri
 * @return {Object} Response from API
 */
export function* updateInvoiceItem({ promise, formData, invoiceIri, iri }) {
    try {
        if (formData?.allocationRemovalIris?.length) {
            yield formData?.allocationRemovalIris.map((allocationIri) =>
                restClient.delete(`${allocationIri}?confirmDelete=DELETE`)
            );
        }
        const updateInvoiceItemAPI = yield restClient.put(iri, formData);
        yield put({
            type: invoiceActions.UPDATE_INVOICE_ITEM_SUCCESSFUL
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(updateInvoiceItemAPI?.data)
        });

        const params = {
            include: invoiceInclude
        };

        yield put({
            type: invoiceActions.START_FETCHING_SINGLE_INVOICE,
            promise,
            iri: invoiceIri,
            params
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.UPDATE_INVOICE_ITEM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Single Invoice
 * @param  {object} promise - promise
 * @param  {string} iri - Invoice iri
 * @param params
 * @return {Object} Response from API
 */
export function* fetchSingleInvoice({ promise, iri, params }) {
    try {
        const fetchSingleInvoiceAPI = yield restClient.get(iri, { params });
        const fetchSingleInvoiceAPIDto = dto(fetchSingleInvoiceAPI?.data);
        yield put({
            type: invoiceActions.FETCHING_SUCCESSFUL_SINGLE_INVOICE
        });

        yield put({
            type: invoiceActions.STORE_INVOICE_DATA,
            response: fetchSingleInvoiceAPIDto,
            iri: fetchSingleInvoiceAPIDto?.data?.iri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.FETCHING_FAILED_INVOICES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Invoice item Allocation
 * @param  {object} promise - promise
 * @param iri
 * @param params
 * @param invoiceType
 * @return {Object} Response from API
 */
export function* fetchInvoiceItemAllocation({ promise, iri, params, invoiceType = 'expenses' }) {
    try {
        const fetchInvoiceItemAllocationAPI = yield restClient.get(
            `/api/accounting/${invoiceType}`,
            {
                params
            }
        );

        yield put({
            type: invoiceActions.FETCHING_ALLOCATIONS_SUCCESSFUL,
            response: dto(fetchInvoiceItemAllocationAPI?.data),
            iri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.FETCHING_ALLOCATIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* changeInvoiceOrder({ promise, formData, iri }) {
    try {
        const changeInvoiceOrderAPI = yield restClient.put(
            `${iri}/change-status?include=${invoiceInclude}`,
            formData
        );
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changeInvoiceOrderAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.INVOICE_CHANGE_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* addInvoicePayment({ promise, payload }) {
    try {
        const apiResponse = yield restClient.post(`/api/accounting/invoice/payments`, payload);

        yield put({
            type: invoiceActions.ADD_INVOICE_PAYMENT_SUCCESSFUL
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: apiResponse?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: invoiceActions.ADD_INVOICE_PAYMENT_FAILED
        });

        if (error?.response?.data?.errors?.[0]?.code === 'payment.paid.invalidPaymentConstraint') {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data?.errors?.[0]
            });
        } else {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
        }
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(invoiceActions.START_DELETE_INVOICE, deleteSingleInvoice),
    takeLatest(invoiceActions.START_CREATE_INVOICE, createSingleInvoice),
    takeLatest(invoiceActions.START_FETCHING_SINGLE_INVOICE, fetchSingleInvoice),
    takeLatest(invoiceActions.START_CREATE_INVOICE_ITEM, createInvoiceItem),
    takeLatest(invoiceActions.START_DELETE_INVOICE_ITEM, deleteSingleInvoiceItem),
    takeLatest(invoiceActions.START_UPDATE_INVOICE_ITEM, updateInvoiceItem),
    takeLatest(invoiceActions.START_UPDATE_INVOICE, updateInvoice),
    takeLatest(invoiceActions.START_FETCHING_ALLOCATIONS, fetchInvoiceItemAllocation),
    takeLatest(invoiceActions.START_INVOICE_CHANGE_STATUS, changeInvoiceOrder),
    takeLatest(invoiceActions.START_ADD_INVOICE_PAYMENT, addInvoicePayment)
];
