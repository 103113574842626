import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import isFinite from 'lodash/isFinite';
import { isNumber } from 'lodash/lang';
import Input from '../Input';

/**
 * Currency Component's local state amount, redux state and input value are integers (number without decimals, value expressed in cents)
 * Value passed to NumberFormat is float, amount divided by 100
 */
const Currency = ({
    input,
    suffix,
    prefix,
    fieldAttr,
    fieldProps,
    field,
    meta,
    allowNegative,
    max,
    onUserDrivenChange
}) => {
    const { name, value, onChange, onBlur } = { ...input };

    const convertToCents = useCallback((float) => {
        // multiply by 100, expect weird decimals (because js binary), convert to string integer, convert to int
        return float || float === 0
            ? // multiplying by 100 (extra code is for weird JS decimals ie. 2500.01 * 100 -> 250001.00000000003)
              parseInt((float * 100).toFixed(), 10)
            : null;
    }, []);

    /**
     * @param values {Object}
     * @param values.formattedValue {string} Value after applying formatting
     * @param values.value {string} Non formatted value as numeric string
     * @param values.floatValue {Number} Floating point representation
     */
    const handleValueChange = useCallback(
        (values, sourceInfo) => {
            const output = convertToCents(values?.floatValue);

            if (sourceInfo?.source === 'event') {
                onUserDrivenChange(output);
            }

            return onChange(output);
        },
        [onUserDrivenChange]
    );

    const checkingForAllowedValueIsRequired = useMemo(() => {
        return isFinite(max);
    }, [max]);

    const isAllowed = useCallback(
        (values) => {
            const userValue = values?.floatValue;

            if (!isNumber(userValue)) {
                return true;
            }

            if (userValue > max) {
                return false;
            }

            return true;
        },
        [max]
    );

    const isEmpty = value === null || value === '' || value === undefined;

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <NumberFormat
                name={name}
                {...fieldAttr}
                className="input__field"
                allowEmptyFormatting={false}
                isNumericString={false}
                value={isEmpty ? '' : value / 100}
                suffix={suffix}
                prefix={prefix}
                onValueChange={handleValueChange}
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                onBlur={() => onBlur()}
                allowNegative={allowNegative}
                isAllowed={checkingForAllowedValueIsRequired ? isAllowed : undefined}
            />
        </Input>
    );
};

Currency.defaultProps = {
    value: '',
    prefix: '', // do not set this to null
    suffix: '', // do not set this to null
    allowNegative: true,
    max: Infinity,
    onUserDrivenChange: () => {},
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Currency.propTypes = {
    value: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    allowNegative: PropTypes.bool,
    max: PropTypes.number,
    onUserDrivenChange: PropTypes.func,
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Currency;
