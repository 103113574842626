export const getCheckedCompanies = (state, name) => state?.[name]?.checked;

export const submittingBulkAction = (state) => state?.companies?.submittingBulkAction;

export const getSingleCompanyFetching = (state) => state?.companies?.singleFetching;

export const getCompanyData = (state, iri) => state.companies?.[iri];

export default {
    getCheckedCompanies,
    submittingBulkAction,
    getSingleCompanyFetching,
    getCompanyData
};
