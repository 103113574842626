export const actions = {
    START_FETCHING_PROJECT_CONTRACTS: 'START_FETCHING_PROJECT_CONTRACTS',
    FETCHING_SUCCESSFUL_PROJECT_CONTRACTS: 'FETCHING_SUCCESSFUL_PROJECT_CONTRACTS',
    FETCHING_FAILED_PROJECT_CONTRACTS: 'FETCHING_FAILED_PROJECT_CONTRACTS',

    START_CREATE_PROJECT_CONTRACT: 'START_CREATE_PROJECT_CONTRACT',
    CREATE_PROJECT_CONTRACT_SUCCESSFUL: 'CREATE_PROJECT_CONTRACT_SUCCESSFUL',
    CREATE_PROJECT_CONTRACT_FAILED: 'CREATE_PROJECT_CONTRACT_FAILED',
    START_FETCHING_PROJECT_CONTRACT: 'START_FETCHING_PROJECT_CONTRACT',
    FETCH_PROJECT_CONTRACT_SUCCESSFUL: 'FETCH_PROJECT_CONTRACT_SUCCESSFUL',

    FETCH_PROJECT_CONTRACT_FAILED: 'FETCH_PROJECT_CONTRACT_FAILED',
    REQUEST_UPDATE_PROJECT_CONTRACT: 'REQUEST_UPDATE_PROJECT_CONTRACT',
    START_UPDATE_PROJECT_CONTRACT: 'START_UPDATE_PROJECT_CONTRACT',
    UPDATE_PROJECT_CONTRACT_SUCCESSFUL: 'UPDATE_PROJECT_CONTRACT_SUCCESSFUL',
    UPDATE_PROJECT_CONTRACT_FAILED: 'UPDATE_PROJECT_CONTRACT_FAILED',
    START_DELETE_PROJECT_CONTRACT: 'START_DELETE_PROJECT_CONTRACT',
    DELETE_PROJECT_CONTRACT_SUCCESSFUL: 'DELETE_PROJECT_CONTRACT_SUCCESSFUL',
    DELETE_PROJECT_CONTRACT_FAILED: 'DELETE_PROJECT_CONTRACT_FAILED',
    STORE_PROJECT_CONTRACT_DATA: 'STORE_PROJECT_CONTRACT_DATA'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleCreating: false,
    singleDeleting: false,
    singleUpdating: false
};

export default (state = initialState, { type, contractIri, response }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECT_CONTRACTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_CONTRACTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_CONTRACTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_CREATE_PROJECT_CONTRACT: {
            return {
                ...state,
                singleCreating: true
            };
        }
        case actions.CREATE_PROJECT_CONTRACT_SUCCESSFUL: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.CREATE_PROJECT_CONTRACT_FAILED: {
            return {
                ...state,
                singleCreating: false
            };
        }
        case actions.START_DELETE_PROJECT_CONTRACT: {
            return {
                ...state,
                singleDeleting: true
            };
        }
        case actions.DELETE_PROJECT_CONTRACT_SUCCESSFUL: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.DELETE_PROJECT_CONTRACT_FAILED: {
            return {
                ...state,
                singleDeleting: false
            };
        }
        case actions.START_FETCHING_PROJECT_CONTRACT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_PROJECT_CONTRACT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_PROJECT_CONTRACT_FAILED: {
            return {
                ...state,
                singleFetching: false,
                singleError: response
            };
        }

        case actions.START_UPDATE_PROJECT_CONTRACT: {
            return {
                ...state,
                singleUpdating: true
            };
        }
        case actions.UPDATE_PROJECT_CONTRACT_SUCCESSFUL: {
            return {
                ...state,
                singleUpdating: false
            };
        }
        case actions.UPDATE_PROJECT_CONTRACT_FAILED: {
            return {
                ...state,
                singleUpdating: false,
                singleError: response
            };
        }
        case actions.STORE_PROJECT_CONTRACT_DATA: {
            return Object.assign({}, state, {
                [contractIri]: response.data
            });
        }
        default:
            return state;
    }
};
