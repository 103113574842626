export const actions = {
    START_FETCHING_TIME_OFFS: 'START_FETCHING_TIME_OFFS',
    FETCHING_SUCCESSFUL_TIME_OFFS: 'FETCHING_SUCCESSFUL_TIME_OFFS',
    FETCHING_FAILED_TIME_OFFS: 'FETCHING_FAILED_TIME_OFFS',

    START_CREATE_TIME_OFF: 'START_CREATE_TIME_OFF',
    CREATE_TIME_OFF_SUCCESSFUL: 'CREATE_TIME_OFF_SUCCESSFUL',
    CREATE_TIME_OFF_FAILED: 'CREATE_TIME_OFF_FAILED',
    START_FETCHING_TIME_OFF: 'START_FETCHING_TIME_OFF',
    FETCH_TIME_OFF_SUCCESSFUL: 'FETCH_TIME_OFF_SUCCESSFUL',
    FETCH_TIME_OFF_FAILED: 'FETCH_TIME_OFF_FAILED',
    START_UPDATE_TIME_OFF: 'START_UPDATE_TIME_OFF',
    UPDATE_TIME_OFF_SUCCESSFUL: 'UPDATE_TIME_OFF_SUCCESSFUL',
    UPDATE_TIME_OFF_FAILED: 'UPDATE_TIME_OFF_FAILED',
    STORE_TIME_OFF_DATA: 'STORE_TIME_OFF_DATA',
    // Change Status
    START_TIME_OFF_CHANGE_STATUS: 'START_TIME_OFF_CHANGE_STATUS',
    TIME_OFF_CHANGE_STATUS_SUCCESSFUL: 'TIME_OFF_CHANGE_STATUS_SUCCESSFUL',
    TIME_OFF_CHANGE_STATUS_FAILED: 'TIME_OFF_CHANGE_STATUS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_TIME_OFFS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TIME_OFFS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TIME_OFFS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_TIME_OFF: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_TIME_OFF_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_TIME_OFF_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_TIME_OFF_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_USER_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_TIME_OFF_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        default:
            return state;
    }
};
