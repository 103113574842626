import breakpoints from 'erpcore/assets/scss/exports/breakpoints.module.scss';
import grid from 'erpcore/assets/scss/exports/grid.module.scss';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import toNumber from 'lodash/toNumber';

/**
 * Convert string property.id to int.
 * Sort property items by defined positions first, undefined/null positions last.
 * Then sort items with defined positions by property.position ascending.
 * If positions are equal then sort by property.id (ascending).
 * Sort group of property items with undefined/null positions by id (ascending).
 *
 * @param list {array}
 * @param includeDeleted {boolean}
 * @return {Object}
 */
const dtoSortByPositionAndId = ({ list = [], includeDeleted = false }) => {
    if (list?.length) {
        const listCopy = [
            ...list.reduce((accumulator, current) => {
                if (includeDeleted || (!includeDeleted && current?.deleted !== true)) {
                    accumulator.push({ ...{}, ...current });
                }
                return accumulator;
            }, [])
        ];
        if (listCopy?.length) {
            listCopy.forEach((item, index) => {
                if (!Number.isNaN(parseInt(item?.id, 10))) {
                    listCopy[index].id = parseInt(item?.id, 10);
                }
            });

            // sort ticket types
            listCopy.sort((firstItem, secondItem) => {
                // if position is not set, treat position as a 999999 (otherwise null position would evaluate as less than any other defined position)
                const firstItemPosition =
                    firstItem.position || firstItem.position === 0 ? firstItem.position : 999999;
                const secondItemPosition =
                    secondItem.position || secondItem.position === 0 ? secondItem.position : 999999;

                let compare = 0;
                //  0 = order is unchanged
                //  1 = secondItem takes precedence over firstItem
                // -1 = firstItem takes precedence over secondItem
                if (firstItemPosition === secondItemPosition) {
                    if (firstItem.id > secondItem.id) {
                        compare = 1;
                    } else if (firstItem.id < secondItem.id) {
                        compare = -1;
                    }
                } else if (firstItemPosition > secondItemPosition) {
                    compare = 1;
                } else if (firstItemPosition < secondItemPosition) {
                    compare = -1;
                }
                return compare;
            });
        }
        return listCopy;
    }
    return list;
};

/**
 *
 * @param datetime
 * @return {string}
 */
const dtoIgnoreTimezone = (datetime = null) => {
    if (!datetime) {
        return null;
    }
    const m = moment.tz(datetime, null);
    const pad = (number, length = 2) => {
        return number.toString().padStart(length, '0');
    };
    const dateBreakdown = {
        year: m.year(),
        month: pad(m.month() + 1),
        date: pad(m.date()),
        hour: pad(m.hour()),
        minute: pad(m.minute()),
        second: pad(m.second())
    };
    return `${dateBreakdown.year}-${dateBreakdown.month}-${dateBreakdown.date}T${dateBreakdown.hour}:${dateBreakdown.minute}:${dateBreakdown.second}`;
};

/**
 * Collection of `util` helper functions
 */

/**
 * Generate keys for React Components
 * @param {String} string Param that is used for the key
 * @return {String} Returns generated key
 */
const generateKey = (string) => {
    if (!string) {
        return null;
    }

    let theKey = '';

    // if FormattedMessage object
    if (string?.constructor === Object) {
        if (string.props && string.props.defaultMessage) {
            string = string.props.defaultMessage;
        }
    }

    // generate key
    theKey = string
        .toString()
        .split('')
        .reduce(
            // eslint-disable-next-line
            (prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
            0
        );

    return theKey;
};

/**
 * Disables the ability to type characters, only numberic values allowed
 * @param {string} value
 * @return {string}
 */
const normalizeNumber = (value) => value.replace(/[^\d]/g, '');

/**
 * Formatting an integer with a comma as a thousands separators
 * @param {string|int} number
 * @returns {String} properly formatted number.
 */
function formatNumber(number) {
    return number?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 *
 * @param number {number}
 * @param decimalPlaces {number}
 * @returns {number}
 */
function preciseFloatingPoint(number, decimalPlaces = 2) {
    return parseFloat(toNumber(number).toFixed(decimalPlaces));
}

/**
 *
 * @param number {number}
 * @param divisor {number}
 * @param decimalPlaces {number}
 * @returns {number}
 */
function preciseRemainder(number, divisor, decimalPlaces = 2) {
    let modulo = preciseFloatingPoint(number % divisor, decimalPlaces);

    if (modulo === divisor) {
        /**
         * HANDLE ADDITIONAL BINARY FLOATING POINT ISSUE SPECIFIC TO MODULO
         * Example:
         * 0.5 % 0.1 should be zero
         * BUT is 0.09999999999999998 because of binary arithmetic,
         * ROUNDED with preciseFloatingPoint() is 0.1
         * and that is not possible, to have modulo being equal to divisor,
         * which means modulo is actually zero
         */
        modulo = 0;
    }

    return modulo;
}

window.preciseFloatingPoint = preciseFloatingPoint;
window.preciseRemainder = preciseRemainder;

const pluralize = (count, noun, suffix = 's') => {
    if (!noun) {
        return '';
    }

    return `${noun}${count !== 1 ? suffix : ''}`;
};

function formatAmPm(hours, minutes) {
    let h = hours;
    let m = minutes;
    const ampm = h >= 12 ? 'pm' : 'am';
    h %= 12;
    h = h || 12; // the hour '0' should be '12'
    m = m < 10 && m > 0 ? `0${m}` : m;
    return `${h}:${m} ${ampm}`;
}

const formatHours = (seconds, format = null) => {
    let decimalTime = Math.abs(seconds);
    const hours = Math.floor(decimalTime / (60 * 60));
    decimalTime -= hours * 60 * 60;
    const minutes = Math.floor(decimalTime / 60);
    decimalTime -= minutes * 60;

    if (format === 'hh:mm') {
        return `${
            hours
                ? `${formatNumber(hours) >= 10 ? formatNumber(hours) : ` 0${formatNumber(hours)}`}h`
                : '00h'
        }${minutes && minutes !== 0 ? ` ${minutes >= 10 ? minutes : ` 0${minutes}`}m` : ' 00m'}`;
    }

    return `${seconds < 0 && (minutes > 0 || hours > 0) ? '-' : ''}${
        hours ? `${formatNumber(hours)}h` : '0h'
    }${minutes && minutes !== 0 ? ` ${minutes}m` : ''}`;
};

/**
 * Format date
 * @param {string} date
 * @param {string} format
 * @return {String} date
 * */
const formatDate = (date, format = 'DD-MM-YYYY') => {
    if (!date) {
        return null;
    }

    const d = new Date(date);
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    function appendZero(time) {
        if (time <= 9) {
            return `0${time}`;
        }
        return time;
    }

    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const monthString = months[month];
    const hour = d.getHours();
    const minute = d.getMinutes();
    const second = d.getSeconds();

    switch (format) {
        case 'YYYY-MM-DD':
            return `${year}-${appendZero(month)}-${appendZero(day)}`;
        case 'YYYY/MM/DD':
            return `${year}/${appendZero(month)}/${appendZero(day)}`;
        case 'DD/MM/YYYY':
            return `${appendZero(day)}/${appendZero(month)}/${year}`;
        case 'MM/DD/YYYY':
            return `${appendZero(month)}/${appendZero(day)}/${year}`;
        case 'DD-MM-YYYY':
            return `${appendZero(day)}-${appendZero(month)}-${year}`;
        case 'MM-DD-YYYY':
            return `${appendZero(month)}-${appendZero(day)}-${year}`;
        case 'Month':
            return monthString;
        case 'Day+Month':
            return `${day} ${monthString}`;
        case 'Month+Day':
            return `${monthString} ${day}`;
        case 'hh:mm:ss':
            return `${appendZero(hour)}:${appendZero(minute)}:${appendZero(second)}`;
        case 'hh:mm':
            return `${appendZero(hour)}:${appendZero(minute)}`;
        case 'hh:mm AA':
            return formatAmPm(hour, minute);
        case 'YYYY-MM-DD hh:mm:ss':
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        case 'YYYY-MM-DD hh:mm AA':
            return `${year}-${month}-${day} ${formatAmPm(hour, minute)}`;
        case 'YYYY/MM/DD hh:mm:ss':
            return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
        case `YYYY/MM/DD hh:mm AA`:
            return `${year}/${month}/${day} ${formatAmPm(hour, minute)}`;
        default:
            return `${day}-${month}-${year}`;
    }
};

/**
 * Formats currency
 * @return {String} currency in form of 55.555 or 55.555,00 ( if decimal )
 * */
const formatCurrency = (value) => {
    if (value === null || value === undefined) {
        return null;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Turns Hex into rgba
 * @return {String} rgba
 * */
const hexToRGB = (hex, alpha) => {
    if (!hex) return hex;

    hex = hex.toString().replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
    }

    return `rgb(${r},${g},${b})`;
};

/**
 * mix two colors by percentage
 * @return {String} rgba
 * */
const hexMix = (colorFrom, colorTo, ratio) => {
    if (ratio > 1) ratio = 1;
    const hex = (x) => {
        x = x.toString(16);
        return x.length === 1 ? `0${x}` : x;
    };

    const r = Math.ceil(
        parseInt(colorTo.substring(0, 2), 16) * ratio +
            parseInt(colorFrom.substring(0, 2), 16) * (1 - ratio)
    );
    const g = Math.ceil(
        parseInt(colorTo.substring(2, 4), 16) * ratio +
            parseInt(colorFrom.substring(2, 4), 16) * (1 - ratio)
    );
    const b = Math.ceil(
        parseInt(colorTo.substring(4, 6), 16) * ratio +
            parseInt(colorFrom.substring(4, 6), 16) * (1 - ratio)
    );

    return `#${hex(r)}${hex(g)}${hex(b)}`;
};

/**
 * Returns Currency in K format
 * @return {String} number
 * */
const kFormatter = (n) => {
    if (n >= 1000) {
        return `${+(n / 1000).toFixed(1)}K`;
    }
    return n;
};

/**
 * Parses a string and returns an integer
 * @param value {string|number}
 * @returns {integer}
 */
function parseInteger(value) {
    return Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
}

/**
 * Access Object with string key as property path
 * @param object {Object}
 * @param path {string} String key
 * @return {*}
 */
const resolveObjectPathByString = (object, path) =>
    path
        .split(/[.[\]'"]/)
        .filter((p) => p)
        .reduce((o, p) => (o ? o[p] : null), object);

// default spacing for app
const spacing = parseInt(grid?.spacing, 10);

/**
 * Check if you hit mobile resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifMobile = (currentWidth) => {
    return currentWidth < parseInt(breakpoints?.tablet, 10) + 1;
};

/**
 * Check if you hit tablet resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifTablet = (currentWidth) => {
    return currentWidth < parseInt(breakpoints?.desktop, 10) + 1;
};

/**
 * Check if you hit mobile or tablet resolution
 * @returns {object}
 */
const getResponsive = () => {
    const [isSmall, setIsSmall] = useState(
        window.innerWidth < parseInt(breakpoints?.small, 10) + 1
    );
    const [isMobile, setIsMobile] = useState(
        window.innerWidth < parseInt(breakpoints?.tablet, 10) + 1
    );
    const [isTablet, setIsTablet] = useState(
        window.innerWidth < parseInt(breakpoints?.desktop, 10) + 1
    );
    useEffect(() => {
        function handleResize() {
            setIsSmall(window.innerWidth < parseInt(breakpoints?.small, 10) + 1);
            setIsMobile(window.innerWidth < parseInt(breakpoints?.tablet, 10) + 1);
            setIsTablet(window.innerWidth < parseInt(breakpoints?.desktop, 10) + 1);
        }
        const debouncedHandler = debounce(handleResize, 100);

        window.addEventListener('resize', debouncedHandler);

        return () => window.removeEventListener('resize', debouncedHandler);
    }, []);
    return { isSmall, isMobile, isTablet };
};

/**
 * Appends main element with width class names
 * @returns {object}
 */
const setLayoutWidth = (width = 'narrow') => {
    useEffect(() => {
        const collection = document.getElementsByClassName('main');
        if (collection.length > 0) collection[0].classList.add(`main--${width}`);
        return () => {
            if (collection.length > 0) collection[0].classList.remove(`main--${width}`);
        };
    }, []);
};

const getHoursFromSeconds = (seconds) => {
    if (!seconds) {
        return 0;
    }

    return Math.floor(Math.abs(seconds) / 60 / 60);
};

const getRemainingMinutesFromSeconds = (seconds) => {
    if (!seconds) {
        return 0;
    }

    return Math.floor(Math.abs(seconds / 60) % 60);
};

const getRemainingSeconds = (seconds) => {
    if (!seconds) {
        return 0;
    }

    return Math.floor(Math.abs(seconds) % 60);
};

const formatTimeValueString = (value, useFullUnitName, unit) => {
    return `${value}${useFullUnitName ? ' ' : ''}${unit}${useFullUnitName && value > 1 ? 's' : ''}`;
};

/**
 * Convert seconds to custom timelog
 * @returns {string}
 * @param totalSeconds {number}
 * @param useFullUnitName {boolean}
 * @param defaultUnit {string}
 */
const timeValueConverter = (totalSeconds, useFullUnitName = false, defaultUnit = 's') => {
    const defaultValue = `${0}${defaultUnit}`;

    // null / undefined / 0 / NaN
    if (!totalSeconds || Number.isNaN(totalSeconds)) {
        return defaultValue;
    }

    const secondsUnit = useFullUnitName ? 'second' : 's';
    const minutesUnit = useFullUnitName ? 'minute' : 'm';
    const hoursUnit = useFullUnitName ? 'hour' : 'h';

    if (Math.abs(totalSeconds) < 60) {
        const secondsValue = getRemainingSeconds(totalSeconds);
        return formatTimeValueString(secondsValue, useFullUnitName, secondsUnit);
    }

    if (Math.abs(totalSeconds) < 3600) {
        const secondsValue = getRemainingSeconds(totalSeconds);
        const minutesValue = getRemainingMinutesFromSeconds(totalSeconds);

        if (secondsValue === 0) {
            return formatTimeValueString(minutesValue, useFullUnitName, minutesUnit);
        }

        return `${formatTimeValueString(
            minutesValue,
            useFullUnitName,
            minutesUnit
        )} ${formatTimeValueString(secondsValue, useFullUnitName, secondsUnit)}`;
    }

    if (Math.abs(totalSeconds) >= 3600) {
        const hoursValue = getHoursFromSeconds(totalSeconds);
        const minutesValue = getRemainingMinutesFromSeconds(totalSeconds);
        const secondsValue = getRemainingSeconds(totalSeconds);

        if (secondsValue === 0 && minutesValue === 0) {
            return formatTimeValueString(hoursValue, useFullUnitName, hoursUnit);
        }

        if (secondsValue === 0) {
            return `${formatTimeValueString(
                hoursValue,
                useFullUnitName,
                hoursUnit
            )} ${formatTimeValueString(minutesValue, useFullUnitName, minutesUnit)}`;
        }

        return `${formatTimeValueString(
            hoursValue,
            useFullUnitName,
            hoursUnit
        )} ${formatTimeValueString(
            minutesValue,
            useFullUnitName,
            minutesUnit
        )} ${formatTimeValueString(secondsValue, useFullUnitName, secondsUnit)}`;
    }

    return defaultValue;
};

/**
 * Spread this function inside an array at the position where you would want the elements to be positioned if the condition is true.
 * Basically function returns elements if the condition is true.
 *
 * Example:
 * const exampleArray = [
 *     'item 1',
 *     'item 2',
 *     ...insertIf(isAdmin, ['item 3']),
 *     'item last',
 *     ..insertIf(isAdmin, ['item x', 'item last admin']),
 * ]
 *
 * @param condition {boolean} elements will be inserted if the condition is true
 * @param elements {Array} array of elements to be inserted
 * @returns {Array}
 */
const insertIf = (condition, elements) => {
    return condition ? [...elements] : [];
};

/**
 * Used to lighten or darken hex color
 * @param col {String} - hex color
 * @param amt {Number} - percentage ( negative numbers darken, positive numbers lighten the color )
 * @returns {String} - Changed hex color
 */
const changeColorShade = (col, amt) => {
    if (!col || !col.length) {
        return false;
    }

    let usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    // eslint-disable-next-line no-bitwise
    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    // eslint-disable-next-line no-bitwise
    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    // eslint-disable-next-line no-bitwise
    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (
        (usePound ? '#' : '') +
        // eslint-disable-next-line no-bitwise
        ((g | (b << 8) | (r << 16)).toString(16)?.length < 6 ? '0' : '') +
        // eslint-disable-next-line no-bitwise
        (g | (b << 8) | (r << 16)).toString(16)
    );
};

/**
 * Check if string is valid URL
 * @param string {String} - URL
 * @returns {Boolean} - True/false URL
 */
const isUrl = (string) => {
    try {
        return Boolean(new URL(string));
    } catch (e) {
        return false;
    }
};

const checkEmailValidity = (value) => {
    if (!value) return false;
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

const getNameForListingColumnWithArchiveText = (name, archived = false) => {
    return `${archived ? '[ARCHIVED] ' : ''}${name}`;
};

//  list of all days
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Parse working days object to match API requirements
 * @param object {Object} - Working days object
 * @returns {Object} - Parsed working days object
 */
const parseWorkingDays = (workingDays, appendSaveLogic = true) => {
    if (!workingDays) return false;
    const parsedWorkDays = {};
    /*
        check if is start date today then patch else append with today start date
        requires start date info to generate
     */
    if (appendSaveLogic) {
        if (workingDays?.start_date && !moment(workingDays?.start_date).isBefore(moment(), 'day')) {
            parsedWorkDays.method = 'patch';
            parsedWorkDays.iri = workingDays.iri;
        } else {
            parsedWorkDays.start_date = moment(new Date()).format('YYYY-M-D');
        }
    }

    days.map((key) => {
        parsedWorkDays[key] = workingDays[key] ? parseFloat(workingDays[key]) : 0;
        return key;
    });
    return parsedWorkDays;
};

const parseDecimal = (value) => {
    return !parseFloat(value) || !Number(value) || value.endsWith('.') ? value : parseFloat(value);
};

const getDatesInRange = (startDate, endDate, step = 'days') => {
    const now = moment(startDate).startOf(step.slice(0, -1));
    const end = moment(endDate);
    const dates = [];

    while (now.isSameOrBefore(end)) {
        dates.push(now.clone().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        now.add(1, step);
    }
    return dates;
};

const getQuarterStartDateForApi = (year, quarter, apiDateFormat) => {
    return moment(year.toString()).quarter(quarter).format(apiDateFormat);
};

const getQuarterEndDateForApi = (year, quarter, apiDateFormat) => {
    return moment(year.toString()).quarter(quarter).endOf('quarter').format(apiDateFormat);
};

const getMonthStartDateForApi = (year, month, apiDateFormat) => {
    return moment(`${year.toString()}-${month.padStart(2, '0')}-01`).format(apiDateFormat);
};

const getMonthEndDateForApi = (year, month, apiDateFormat) => {
    return moment(`${year.toString()}-${month.padStart(2, '0')}-01`)
        .endOf('month')
        .format(apiDateFormat);
};

const getYearStartDateForApi = (year, apiDateFormat) => {
    return moment(year.toString()).format(apiDateFormat);
};

const getYearEndDateForApi = (year, apiDateFormat) => {
    return moment(year.toString()).endOf('year').format(apiDateFormat);
};

const isWeekend = (dayString) => {
    const dayOfWeek = moment(dayString).weekday();

    // Sunday has a value of 0, Saturday has a value of 6
    return dayOfWeek === 0 || dayOfWeek === 6;
};

const cleanRepeaterOutput = (list, additionalKeys = []) => {
    if (list?.length) {
        return [...list].map((item) => {
            const newItem = { ...item };
            delete newItem.isNewRepeaterItem;
            if (additionalKeys?.length) {
                additionalKeys.forEach((key) => delete newItem[key]);
            }
            return newItem;
        });
    }

    return list;
};

const sortSelectOptions = (a, b) => {
    const nameA = a?.label?.toUpperCase() || '';
    const nameB = b?.label?.toUpperCase() || '';
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
};

export {
    dtoSortByPositionAndId,
    dtoIgnoreTimezone,
    generateKey,
    normalizeNumber,
    formatDate,
    formatAmPm,
    formatCurrency,
    formatHours,
    hexToRGB,
    hexMix,
    kFormatter,
    formatNumber,
    parseInteger,
    resolveObjectPathByString,
    spacing,
    ifMobile,
    ifTablet,
    getResponsive,
    breakpoints,
    timeValueConverter,
    insertIf,
    changeColorShade,
    isUrl,
    checkEmailValidity,
    preciseFloatingPoint,
    preciseRemainder,
    pluralize,
    getNameForListingColumnWithArchiveText,
    parseWorkingDays,
    days,
    parseDecimal,
    getDatesInRange,
    getMonthStartDateForApi,
    getMonthEndDateForApi,
    getQuarterStartDateForApi,
    getQuarterEndDateForApi,
    getYearStartDateForApi,
    getYearEndDateForApi,
    isWeekend,
    setLayoutWidth,
    getHoursFromSeconds,
    getRemainingMinutesFromSeconds,
    cleanRepeaterOutput,
    getRemainingSeconds,
    sortSelectOptions
};
