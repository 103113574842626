export const actions = {
    START_FETCHING_INVOICES: 'START_FETCHING_INVOICES',
    FETCHING_SUCCESSFUL_INVOICES: 'FETCHING_SUCCESSFUL_INVOICES',
    FETCHING_FAILED_INVOICES: 'FETCHING_FAILED_INVOICES',

    START_DELETE_INVOICE: 'START_DELETE_INVOICE',
    DELETE_INVOICE_SUCCESSFUL: 'DELETE_INVOICE_SUCCESSFUL',
    DELETE_INVOICE_FAILED: 'DELETE_INVOICE_FAILED',

    START_CREATE_INVOICE: 'START_CREATE_INVOICE',
    CREATE_INVOICE_SUCCESSFUL: 'CREATE_INVOICE_SUCCESSFUL',
    CREATE_INVOICE_FAILED: 'CREATE_INVOICE_FAILED',

    START_FETCHING_SINGLE_INVOICE: 'START_FETCHING_SINGLE_INVOICE',
    FETCHING_SUCCESSFUL_SINGLE_INVOICE: 'FETCHING_SUCCESSFUL_SINGLE_INVOICE',
    FETCHING_FAILED_SINGLE_INVOICE: 'FETCHING_FAILED_SINGLE_INVOICE',
    STORE_INVOICE_DATA: 'STORE_INVOICE_DATA',

    START_CREATE_INVOICE_ITEM: 'START_CREATE_INVOICE_ITEM',
    CREATE_INVOICE_ITEM_SUCCESSFUL: 'CREATE_INVOICE_ITEM_SUCCESSFUL',
    CREATE_INVOICE_ITEM_FAILED: 'CREATE_INVOICE_ITEM_FAILED',

    START_DELETE_INVOICE_ITEM: 'START_DELETE_INVOICE_ITEM',
    DELETE_INVOICE_ITEM_SUCCESSFUL: 'DELETE_INVOICE_ITEM_SUCCESSFUL',
    DELETE_INVOICE_ITEM_FAILED: 'DELETE_INVOICE_ITEM_FAILED',

    START_UPDATE_INVOICE_ITEM: 'START_UPDATE_INVOICE_ITEM',
    UPDATE_INVOICE_ITEM_SUCCESSFUL: 'UPDATE_INVOICE_ITEM_SUCCESSFUL',
    UPDATE_INVOICE_ITEM_FAILED: 'UPDATE_INVOICE_ITEM_FAILED',

    START_UPDATE_INVOICE: 'START_UPDATE_INVOICE',
    UPDATE_INVOICE_SUCCESSFUL: 'UPDATE_INVOICE_SUCCESSFUL',
    UPDATE_INVOICE_FAILED: 'UPDATE_INVOICE_FAILED',

    START_FETCHING_ALLOCATIONS: 'START_FETCHING_ALLOCATIONS',
    FETCHING_ALLOCATIONS_SUCCESSFUL: 'FETCHING_ALLOCATIONS_SUCCESSFUL',
    FETCHING_ALLOCATIONS_FAILED: 'FETCHING_ALLOCATIONS_FAILED',

    START_INVOICE_CHANGE_STATUS: 'START_INVOICE_CHANGE_STATUS',
    INVOICE_CHANGE_STATUS_SUCCESSFUL: 'INVOICE_CHANGE_STATUS_SUCCESSFUL',
    INVOICE_CHANGE_STATUS_FAILED: 'INVOICE_CHANGE_STATUS_FAILED',

    START_ADD_INVOICE_PAYMENT: 'START_ADD_INVOICE_PAYMENT',
    ADD_INVOICE_PAYMENT_SUCCESSFUL: 'ADD_INVOICE_PAYMENT_SUCCESSFUL',
    ADD_INVOICE_PAYMENT_FAILED: 'ADD_INVOICE_PAYMENT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    allocationsFetching: false,
    singleError: null,
    invoiceItemSubmitting: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_CREATE_INVOICE_ITEM: {
            return {
                ...state,
                invoiceItemSubmitting: true
            };
        }
        case actions.CREATE_INVOICE_ITEM_SUCCESSFUL: {
            return {
                ...state,
                invoiceItemSubmitting: false
            };
        }
        case actions.CREATE_INVOICE_ITEM_FAILED: {
            return {
                ...state,
                invoiceItemSubmitting: false
            };
        }
        case actions.START_UPDATE_INVOICE_ITEM: {
            return {
                ...state,
                invoiceItemSubmitting: true
            };
        }
        case actions.UPDATE_INVOICE_ITEM_SUCCESSFUL: {
            return {
                ...state,
                invoiceItemSubmitting: false
            };
        }
        case actions.UPDATE_INVOICE_ITEM_FAILED: {
            return {
                ...state,
                invoiceItemSubmitting: false
            };
        }
        case actions.START_FETCHING_INVOICES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_INVOICES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_INVOICES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_INVOICE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SINGLE_INVOICE: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_FAILED_SINGLE_INVOICE: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_INVOICE_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        case actions.START_FETCHING_ALLOCATIONS: {
            return {
                ...state,
                allocationsFetching: true
            };
        }
        case actions.FETCHING_ALLOCATIONS_SUCCESSFUL: {
            return {
                ...state,
                allocationsFetching: false,
                [iri]: response
            };
        }
        case actions.FETCHING_ALLOCATIONS_FAILED: {
            return {
                ...state,
                allocationsFetching: false
            };
        }

        default:
            return state;
    }
};
