import React from 'react';
import PropTypes from 'prop-types';
import { SelectNew } from 'erpcore/components/Form';

const SelectMiddlewareToNewField = ({ fieldProps, fieldAttr, field, input, meta, ...rest }) => {
    return (
        <SelectNew
            {...rest}
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            actionButton={fieldProps?.actionButton}
            options={fieldProps?.options}
            additionalOptions={fieldProps?.additionalOptions}
            isClearable={fieldProps?.clearable === undefined ? true : fieldProps?.clearable}
            // exactly matching the old logic, that weirdly supported two props
            {...('isClearable' in { ...fieldProps }
                ? // eslint-disable-next-line react/prop-types
                  { isClearable: fieldProps.isClearable }
                : null)}
            isMulti={false}
        />
    );
};

SelectMiddlewareToNewField.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
SelectMiddlewareToNewField.propTypes = {
    fieldProps: PropTypes.shape({
        params: PropTypes.object,
        defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        options: PropTypes.object,
        actionButton: PropTypes.object,
        forceLabelActive: PropTypes.bool,
        menuPlacement: PropTypes.string,
        additionalOptions: PropTypes.array,
        clearable: PropTypes.bool
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default SelectMiddlewareToNewField;
