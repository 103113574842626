export const actions = {
    // Create accounting category
    START_CREATE_ACCOUNTING_CATEGORY: 'START_CREATE_ACCOUNTING_CATEGORY',
    CREATE_ACCOUNTING_CATEGORY_SUCCESSFUL: 'CREATE_ACCOUNTING_CATEGORY_SUCCESSFUL',
    CREATE_ACCOUNTING_CATEGORY_FAILED: 'CREATE_ACCOUNTING_CATEGORY_FAILED',
    // Update accounting category
    START_UPDATE_ACCOUNTING_CATEGORY: 'START_UPDATE_ACCOUNTING_CATEGORY',
    UPDATE_ACCOUNTING_CATEGORY_SUCCESSFUL: 'UPDATE_ACCOUNTING_CATEGORY_SUCCESSFUL',
    UPDATE_ACCOUNTING_CATEGORY_FAILED: 'UPDATE_ACCOUNTING_CATEGORY_FAILED',
    // Delete accounting category
    START_DELETE_ACCOUNTING_CATEGORY: 'START_DELETE_ACCOUNTING_CATEGORY',
    DELETE_ACCOUNTING_CATEGORY_SUCCESSFUL: 'DELETE_ACCOUNTING_CATEGORY_SUCCESSFUL',
    DELETE_ACCOUNTING_CATEGORY_FAILED: 'DELETE_ACCOUNTING_CATEGORY_FAILED',
    // Fetch accounting category
    START_FETCH_ACCOUNTING_CATEGORY: 'START_FETCH_ACCOUNTING_CATEGORY',
    FETCH_ACCOUNTING_CATEGORY_SUCCESSFUL: 'FETCH_ACCOUNTING_CATEGORY_SUCCESSFUL',
    FETCH_ACCOUNTING_CATEGORY_FAILED: 'FETCH_ACCOUNTING_CATEGORY_FAILED',
    STORE_ACCOUNTING_CATEGORY_DATA: 'STORE_ACCOUNTING_CATEGORY_DATA',
    // Fetch expense categories
    START_FETCHING_EXPENSE_CATEGORIES: 'START_FETCHING_EXPENSE_CATEGORIES',
    FETCHING_EXPENSE_CATEGORIES_SUCCESSFUL: 'FETCHING_EXPENSE_CATEGORIES_SUCCESSFUL',
    FETCHING_EXPENSE_CATEGORIES_FAILED: 'FETCHING_EXPENSE_CATEGORIES_FAILED',
    // Fetch revenue categories
    START_FETCHING_REVENUE_CATEGORIES: 'START_FETCHING_REVENUE_CATEGORIES',
    FETCHING_REVENUE_CATEGORIES_SUCCESSFUL: 'FETCHING_REVENUE_CATEGORIES_SUCCESSFUL',
    FETCHING_REVENUE_CATEGORIES_FAILED: 'FETCHING_REVENUE_CATEGORIES_FAILED'
};

export const initialState = {
    expense: {
        data: [],
        meta: {},
        included: [],
        fetching: false,
        singleFetching: false
    },
    revenue: {
        data: [],
        meta: {},
        included: [],
        fetching: false,
        singleFetching: false
    }
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCH_ACCOUNTING_CATEGORY: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_ACCOUNTING_CATEGORY_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_ACCOUNTING_CATEGORY_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_ACCOUNTING_CATEGORY_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        case actions.START_FETCHING_EXPENSE_CATEGORIES: {
            return {
                ...state,
                expense: { ...state.expense, fetching: true }
            };
        }
        case actions.FETCHING_EXPENSE_CATEGORIES_SUCCESSFUL: {
            return {
                ...state,
                expense: { ...state.expense, ...response, fetching: false }
            };
        }
        case actions.FETCHING_EXPENSE_CATEGORIES_FAILED: {
            return {
                ...state,
                expense: { ...state.expense, fetching: false }
            };
        }
        case actions.START_FETCHING_REVENUE_CATEGORIES: {
            return {
                ...state,
                revenue: { ...state.revenue, fetching: true }
            };
        }
        case actions.FETCHING_REVENUE_CATEGORIES_SUCCESSFUL: {
            return {
                ...state,
                revenue: { ...state.revenue, ...response, fetching: false }
            };
        }
        case actions.FETCHING_REVENUE_CATEGORIES_FAILED: {
            return {
                ...state,
                revenue: { ...state.revenue, fetching: false }
            };
        }
        default:
            return state;
    }
};
