export const actions = {
    START_FETCHING_CONTRACT_JIRA_BULK_ISSUES: 'START_FETCHING_CONTRACT_JIRA_BULK_ISSUES',
    FETCHING_SUCCESSFUL_CONTRACT_JIRA_BULK_ISSUES: 'FETCHING_SUCCESSFUL_CONTRACT_JIRA_BULK_ISSUES',
    FETCHING_FAILED_CONTRACT_JIRA_BULK_ISSUES: 'FETCHING_FAILED_CONTRACT_JIRA_BULK_ISSUES',
    CLEAR_STATE: 'CLEAR_STATE'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    isInitialFetchDone: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_CONTRACT_JIRA_BULK_ISSUES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_CONTRACT_JIRA_BULK_ISSUES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response,
                isInitialFetchDone: true
            };
        }
        case actions.FETCHING_FAILED_CONTRACT_JIRA_BULK_ISSUES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.CLEAR_STATE: {
            return initialState;
        }
        default:
            return state;
    }
};
