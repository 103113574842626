export const actions = {
    START_UPDATING_HARVEST_MANUAL_SYNC: 'START_UPDATING_HARVEST_MANUAL_SYNC',
    UPDATE_HARVEST_MANUAL_SYNC_SUCCESSFUL: 'UPDATE_HARVEST_MANUAL_SYNC_SUCCESSFUL',
    UPDATE_HARVEST_MANUAL_SYNC_FAILED: 'UPDATE_HARVEST_MANUAL_SYNC_FAILED'
};

export const initialState = {};

export default (state = initialState, { type }) => {
    switch (type) {
        case actions.START_UPDATING_HARVEST_MANUAL_SYNC: {
            return {
                ...state
            };
        }
        case actions.UPDATE_HARVEST_MANUAL_SYNC_SUCCESSFUL: {
            return {
                ...state
            };
        }
        case actions.UPDATE_HARVEST_MANUAL_SYNC_FAILED: {
            return {
                ...state
            };
        }
        default:
            return state;
    }
};
