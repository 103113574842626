import { put, take, takeLatest, fork, call } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import dto, { getIdFromIri } from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as employmentRecordsActions } from './UserEmploymentRecords.reducer';

const employmentRecordsIncludeString =
    'include=contract,jobTitle,office,division,department,reportsTo';
const employmentRecordsOrderString = 'order_by[startDate]=DESC';

/**
 * Fetch EmploymentRecords Per USer Saga
 * @param  {Object} promise Resolve and reject promise
 * @param  {Object} params Set query params for listing request
 * @param  {String} entity Name of the listing entity
 * @param  {String} endpoint Endpoint to wich saga points
 * @return {Object} Response from API
 */
export function* fetchEmploymentRecordsPerUser({ promise, userIri }) {
    try {
        const fetchEmploymentRecordsAPI = yield restClient.get(
            `api/employment-records?filters[user][equals]=${userIri}&${employmentRecordsIncludeString}&${employmentRecordsOrderString}`
        );
        yield put({
            type: employmentRecordsActions.FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS_PER_USER,
            response: dto(fetchEmploymentRecordsAPI?.data),
            userIri
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: employmentRecordsActions.FETCHING_FAILED_EMPLOYMENT_RECORDS_PER_USER,
            response: error?.response?.data || error,
            userIri
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Create EmploymentRecord
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createEmploymentRecord({ promise, formData }) {
    try {
        const createEmploymentRecordAPI = yield restClient.post(
            `/api/employment-records`,
            formData
        );
        yield put({
            type: employmentRecordsActions.CREATE_EMPLOYMENT_RECORD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEmploymentRecordAPI?.data
        });
        yield call(promise.resolve, createEmploymentRecordAPI?.data);
    } catch (error) {
        yield put({
            type: employmentRecordsActions.CREATE_EMPLOYMENT_RECORD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch EmploymentRecord
 * @param  {Object} promise
 * @param  {string} iri EmploymentRecord iri
 * @return {Promise}
 */
export function* fetchEmploymentRecord({ promise, iri }) {
    try {
        const fetchEmploymentRecordAPI = yield restClient.get(
            `api/employment-records/${getIdFromIri(iri)}?${employmentRecordsIncludeString}`
        );
        yield put({
            type: employmentRecordsActions.FETCH_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL
        });
        yield put({
            type: employmentRecordsActions.STORE_SINGLE_EMPLOYMENT_RECORD_DATA,
            iri,
            response: dto(fetchEmploymentRecordAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: employmentRecordsActions.FETCH_SINGLE_EMPLOYMENT_RECORD_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update EmploymentRecord single data
 * @param  {Object} promise
 * @param  {object} formData
 * @param  {string} iri ID of an EmploymentRecord
 * @return {Object} Response from API
 */
export function* updateSingleEmploymentRecord({ promise, formData, iri }) {
    try {
        const updateSingleEmploymentRecordAPI = yield restClient.patch(
            `api/employment-records/${getIdFromIri(iri)}?${employmentRecordsIncludeString}`,
            formData
        );
        const dtoUpdateSingleEmploymentRecordAPI = yield dto(updateSingleEmploymentRecordAPI?.data);
        yield put({
            type: employmentRecordsActions.UPDATE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEmploymentRecordAPI?.data
        });
        yield put({
            type: employmentRecordsActions.STORE_SINGLE_EMPLOYMENT_RECORD_DATA,
            iri,
            response: dtoUpdateSingleEmploymentRecordAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: employmentRecordsActions.UPDATE_SINGLE_EMPLOYMENT_RECORD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Single EmploymentRecord
 * @param  {Object} id ID of an EmploymentRecord
 * @return {Object} Response from API
 */
export function* deleteSingleEmploymentRecord({ promise, iri }) {
    try {
        const deleteSingleEmploymentRecordAPI = yield restClient.delete(
            `api/employment-records/${getIdFromIri(iri)}`
        );
        yield put({
            type: employmentRecordsActions.DELETE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEmploymentRecordAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: employmentRecordsActions.DELETE_SINGLE_EMPLOYMENT_RECORD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const employmentRecordsSaga = [
    takeLatestPerProps(
        'entity',
        employmentRecordsActions.START_FETCHING_EMPLOYMENT_RECORDS_PER_USER,
        fetchEmploymentRecordsPerUser
    ),
    takeLatest(employmentRecordsActions.START_CREATE_EMPLOYMENT_RECORD, createEmploymentRecord),
    takeLatest(
        employmentRecordsActions.START_FETCHING_SINGLE_EMPLOYMENT_RECORD,
        fetchEmploymentRecord
    ),
    takeLatest(
        employmentRecordsActions.START_UPDATE_SINGLE_EMPLOYMENT_RECORD,
        updateSingleEmploymentRecord
    ),
    takeLatest(
        employmentRecordsActions.START_DELETE_SINGLE_EMPLOYMENT_RECORD,
        deleteSingleEmploymentRecord
    )
];
