export const actions = {
    // Listing
    START_FETCHING_PAYMENT_TERMS: 'START_FETCHING_PAYMENT_TERMS',
    FETCHING_SUCCESSFUL_PAYMENT_TERMS: 'FETCHING_SUCCESSFUL_PAYMENT_TERMS',
    FETCHING_FAILED_PAYMENT_TERMS: 'FETCHING_FAILED_PAYMENT_TERMS',
    // Create
    START_CREATE_PAYMENT_TERM: 'START_CREATE_PAYMENT_TERM',
    CREATE_PAYMENT_TERM_SUCCESSFUL: 'CREATE_PAYMENT_TERM_SUCCESSFUL',
    CREATE_PAYMENT_TERM_FAILED: 'CREATE_PAYMENT_TERM_FAILED',
    // Edit
    START_UPDATE_PAYMENT_TERM: 'START_UPDATE_PAYMENT_TERM',
    UPDATE_PAYMENT_TERM_SUCCESSFUL: 'UPDATE_PAYMENT_TERM_SUCCESSFUL',
    UPDATE_PAYMENT_TERM_FAILED: 'UPDATE_PAYMENT_TERM_FAILED',
    // Delete
    START_DELETE_PAYMENT_TERM: 'START_DELETE_PAYMENT_TERM',
    DELETE_PAYMENT_TERM_SUCCESSFUL: 'DELETE_PAYMENT_TERM_SUCCESSFUL',
    DELETE_PAYMENT_TERM_FAILED: 'DELETE_PAYMENT_TERM_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PAYMENT_TERMS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PAYMENT_TERMS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PAYMENT_TERMS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
