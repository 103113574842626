export const actions = {
    START_FETCHING_LEAD_STATUSES: 'START_FETCHING_LEAD_STATUSES',
    FETCHING_SUCCESSFUL_LEAD_STATUSES: 'FETCHING_SUCCESSFUL_LEAD_STATUSES',
    FETCHING_FAILED_LEAD_STATUSES: 'FETCHING_FAILED_LEAD_STATUSES',

    START_CREATE_LEAD_STATUS: 'START_CREATE_LEAD_STATUS',
    CREATE_LEAD_STATUS_SUCCESFUL: 'CREATE_LEAD_STATUS_SUCCESFUL',
    CREATE_LEAD_STATUS_FAILED: 'CREATE_LEAD_STATUS_FAILED',
    START_UPDATE_LEAD_STATUS: 'START_UPDATE_LEAD_STATUS',
    UPDATE_LEAD_STATUS_SUCCESSFUL: 'UPDATE_LEAD_STATUS_SUCCESSFUL',
    UPDATE_LEAD_STATUS_FAILED: 'UPDATE_LEAD_STATUS_FAILED',
    START_FETCHING_LEAD_STATUS: 'START_FETCHING_LEAD_STATUS',
    FETCHING_LEAD_STATUS_SUCCESSFUL: 'FETCHING_LEAD_STATUS_SUCCESSFUL',
    FETCHING_LEAD_STATUS_FAILED: 'FETCHING_LEAD_STATUS_FAILED',
    STORE_LEAD_STATUS_DATA: 'STORE_LEAD_STATUS_DATA',
    START_DELETE_LEAD_STATUS: 'START_DELETE_LEAD_STATUS',
    DELETE_LEAD_STATUS_SUCCESSFUL: 'DELETE_LEAD_STATUS_SUCCESSFUL',
    DELETE_LEAD_STATUS_FAILED: 'DELETE_LEAD_STATUS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_LEAD_STATUSES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_LEAD_STATUSES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_LEAD_STATUSES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_LEAD_STATUS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_LEAD_STATUS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_LEAD_STATUS_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_LEAD_STATUS_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
