import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as payrollReportsActions } from 'erpcore/screens/Accounting/screens/PayrollReports/PayrollReports.reducer';

const includeString =
    'include=office,currency,fullTimeEmployees,fullTimeEmployees.user,workflowTransitionLogs,workflowTransitionLogs.user,contractors,contractors.user';

/**
 * Delete Payroll report
 * @param  {Object} iri of a Payroll report
 * @return {Object} Response from API
 */
export function* deletePayrollReport({ promise, iri }) {
    try {
        const deletePayrollReportAPI = yield restClient.delete(iri);
        yield put({
            type: payrollReportsActions.DELETE_PAYROLL_REPORT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deletePayrollReportAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.DELETE_PAYROLL_REPORT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Payroll report
 * @param  {Object} promise
 * @return {Object} formData
 */
export function* createPayrollReport({ promise, formData }) {
    try {
        const createPayrollReportAPI = yield restClient.post(`/api/payroll-reports`, formData);
        yield put({
            type: payrollReportsActions.CREATE_PAYROLL_REPORT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createPayrollReportAPI?.data
        });
        yield call(promise.resolve, createPayrollReportAPI?.data);
    } catch (error) {
        yield put({
            type: payrollReportsActions.CREATE_PAYROLL_REPORT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Payroll report
 * @param  {Object} promise
 * @return {string} iri Payroll report iri
 */
export function* fetchPayrollReport({ promise, iri }) {
    try {
        const fetchPayrollReportAPI = yield restClient.get(`${iri}?${includeString}`);
        yield put({
            type: payrollReportsActions.FETCH_PAYROLL_REPORT_SUCCESSFUL
        });
        yield put({
            type: payrollReportsActions.STORE_PAYROLL_REPORT_DATA,
            iri,
            response: dto(fetchPayrollReportAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.FETCH_PAYROLL_REPORT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Payroll report single data
 * @param  {Object} iri iri of Payroll report
 * @return {Object} Response from API
 */
export function* updateSinglePayrollReport({ promise, formData, iri }) {
    try {
        const updateSinglePayrollReportAPI = yield restClient.put(
            `${iri}?${includeString}`,
            formData
        );

        yield put({
            type: payrollReportsActions.UPDATE_PAYROLL_REPORT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSinglePayrollReportAPI?.data
        });
        yield put({
            type: payrollReportsActions.STORE_PAYROLL_REPORT_DATA,
            iri,
            response: dto(updateSinglePayrollReportAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.UPDATE_PAYROLL_REPORT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Status Payroll report single data
 * @param  {Object} iri of an Payroll report
 * @return {Object} Response from API
 */
export function* changeStatusPayrollReport({ promise, formData, iri }) {
    try {
        const changeStatusPayrollReportAPI = yield restClient.patch(
            `${iri}/status?${includeString}`,
            formData
        );
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changeStatusPayrollReportAPI?.data
        });
        yield put({
            type: payrollReportsActions.PAYROLL_REPORT_STATUS_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.PAYROLL_REPORT_CHANGE_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Payroll report currencies
 * @param  {Object} promise
 * @return {string} iri Payroll report iri
 */
export function* fetchCurrency({ promise, iri }) {
    try {
        const fetchCurrencyAPI = yield restClient.get(iri);
        const currencyResponse = dto(fetchCurrencyAPI?.data);
        yield put({
            type: payrollReportsActions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_CURRENCY,
            iri,
            response: currencyResponse
        });
        yield call(promise.resolve, currencyResponse.data);
    } catch (error) {
        yield put({
            type: payrollReportsActions.FETCHING_FAILED_PAYROLL_REPORT_CURRENCY
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Payroll report user hourse worked
 * @param  {Object} promise
 * @return {string} iri Payroll report iri
 */
export function* fetchUserWorked({ promise, iri, datePeriod }) {
    try {
        const fetchUserWorkedAPI = yield restClient.get(`/api/reports/time-logs`, {
            params: {
                'filters[created_by][equals]': iri,
                'filters[date][between]': datePeriod
                    ? [datePeriod.start || '', datePeriod.end || '']
                    : []
            },
            headers: { Accept: 'application/json' }
        });
        yield put({
            type: payrollReportsActions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_WORKED,
            iri,
            response: fetchUserWorkedAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.FETCHING_FAILED_PAYROLL_REPORT_USER_WORKED,
            iri
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Payroll report user hourse worked
 * @param  {Object} promise
 * @return {string} iri Payroll report iri
 */
export function* fetchUserRecords({ promise, iri }) {
    try {
        const fetchUserRecordAPI = yield restClient.get(iri);
        const fetchUserRecordAPIResponse = dto(fetchUserRecordAPI?.data);
        yield put({
            type: payrollReportsActions.FETCHING_SUCCESSFUL_PAYROLL_REPORT_USER_RECORDS,
            iri,
            response: fetchUserRecordAPIResponse
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: payrollReportsActions.FETCHING_FAILED_PAYROLL_REPORT_USER_RECORDS,
            iri
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(payrollReportsActions.START_DELETE_PAYROLL_REPORT, deletePayrollReport),
    takeLatest(payrollReportsActions.START_CREATE_PAYROLL_REPORT, createPayrollReport),
    takeLatest(payrollReportsActions.START_FETCHING_PAYROLL_REPORT, fetchPayrollReport),
    takeLatest(payrollReportsActions.START_UPDATE_PAYROLL_REPORT, updateSinglePayrollReport),
    takeLatest(payrollReportsActions.START_PAYROLL_REPORT_CHANGE_STATUS, changeStatusPayrollReport),
    takeLatest(payrollReportsActions.START_FETCHING_PAYROLL_REPORT_CURRENCY, fetchCurrency),
    takeLatest(payrollReportsActions.START_FETCHING_PAYROLL_REPORT_USER_WORKED, fetchUserWorked),
    takeLatest(payrollReportsActions.START_FETCHING_PAYROLL_REPORT_USER_RECORDS, fetchUserRecords)
];
