export const actions = {
    START_FETCHING_EMPLOYMENT_RECORDS_PER_USER: 'START_FETCHING_EMPLOYMENT_RECORDS_PER_USER',
    FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS_PER_USER:
        'FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS_PER_USER',
    FETCHING_FAILED_EMPLOYMENT_RECORDS_PER_USER: 'FETCHING_FAILED_EMPLOYMENT_RECORDS_PER_USER',

    START_FETCHING_EMPLOYMENT_RECORDS: 'START_FETCHING_EMPLOYMENT_RECORDS',
    FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS: 'FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS',
    FETCHING_FAILED_EMPLOYMENT_RECORDS: 'FETCHING_FAILED_EMPLOYMENT_RECORDS',

    START_CREATE_EMPLOYMENT_RECORD: 'START_CREATE_EMPLOYMENT_RECORD',
    CREATE_EMPLOYMENT_RECORD_SUCCESSFUL: 'CREATE_EMPLOYMENT_RECORD_SUCCESSFUL',
    CREATE_EMPLOYMENT_RECORD_FAILED: 'CREATE_EMPLOYMENT_RECORD_FAILED',
    START_FETCHING_SINGLE_EMPLOYMENT_RECORD: 'START_FETCHING_SINGLE_EMPLOYMENT_RECORD',
    FETCH_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL: 'FETCH_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL',
    FETCH_SINGLE_EMPLOYMENT_RECORD_FAILED: 'FETCH_SINGLE_EMPLOYMENT_RECORD_FAILED',
    START_UPDATE_SINGLE_EMPLOYMENT_RECORD: 'START_UPDATE_SINGLE_EMPLOYMENT_RECORD',
    UPDATE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL: 'UPDATE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL',
    UPDATE_SINGLE_EMPLOYMENT_RECORD_FAILED: 'UPDATE_SINGLE_EMPLOYMENT_RECORD_FAILED',
    START_DELETE_SINGLE_EMPLOYMENT_RECORD: 'START_DELETE_SINGLE_EMPLOYMENT_RECORD',
    DELETE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL: 'DELETE_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL',
    DELETE_SINGLE_EMPLOYMENT_RECORD_FAILED: 'DELETE_SINGLE_EMPLOYMENT_RECORD_FAILED',
    STORE_SINGLE_EMPLOYMENT_RECORD_DATA: 'STORE_SINGLE_EMPLOYMENT_RECORD_DATA',

    START_FETCHING_USER_WORK_TYPES_QUALIFICATIONS: 'START_FETCHING_USER_WORK_TYPES_QUALIFICATIONS',
    FETCHING_SUCCESSFUL_USER_WORK_TYPES_QUALIFICATIONS:
        'FETCHING_SUCCESSFUL_USER_WORK_TYPES_QUALIFICATIONS',
    FETCHING_FAILED_USER_WORK_TYPES_QUALIFICATIONS: 'FETCHING_FAILED_USER_WORK_TYPES_QUALIFICATIONS'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    listingPerUser: null,
    listingPerUserError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null
};

export default (state = initialState, { type, userIri, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_EMPLOYMENT_RECORDS_PER_USER:
        case actions.START_FETCHING_EMPLOYMENT_RECORDS:
        case actions.START_FETCHING_USER_WORK_TYPES_QUALIFICATIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS_PER_USER: {
            return {
                ...state,
                listingFetching: false,
                listingPerUser: { ...state.listingPerUser, [userIri || 'general']: response }
            };
        }
        case actions.FETCHING_SUCCESSFUL_EMPLOYMENT_RECORDS:
        case actions.FETCHING_SUCCESSFUL_USER_WORK_TYPES_QUALIFICATIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EMPLOYMENT_RECORDS_PER_USER: {
            return {
                ...state,
                listingFetching: false,
                listingPerUserError: {
                    ...state.listingPerUserError,
                    [userIri || 'general']: response
                }
            };
        }
        case actions.FETCHING_FAILED_EMPLOYMENT_RECORDS:
        case actions.FETCHING_FAILED_USER_WORK_TYPES_QUALIFICATIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_EMPLOYMENT_RECORD: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_EMPLOYMENT_RECORD_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_EMPLOYMENT_RECORD_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_EMPLOYMENT_RECORD_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_EMPLOYMENT_RECORD_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SINGLE_EMPLOYMENT_RECORD_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        default:
            return state;
    }
};
