import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import defaultDateFormat from 'erpcore/utils/defaultDateFormat';
import convertMomentJsToDateFnsFormat from 'erpcore/utils/convertMomentJsToDateFnsFormat';
import { createPortal } from 'react-dom';
import { useId } from 'react-id-generator';
import Input from '../Input';
import './DateTime.scss';

const momentFormat = defaultDateFormat;
const momentFormatTime = 'h:mm a';

const PopperContainer = ({ children, containerElement }) => {
    return createPortal(children, containerElement);
};

//  Passing custom input gives plugin console error. This is a 3rd party issue
const CustomInput = ({ fieldAttr, onClick, value }) => {
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <input
            type="button"
            className="input__field"
            autoComplete="off"
            onClick={onClick}
            value={value}
            readOnly
            aria-label="open date picker"
            {...fieldAttr}
        />
    );
};
CustomInput.defaultProps = {
    fieldAttr: {},
    onClick: () => {},
    value: {}
};
CustomInput.propTypes = {
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    onClick: PropTypes.func,
    value: PropTypes.string
};

/**
 *
 * @param input
 * @param meta
 * @param fieldProps
 * @param fieldAttr
 * @param field
 * @param outputNoTimeZone {boolean} if true; value is saved as formatted string,
 *                                   otherwise value is saved as Date string with timezone
 * @param alwaysOpen {boolean}
 * @param greedyPortal {boolean}
 * @return {*}
 * @constructor
 */
const DateTime = ({
    input,
    meta,
    fieldProps,
    fieldAttr,
    field,
    outputNoTimeZone,
    alwaysOpen,
    greedyPortal
}) => {
    const [dateFormatMoment, dateFormatFns] = useMemo(() => {
        const formats = {
            date: momentFormat,
            time: momentFormatTime,
            dateTime: `${momentFormat} ${momentFormatTime}`
        };

        let selectedFormat = formats.date;

        const getMomentFormatMaybeTimezone = (date) => (outputNoTimeZone ? date : '');

        if (fieldProps?.showTimeSelect) {
            selectedFormat = formats.dateTime;
            if (fieldProps?.showTimeSelectOnly) {
                selectedFormat = formats.time;
            }
        }

        return [
            getMomentFormatMaybeTimezone(selectedFormat),
            convertMomentJsToDateFnsFormat(selectedFormat)
        ];
    }, [fieldProps?.showTimeSelect, fieldProps?.showTimeSelectOnly]);

    const [globallyUniquePopperId] = useId(1, 'date-time-portal-');
    const dateTimePortalWrapper =
        document.getElementById('datetime-portal-wrapper') || document.body;
    const popperContainerElement = useRef(dateTimePortalWrapper); // does not need to be reactive
    // create popper container node element
    if (!document.getElementById(globallyUniquePopperId)) {
        const newPopperContainerElement = document.createElement('div');
        newPopperContainerElement.setAttribute('id', globallyUniquePopperId);
        newPopperContainerElement.setAttribute(
            'class',
            `date-time-portal ${greedyPortal ? 'date-time-portal--greedy' : ''}`
        );
        popperContainerElement.current = newPopperContainerElement;
        dateTimePortalWrapper.appendChild(newPopperContainerElement);
    }

    // remove popper container node element on unMount
    useEffect(() => {
        return () => {
            const popperContainerElementForRemoval =
                document.getElementById(globallyUniquePopperId);
            if (popperContainerElementForRemoval) {
                popperContainerElementForRemoval.remove();
            }
        };
    }, []);

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--datepicker"
        >
            <DatePicker
                {...(alwaysOpen ? { open: true } : null)}
                className="input__field"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperModifiers={[
                    {
                        name: 'preventOverflow',
                        options: {
                            rootBoundary: 'viewport',
                            altAxis: true
                        }
                    }
                ]}
                {...fieldAttr}
                {...fieldProps}
                onBlur={() => input.onBlur()}
                dateFormat={dateFormatFns}
                selected={
                    input.value &&
                    Date.parse(input.value) &&
                    Number.isNaN(Date.parse(input.value)) === false
                        ? new Date(input.value)
                        : ''
                }
                disabledKeyboardNavigation
                // if outputNoTimeZone === true -> value is saved as formatted string, otherwise as Date string with timezone
                onChange={(value) => input.onChange(moment(value).format(dateFormatMoment))}
                customInput={<CustomInput fieldAttr={fieldAttr} />}
                popperContainer={({ children, ...rest }) => (
                    <PopperContainer {...rest} containerElement={popperContainerElement.current}>
                        {children}
                    </PopperContainer>
                )}
            />
        </Input>
    );
};

DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    outputNoTimeZone: true,
    alwaysOpen: false,
    greedyPortal: false
};

DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    outputNoTimeZone: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    greedyPortal: PropTypes.bool
};

export default DateTime;
