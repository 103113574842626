import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { isNumber } from 'lodash/lang';
import isFinite from 'lodash/isFinite';
import Input from '../Input';

const Float = ({
    input,
    fieldAttr,
    fieldProps,
    field,
    meta,
    falsyValue,
    allowNegative,
    max,
    onUserDrivenChange
}) => {
    const { name, value, onChange, onBlur } = { ...input };

    /**
     * @param values {Object}
     * @param values.floatValue {Number} Floating point representation
     */
    const handleValueChange = useCallback(
        (values, sourceInfo) => {
            const newValue =
                values?.floatValue || values?.floatValue === 0 ? values.floatValue : falsyValue;

            onChange(newValue);

            if (sourceInfo?.source === 'event') {
                onUserDrivenChange(newValue);
            }
        },
        [onUserDrivenChange]
    );

    const checkingForAllowedValueIsRequired = useMemo(() => {
        return isFinite(max);
    }, [max]);

    const isAllowed = useCallback(
        (values) => {
            const userValue = values?.floatValue;

            if (!isNumber(userValue)) {
                return true;
            }

            if (userValue > max) {
                return false;
            }

            return true;
        },
        [max]
    );

    const isEmpty = value === null || value === '' || value === undefined;

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <NumberFormat
                name={name}
                {...fieldAttr}
                className="input__field"
                allowEmptyFormatting
                isNumericString={false}
                value={isEmpty ? '' : value}
                onValueChange={handleValueChange}
                thousandSeparator={false}
                decimalSeparator="."
                allowedDecimalSeparators={[',', '.']}
                onBlur={() => onBlur()}
                allowNegative={allowNegative}
                isAllowed={checkingForAllowedValueIsRequired ? isAllowed : undefined}
            />
        </Input>
    );
};

Float.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    falsyValue: '',
    allowNegative: true,
    max: Infinity,
    onUserDrivenChange: () => {}
};

Float.propTypes = {
    fieldProps: PropTypes.object,
    fieldAttr: PropTypes.object,
    field: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    falsyValue: PropTypes.any,
    allowNegative: PropTypes.bool,
    max: PropTypes.number,
    onUserDrivenChange: PropTypes.func
};

export default Float;
