import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
    getImage,
    getImageFetchingData,
    getImageUrl
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import defaultImage from 'erpcore/assets/favicon/favicon.ico';

const Favicon = ({ iri, version }) => {
    const dispatch = useDispatch();

    const image = useSelector(state => getImage(state, iri));

    const imageUrl = useSelector(state => getImageUrl(state, iri, version));

    const imageFetchingData = useSelector(state => getImageFetchingData(state));

    const fetchImage = () => {
        return new Promise((resolve, reject) => {
            let fetchNeeded = false;

            if (iri && !imageFetchingData?.[iri]) {
                if (image) {
                    if (image?.iri !== iri) {
                        fetchNeeded = true;
                    }
                } else {
                    fetchNeeded = true;
                }
            }

            if (fetchNeeded) {
                dispatch({
                    promise: { resolve, reject },
                    type: imageManagerActions.START_FETCHING_IMAGE,
                    iri
                });
            }
        });
    };

    useEffect(() => {
        if (iri) {
            fetchImage(iri);
        }
    }, [iri, image]);

    if (imageUrl) {
        return (
            <Helmet>
                <link rel="shortcut icon" href={imageUrl} />
            </Helmet>
        );
    }

    return (
        <Helmet>
            <link rel="shortcut icon" href={defaultImage} />
        </Helmet>
    );
};

Favicon.defaultProps = {
    iri: null,
    version: null
};
Favicon.propTypes = {
    iri: PropTypes.string,
    version: PropTypes.string
};

export default React.memo(Favicon);
