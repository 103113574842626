import { CancelToken } from 'axios';
import restClient from 'erpcore/api/restClient';
import { call, put, select, cancelled, takeEvery, takeLatest } from 'redux-saga/effects';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { takeLatestPerProps } from 'erpcore/components/Listing/Listing.saga';
import { getResourceAllocationsUsers } from './Scheduling.selectors';
import { actions as schedulingAllocationActions } from './Scheduling.reducers';

/**
 *  Fetch resource allocations
 * @param promise
 * @param params
 * @param id
 * @param method
 * @returns Response from API
 */
export function* fetchResourceAllocations({ promise, params, id, method }) {
    try {
        let users = yield select((state) => getResourceAllocationsUsers(state, id));
        let usersDTO = users;

        const fetchResourceAllocationsAPI = yield restClient.get(
            '/api/project-stages/simplified?pagination=false',
            { params }
        );

        if (!users) {
            users = yield restClient.get(
                '/api/users/list?filters[is_active][equals]=true&pagination=false'
            );

            usersDTO = dto(users?.data)?.data;
        }

        const stagesDTO = dto(fetchResourceAllocationsAPI?.data)?.data;

        yield put({
            type:
                method && method === 'append'
                    ? schedulingAllocationActions.FETCHING_SUCCESSFUL_ADDITIONAL_RESOURCE_ALLOCATIONS
                    : schedulingAllocationActions.FETCHING_SUCCESSFUL_RESOURCE_ALLOCATIONS,
            stages: stagesDTO,
            users: usersDTO,
            params,
            id
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: schedulingAllocationActions.FETCHING_FAILED_RESOURCE_ALLOCATIONS,
            id
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *  Fetch Capacity Management data
 * @param promise
 * @param params
 * @param id
 * @param method
 * @returns Response from API
 */
export function* fetchCapacityManagement({ promise, id, params }) {
    const source = CancelToken.source();
    try {
        const requestParams = {
            include: '',
            report: 'utilizationRate',
            start_date: params?.start,
            end_date: params?.end,
            period: params?.period,
            'filters[is_active][equals][]': true,
            'filters[id][equals]': params?.user?.split('/')[3],
            'filters[department][equals]': params?.department,
            page: params?.page
        };

        const users = yield restClient.get(`api/users/list`, {
            params: { ...requestParams, ...params },
            cancelToken: source.token
        });

        const usersDTO = dto(users?.data);

        yield put({
            type: schedulingAllocationActions.FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT,
            users: usersDTO,
            id
        });

        if (promise) {
            yield call(promise.resolve, usersDTO);
        }
    } catch (error) {
        yield put({
            type: schedulingAllocationActions.FETCHING_FAILED_CAPACITY_MANAGEMENT,
            id
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    } finally {
        if (yield cancelled()) {
            source.cancel();
        }
    }
}

/**
 *  Fetch Capacity Management data from generic reports
 * @param promise
 * @param params
 * @param id
 * @param method
 * @returns Response from API
 */
export function* fetchCapacityManagementGR({ promise, id, params }) {
    const source = CancelToken.source();
    try {
        const usersParams = {
            include: '',
            start_date: params?.start,
            end_date: params?.end,
            period: params?.period,
            'filters[is_active][equals][]': true,
            'filters[id][equals]': params?.user?.split('/')[3],
            'filters[department][equals]': params?.department,
            page: params?.page
        };

        const users = yield restClient.get(`api/users/list`, {
            params: usersParams,
            cancelToken: source.token
        });

        const usersDTO = dto(users?.data);

        const capacityParams = {
            'filters[date][between]': [params?.start, params?.end],
            groups: 'simple_list,media_object_read',
            preparedQuery: `capacity,${params?.period}`,
            'filters[user][in]': usersDTO?.data?.map((user) => user.iri)
        };
        const capacity = yield restClient.get(`api/generic-reports/capacity`, {
            params: capacityParams,
            headers: { Accept: 'application/json' },
            cancelToken: source.token
        });

        usersDTO.data.forEach((user, index) => {
            usersDTO.data[index].capacity =
                capacity?.data?.data?.filter(
                    (capacityFilter) => capacityFilter.user === user.iri
                )[0]?.data || null;
        });

        yield put({
            type: schedulingAllocationActions.FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT,
            users: usersDTO,
            id
        });

        if (promise) yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: schedulingAllocationActions.FETCHING_FAILED_CAPACITY_MANAGEMENT,
            id
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) yield call(promise.reject, error?.response?.data || error);
    } finally {
        if (yield cancelled()) {
            source.cancel();
        }
    }
}

/**
 *  Fetch Capacity Management data
 * @param promise
 * @param params
 * @param id
 * @param method
 * @returns Response from API
 */
export function* fetchCapacityManagementSimulation({ promise, id, params }) {
    const source = CancelToken.source();

    try {
        const requestParams = {
            report: 'utilizationRate',
            start_date: params?.start,
            end_date: params?.end,
            period: params?.period,
            'filters[is_active][equals][]': true
        };

        const users = yield restClient.get(`api/users/list`, {
            params: { ...requestParams, ...params },
            cancelToken: source.token
        });

        const usersDTO = dto(users?.data);
        yield put({
            type: schedulingAllocationActions.FETCHING_SUCCESSFUL_CAPACITY_MANAGEMENT_SIMULATION,
            id
        });

        if (promise) yield call(promise.resolve, usersDTO);
    } catch (error) {
        yield put({
            type: schedulingAllocationActions.FETCHING_FAILED_CAPACITY_MANAGEMENT_SIMULATION,
            id
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });

        if (promise) yield call(promise.reject, error?.response?.data || error);
    } finally {
        if (yield cancelled()) {
            source.cancel();
        }
    }
}

/**
 * Register action to watcher
 */
const schedulingAllocationsSaga = [
    takeEvery(
        schedulingAllocationActions.START_FETCHING_RESOURCE_ALLOCATIONS,
        fetchResourceAllocations
    ),
    takeLatestPerProps(
        'id',
        schedulingAllocationActions.START_FETCHING_CAPACITY_MANAGEMENT,
        fetchCapacityManagement
    ),
    takeLatestPerProps(
        'id',
        schedulingAllocationActions.START_FETCHING_CAPACITY_MANAGEMENT_GR,
        fetchCapacityManagementGR
    ),
    takeLatest(
        schedulingAllocationActions.START_FETCHING_CAPACITY_MANAGEMENT_SIMULATION,
        fetchCapacityManagementSimulation
    )
];

export default schedulingAllocationsSaga;
