export default {
    clearIndicator: () => ({}),
    container: () => ({}),
    control: () => ({}),
    dropdownIndicator: () => ({}),
    group: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    input: () => ({}),
    loadingMessage: () => ({}),
    menu: () => ({}),
    menuPortal: () => ({}),
    singleValue: (base, state) => ({
        ...(state?.data?.isDisabled ? { color: '#bd4c4c', fontSize: '0.9em' } : null)
    }),
    multiValueLabel: (base, state) => {
        return {
            ...(state?.data?.isDisabled ? { color: '#f5dbdb', fontSize: '0.9em' } : null)
        };
    },
    multiValue: () => ({}),
    multiValueRemove: (base, state) => {
        return state.data.fixed ? { display: 'none' } : {};
    },
    noOptionsMessage: () => ({}),
    option: (styles, { data }) => {
        return {
            ...data
        };
    },
    placeholder: () => ({}),
    valueContainer: () => ({})
};
