export const actions = {
    START_FORGOT_PASSWORD: 'START_FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESSFUL: 'FORGOT_PASSWORD_SUCCESSFUL',
    FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',
    START_RESEND_EMAIL_RESET_PASSWORD: 'START_RESEND_EMAIL_RESET_PASSWORD',
    RESEND_EMAIL_RESET_PASSWORD_SUCCESSFUL: 'RESEND_EMAIL_RESET_PASSWORD_SUCCESSFUL',
    RESEND_EMAIL_RESET_PASSWORD_FAILED: 'RESEND_EMAIL_RESET_PASSWORD_FAILED',
    START_RESET_PASSWORD: 'START_RESET_PASSWORD',
    RESET_PASSWORD_SUCCESSFUL: 'RESET_PASSWORD_SUCCESSFUL',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED'
};

export const initialState = {
    error: null,
    email: ''
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.FORGOT_PASSWORD_SUCCESSFUL: {
            return {
                ...state,
                email: response.email
            };
        }
        case actions.FORGOT_PASSWORD_FAILED: {
            return {
                ...state,
                error: response
            };
        }
        default:
            return state;
    }
};
