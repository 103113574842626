import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import LayoutManager from 'erpcore/utils/LayoutManager';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

import './Topbar.scss';

const Topbar = ({ layout }) => {
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    if (!hasPermissionsAccessForEntities.timeLogs && !hasPermissionsAccessForEntities.props) {
        return null;
    }

    return (
        <div className="topbar">
            <Helmet
                bodyAttributes={{
                    'has-top-bar': true
                }}
            />
            <LayoutManager layout={layout} />
        </div>
    );
};

Topbar.defaultProps = {
    layout: null
};

Topbar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object])
};

export default Topbar;
