import React, { useEffect, useRef, useState } from 'react';
import Button from 'erpcore/components/Button';
import PropTypes from 'prop-types';
import colors from 'erpcore/assets/scss/exports/colors.module.scss';
import styles from './SpecifiedColorPicker.module.scss';

const SpecifiedColorPicker = ({ fieldProps, input }) => {
    const colorPickerRef = useRef(null);
    const [colorPickerOpened, setColorPickerOpened] = useState(false);
    const colorPickerButtonClassName = styles?.['specified-color-picker__open-button'];

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                colorPickerRef.current &&
                !colorPickerRef.current.contains(event.target) &&
                !event.target.classList.value.includes(colorPickerButtonClassName)
            ) {
                setColorPickerOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [colorPickerButtonClassName]);

    return (
        <div className={styles?.['specified-color-picker']}>
            <Button
                className={colorPickerButtonClassName}
                size="small"
                label={
                    <span
                        className={styles?.['specified-color-picker__open-button-bg']}
                        style={{
                            backgroundColor: input?.value?.length > 0 ? input?.value : colors.bordo
                        }}
                    />
                }
                iconName="arrowDown"
                iconColor="mid-grey"
                onClick={() => setColorPickerOpened(value => !value)}
            />

            {colorPickerOpened && (
                <div ref={colorPickerRef} className={styles?.['specified-color-picker__dropdown']}>
                    {fieldProps?.colors?.map(color => {
                        return (
                            <Button
                                key={color}
                                label={
                                    <span
                                        className={styles?.['specified-color-picker__dropdown-bg']}
                                        style={{ backgroundColor: color }}
                                    />
                                }
                                onClick={() => {
                                    setColorPickerOpened(false);
                                    input.onChange(color);
                                }}
                                className={styles?.['specified-color-picker__dropdown-color']}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

SpecifiedColorPicker.defaultProps = {
    input: {},
    fieldProps: {}
};

SpecifiedColorPicker.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object])
};

export default SpecifiedColorPicker;
