export const actions = {
    START_FETCHING_SCHEDULED_COMMANDS: 'START_FETCHING_SCHEDULED_COMMANDS',
    FETCH_SCHEDULED_COMMANDS_SUCCESSFUL: 'FETCH_SCHEDULED_COMMANDS_SUCCESSFUL',
    FETCH_SCHEDULED_COMMANDS_FAILED: 'FETCH_SCHEDULED_COMMANDS_FAILED',
    STORE_SCHEDULED_COMMANDS_DATA: 'STORE_SCHEDULED_COMMANDS_DATA',

    START_UPDATING_SCHEDULED_COMMANDS: 'START_UPDATING_SCHEDULED_COMMANDS',
    UPDATE_SCHEDULED_COMMAND_SUCCESSFUL: 'UPDATE_SCHEDULED_COMMAND_SUCCESSFUL',
    UPDATE_SCHEDULED_COMMAND_FAILED: 'UPDATE_SCHEDULED_COMMAND_FAILED',

    START_CREATE_SCHEDULED_COMMANDS: 'START_CREATE_SCHEDULED_COMMANDS',
    CREATE_SCHEDULED_COMMAND_SUCCESSFUL: 'CREATE_SCHEDULED_COMMAND_SUCCESSFUL',
    CREATE_SCHEDULED_COMMAND_FAILED: 'CREATE_SCHEDULED_COMMAND_FAILED'
};

export const initialState = {
    data: {},
    fetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_SCHEDULED_COMMANDS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_SCHEDULED_COMMANDS_SUCCESSFUL: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCH_SCHEDULED_COMMANDS_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.STORE_SCHEDULED_COMMANDS_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [response && response?.name]: {
                        ...response
                    }
                }
            };
        }
        default:
            return state;
    }
};
