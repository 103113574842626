import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Signature } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ProjectApprovalSignatureForm = ({
    initialValues,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    modalBtn
}) => {
    const hasSignature = initialValues?.signature?.iri;
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    id="signature"
                    name="signature"
                    fieldProps={{
                        label: 'Signature',
                        clearable: true
                    }}
                    component={Signature}
                    fieldAttr={{ required: !hasSignature, disabled: hasSignature }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {!hasSignature && (
                <Form.Row>
                    <div>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            label="Approve"
                        />
                        {modalBtn && <>{modalBtn}</>}
                    </div>
                </Form.Row>
            )}
        </Form>
    );
};

ProjectApprovalSignatureForm.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    modalBtn: null
};

ProjectApprovalSignatureForm.propTypes = {
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    modalBtn: PropTypes.element
};

export default reduxForm({
    form: 'ProjectApprovalSignatureForm',
    enableReinitialize: true
})(ProjectApprovalSignatureForm);
