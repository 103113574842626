import React, { useState } from 'react';
import map from 'lodash/map';
import { useSelector, useDispatch } from 'react-redux';
import { generateKey } from 'erpcore/utils/utils';
import { Field, reduxForm, reset, getFormValues, change } from 'redux-form';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcore/components/Button';
import Form, { Select } from 'erpcore/components/Form';
import { stringToFormFieldComponent } from 'erpcore/utils/FormGenerator';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import './Filter.scss';

const Filter = ({ form, pristine, invalid, submitting, handleSubmit, onSubmit, filterSchema }) => {
    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));
    const [opened, setOpened] = useState(false);

    const resetFields = () => {
        dispatch(reset(form));
    };

    const renderFilterAdditional = field => {
        //  Replacing . with > so redux form can create field without nesting it
        //  ( this will store location.city field like location>city in redux form )
        const selectedFieldName = field.replace(/\./g, '>');
        const filterAttr = filterSchema.find(
            filterItem => filterItem.name.replace(/\./g, '>') === selectedFieldName
        );
        const { filterFields } = filterAttr;

        //  Append default operator to filter
        if (
            filterAttr?.defaultOperator &&
            formValues?.filter &&
            formValues?.filter[selectedFieldName][0] &&
            !formValues?.filter[selectedFieldName][0].operator
        ) {
            dispatch(
                change(form, `filter.${selectedFieldName}[0].operator`, filterAttr.defaultOperator)
            );
        }

        return map(filterFields, (filterField, key) => {
            if (filterField.component) {
                const { component, fieldValidation, ...rest } = {
                    ...filterField
                };

                return (
                    <Form.Row key={generateKey(component + key)}>
                        <Field
                            name={`filter.${selectedFieldName}[0].${key}`}
                            field={filterField}
                            component={stringToFormFieldComponent[component]}
                            validate={valueValidation(fieldValidation)}
                            {...rest}
                        />
                    </Form.Row>
                );
            }

            return null;
        });
    };

    // Function called on Add Filter button click
    const onAddFilter = () => {
        // check if Filter defaultValue is set
        // dispatch value
        const selectedFieldName = formValues?.filterBy.replace(/\./g, '>');
        const filterAttr = filterSchema.find(
            filterItem => filterItem.name.replace(/\./g, '>') === selectedFieldName
        );
        const { filterFields } = filterAttr;

        if (filterFields.defaultValue) {
            dispatch(
                change(form, `filter.${selectedFieldName}.[0].value`, filterFields.defaultValue)
            );
        }

        return setOpened(false);
    };

    const renderForm = field => {
        const filterKeys = [];
        filterSchema.forEach(item => {
            filterKeys.push({ label: item.label, value: item.name });
        });

        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        id="filterBy"
                        name="filterBy"
                        fieldProps={{
                            isSearchable: false,
                            isClearable: false,
                            options: filterKeys,
                            label: 'Filter'
                        }}
                        component={Select}
                        onChange={() => {
                            resetFields();
                        }}
                    />
                </Form.Row>
                {field && renderFilterAdditional(field)}
                {field && (
                    <Form.Row>
                        <Button
                            submit
                            disabled={pristine || invalid}
                            loading={submitting}
                            onClick={onAddFilter}
                            label="Add filter"
                        />
                    </Form.Row>
                )}
            </Form>
        );
    };

    return (
        <div className={`filter ${opened ? 'filter--opened' : ''}`}>
            <button
                type="button"
                aria-pressed={opened}
                className="filter__btn"
                onClick={() => setOpened(!opened)}
                onKeyPress={() => setOpened(!opened)}
            >
                <Svg icon="filter" className="filter__btn-icon" />
                <span className="filter__btn-label">Filter</span>
            </button>
            <div className="filter__dropdown">
                <div className="filter__dropdown-header">
                    <div className="filter__dropdown-header-col">Show all results where:</div>
                    <div className="filter__dropdown-header-col">
                        <button
                            type="button"
                            aria-pressed={!opened}
                            className="filter__dropdown-close"
                            onClick={() => setOpened(!opened)}
                            onKeyPress={() => setOpened(!opened)}
                        >
                            <Svg icon="close" />
                        </button>
                    </div>
                </div>
                {renderForm(formValues?.filterBy)}
            </div>
        </div>
    );
};

Filter.defaultProps = {
    filterSchema: [],
    submitting: false,
    pristine: false,
    invalid: false,
    handleSubmit: () => {},
    onSubmit: () => {},
    form: ''
};

Filter.propTypes = {
    filterSchema: PropTypes.oneOfType([PropTypes.array]),
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    form: PropTypes.string
};

export default reduxForm({
    enableReinitialize: false
})(Filter);
