import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as leadStatusesActions } from 'erpcore/screens/Settings/LeadStatuses/LeadStatuses.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create LeadStatus
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createLeadStatus({ promise, formData }) {
    try {
        const createLeadStatusAPI = yield restClient.post('api/project-lead-statuses', formData);
        yield put({
            type: leadStatusesActions.CREATE_LEAD_STATUS_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createLeadStatusAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: leadStatusesActions.CREATE_LEAD_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch LeadStatus
 * @param  {Object} promise
 * @return {string} id LeadStatus id
 */
export function* fetchLeadStatus({ promise, iri }) {
    try {
        const fetchLeadStatusAPI = yield restClient.get(iri, {
            params: { include: 'permissions' }
        });
        yield put({
            type: leadStatusesActions.FETCHING_LEAD_STATUS_SUCCESSFUL
        });

        yield put({
            type: leadStatusesActions.STORE_LEAD_STATUS_DATA,
            iri,
            response: dto(fetchLeadStatusAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: leadStatusesActions.FETCHING_LEAD_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update LeadStatus data
 * @param  {Object} id id of LeadStatus
 * @return {Object} Response from API
 */
export function* updateLeadStatus({ promise, iri, formData }) {
    try {
        const updateLeadStatusAPI = yield restClient.patch(`${iri}`, formData);
        yield put({
            type: leadStatusesActions.UPDATE_LEAD_STATUS_SUCCESSFUL
        });
        yield put({
            type: leadStatusesActions.STORE_LEAD_STATUS_DATA,
            iri,
            response: dto(updateLeadStatusAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (updateLeadStatusAPI?.data?.code && updateLeadStatusAPI?.data) || {
                code: 'leadStatus.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: leadStatusesActions.UPDATE_LEAD_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete LeadStatus
 * @param  {Object} leadStatusIri iri of LeadStatus
 * @return {Object} Response from API
 */
export function* deleteLeadStatus({ promise, leadStatusIri }) {
    try {
        const deleteLeadStatusAPI = yield restClient.delete(leadStatusIri);
        yield put({
            type: leadStatusesActions.DELETE_LEAD_STATUS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (deleteLeadStatusAPI?.data?.code && deleteLeadStatusAPI?.data) || {
                code: 'leadStatus.itemSuccessfullyRemoved'
            }
        });

        const params = yield select(getQueryParams, { name: 'leadStatuses' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'LEAD_STATUSES',
            name: 'leadStatuses',
            endpoint: 'api/project-lead-statuses'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: leadStatusesActions.DELETE_LEAD_STATUS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const leadStatusesSaga = [
    takeLatest(leadStatusesActions.START_CREATE_LEAD_STATUS, createLeadStatus),
    takeLatest(leadStatusesActions.START_FETCHING_LEAD_STATUS, fetchLeadStatus),
    takeLatest(leadStatusesActions.START_UPDATE_LEAD_STATUS, updateLeadStatus),
    takeLatest(leadStatusesActions.START_DELETE_LEAD_STATUS, deleteLeadStatus)
];

export default leadStatusesSaga;
