export const actions = {
    START_FETCHING_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT:
        'START_FETCHING_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT',
    FETCHING_SUCCESSFUL_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT:
        'FETCHING_SUCCESSFUL_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT',
    FETCHING_FAILED_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT:
        'FETCHING_FAILED_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT'
};

export default (state = {}, { type }) => {
    switch (type) {
        default:
            return state;
    }
};
