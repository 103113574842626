import { takeLatest, put, fork, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';

/**
 * Custom effect creator
 *
 * @param propsOrSelector
 * @param pattern
 * @param worker
 * @param args
 * @return {ForkEffect}
 */
function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

// eslint-disable-next-line import/prefer-default-export
export { takeLatestPerProps };
