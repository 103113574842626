import { put, call, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

import { actions as projectsStatusActions } from 'erpcore/screens/Dashboard/components/ProjectsStatusWidget/ProjectsStatusWidget.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

export function* fetchProjectStatusWidget({ promise, params, endpoint }) {
    try {
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type: projectsStatusActions.FETCHING_SUCCESSFUL_PROJECTS_STATUS_WIDGET,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve, dto(fetchListingAPI?.data));
        }
    } catch (error) {
        yield put({
            type: projectsStatusActions.FETCHING_FAILED_PROJECTS_STATUS_WIDGET,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Register action to watcher
 */
export const projectStatusWidgetSaga = [
    takeEvery(
        projectsStatusActions.START_FETCHING_PROJECTS_STATUS_WIDGET,
        fetchProjectStatusWidget
    )
];
