import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from '../Input';

const CurrencyRange = ({ input, fieldAttr, fieldProps, field, meta }) => {
    const { value, onChange } = { ...input };

    const handleValueChange = values => {
        const min = Array.isArray(value) ? value?.[0] || 0 : value?.min || 0;
        const max = Array.isArray(value) ? value?.[1] || 0 : value?.max || 0;

        // multiply by 100, expect weird decimals (because js binary), convert to string integer, convert to int
        const newValue =
            values?.[values?.min ? 'min' : 'max'] || values?.[values?.min ? 'min' : 'max'] === 0
                ? // multiplying by 100 (extra code is for weird JS decimals ie. 2500.01 * 100 -> 250001.00000000003)
                  parseInt((values?.[values?.min ? 'min' : 'max'] * 100).toFixed(), 10)
                : null;

        if (fieldProps?.arraySupport) {
            const formArray = [];

            formArray.push(values?.min ? newValue : min);
            formArray.push(values?.max ? newValue : max);

            onChange(formArray);
        } else {
            const formObject = {};

            formObject.min = values?.min ? newValue : min;
            formObject.max = values?.max ? newValue : max;

            onChange(formObject);
        }
    };

    return (
        <>
            <Input
                fieldProps={{ ...fieldProps, label: 'Min' }}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <NumberFormat
                    name="min"
                    {...fieldAttr}
                    className="input__field"
                    allowEmptyFormatting={false}
                    value={
                        Array.isArray(value) ? value?.[0] / 100 || null : value?.min / 100 || null
                    }
                    suffix={fieldAttr?.suffix}
                    prefix={fieldAttr?.prefix}
                    onValueChange={values => handleValueChange({ min: values?.floatValue })}
                    isNumericString={false}
                    thousandSeparator
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                />
            </Input>

            <Input
                fieldProps={{ ...fieldProps, label: 'Max' }}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <NumberFormat
                    name="max"
                    {...fieldAttr}
                    className="input__field"
                    allowEmptyFormatting={false}
                    value={
                        Array.isArray(value) ? value?.[1] / 100 || null : value?.max / 100 || null
                    }
                    suffix={fieldAttr?.suffix}
                    prefix={fieldAttr?.prefix}
                    onValueChange={values => handleValueChange({ max: values?.floatValue })}
                    isNumericString={false}
                    thousandSeparator
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                />
            </Input>
        </>
    );
};

CurrencyRange.defaultProps = {
    value: '',
    prefix: '', // do not set this to null
    suffix: '', // do not set this to null
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

CurrencyRange.propTypes = {
    value: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default CurrencyRange;
