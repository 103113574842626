import React, { useCallback, useEffect, useState } from 'react';
import TimeTrackingCreateForm from 'erpcore/components/TimeTracking/components/TimeTrackingCreateForm';
import DraftTimeLogs from 'erpcore/components/TimeTracking/components/DraftTimeLogs';
import SubmittedTimelogs from 'erpcore/components/TimeTracking/components/SubmittedTimelogs';
import { useDispatch } from 'react-redux';
import { actions as timeTrackingActions } from 'erpcore/components/TimeTracking/TimeTracking.reducer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import styles from './TimeTracking.module.scss';

const formName = 'TimeTrackingCreateForm';

const TimeTracking = ({ preventFetching, onTimelogSubmitCallback, className }) => {
    const dispatch = useDispatch();
    const [ref, setRef] = useState(null);
    const [width, setWidth] = useState(null);

    const onSubmit = useCallback((formData) => {
        formData = {
            ...formData,
            status: 'paused',
            timeSpent: 0,
            date: moment().format('YYYY-MM-DD')
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: timeTrackingActions.START_CREATE_DRAFT_TIMELOG,
                formData,
                promise: { resolve, reject }
            });
        })
            .then(() => {
                dispatch(reset(formName));
            })
            .catch((error) => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: error?.response?.data || error
                });
            });
    }, []);

    const resizeObserver = new ResizeObserver((entries) => {
        if ('requestAnimationFrame' in window) {
            // limit execution to a single frame
            window.requestAnimationFrame(() => {
                setWidth(entries?.[0]?.contentRect?.width);
            });
        } else {
            setWidth(entries?.[0]?.contentRect?.width);
        }
    });

    useEffect(() => {
        if (ref) resizeObserver.observe(ref);
    }, [ref]);

    return (
        <div
            ref={(reference) => reference && setRef(reference)}
            className={`${className || ''} ${styles['time-tracking']} ${
                width < 1250 ? styles['time-tracking--tablet'] : ''
            } ${width < 1000 ? styles['time-tracking--tablet-small'] : ''} ${
                width < 850 ? styles['time-tracking--mobile'] : ''
            }  ${width < 500 ? styles['time-tracking--small'] : ''}`}
        >
                    <TimeTrackingCreateForm form={formName} onSubmit={onSubmit} width={width} />
                    <DraftTimeLogs
                        onTimelogSubmitCallback={onTimelogSubmitCallback}
                        preventFetching={preventFetching}
                    />
                    <SubmittedTimelogs
                        onTimelogSubmitCallback={onTimelogSubmitCallback}
                        preventFetching={preventFetching}
                    />
        </div>
    );
};

TimeTracking.defaultProps = {
    preventFetching: false,
    onTimelogSubmitCallback: () => {},
    className: null
};

TimeTracking.propTypes = {
    preventFetching: PropTypes.bool,
    onTimelogSubmitCallback: PropTypes.func,
    className: PropTypes.string
};

export default TimeTracking;
