export const getFetchingMe = (state) => state.authManager?.fetchingMe;

export const getSignedIn = (state) => state.authManager.isSignedIn;

export const getMeData = (state) => state?.authManager?.user || {};

export const getPersonalDashboard = (state) =>
    state?.authManager?.user?.meta?.personal_dashboard || null;

export const isUserClient = (state) =>
    !!state?.authManager?.user?.userTypes?.find(
        (userType) => userType?.attribute === 'CAN_USER_TYPE_CLIENT'
    );

export const getUserClientData = (state) => state?.authManager?.userClientData || {};

export const getUserColumnSettings = (state, settingsFor) =>
    state.authManager?.user?.meta?.[settingsFor] || null;

export const getUserPermissions = (state) => {
    if (
        state &&
        state.authManager &&
        state.authManager.user &&
        state.authManager.user.meta &&
        state.authManager.user.meta.permissions &&
        state.authManager.user.meta.permissions.constructor === Object
    ) {
        return state.authManager.user.meta.permissions;
    }
    return {
        events: false,
        vendors: false,
        eventsPerPermission: {}
    };
};

export const getUserCurrentOrganization = (state) => state?.authManager?.user?.userOrganization;

export const getUserRole = (state) =>
    state.authManager.isSignedIn === true
        ? state.authManager.user && state.authManager.user.role
        : '';

export const getIsImpersonateActive = (state) => !!state?.authManager?.impersonateUserId;

export const getSavedFiltersForListing = (state, listingName) => {
    const activeListing = Object.keys(state?.authManager?.savedListingFilter || {}).find((item) => {
        return state?.authManager?.savedListingFilter?.[item]?.listing === listingName;
    });

    return activeListing ? state.authManager?.savedListingFilter?.[activeListing] : {};
};
