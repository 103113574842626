import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Receiver } from 'react-file-uploader';
import Svg from 'erpcore/components/Svg';
import './ImageUpload.scss';
import Input from '../Input';

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploads: [],
            initialized: false
        };

        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.onImageDrop = this.onImageDrop.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialized) {
            return {
                uploads: props.meta.initial || [],
                initialized: true
            };
        }
        return {};
    }

    onRemoveImage(index) {
        const { uploads } = this.state;
        const { input } = this.props;
        uploads.splice(index, 1);
        this.setState({ uploads });
        input.onChange(uploads);
    }

    onImageDrop = (event, files, inputField) => {
        let preparedFiles = [];
        if (inputField) {
            preparedFiles.push({ data: files[0] });
        } else {
            preparedFiles = files;
        }
        const { uploads } = this.state;
        const { input } = this.props;
        const tempUploads = uploads;
        preparedFiles.map(upload => {
            if (upload.data.size > 1000 * 1000) {
                return Object.assign({}, upload, {
                    error: 'file size exceeded 1MB'
                });
            }
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                tempUploads.push({ meta: upload.data, raw: fileReader.result });
                this.setState({
                    uploads: tempUploads
                });
                input.onChange(tempUploads);
            };
            return fileReader.readAsDataURL(upload.data);
        });
    };

    render() {
        const { input, meta, fieldProps, fieldAttr, field } = this.props;
        const { uploads } = this.state;
        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
                forceLabelActive
            >
                <div className="image-upload">
                    <Receiver
                        isOpen
                        onDragEnter={() => {}}
                        onDragOver={() => {}}
                        onDragLeave={() => {}}
                        onFileDrop={this.onImageDrop}
                    >
                        <div className="image-upload__drop-area">
                            <label className="image-upload__drop-area-button">
                                <input
                                    type="file"
                                    onChange={event =>
                                        this.onImageDrop(event, event.target.files, true)
                                    }
                                />
                            </label>
                            <span>Browse Images or Drag & Drop</span>
                        </div>
                    </Receiver>
                    {uploads.map((upload, index) => (
                        <div
                            className="image-upload__preview"
                            style={{ backgroundImage: `url(${upload.raw})` }}
                        >
                            <div className="image-upload__preview-overlay">
                                <button
                                    type="button"
                                    className="image-upload__preview-overlay-action"
                                    onClick={() => this.onRemoveImage(index)}
                                >
                                    <Svg icon="remove" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </Input>
        );
    }
}

ImageUpload.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
ImageUpload.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default ImageUpload;
