import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    }, []);

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
        };
    }, []);

    return windowWidth;
};

const placeholder = null;

const useDropdown = () => {
    const [dropdownOpenStatus, setDropdownOpenStatus] = useState({});
    const dropdownOpenStatusRef = useRef({});
    const [classNameSelectors, setClassNameSelector] = useState(null);

    const toggleDropdown = useCallback(
        (dropdownKey) => {
            setDropdownOpenStatus({
                [dropdownKey]: !dropdownOpenStatus[dropdownKey]
            });
        },
        [dropdownOpenStatus]
    );

    /**
     * Used to close dropdowns on outside click
     * @param e {Object} Click event
     * @returns void
     */
    const handleClick = useCallback(
        (e) => {
            if (e?.path?.length && classNameSelectors) {
                const clickTargetIsDropdown = Array.from(e?.path).some((DOMElement) => {
                    return !!classNameSelectors.some((className) =>
                        DOMElement?.classList?.contains(className)
                    );
                });

                if (!clickTargetIsDropdown) {
                    setDropdownOpenStatus({});
                }
            }
        },
        [classNameSelectors]
    );

    const handleKeyUp = useCallback((e) => {
        if (e?.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();

            setDropdownOpenStatus({});
        }
    }, []);

    useEffect(() => {
        // Used to close dropdown on outside click
        if (Object.keys(dropdownOpenStatus)?.length) {
            window.addEventListener('click', handleClick);
            window.addEventListener('keypress', handleKeyUp);
        } else {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keypress', handleKeyUp);
        }

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keypress', handleKeyUp);
        };
    }, [dropdownOpenStatus]);

    useEffect(() => {
        dropdownOpenStatusRef.current = dropdownOpenStatus;
    }, [dropdownOpenStatus]);

    return {
        toggleDropdown,
        dropdownOpenStatus,
        setDropdownOpenStatus,
        setClassNameSelector
    };
};

// Use "MM/DD/YYYY" format for start and end dates
function useWorkingDays({ startDate, endDate }) {
    const [dateRange, setDateRange] = useState({ start: startDate, end: endDate });
    const [isFetchInProgress, setIsFetchInProgress] = useState(false);
    const [isError, setIsError] = useState(false);
    const controller = useRef(new AbortController());
    const [workingDays, setWorkingDays] = useState(0);

    const dispatch = useDispatch();

    const cancelApiCall = () => {
        // If fetch is not in progress, do nothing
        if (!isFetchInProgress) {
            return;
        }

        // If fetch is in progress, abort the current request
        controller.current.abort();
    };

    const setController = (newController) => {
        /* If we are setting new controller, 
        it means that start and end dates have changed 
        and we have to cancel the previous API call */
        cancelApiCall();

        controller.current = newController;
    };

    useEffect(() => {
        if (!startDate || !endDate) {
            cancelApiCall();
            return undefined;
        }

        // Do not make another API call if both the start date are the same
        if (dateRange.start === startDate && dateRange.end === endDate) {
            return undefined;
        }

        const fetchWorkingDays = async () => {
            setController(new AbortController());
            setIsError(false);
            setIsFetchInProgress(true);

            try {
                const response = await restClient.get('/api/organization/working-days', {
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    },
                    signal: controller.current.signal
                });

                setWorkingDays(response?.data?.workingDays);
            } catch (error) {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: error.response.data
                });

                setWorkingDays(0);
                setIsError(true);
            } finally {
                setIsFetchInProgress(false);
                setDateRange({ start: startDate, end: endDate });
            }
        };

        fetchWorkingDays();

        return () => cancelApiCall();
    }, [startDate, endDate]);

    return [workingDays, isFetchInProgress, isError];
}

export const useDragScroll = () => {
    const [node, setNode] = useState(null);

    const ref = useCallback((nodeEle) => {
        setNode(nodeEle);
    }, []);

    const handleMouseDown = useCallback(
        (e) => {
            if (!node) {
                return;
            }
            const startPos = {
                left: node.scrollLeft,
                top: node.scrollTop,
                x: e.clientX,
                y: e.clientY
            };

            const handleMouseMove = (event) => {
                const dx = event.clientX - startPos.x;
                const dy = event.clientY - startPos.y;
                node.scrollTop = startPos.top - dy;
                node.scrollLeft = startPos.left - dx;
            };

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        },
        [node]
    );

    const handleTouchStart = useCallback(
        (e) => {
            if (!node) {
                return;
            }
            const touch = e.touches[0];
            const startPos = {
                left: node.scrollLeft,
                top: node.scrollTop,
                x: touch.clientX,
                y: touch.clientY
            };

            const handleTouchMove = (event) => {
                const touchEvent = event.touches[0];
                const dx = touchEvent.clientX - startPos.x;
                const dy = touchEvent.clientY - startPos.y;
                node.scrollTop = startPos.top - dy;
                node.scrollLeft = startPos.left - dx;
            };

            const handleTouchEnd = () => {
                document.removeEventListener('touchmove', handleTouchMove);
                document.removeEventListener('touchend', handleTouchEnd);
            };

            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
        },
        [node]
    );

    useEffect(() => {
        if (!node) {
            return;
        }
        node.addEventListener('mousedown', handleMouseDown);
        node.addEventListener('touchstart', handleTouchStart);
        // eslint-disable-next-line consistent-return
        return () => {
            node.removeEventListener('mousedown', handleMouseDown);
            node.removeEventListener('touchstart', handleTouchStart);
        };
    }, [node]);

    return [ref, node];
};

export { useWindowWidth, placeholder, useDropdown, useWorkingDays };
