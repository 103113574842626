import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const Textarea = ({ input, meta, fieldProps, fieldAttr, field }) => (
    <Input fieldProps={fieldProps} fieldAttr={fieldAttr} field={field} input={input} meta={meta}>
        <textarea
            id={input.name}
            {...input}
            {...fieldAttr}
            aria-label={`${input.name}`}
            type="text"
            className="input__field input__field--textarea"
        />
    </Input>
);

Textarea.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Textarea.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Textarea;
