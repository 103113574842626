export const actions = {
    START_FETCHING_PROJECTS_STATUS_WIDGET:
        'START_FETCHING_PROJECTS_STATUS_WIDGET',
    FETCHING_SUCCESSFUL_PROJECTS_STATUS_WIDGET:
        'FETCHING_SUCCESSFUL_PROJECTS_STATUS_WIDGET',
    FETCHING_FAILED_PROJECTS_STATUS_WIDGET:
        'FETCHING_FAILED_PROJECTS_STATUS_WIDGET'
};

export const initialState = {};
export default (state = initialState, { type }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECTS_STATUS_WIDGET: {
            return state;
        }
        case actions.FETCHING_SUCCESSFUL_PROJECTS_STATUS_WIDGET: {
            return state;
        }
        case actions.FETCHING_FAILED_PROJECTS_STATUS_WIDGET: {
            return state;
        }
        default:
            return state;
    }
};
