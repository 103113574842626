import moment from 'moment-timezone';
import mapValues from 'lodash/mapValues';

const dateRangePresetMap = {
    last7Days: [moment().subtract(7, 'days'), moment()],
    last30Days: [moment().subtract(30, 'days'), moment()],
    last365Days: [moment().subtract(365, 'days'), moment()],
    currentWeek: [moment().startOf('week'), moment().endOf('week')],
    currentMonth: [moment().startOf('month'), moment().endOf('month')],
    currentYear: [moment().startOf('year'), moment().endOf('year')],
    startOfWeek: [moment().startOf('week'), moment()],
    startOfMonth: [moment().startOf('month'), moment()],
    startOfYear: [moment().startOf('year'), moment()],
    endOfWeek: [moment(), moment().endOf('week')],
    endOfMonth: [moment(), moment().endOf('month')],
    endOfYear: [moment(), moment().endOf('year')],
    previousWeek: [
        moment().subtract(1, 'weeks').startOf('week'),
        moment().subtract(1, 'weeks').endOf('week')
    ],
    previousMonth: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month')
    ],
    previousYear: [
        moment().subtract(1, 'years').startOf('year'),
        moment().subtract(1, 'years').endOf('year')
    ],
    allTime: [moment().subtract(100, 'years').startOf('year'), moment()]
};

const getFormattedDateRangePresetMap = (dateFormat =  'YYYY-M-D') => {
    return mapValues(dateRangePresetMap, item => {
        return [item[0].format(dateFormat), item[1].format(dateFormat)]
    })
};

export { dateRangePresetMap, getFormattedDateRangePresetMap };
