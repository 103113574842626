import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';

import ResetPasswordForm from 'erpcore/screens/ForgotPassword/components/ResetPasswordForm';

import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ResetPassword = () => {
    const routeParams = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();

    const isLoginInProcess = location.pathname === '/reset-password-login';

    const title = 'Reset Password';

    const signIn = (token) => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN_WITH_TOKEN,
                token
            })
        );
    };

    const onSubmitResetPasswordForm = (formData) => {
        formData = Object.assign({}, formData, { reset_token: routeParams.token });

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_RESET_PASSWORD,
                formData
            })
        )
            .then((response) => {
                navigate('/reset-password-login');
                signIn(response?.token)
                    .then(() => {
                        navigate('/');
                    })
                    .catch((error) => {
                        return error;
                    });
            })
            .catch((error) => {
                return error;
            });
    };

    return (
        <>
            <HeadMeta title={title} />
            <h2 className="front-main__title">Setup your new password</h2>

            <PageNotifications />

            {!isLoginInProcess && <ResetPasswordForm onSubmit={onSubmitResetPasswordForm} />}

            {!!isLoginInProcess && <PageLoader />}
        </>
    );
};

ResetPassword.defaultProps = {};

ResetPassword.propTypes = {};

export default ResetPassword;
