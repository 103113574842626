export const actions = {
    START_FETCHING_TIMELOGS: 'START_FETCHING_TIMELOGS',
    FETCHING_SUCCESSFUL_TIMELOGS: 'FETCHING_SUCCESSFUL_TIMELOGS',
    FETCHING_FAILED_TIMELOGS: 'FETCHING_FAILED_TIMELOGS',

    START_FETCHING_TIMELOGS_PROJECT_ADDITIONAL: 'START_FETCHING_TIMELOGS_PROJECT_ADDITIONAL',
    FETCHING_SUCCESSFUL_TIMELOGS_PROJECT_ADDITIONAL:
        'FETCHING_SUCCESSFUL_TIMELOGS_PROJECT_ADDITIONAL',
    FETCHING_FAILED_TIMELOGS_PROJECT_ADDITIONAL: 'FETCHING_FAILED_TIMELOGS_PROJECT_ADDITIONAL',
    STORE_TIMELOGS_PROJECT_DATA: 'STORE_TIMELOGS_PROJECT_DATA',

    START_CREATING_TIME_LOG_ENTRY: 'START_CREATING_TIME_LOG_ENTRY',
    CREATE_TIME_LOG_SUCCESSFUL: 'CREATE_TIME_LOG_SUCCESSFUL',
    CREATE_TIME_LOG_FAILED: 'CREATE_TIME_LOG_FAILED',

    START_DELETE_TIME_LOG_ENTRY: 'START_DELETE_TIME_LOG_ENTRY',
    DELETE_TIME_LOG_ENTRY_SUCCESSFUL: 'DELETE_TIME_LOG_ENTRY_SUCCESSFUL',
    DELETE_TIME_LOG_ENTRY_FAILED: 'DELETE_TIME_LOG_ENTRY_FAILED',

    START_UPDATE_TIME_LOG_ENTRY: 'START_UPDATE_TIME_LOG_ENTRY',
    UPDATE_TIME_LOG_ENTRY_SUCCESSFUL: 'UPDATE_TIME_LOG_ENTRY_SUCCESSFUL',
    UPDATE_TIME_LOG_ENTRY_FAILED: 'UPDATE_TIME_LOG_ENTRY_FAILED',

    START_FETCHING_SINGLE_TIME_LOG_ENTRY: 'START_FETCHING_SINGLE_TIME_LOG_ENTRY',
    SINGLE_TIME_LOG_ENTRY_FETCHING_FAILED: 'SINGLE_TIME_LOG_ENTRY_FETCHING_FAILED',
    SINGLE_TIME_LOG_ENTRY_FETCHING_SUCCESS: 'SINGLE_TIME_LOG_ENTRY_FETCHING_SUCCESS',
    STORE_TIME_LOG_ENTRY_DATA: 'STORE_TIME_LOG_ENTRY_DATA'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    projects: {},
    timelogs: {},
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_TIMELOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_TIMELOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_TIMELOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.STORE_TIMELOGS_PROJECT_DATA: {
            const oldObject = state.projects;
            return {
                ...state,
                projects: {
                    ...oldObject,
                    [iri]: response.data
                }
            };
        }
        case actions.START_FETCHING_SINGLE_TIME_LOG_ENTRY: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.SINGLE_TIME_LOG_ENTRY_FETCHING_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.SINGLE_TIME_LOG_ENTRY_FETCHING_SUCCESS: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_TIME_LOG_ENTRY_DATA: {
            const oldObject = state.timelogs;
            return {
                ...state,
                timelogs: {
                    ...oldObject,
                    [iri]: response?.data
                }
            };
        }
        default:
            return state;
    }
};
