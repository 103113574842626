import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalPlugin from 'react-modal';
import Svg from 'erpcore/components/Svg';
import './Modal.scss';

class Modal extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.props = props;

        ModalPlugin.setAppElement(props.root);
    }

    handleVariation() {
        const { variation } = this.props;

        let className = `modal__inner`;

        if (variation && variation === 'small') {
            className = `${className} modal__inner--small`;
        } else {
            className = className.replace(' modal__inner--small', '');
        }

        if (variation && variation === 'medium') {
            className = `${className} modal__inner--medium`;
        } else {
            className = className.replace(' modal__inner--medium', '');
        }

        if (variation && variation === 'medium-large') {
            className = `${className} modal__inner--medium-large`;
        } else {
            className = className.replace(' modal__inner--medium-large', '');
        }

        if (variation && variation === 'large') {
            className = `${className} modal__inner--large`;
        } else {
            className = className.replace(' modal__inner--large', '');
        }

        return className;
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const {
            className,
            opened,
            onClose,
            title,
            subtitle,
            children,
            noHeader,
            style,
            shouldCloseOnOverlayClick
        } = this.props;

        return (
            <ModalPlugin
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                parentSelector={() => document.getElementById('modal-wrapper')}
                onRequestClose={onClose}
                className={`modal ${className}`}
                isOpen={opened}
                style={{ ...style }}
            >
                <div className={this.handleVariation()}>
                    {onClose && (
                        <button type="button" className="modal__close" onClick={onClose}>
                            <Svg icon="close" />
                        </button>
                    )}
                    {!noHeader && (
                        <div className="modal__header">
                            {title && <h3 className="modal__title">{title}</h3>}
                            {subtitle && <h4 className="modal__subtitle">{subtitle}</h4>}
                        </div>
                    )}
                    <div className="modal__content">{children}</div>
                </div>
            </ModalPlugin>
        );
    }
}

Modal.defaultProps = {
    className: '',
    variation: null,
    title: null,
    subtitle: null,
    children: null,
    opened: false,
    onClose: () => {},
    root: '#wrapper',
    noHeader: false,
    style: {},
    shouldCloseOnOverlayClick: true
};

Modal.propTypes = {
    className: PropTypes.string,
    variation: PropTypes.oneOf([null, 'small', 'medium', 'medium-large', 'large']),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string
    ]),
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    root: PropTypes.string,
    noHeader: PropTypes.bool,
    style: PropTypes.object,
    shouldCloseOnOverlayClick: PropTypes.bool
};

export default Modal;
