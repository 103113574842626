export const actions = {
    STORE_INITIAL_TIMELINE_DATA: 'STORE_INITIAL_TIMELINE_DATA',
    ADD_TIMELINE_ITEM: 'ADD_TIMELINE_ITEM',
    REMOVE_TIMELINE_ITEM: 'REMOVE_TIMELINE_ITEM',
    UPDATE_SINGLE_TIMELINE_DATA: 'UPDATE_SINGLE_TIMELINE_DATA'
};

export const initialState = {
    initialTimelineData: [],
    timelineData: []
};

const deReferenceObject = item => ({ ...item });

/**
 *
 * timelineData must be changed each time the initialTimelineData is changed (rule doesn't apply for vice versa).
 * Don't share item references between these two lists.
 */
export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.STORE_INITIAL_TIMELINE_DATA: {
            // IMPORTANT: don't share references between lists
            let timestamp = +new Date();
            return {
                ...state,
                initialTimelineData: response,
                timelineData: [
                    ...response.map(item => ({
                        ...item,
                        meta: {
                            ...item?.meta,
                            // eslint-disable-next-line no-plusplus
                            group: item?.meta?.group || ++timestamp,
                            order: 'order' in { ...item?.meta } ? item?.meta?.order : 1
                        }
                    }))
                ]
            };
        }
        case actions.ADD_TIMELINE_ITEM: {
            return {
                ...state,
                // IMPORTANT: must be two referentially different lists
                initialTimelineData: [
                    ...state.timelineData.map(deReferenceObject),
                    deReferenceObject(response)
                ],
                timelineData: [
                    ...state.timelineData.map(deReferenceObject),
                    deReferenceObject(response)
                ]
            };
        }
        case actions.REMOVE_TIMELINE_ITEM: {
            const filteredList = state.timelineData.filter(item => item?.iri !== iri);

            return {
                ...state,
                // IMPORTANT: must be two referentially different lists
                initialTimelineData: [...filteredList.map(deReferenceObject)],
                timelineData: [...filteredList.map(deReferenceObject)]
            };
        }
        case actions.UPDATE_SINGLE_TIMELINE_DATA: {
            return {
                ...state,
                timelineData: state?.timelineData.map(item => {
                    if (item?.iri === iri) {
                        item = { ...item, ...response };
                    }
                    return item;
                })
            };
        }
        default:
            return state;
    }
};
