import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'erpcore/components/Tooltip';
import classnames from 'classnames';
import moment from 'moment-timezone';
import './CustomInput.scss';
import Svg from 'erpcore/components/Svg';

const LabelIcon = ({ id, icon, style }) => {
    return (
        <label htmlFor={id} className="daterange-input__label-icon">
            <Svg
                icon={icon}
                {...(style ? { style } : null)}
                className="daterange-input__label-icon-svg"
            />
        </label>
    );
};
LabelIcon.defaultProps = {
    id: null,
    icon: 'date',
    style: null
};
LabelIcon.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string,
    style: PropTypes.object
};

const CustomInput = forwardRef(
    (
        {
            fieldAttr,
            inputNameStart,
            inputNameEnd,
            startDate,
            startDateId,
            endDate,
            endDateId,
            presentationalDateFormat,
            disabled,
            iconOnly,
            iconOnlyStyle,
            icon,
            isEmpty,
            onInputEndClick,
            onFocus,
            onFocusStartInput,
            onFocusEndInput,
            inputFocused,
            onBlur,
            onClick
        },
        ref
    ) => {
        const startDateFormatted = useMemo(() => {
            return startDate ? moment(startDate).format(presentationalDateFormat) : '';
        }, [startDate, presentationalDateFormat]);

        const endDateFormatted = useMemo(() => {
            return endDate ? moment(endDate).format(presentationalDateFormat) : '';
        }, [endDate, presentationalDateFormat]);

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                ref={ref}
                className={classnames({
                    'daterange-input': true,
                    'daterange-input--empty': isEmpty,
                    'daterange-input--icon-only': iconOnly
                })}
            >
                {!!iconOnly && (
                    <>
                        {!!startDateFormatted && !!endDateFormatted ? (
                            <Tooltip
                                content={
                                    <>
                                        {startDateFormatted} &rarr; {endDateFormatted}
                                    </>
                                }
                                direction="left"
                                forwardProps={{
                                    hoverDelay: 0,
                                }}
                            >
                                <LabelIcon id={startDateId} icon={icon} style={iconOnlyStyle} />
                            </Tooltip>
                        ) : (
                            <LabelIcon id={startDateId} icon={icon} style={iconOnlyStyle} />
                        )}
                    </>
                )}
                <div
                    className="daterange-input__start"
                    data-active={inputFocused === 'start' ? '1' : '0'}
                >
                    <input
                        type="button"
                        className="daterange-input__field"
                        autoComplete="off"
                        onClick={onClick}
                        onFocus={(...rest) => {
                            onFocus(...rest);
                            onFocusStartInput(...rest);
                        }}
                        onBlur={onBlur}
                        value={startDateFormatted}
                        aria-label="Select start date"
                        {...fieldAttr}
                        readOnly
                        id={startDateId}
                        name={inputNameStart}
                    />
                </div>
                <div className="daterange-input__arrow" aria-hidden="true" role="presentation">
                    <svg
                        className="daterange-input__arrow-svg"
                        focusable="false"
                        viewBox="0 0 1000 1000"
                    >
                        <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
                    </svg>
                </div>
                <div
                    className="daterange-input__end"
                    data-active={inputFocused === 'end' ? '1' : '0'}
                >
                    <input
                        type="button"
                        className="daterange-input__field"
                        autoComplete="off"
                        onFocus={(...rest) => {
                            onFocus(...rest);
                            onFocusEndInput(...rest);
                        }}
                        onBlur={onBlur}
                        onClick={(...rest) => {
                            onClick(...rest);
                            onInputEndClick(...rest);
                        }}
                        value={endDateFormatted}
                        readOnly
                        aria-label="Select end date"
                        {...fieldAttr}
                        id={endDateId}
                        name={inputNameEnd}
                        disabled={!startDate ? true : disabled}
                    />
                </div>
            </div>
        );
    }
);

CustomInput.defaultProps = {
    fieldAttr: {},
    inputNameStart: null,
    inputNameEnd: null,
    startDate: null,
    startDateId: null,
    endDate: null,
    endDateId: null,
    presentationalDateFormat: 'M/D/YYYY',
    disabled: false,
    iconOnly: 'date',
    iconOnlyStyle: null,
    icon: false,
    isEmpty: false,
    onInputEndClick: () => {},
    onFocus: () => {},
    onFocusStartInput: () => {},
    onFocusEndInput: () => {},
    inputFocused: null,
    onBlur: () => {},
    onClick: () => {},
    value: {}
};

CustomInput.propTypes = {
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    inputNameStart: PropTypes.string,
    inputNameEnd: PropTypes.string,
    startDate: PropTypes.string,
    startDateId: PropTypes.string,
    endDate: PropTypes.string,
    endDateId: PropTypes.string,
    presentationalDateFormat: PropTypes.string, // do not rename to renderDateFormat, it will collide with library internals
    disabled: PropTypes.bool,
    iconOnly: PropTypes.bool,
    iconOnlyStyle: PropTypes.object,
    icon: PropTypes.string,
    isEmpty: PropTypes.bool,
    onInputEndClick: PropTypes.func,
    onFocus: PropTypes.func,
    onFocusStartInput: PropTypes.func,
    onFocusEndInput: PropTypes.func,
    inputFocused: PropTypes.oneOf(['start', 'end']),
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.string
};

export default CustomInput;
