export const actions = {
    START_FETCHING_SAGE_COMPANIES: 'START_FETCHING_SAGE_COMPANIES',
    FETCHING_SUCCESSFUL_SAGE_COMPANIES: 'FETCHING_SUCCESSFUL_SAGE_COMPANIES',
    FETCHING_FAILED_SAGE_COMPANIES: 'FETCHING_FAILED_SAGE_COMPANIES'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_SAGE_COMPANIES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SAGE_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SAGE_COMPANIES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
