import { valueValidation } from 'erpcore/components/Form/Form.utils';

const passwordLength = 8;

const messages = {
    required: 'is required',
    minimumLength: `is too short (minimum is ${passwordLength} characters)`,
    confirmationMismatch: 'passwords do not match'
};

const isValid = (validatorFunctions = [], value) => {
    const errors = validatorFunctions.map(validatorFunction => {
        return validatorFunction(value);
    });
    const isNotValid = errors.find(item => item !== undefined);

    return !isNotValid;
};

const validatePassword = (componentData, validationData) => {
    const { isRequired, oldPasswordField } = componentData;
    const { value, allValues } = validationData;
    const { [oldPasswordField?.input?.name]: oldPasswordValue } = { ...allValues };

    // field is required
    if (isRequired) {
        if (!isValid(valueValidation([{ validator: 'required' }]), value)) {
            return messages.required;
        }
        if (
            !isValid(
                valueValidation([{ validator: 'length', args: { min: passwordLength } }]),
                value
            )
        ) {
            return messages.minimumLength;
        }
    }

    // field is not required or old password field has value // field has value
    if (
        (oldPasswordValue?.length || !isRequired) &&
        value?.length &&
        value?.length < passwordLength
    ) {
        return messages.minimumLength;
    }

    // old password field has value
    if (oldPasswordValue?.length) {
        if (!isValid(valueValidation([{ validator: 'required' }]), value)) {
            return messages.required;
        }
    }

    return undefined;
};

const validateOldPassword = (componentData, validationData) => {
    const { isRequired, passwordField } = componentData;
    const { value, allValues } = validationData;
    const { [passwordField?.input?.name]: passwordFieldValue } = { ...allValues };

    // field is required
    if (isRequired && !isValid(valueValidation([{ validator: 'required' }]), value)) {
        return messages.required;
    }

    // password (!confirm_password) field has value
    if (passwordFieldValue?.length) {
        if (!isValid(valueValidation([{ validator: 'required' }]), value)) {
            return messages.required;
        }
    }

    return undefined;
};

const validateConfirmationPassword = (componentData, validationData) => {
    const { manualField, passwordField } = componentData;
    const { value, allValues } = validationData;
    const { [manualField?.input?.name]: manualFieldValue } = { ...allValues };
    const { [passwordField?.input?.name]: passwordFieldValue } = { ...allValues };

    // input-manual is on // password (!confirm_password) field has value
    if (manualFieldValue && passwordFieldValue?.length) {
        // required
        if (!isValid(valueValidation([{ validator: 'required' }]), value)) {
            return messages.required;
        }
        // password mismatch
        if (passwordFieldValue !== value) {
            return messages.confirmationMismatch;
        }
    }

    // input-manual is on // password (!confirm_password) field has value
    if (manualFieldValue && passwordFieldValue?.length) {
        if (!isValid(valueValidation([{ validator: 'required' }]), value)) {
            return messages.required;
        }
        if (
            !isValid(
                valueValidation([{ validator: 'length', args: { min: passwordLength } }]),
                value
            )
        ) {
            return messages.minimumLength;
        }
    }

    return undefined;
};

export { validatePassword, validateOldPassword, validateConfirmationPassword };
