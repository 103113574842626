import React from 'react';
import PropTypes from 'prop-types';
import styles from 'erpcore/components/Form/components/Input/Input.scss';
import Input from '../Input';

const ViewUrl = ({ input, meta, fieldProps, fieldAttr, field }) => (
    <Input fieldProps={fieldProps} fieldAttr={fieldAttr} field={field} input={input} meta={meta}>
        {input.value ? (
            <a
                href={input.value}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.input__field}`}
                id={input.name}
            >
                {input.value}
            </a>
        ) : (
            <p
                className={`${styles.input__field} ${styles['input__field--empty']}`}
                id={input.name}
            >
                -
            </p>
        )}
    </Input>
);

ViewUrl.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

ViewUrl.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ViewUrl;
