import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as userReportsActions } from './UserReports.reducer';

/**
 * Fetch Time Logs Report
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchTimeLogsReport({ promise, params, by, isGenericReport }) {
    try {
        const endpoint = isGenericReport
            ? 'api/generic-reports/time-logs'
            : 'api/reports/time-logs';

        const fetchTimeLogsReportAPI = yield restClient.get(endpoint, {
            params,
            headers: { Accept: 'application/json' }
        });

        const responseDto = dto(fetchTimeLogsReportAPI?.data, {}, isGenericReport);

        yield put({
            type: userReportsActions.FETCHING_SUCCESSFUL_USER_REPORTS_TIME_LOGS,
            by
        });
        yield put({
            type: userReportsActions.STORE_USER_REPORTS_TIME_LOGS_DATA,
            response: responseDto,
            by
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: userReportsActions.FETCHING_FAILED_USER_REPORTS_TIME_LOGS,
            by
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(userReportsActions.START_FETCHING_USER_REPORTS_TIME_LOGS, fetchTimeLogsReport)
];
