import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardWidgetsActions } from 'erpcore/screens/Dashboard/DashboardWidgets.reducer';
import { actions as timeOffWidgetActions } from './TimeOffWidget.reducer';

/**
 * Fetch Time Offs
 * @param  {Object} promise
 * @param  {Object} params
 * @param  {string} widgetId
 * @return {string}
 */
export function* fetchTimeOffs({ promise, params, widgetId }) {
    yield put({
        type: dashboardWidgetsActions.START_FETCHING_WIDGET_DATA,
        widgetId
    });

    try {
        const fetchTimeOffsAPI = yield restClient.get(
            `api/time-offs?pagination=false&include=requestedBy,department,requestedBy.department`,
            {
                params
            }
        );
        yield put({
            type: timeOffWidgetActions.FETCHING_SUCCESSFUL_WIDGET_TIME_OFFS
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: dashboardWidgetsActions.STORE_WIDGET_DATA,
            widgetId,
            response: dto(fetchTimeOffsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeOffWidgetActions.FETCHING_FAILED_WIDGET_TIME_OFFS
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [takeEvery(timeOffWidgetActions.START_FETCHING_WIDGET_TIME_OFFS, fetchTimeOffs)];
