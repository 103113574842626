import React, { useEffect, useState } from 'react';

const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
};

const ScrollbarWidthDetector = () => {
    const [currentScrollbarWidth, setCurrentScrollbarWidth] = useState(0);
    const [noModalScrollbarWidth, setNoModalScrollbarWidth] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const scrollbarWidth = getScrollbarWidth();
            const isModalOpen = document.body.classList?.contains('ReactModal__Body--open');

            setCurrentScrollbarWidth(scrollbarWidth);

            // opened Modal prevents body scroll
            if (!isModalOpen) {
                setNoModalScrollbarWidth(scrollbarWidth);
            }
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <style>{`
        body {
            --scrollbarWidthCurrent: ${currentScrollbarWidth || 0}px;
            --scrollbarWidthWithNoModal: ${noModalScrollbarWidth || 0}px;
        }
    `}</style>
    );
};

export default ScrollbarWidthDetector;
