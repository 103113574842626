export const actions = {
    START_UPDATE_BRANDING_SETTINGS: 'START_UPDATE_BRANDING_SETTINGS',
    UPDATE_BRANDING_SETTINGS_SUCCESSFUL: 'UPDATE_BRANDING_SETTINGS_SUCCESSFUL',
    UPDATE_BRANDING_SETTINGS_FAILED: 'UPDATE_BRANDING_SETTINGS_FAILED',

    START_CREATE_BRANDING_SETTINGS: 'START_CREATE_BRANDING_SETTINGS',
    CREATE_BRANDING_SETTINGS_SUCCESSFUL: 'CREATE_BRANDING_SETTINGS_SUCCESSFUL',
    CREATE_BRANDING_SETTINGS_FAILED: 'CREATE_BRANDING_SETTINGS_FAILED',

    START_FETCHING_BRANDING_SETTINGS: 'START_FETCHING_BRANDING_SETTINGS',
    FETCHING_BRANDING_SETTINGS_SUCCESSFUL: 'FETCHING_BRANDING_SETTINGS_SUCCESSFUL',
    FETCHING_BRANDING_SETTINGS_FAILED: 'FETCHING_BRANDING_SETTINGS_FAILED',

    SET_BRANDING_OPTION: 'SET_BRANDING_OPTION'
};

export const initialState = {
    data: null,
    fetching: true
};

export default (state = initialState, { type, response, options = {} }) => {
    switch (type) {
        case actions.START_FETCHING_BRANDING_SETTINGS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_BRANDING_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                data: {
                    ...state?.data,
                    ...response
                }
            };
        }
        case actions.UPDATE_BRANDING_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                data: {
                    ...state?.data,
                    ...response
                }
            };
        }
        case actions.SET_BRANDING_OPTION: {
            return {
                ...state,
                data: {
                    ...state?.data,
                    ...options
                }
            };
        }
        default:
            return state;
    }
};
