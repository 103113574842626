import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardWidgetsActions } from 'erpcore/screens/Dashboard/DashboardWidgets.reducer';
import { actions as teamActivityWidgetActions } from './TeamActivityWidget.reducer';

/**
 * Fetch Time Logs Report
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchTimeLogsReport({ promise, params, widgetId }) {
    yield put({
        type: dashboardWidgetsActions.START_FETCHING_WIDGET_DATA,
        widgetId
    });

    try {
        const fetchTimeLogsReportAPI = yield restClient.get(`api/reports/time-logs`, {
            params,
            headers: { Accept: 'application/json' }
        });
        yield put({
            type:
                teamActivityWidgetActions.FETCHING_SUCCESSFUL_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: dashboardWidgetsActions.STORE_WIDGET_DATA,
            widgetId,
            response: fetchTimeLogsReportAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: teamActivityWidgetActions.FETCHING_FAILED_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(
        teamActivityWidgetActions.START_FETCHING_TEAM_ACTIVITY_WIDGET_TIME_LOGS_REPORT,
        fetchTimeLogsReport
    )
];
