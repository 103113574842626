import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import Input from '../Input';
import './Signature.scss';

const Signature = ({ input, fieldProps, fieldAttr, field, meta }) => {
    const signatureCanvas = useRef(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            signatureCanvas.current.clear();
            signatureCanvas.current.fromDataURL(input.value);
            setInitialized(true);
        } else if (!input.value) {
            signatureCanvas.current.clear();
        }

        if (fieldProps.isDisabled) {
            signatureCanvas.current.off();
        } else {
            signatureCanvas.current.on();
        }
    }, [input.value]);

    return (
        <Input
            className="input--signature"
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <div className="input__field input__field--signature">
                {typeof input.value === 'object' && (
                    <img
                        className="signature"
                        alt={input.value.content_url}
                        src={input.value.content_url}
                    />
                )}
                <SignatureCanvas
                    ref={signatureCanvas}
                    onBegin={() => input.onFocus()}
                    onEnd={() => input.onChange(signatureCanvas.current.toDataURL())}
                    id={input.name}
                    {...input}
                    clearOnResize={false}
                    canvasProps={{
                        disabled: true,
                        width: 300,
                        height: 200
                    }}
                />
            </div>
        </Input>
    );
};

Signature.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
Signature.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Signature;
