import unionWith from 'lodash/unionWith';
import dtoGenericReports from 'erpcore/utils/dtoGenericReports';

/**
 * get id From iri
 * @param {string} iri
 * @returns {string}
 */
const getIdFromIri = (iri) => {
    if (!iri) {
        return null;
    }
    if (typeof iri === 'number') {
        return iri.toString();
    }

    // Assuming the id value is the last part after the /
    const n = iri.lastIndexOf('/');
    const id = iri.substring(n + 1);

    return id;
};

/**
 * Build Item Object with data
 * @param {object} item
 * @returns {object}
 */
const buildItemObject = (item) => {
    if (!item) {
        return null;
    }

    return {
        ...item?.attributes,
        type: item.type,
        iri: item.id,
        id: getIdFromIri(item.id)
    };
};

/**
 * Handling relationships data
 * @param {object} relationships
 * @returns {object}
 */
const getRelationshipsFlattened = (relationships) => {
    if (!relationships) {
        throw new Error('Incorrect data passed!');
    }

    const relationshipsData = {};

    Object.keys(relationships).forEach((key) => {
        // If relationships data is Array
        // else is Object
        if (relationships[key]?.data?.constructor === Array) {
            relationshipsData[key] = [];
            relationships[key].data.forEach((item) => {
                const relationshipItem = buildItemObject(item);
                relationshipsData[key].push(relationshipItem);
            });
        } else {
            relationshipsData[key] = buildItemObject(relationships[key].data);
        }
    });

    return relationshipsData;
};

/**
 * Handling included data
 * @param relationships {object} Object with relationships as properties. Each property is object with data property, which can be Object containing id and type, or Array with Objects containing id and type.
 * @param included {array} Included data
 * @param excluded {object} Hash map of relationships to exclude from processing, passed on each recursive call by the function itself, to avoid infinite loops in cases when included items relate to each other.
 * @param config {object}
 * @param identifier {string}
 * @returns {object} containing attributes
 */
const getIncludedDataByRelationship = (
    relationships,
    included,
    excluded = {},
    config,
    identifier = ''
) => {
    if (!relationships || !included) {
        throw new Error('Incorrect data passed!');
    }

    const includedData = {};

    const shouldPathBeIgnored = (configuration, propertyName, path) => {
        const excludedPropertyNames = configuration?.listOfNoIncludePropertyNames || [];
        const excludedPaths = configuration?.listOfNoIncludePaths || [];

        // check property name
        if (
            propertyName &&
            excludedPropertyNames.length &&
            excludedPropertyNames.includes(propertyName)
        ) {
            return true;
        }

        // check path
        if (path && excludedPaths.length && excludedPaths.includes(path)) {
            return true;
        }

        return false;
    };

    const getIncludedItem = (targetKey, targetIdentifier, relationshipData = {}) => {
        let output = null;

        if (!shouldPathBeIgnored(config, targetKey, targetIdentifier)) {
            output = included.find(
                (item) =>
                    item?.id &&
                    item.id === relationshipData.id &&
                    item.type === relationshipData.type
            );
        }

        if (config?.includeRelationshipsWithNoIncludes && !output) {
            output = relationships[targetKey].data;
        }

        return output;
    };

    Object.keys(relationships).forEach((relationshipKey) => {
        // if relationship is array
        //      loop array
        //          formatt data
        // else relationship is object
        //      if included data merge

        const itemIdentifier = `${identifier && `${identifier}.`}${relationshipKey}`;

        if (relationships[relationshipKey]?.data?.constructor === Array) {
            /**
             * Processing LIST
             */
            includedData[relationshipKey] = [];
            relationships[relationshipKey].data.forEach((relationshipItem) => {
                // if (!excluded[relationshipItem.id]) {
                const includedItem = getIncludedItem(
                    relationshipKey,
                    itemIdentifier,
                    relationshipItem
                );
                if (includedItem) {
                    //  If relationship in relationship exists merge included relations to attributes
                    if (includedItem.relationships) {
                        const excludedNew = { ...excluded, [includedItem.id]: true }; // avoiding infinite loop
                        includedItem.attributes = {
                            ...includedItem.attributes,
                            ...getIncludedDataByRelationship(
                                includedItem.relationships,
                                included,
                                excludedNew,
                                config,
                                itemIdentifier
                            )
                        };
                    }
                    includedData[relationshipKey].push(buildItemObject(includedItem));
                }
                // }
            });
        } else {
            /**
             * Processing SINGLE
             */
            // eslint-disable-next-line
            if (!excluded[relationships[relationshipKey].data.id]) {
                const includedItem = getIncludedItem(
                    relationshipKey,
                    itemIdentifier,
                    relationships[relationshipKey].data
                );
                if (includedItem) {
                    let deepIncludedData = {};
                    if (includedItem.relationships) {
                        const excludedNew = { ...excluded, [includedItem.id]: true }; // avoiding infinite loop
                        deepIncludedData = getIncludedDataByRelationship(
                            includedItem.relationships,
                            included,
                            excludedNew,
                            config,
                            itemIdentifier
                        );
                    }
                    includedData[relationshipKey] = {
                        ...buildItemObject(includedItem),
                        ...deepIncludedData
                    };
                }
            }
        }
    });
    return includedData;
};

/**
 * Get included data in formFormated DTO
 * @param {object} iri
 * @param {object} includedData
 * @returns {object}
 */
export const getIncludedDataByIri = (iri, includedData) => {
    if (includedData?.included?.length && iri) {
        return includedData.included.find((include) => include?.iri === iri) || {};
    }

    return {};
};

/**
 * Prepare data for included from select
 * @param {object} data
 * @param {object} included
 * @returns {object}
 */
export const formatSelectDataForIncluded = (data, included) => {
    const includedData = (data?.length ? data : []).map((item) => {
        return {
            iri: item.value,
            label: item.label
        };
    });

    return unionWith(included, includedData, (a, b) => a.iri === b.iri);
};

/**
 * Transfering data from the API to Frontend Form friendly format
 * TODO: antonio: adding included to form state is a terrible idea
 * @param {object} inputData
 * @returns {object}
 */
const dtoForm = (inputData) => {
    if (!inputData) {
        return null;
    }

    let data = null;

    data = Object.assign({}, inputData);
    data.included = [];

    // Loop through the data
    Object.keys(data).forEach((key) => {
        // check if property is array
        if (key !== 'included' && data[key] && Array.isArray(data[key])) {
            // create new array rewriting array objects with iri values
            data[key] = data[key].map((item) => {
                // check if property is object
                if (item && item.constructor === Object) {
                    // check if property has iri value
                    if (item.iri) {
                        data.included.push(item);
                        // rewrite property value to match iri
                        return item.iri;
                    }
                    return item;
                }
                return item;
            });
        }
        // check if property is object
        if (data[key] && data[key].constructor === Object) {
            // check if property has iri value
            if (data[key].iri) {
                data.included.push(inputData[key]);
                // rewrite property value to match iri
                data[key] = data[key].iri;
                return data;
            }
            return data;
        }
        return data;
    });

    return data;
};

/**
 * Transforming data from the API to Frontend (and Form) friendly format for Listing API endpoints
 * @param {object} inputData
 * @returns {object}
 */
const dtoListing = (inputData, config) => {
    if (!inputData) {
        throw new Error('Incorrect data passed!');
    }

    if (!inputData.data) {
        return null;
    }

    // creating new duplicate object
    const data = Object.assign({}, inputData);

    const listingData = [];

    inputData.data.forEach((inputItem) => {
        // creating new duplicate object
        let item = Object.assign({}, inputItem);

        let includedData = {};

        const defaultItemData = {
            iri: item.id,
            id: getIdFromIri(item.id)
        };

        // Handling included data
        if (item.relationships && inputData.included) {
            includedData = getIncludedDataByRelationship(
                item.relationships,
                inputData.included,
                {
                    [item.id]: true
                },
                config
            );
        }
        // If no included data, flatten relationships
        if (item.relationships && !inputData.included) {
            includedData = getRelationshipsFlattened(item.relationships);
        }

        // handling the data
        item = Object.assign(item, item.attributes, includedData, defaultItemData);

        // item.isDto = true;

        // cleanup
        delete item.attributes;
        if (item.relationships) {
            delete item.relationships;
        }

        return listingData.push(item);
    });

    // Replacing data
    delete data.data;
    data.data = listingData;

    // cleanup
    if (data.included) {
        delete data.included;
    }

    return data;
};

/**
 * Transforming data from the API to Frontend (and Form) friendly format for Single API endpoints
 * @param {object} inputData
 * @returns {object}
 */
const dtoSingle = (inputData, config) => {
    if (!inputData) {
        throw new Error('Incorrect data passed!');
    }

    if (!inputData.data) {
        return null;
    }

    let includedData = {};

    // setting iri and id values
    const defaultData = {
        iri: inputData.data.id,
        id: getIdFromIri(inputData.data.id)
    };

    // creating new duplicate object
    const data = Object.assign({}, inputData);

    // Handling included data
    if (data.data.relationships && inputData.included) {
        includedData = getIncludedDataByRelationship(
            data.data.relationships,
            inputData.included,
            {
                [inputData.data.id]: true
            },
            config
        );
    }
    // If no included data, flatten relationships
    if (data.data.relationships && !inputData.included) {
        includedData = getRelationshipsFlattened(data.data.relationships);
    }

    // handling the data
    data.data = Object.assign(data.data, inputData.data.attributes, includedData, defaultData);

    // data.data.isDto = true;

    // cleanup
    if (data.included) {
        delete data.included;
    }
    delete data.data.attributes;
    if (data.data.relationships) {
        delete data.data.relationships;
    }
    return data;
};

/**
 * Transfering data from the API to Frontend (and Form) friendly format
 * @param {object} inputData
 * @param {object} config
 * @param {boolean} [config.includeRelationshipsWithNoIncludes] False by default
 * @param {array} [config.listOfNoIncludePaths] List of paths (where each path is a string representation of identifier) where included data will be ignored
 * @param {array} [config.listOfNoIncludePropertyNames] List of property names where included data will be ignored
 * @returns {object}
 */
const dto = (inputData, config, isGenericReport = false) => {
    if (!inputData) {
        return null;
    }

    config = {
        includeRelationshipsWithNoIncludes: false,
        listOfNoIncludePaths: [],
        listOfNoIncludePropertyNames: [],
        ...config
    };

    if (isGenericReport) {
        return dtoGenericReports(inputData);
    }

    // Listing is Array
    if (inputData.data && inputData.data.constructor === Array) {
        return dtoListing(inputData, config);
    }
    // Single is Object
    if (inputData.data && inputData.data.constructor === Object) {
        return dtoSingle(inputData, config);
    }

    return inputData;
};

export { dtoForm, getIdFromIri, getIncludedDataByRelationship, getRelationshipsFlattened };
export default dto;
