import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as servicesActions } from 'erpcore/screens/Settings/Services/Services.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Service
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createService({ promise, formData }) {
    try {
        const createServiceAPI = yield restClient.post('api/organization/services', formData);
        yield put({
            type: servicesActions.CREATE_SERVICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createServiceAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'services' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'SERVICES',
            name: 'services',
            endpoint: 'api/organization/services?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: servicesActions.CREATE_SERVICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Service data
 * @param  {Object} iri of Service
 * @return {Object} Response from API
 */
export function* updateService({ promise, iri, formData }) {
    try {
        const updateServiceAPI = yield restClient.patch(iri, formData);
        yield put({
            type: servicesActions.UPDATE_SERVICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateServiceAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'services' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'SERVICES',
            name: 'services',
            endpoint: 'api/organization/services?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: servicesActions.UPDATE_SERVICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Service
 * @param  {Object} iri of Service
 * @return {Object} Response from API
 */
export function* deleteService({ promise, iri }) {
    try {
        const deleteServiceAPI = yield restClient.delete(iri);
        yield put({
            type: servicesActions.DELETE_SERVICE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteServiceAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'services' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'SERVICES',
            name: 'services',
            endpoint: 'api/organization/services?order_by[name]=ASC'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: servicesActions.DELETE_SERVICE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const servicesSaga = [
    takeLatest(servicesActions.START_CREATE_SERVICE, createService),
    takeLatest(servicesActions.START_UPDATE_SERVICE, updateService),
    takeLatest(servicesActions.START_DELETE_SERVICE, deleteService)
];

export default servicesSaga;
