import React, { useEffect, memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Timer from 'react-compound-timer';
import { useSelector } from 'react-redux';
import { getDraftTimelogs } from 'erpcore/components/TimeTracking/TimeTracking.selectors';

const CalculateTotalTime = ({ currentTime }) => {
    const timerRef = useRef();
    const draftTimelogs = useSelector(getDraftTimelogs);

    const hasRunningTimer = useMemo(
        () => draftTimelogs.find(item => item?.started_at && item?.status === 'draft'),
        [draftTimelogs]
    );

    const calculatedTimeSpent = useMemo(() => {
        return draftTimelogs?.reduce((acc, curr) => {
            acc += curr?.time_spent;

            return acc;
        }, null);
    }, [draftTimelogs]);

    useEffect(() => {
        if (hasRunningTimer && timerRef.current) timerRef.current.start();
        if (!hasRunningTimer && timerRef.current) timerRef.current.stop();
    }, [hasRunningTimer]);

    useEffect(() => {
        if (hasRunningTimer && timerRef.current)
            timerRef.current.setTime(
                calculatedTimeSpent * 1000 + (currentTime() - hasRunningTimer?.time_spent * 1000)
            );

        if (!hasRunningTimer && timerRef.current)
            timerRef.current.setTime(calculatedTimeSpent * 1000);
    }, [calculatedTimeSpent]);

    return (
        <Timer startImmediately="true" lastUnit="h" direction="forward">
            {timerProps => {
                if (!timerRef.current) timerRef.current = timerProps;

                return (
                    <>
                        <Timer.Hours formatValue={value => `${value > 0 ? `${value}:` : ''}`} />
                        <Timer.Minutes
                            formatValue={value => {
                                return `${value > 9 ? value : `0${value}`}`;
                            }}
                        />
                        :
                        <Timer.Seconds
                            formatValue={value => `${value > 9 ? value : `0${value}`}`}
                        />
                    </>
                );
            }}
        </Timer>
    );
};

CalculateTotalTime.propTypes = {
    currentTime: PropTypes.number.isRequired
};

export default memo(CalculateTotalTime);
