import SidebarClient from 'erpcore/components/Layout/SidebarClient';
import Main from 'erpcore/components/Layout/Main';

const clientLayout = {
    data: [
        {
            slotName: 'leftSidebar',
            component: SidebarClient,
            position: 0
        },
        {
            slotName: 'main',
            component: Main,
            position: 0
        }
    ]
};

export default clientLayout;
