import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

/**
 * Form Component
 */
function Form({ id, children, onSubmit, className, attrs }) {
    return (
        <form id={id} onSubmit={onSubmit} className={`form ${className || ''}`} {...attrs}>
            {children}
        </form>
    );
}

Form.defaultProps = {
    id: null,
    children: null,
    onSubmit: null,
    className: '',
    attrs: null
};

Form.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    attrs: PropTypes.oneOfType([PropTypes.object])
};

/**
 * Form Columns Component
 */
Form.Columns = function Columns({ children, className, verticalCenter }) {
    return (
        <div
            className={`form__columns ${className}${
                verticalCenter === true ? ' form__columns--vertical-center' : ''
            }`}
        >
            {children}
        </div>
    );
};

Form.Columns.defaultProps = {
    children: null,
    className: '',
    verticalCenter: false
};

Form.Columns.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    verticalCenter: PropTypes.bool
};

/**
 * Form Column Component
 */
Form.Column = function Column({ children }) {
    return <div className="form__column">{children}</div>;
};

Form.Column.defaultProps = {
    children: null
};

Form.Column.propTypes = {
    children: PropTypes.node
};

/**
 * Form Grid Column Component
 */
Form.GridColumn = function Column({ size, children }) {
    return <div className={`form__grid-column form__grid-column--${size}`}>{children}</div>;
};

Form.GridColumn.defaultProps = {
    size: 12,
    children: null
};

Form.GridColumn.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node
};

/**
 * Form Section Component
 */
Form.Section = function Section({ children }) {
    return <div className="form__section">{children}</div>;
};

Form.Section.defaultProps = {
    children: null
};

Form.Section.propTypes = {
    children: PropTypes.node
};

/**
 * Form Seperate Section Component
 */
Form.SeperateSection = function Section({ children }) {
    return <div className="form__seperate-section">{children}</div>;
};

Form.SeperateSection.defaultProps = {
    children: null
};

Form.SeperateSection.propTypes = {
    children: PropTypes.node
};

/**
 * Form Section Title Component
 */
Form.SectionTitle = function Title({ children }) {
    return <h3 className="form__section-title">{children}</h3>;
};

Form.SectionTitle.defaultProps = {
    children: null
};

Form.SectionTitle.propTypes = {
    children: PropTypes.node
};

/**
 * Form Section small Title Component
 */
Form.SectionTitleSmall = function Title({ children }) {
    return <h4 className="form__section-title-small">{children}</h4>;
};

Form.SectionTitleSmall.defaultProps = {
    children: null
};

Form.SectionTitleSmall.propTypes = {
    children: PropTypes.node
};

/**
 * Form Row Component
 */
Form.Row = function Row({ children, className, verticalCenter }) {
    return (
        <div
            className={`form__row ${className} ${
                verticalCenter === true ? ' form__row--vertical-center' : ''
            }`}
        >
            {children}
        </div>
    );
};

Form.Row.defaultProps = {
    children: null,
    className: '',
    verticalCenter: false
};

Form.Row.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    verticalCenter: PropTypes.bool
};

/**
 * Form Row Responsive Component
 */
Form.RowColResponsive = function RowColResponsive({ children, className }) {
    return <div className={`form__row-column-responsive ${className}`}>{children}</div>;
};

Form.RowColResponsive.defaultProps = {
    children: null,
    className: ''
};

Form.RowColResponsive.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

/**
 * Form Group
 */
Form.Group = function Title({ children, label }) {
    return (
        <div className={`form__group ${label ? 'form__group--with-label' : ''}`}>
            {!!label && <div className="form__group-label">{label}</div>}
            <div className="form__group-content">{children}</div>
        </div>
    );
};

Form.Group.defaultProps = {
    children: null,
    label: null
};

Form.Group.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any,
    label: PropTypes.string
};

export default Form;
