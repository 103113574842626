export const actions = {
    // Listing
    START_FETCHING_WORK_TYPES: 'START_FETCHING_WORK_TYPES',
    FETCHING_SUCCESSFUL_WORK_TYPES: 'FETCHING_SUCCESSFUL_WORK_TYPES',
    FETCHING_FAILED_WORK_TYPES: 'FETCHING_FAILED_WORK_TYPES',
    // Create
    START_CREATE_WORK_TYPE: 'START_CREATE_WORK_TYPE',
    CREATE_WORK_TYPE_SUCCESSFUL: 'CREATE_WORK_TYPE_SUCCESSFUL',
    CREATE_WORK_TYPE_FAILED: 'CREATE_WORK_TYPE_FAILED',
    // Edit
    START_UPDATE_WORK_TYPE: 'START_UPDATE_WORK_TYPE',
    UPDATE_WORK_TYPE_SUCCESSFUL: 'UPDATE_WORK_TYPE_SUCCESSFUL',
    UPDATE_WORK_TYPE_FAILED: 'UPDATE_WORK_TYPE_FAILED',
    // Delete
    START_DELETE_WORK_TYPE: 'START_DELETE_WORK_TYPE',
    DELETE_WORK_TYPE_SUCCESSFUL: 'DELETE_WORK_TYPE_SUCCESSFUL',
    DELETE_WORK_TYPE_FAILED: 'DELETE_WORK_TYPE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_WORK_TYPES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_WORK_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_WORK_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
