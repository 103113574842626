export const actions = {
    START_FETCHING_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS:
        'START_FETCHING_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS',
    FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS:
        'FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS',
    FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS:
        'FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS',
    STORE_REPORT_PROJECT_CONTRACT_DASHBOARD_TIME_LOGS_DATA:
        'STORE_REPORT_PROJECT_CONTRACT_DASHBOARD_TIME_LOGS_DATA',

    START_FETCHING_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING:
        'START_FETCHING_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING',
    FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING:
        'FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING',

    START_FETCHING_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING:
        'START_FETCHING_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING',
    FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING:
        'FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING',

    START_FETCHING_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING:
        'START_FETCHING_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING',
    FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING:
        'FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING',
    FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING:
        'FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING',

    START_UPDATE_COMPLETION_RATE: 'START_UPDATE_COMPLETION_RATE',
    UPDATE_SUCCESSFUL_COMPLETION_RATE: 'UPDATE_SUCCESSFUL_COMPLETION_RATE',
    UPDATE_FAILED_COMPLETION_RATE: 'UPDATE_FAILED_COMPLETION_RATE',

    CLEAR_CONTRACT_DASHBOARD_STATE: 'CLEAR_CONTRACT_DASHBOARD_STATE'
};

export const initialState = {
    projectWorkTypesListingFetching: false,
    projectWorkTypesListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    projectWorkTypesListingError: [],
    billingRequestsListingFetching: false,
    billingRequestsListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    billingRequestsListingError: [],
    invoiceListingFetching: false,
    invoiceListingResponse: {
        data: [],
        meta: {},
        included: []
    },
    invoiceListingError: []
};

export default (state = initialState, { type, response, by }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: true } } }
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false } } }
            };
        }
        case actions.FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_REPORT_TIME_LOGS: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ fetching: false, error: response } } }
            };
        }
        case actions.STORE_REPORT_PROJECT_CONTRACT_DASHBOARD_TIME_LOGS_DATA: {
            return {
                ...state,
                ...{ [by]: { ...state[by], ...{ data: response } } }
            };
        }

        case actions.START_FETCHING_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING: {
            return {
                ...state,
                projectWorkTypesListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING: {
            return {
                ...state,
                projectWorkTypesListingFetching: false,
                projectWorkTypesListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_WORK_TYPES_LISTING: {
            return {
                ...state,
                projectWorkTypesListingFetching: false,
                projectWorkTypesListingError: response
            };
        }

        case actions.START_FETCHING_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING: {
            return {
                ...state,
                billingRequestsListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING: {
            return {
                ...state,
                billingRequestsListingFetching: false,
                billingRequestsListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_BILLING_REQUESTS_LISTING: {
            return {
                ...state,
                billingRequestsListingFetching: false,
                billingRequestsListingError: response
            };
        }

        case actions.CLEAR_CONTRACT_DASHBOARD_STATE: {
            return {
                ...initialState
            };
        }

        case actions.START_FETCHING_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING: {
            return {
                ...state,
                invoiceListingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING: {
            return {
                ...state,
                invoiceListingFetching: false,
                invoiceListingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECT_CONTRACT_DASHBOARD_INVOICE_LISTING: {
            return {
                ...state,
                invoiceListingFetching: false,
                invoiceListingError: response
            };
        }

        default:
            return state;
    }
};
