import React from 'react';
import PropTypes from 'prop-types';
import styles from './Radio.module.scss';

const Radio = ({ input, meta, fieldProps, fieldAttr, isGroup }) => {
    const checked = String(input.value) === String(fieldProps.value);

    const id = fieldProps.id
        ? fieldProps.id
        : `${fieldAttr?.id}-${input?.name}-${fieldProps?.value}`;

    return (
        <div className={`${styles['input-radio']}`}>
            <input
                type="radio"
                aria-label={`${fieldProps.label}`}
                {...fieldAttr}
                name={input.name}
                id={id}
                value={fieldProps.value}
                checked={checked}
                onChange={value => {
                    input.onBlur();
                    return input.onChange(value);
                }}
            />
            <label className={`${styles['input-radio__label']}`} htmlFor={id}>
                {fieldProps.label}
            </label>
            {meta.touched && meta.error && !isGroup && (
                <span className={`${styles['input-checkbox__error']}`}>
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
        </div>
    );
};
Radio.defaultProps = {
    fieldProps: {
        label: '',
        options: []
    },
    fieldAttr: {},
    input: {},
    meta: {},
    isGroup: false
};

Radio.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    isGroup: PropTypes.bool
};

export default Radio;
