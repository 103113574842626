import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - MyAccount Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const MyAccountNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'user.currentUserSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Profile details successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'user.missingNewPassword':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Please enter new password!"
                    type="error"
                    expire="3500"
                />
            );
        case 'user.invalidOldPassword':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Invalid old password!"
                    type="error"
                    expire="3500"
                />
            );
        case 'user.ownerSuccessfullyChangePassword':
        case 'user.adminSuccessfullyChangePassword':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Password changed successfully!"
                    type="success"
                    expire="3500"
                />
            );
        case 'user.ownerNewPassordConfirmationWrongMatch':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="New password confirmation does not match!"
                    type="error"
                />
            );
        default:
            return null;
    }
};

MyAccountNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

MyAccountNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default MyAccountNotifications;
