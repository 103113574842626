export const actions = {
    // Listing
    START_FETCHING_OFFICES: 'START_FETCHING_OFFICES',
    FETCHING_SUCCESSFUL_OFFICES: 'FETCHING_SUCCESSFUL_OFFICES',
    FETCHING_FAILED_OFFICES: 'FETCHING_FAILED_OFFICES',
    // Create
    START_CREATE_OFFICE: 'START_CREATE_OFFICE',
    CREATE_OFFICE_SUCCESSFUL: 'CREATE_OFFICE_SUCCESSFUL',
    CREATE_OFFICE_FAILED: 'CREATE_OFFICE_FAILED',
    // Edit
    START_UPDATE_OFFICE: 'START_UPDATE_OFFICE',
    UPDATE_OFFICE_SUCCESSFUL: 'UPDATE_OFFICE_SUCCESSFUL',
    UPDATE_OFFICE_FAILED: 'UPDATE_OFFICE_FAILED',
    // Delete
    START_DELETE_OFFICE: 'START_DELETE_OFFICE',
    DELETE_OFFICE_SUCCESSFUL: 'DELETE_OFFICE_SUCCESSFUL',
    DELETE_OFFICE_FAILED: 'DELETE_OFFICE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_OFFICES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_OFFICES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_OFFICES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
