import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as jobTitlesActions } from 'erpcore/screens/Settings/JobTitles/JobTitles.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Job Title
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createJobTitle({ promise, formData }) {
    try {
        const createJobTitleAPI = yield restClient.post('api/job-titles', formData);
        yield put({
            type: jobTitlesActions.CREATE_JOB_TITLE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createJobTitleAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'jobTitles' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'JOB_TITLES',
            name: 'jobTitles',
            endpoint: 'api/job-titles?include=workType'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: jobTitlesActions.CREATE_JOB_TITLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Job Title data
 * @param  {Object} iri of Job Title
 * @return {Object} Response from API
 */
export function* updateJobTitle({ promise, iri, formData }) {
    try {
        const updateJobTitleAPI = yield restClient.patch(iri, formData);
        yield put({
            type: jobTitlesActions.UPDATE_JOB_TITLE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateJobTitleAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'jobTitles' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'JOB_TITLES',
            name: 'jobTitles',
            endpoint: 'api/job-titles?include=workType'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: jobTitlesActions.UPDATE_JOB_TITLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Job Title
 * @param  {Object} iri of Job Title
 * @return {Object} Response from API
 */
export function* deleteJobTitle({ promise, iri }) {
    try {
        const deleteJobTitleAPI = yield restClient.delete(iri);
        yield put({
            type: jobTitlesActions.DELETE_JOB_TITLE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteJobTitleAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'jobTitles' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'JOB_TITLES',
            name: 'jobTitles',
            endpoint: 'api/job-titles?include=workType'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: jobTitlesActions.DELETE_JOB_TITLE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(jobTitlesActions.START_CREATE_JOB_TITLE, createJobTitle),
    takeLatest(jobTitlesActions.START_UPDATE_JOB_TITLE, updateJobTitle),
    takeLatest(jobTitlesActions.START_DELETE_JOB_TITLE, deleteJobTitle)
];
