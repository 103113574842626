export const actions = {
    // Listing
    START_FETCHING_REPORT_EMPLOYEE_RECORDS: 'START_FETCHING_REPORT_EMPLOYEE_RECORDS',
    FETCHING_SUCCESSFUL_REPORT_EMPLOYEE_RECORDS: 'FETCHING_SUCCESSFUL_REPORT_EMPLOYEE_RECORDS',
    FETCHING_FAILED_REPORT_EMPLOYEE_RECORDS: 'FETCHING_FAILED_REPORT_EMPLOYEE_RECORDS'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_REPORT_EMPLOYEE_RECORDS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_REPORT_EMPLOYEE_RECORDS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_REPORT_EMPLOYEE_RECORDS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
