import clone from 'lodash/clone';
import union from 'lodash/union';

//  Used for parsing value of filter object
const parseParamsValueForApi = filterItem => {
    const tempFilterItem = clone(filterItem);
    delete tempFilterItem.operator;
    let parsedValue = Object.keys(tempFilterItem).length > 1 ? [] : '';

    Object.keys(tempFilterItem).map(filterItemKey => {
        if (Array.isArray(parsedValue)) {
            parsedValue.push(tempFilterItem[filterItemKey]);
        } else {
            parsedValue = tempFilterItem[filterItemKey];
        }
        return true;
    });

    return parsedValue;
};

/*
 *  Converts param objects to API accepted params
 *  ex. converts filter objects from regular javascript object
 *      to API readable object:
 *      from: filter {
 *              name: {
 *                  operator 'contains',
 *                  value: 'value'
 *                  }
 *             }
 *      to:
 *          filter[name][contains]=value
 */
const parseParamsForApi = (incomingParams, filterSchema) => {
    const parsedParams = {};

    if (!incomingParams) {
        return parsedParams;
    }

    const params = clone(incomingParams);

    //  Go trough all params
    Object.keys(params).map(paramsKey => {
        const item = clone(params[paramsKey]);

        //  Preparing api params for filter API
        //  Checking if filter object is array or object and preparing object to API readable filters
        if (typeof item === 'object' && paramsKey === 'filter') {
            Object.keys(item).map(paramKey => {
                //  If filter is an array of objects (for multiple filtered items)
                if (Array.isArray(item[paramKey])) {
                    item[paramKey].map(filterItem => {
                        if (filterItem.operator) {
                            //  If you want to have deep filtering like location.city you should pass name like location>city
                            //  Preparing filter[field][firstOperator]=value
                            //            filter[field][secondOperator]=value
                            //  `${paramsKey}[${paramKey.replace('>
                            //  This has been hardcoded to filters but can be dynamicly also like example above
                            //  `filters[${paramKey.replace('>

                            // Preparing filter[field]=value or filter[field][]=value if is array
                            const key = `filters[${paramKey.replace(/>/g, '.')}][${
                                filterItem.operator
                            }]`;

                            //  TODO: write nicer in future
                            if (parsedParams[key]) {
                                const prevValue = parsedParams[key];
                                const value = parseParamsValueForApi(filterItem);
                                if (Array.isArray(prevValue)) {
                                    if (Array.isArray(value)) {
                                        parsedParams[key] = union(prevValue, value);
                                    } else {
                                        parsedParams[key] = union(prevValue, [value]);
                                    }
                                } else if (Array.isArray(value)) {
                                    parsedParams[key] = union([prevValue], value);
                                } else {
                                    parsedParams[key] = union([prevValue], [value]);
                                }
                            } else {
                                parsedParams[key] = parseParamsValueForApi(filterItem);
                            }
                        } else {
                            let filterOperator = 'equals';
                            if (filterSchema?.length) {
                                filterSchema.map(filterSchemaItem => {
                                    if (filterSchemaItem.name === paramKey) {
                                        if (filterSchemaItem.defaultOperator) {
                                            filterOperator = filterSchemaItem.defaultOperator;
                                        }
                                    }
                                    return filterOperator;
                                });
                            }

                            //  TODO: write nicer in future
                            //  Preparing filter[field]=value or filter[field][]=value
                            //  check if already exists
                            const key = `filters[${paramKey.replace(
                                />/g,
                                '.'
                            )}][${filterOperator}]`;
                            if (parsedParams[key]) {
                                const prevValue = parsedParams[key];
                                const value = parseParamsValueForApi(filterItem);
                                if (Array.isArray(prevValue)) {
                                    if (Array.isArray(value)) {
                                        parsedParams[key] = union(prevValue, value);
                                    } else {
                                        parsedParams[key] = union(prevValue, [value]);
                                    }
                                } else if (Array.isArray(value)) {
                                    parsedParams[key] = union([prevValue], value);
                                } else {
                                    parsedParams[key] = union([prevValue], [value]);
                                }
                            } else {
                                parsedParams[key] = parseParamsValueForApi(filterItem);
                            }
                        }
                        return true;
                    });
                }
                return true;
            });
        } else {
            //  If is normal pram just send it directly
            parsedParams[paramsKey] = item;
        }

        //  Prepare order_by
        if (paramsKey === 'order_by') {
            const orderKey = Object.keys(item)[0];
            parsedParams[`${paramsKey}[${orderKey}]`] = item[orderKey];
            delete parsedParams.order_by;
        }

        return true;
    });

    return parsedParams;
};

const defaultListingReducer = {
    listingFetching: false,
    listingError: {},
    listingResponse: {},
    singleFetching: false
};

export { parseParamsValueForApi, parseParamsForApi, defaultListingReducer };
