export const actions = {
    // Listing
    START_FETCHING_PAYMENT_METHODS: 'START_FETCHING_PAYMENT_METHODS',
    FETCHING_SUCCESSFUL_PAYMENT_METHODS: 'FETCHING_SUCCESSFUL_PAYMENT_METHODS',
    FETCHING_FAILED_PAYMENT_METHODS: 'FETCHING_FAILED_PAYMENT_METHODS',
    // Create
    START_CREATE_PAYMENT_METHOD: 'START_CREATE_PAYMENT_METHOD',
    CREATE_PAYMENT_METHOD_SUCCESSFUL: 'CREATE_PAYMENT_METHOD_SUCCESSFUL',
    CREATE_PAYMENT_METHOD_FAILED: 'CREATE_PAYMENT_METHOD_FAILED',
    // Edit
    START_UPDATE_PAYMENT_METHOD: 'START_UPDATE_PAYMENT_METHOD',
    UPDATE_PAYMENT_METHOD_SUCCESSFUL: 'UPDATE_PAYMENT_METHOD_SUCCESSFUL',
    UPDATE_PAYMENT_METHOD_FAILED: 'UPDATE_PAYMENT_METHOD_FAILED',
    // Delete
    START_DELETE_PAYMENT_METHOD: 'START_DELETE_PAYMENT_METHOD',
    DELETE_PAYMENT_METHOD_SUCCESSFUL: 'DELETE_PAYMENT_METHOD_SUCCESSFUL',
    DELETE_PAYMENT_METHOD_FAILED: 'DELETE_PAYMENT_METHOD_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PAYMENT_METHODS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PAYMENT_METHODS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PAYMENT_METHODS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
