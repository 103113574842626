export const actions = {
    START_FETCHING_COMPANY_CONTACTS: 'START_FETCHING_COMPANY_CONTACTS',
    FETCHING_SUCCESSFUL_COMPANY_CONTACTS: 'FETCHING_SUCCESSFUL_COMPANY_CONTACTS',
    FETCHING_FAILED_COMPANY_CONTACTS: 'FETCHING_FAILED_COMPANY_CONTACTS',

    START_CREATE_COMPANY_CONTACT: 'START_CREATE_COMPANY_CONTACT',
    CREATE_COMPANY_CONTACT_SUCCESSFUL: 'CREATE_COMPANY_CONTACT_SUCCESSFUL',
    CREATE_COMPANY_CONTACT_FAILED: 'CREATE_COMPANY_CONTACT_FAILED',

    START_UPDATE_COMPANY_CONTACT: 'START_UPDATE_COMPANY_CONTACT',
    UPDATE_COMPANY_CONTACT_SUCCESSFUL: 'UPDATE_COMPANY_CONTACT_SUCCESSFUL',
    UPDATE_COMPANY_CONTACT_FAILED: 'UPDATE_SINGLE_COMPANY_FAILED',

    START_DELETE_COMPANY_CONTACT: 'START_DELETE_COMPANY_CONTACT',
    DELETE_COMPANY_CONTACT_SUCCESSFUL: 'DELETE_COMPANY_CONTACT_SUCCESSFUL',
    DELETE_COMPANY_CONTACT_FAILED: 'DELETE_COMPANY_CONTACT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_COMPANY_CONTACTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_COMPANY_CONTACTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_COMPANY_CONTACTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }

        default:
            return state;
    }
};
