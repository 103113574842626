export const actions = {
    START_FETCHING_PROPS: 'START_FETCHING_PROPS',
    FETCHING_SUCCESSFUL_PROPS: 'FETCHING_SUCCESSFUL_PROPS',
    FETCHING_FAILED_PROPS: 'FETCHING_FAILED_PROPS',

    CLEAR_STATE: 'CLEAR_STATE'
};

export const initialState = {
    propsData: {
        data: [],
        meta: {}
    },
    propsFetching: false,
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PROPS: {
            return {
                ...state,
                propsFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROPS: {
            const mergedData = [...state?.propsData?.data, ...response?.data];

            return {
                ...state,
                propsFetching: false,
                propsData: {
                    ...state.propsData,
                    meta: response?.meta,
                    data: mergedData.reduce((accumulator, currentValue) => {
                        const alreadyExists = accumulator.find(
                            item => item.iri === currentValue.iri
                        );

                        if (!alreadyExists) {
                            accumulator.push(currentValue);
                        }

                        return accumulator;
                    }, [])
                }
            };
        }
        case actions.FETCHING_FAILED_PROPS: {
            return {
                ...state,
                propsFetching: false,
                listingError: response
            };
        }

        case actions.CLEAR_STATE: {
            return initialState;
        }
        default:
            return state;
    }
};
