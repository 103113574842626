import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as timeLogsReportActions } from './TimeLogs.reducer';

/**
 * Fetch Time Logs Report
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchTimeLogsReport({ promise, params, by }) {
    try {
        const fetchTimeLogsReportAPI = yield restClient.get(
            `api/reports/time-logs?filters[archived][equals]=all`,
            {
                params,
                headers: { Accept: 'application/json' }
            }
        );
        yield put({
            type: timeLogsReportActions.FETCHING_SUCCESSFUL_REPORT_TIME_LOGS,
            by
        });
        yield put({
            type: timeLogsReportActions.STORE_REPORT_TIME_LOGS_DATA,
            response: fetchTimeLogsReportAPI?.data,
            by
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: timeLogsReportActions.FETCHING_FAILED_REPORT_TIME_LOGS,
            by
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(timeLogsReportActions.START_FETCHING_REPORT_TIME_LOGS, fetchTimeLogsReport)
];
