import React from 'react';
import PropTypes from 'prop-types';

const Hidden = ({ input, fieldAttr }) => (
    <input id={input.name} {...input} {...fieldAttr} type="hidden" />
);

Hidden.defaultProps = {
    input: {},
    id: null,
    fieldAttr: {}
};

Hidden.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    id: PropTypes.string,
    fieldAttr: PropTypes.oneOfType([PropTypes.object])
};

export default Hidden;
