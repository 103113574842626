import Hero from 'erpcore/components/Layout/Hero';
import FrontMain from 'erpcore/components/Layout/FrontMain';

const frontLayout = {
    data: [
        {
            slotName: 'hero',
            component: Hero,
            position: 0
        },
        {
            slotName: 'main',
            component: FrontMain,
            position: 0
        }
    ]
};

export default frontLayout;
