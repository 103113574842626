import { combineReducers } from 'redux';

import categories from 'erpcore/screens/Accounting/screens/Categories/Categories.reducer';
import payrollReports from 'erpcore/screens/Accounting/screens/PayrollReports/PayrollReports.reducer';
import invoices from 'erpcore/screens/Accounting/screens/Invoices/Invoices.reducer';
import expenses from 'erpcore/screens/Accounting/screens/Expenses/Expenses.reducer';
import revenues from 'erpcore/screens/Accounting/screens/Revenues/Revenues.reducer';

//  Combining all accounting reducers
const accounting = combineReducers({
    categories,
    payrollReports,
    invoices,
    expenses,
    revenues
});

export default accounting;
