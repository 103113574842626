import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Departments Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const TimeLogs = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'timelog.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.itemSuccessfullyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.bulkActions.lockEntriesSuccessful':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text="Locking for selected time log entries is in progress, please refresh the page in 30 seconds!"
                    type="info"
                    // expire="3500"
                />
            );
        case 'timelog.bulkActions.changeWorkTypeSuccessful':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text="Changing work type for selected time log entries is in progress, please refresh the page in 30 seconds!"
                    type="info"
                    // expire="3500"
                />
            );
        case 'timelog.bulkActions.changeProjectSuccessful':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text="Changing project for selected time log entries is in progress, please refresh the page in 30 seconds!"
                    type="info"
                    // expire="7000"
                />
            );
        case 'timelog.bulkActions.changeProjectStageSuccessful':
            return (
                <Notification
                    identifier={identifier}
                    title="In progress"
                    text="Changing project stage for selected time log entries is in progress, please refresh the page in 30 seconds! Please note that at some time log entries stage may not be added if there is not enough allocated time at the selected stage."
                    type="info"
                    // expire="7000"
                />
            );
        case 'timelogs.integration.ok':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log integration successfully synced!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.stage.invalidTimeLogConstraint':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={detail}
                    type="error"
                    expire="3500"
                />
            );
        case 'timelog.cannotEditBillingRequestAttachedTimelog':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="This Time Log is connected to a Billing Request and cannot be edited."
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

TimeLogs.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

TimeLogs.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default TimeLogs;
