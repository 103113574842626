import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as departmentsActions } from 'erpcore/screens/Settings/Departments/Departments.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import dto from 'erpcore/utils/dto';

/**
 * Create Department
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createDepartment({ promise, formData }) {
    try {
        const createDepartmentAPI = yield restClient.post('api/departments', formData);
        yield put({
            type: departmentsActions.CREATE_DEPARTMENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createDepartmentAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'departments' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DEPARTMENTS',
            name: 'departments',
            endpoint: 'api/departments?include=billingRequestSubscribers'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: departmentsActions.CREATE_DEPARTMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Department
 * @param  {Object} promise
 * @return {string} iri Department iri
 */
export function* fetchDepartment({ promise, iri }) {
    try {
        const fetchDepartmentAPI = yield restClient.get(`${iri}?include=billingRequestSubscribers`);
        yield put({
            type: departmentsActions.FETCH_DEPARTMENT_SUCCESSFUL
        });

        yield put({
            type: departmentsActions.STORE_DEPARTMENT_DATA,
            iri,
            response: dto(fetchDepartmentAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: departmentsActions.FETCH_DEPARTMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Department data
 * @param  {Object} iri of Department
 * @return {Object} Response from API
 */
export function* updateDepartment({ promise, iri, formData }) {
    try {
        const updateDepartmentAPI = yield restClient.patch(iri, formData);
        yield put({
            type: departmentsActions.UPDATE_DEPARTMENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateDepartmentAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'departments' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DEPARTMENTS',
            name: 'departments',
            endpoint: 'api/departments?include=billingRequestSubscribers'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: departmentsActions.UPDATE_DEPARTMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Department
 * @param  {Object} iri of Department
 * @return {Object} Response from API
 */
export function* deleteDepartment({ promise, iri }) {
    try {
        const deleteDepartmentAPI = yield restClient.delete(iri);
        yield put({
            type: departmentsActions.DELETE_DEPARTMENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteDepartmentAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'departments' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DEPARTMENTS',
            name: 'departments',
            endpoint: 'api/departments?include=billingRequestSubscribers'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: departmentsActions.DELETE_DEPARTMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const departmentsSaga = [
    takeLatest(departmentsActions.START_CREATE_DEPARTMENT, createDepartment),
    takeLatest(departmentsActions.START_FETCHING_DEPARTMENT, fetchDepartment),
    takeLatest(departmentsActions.START_UPDATE_DEPARTMENT, updateDepartment),
    takeLatest(departmentsActions.START_DELETE_DEPARTMENT, deleteDepartment)
];

export default departmentsSaga;
