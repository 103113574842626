/*
import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
*/
/**
 * Register action to watcher
 */
export default [
    // takeLatest(servicesActions.START_CREATE_SERVICE, createService)
];
