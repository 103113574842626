import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { setToken } from 'erpcore/api';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as myAccountActions } from './MyAccount.reducer';

/**
 * Update current user data
 * @param  {Object} promise {resolve, reject}
 * @return {Object} formData from the form
 */
export function* updateMyAccount({ promise, formData }) {
    try {
        // use selector for current user IRI
        const currentUser = yield select(getMeData);

        const myAccountAPICall = yield restClient.put(
            `${currentUser?.iri}?include=organizations,country,state,city,image,roles,permissions`,
            formData
        );
        yield put({
            type: myAccountActions.UPDATE_USER_SUCCESSFUL
        });
        // Store User Data
        // Overriding message code to use different than the message code for the User entity
        yield put({
            type: authActions.UPDATE_USER_DATA,
            response: dto(myAccountAPICall?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: Object.assign({}, myAccountAPICall?.data, {
                code: 'user.currentUserSuccessfulyUpdated'
            })
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: myAccountActions.UPDATE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Password
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* changePassword({ promise, formData }) {
    const { id, new_password: newPassword, old_password: oldPassword } = { ...formData };

    try {
        const myAccountChangePasswordAPICall = yield restClient.patch(`api/users/${id}/password`, {
            new_password: newPassword,
            old_password: oldPassword
        });
        yield put({
            type: myAccountActions.CHANGE_PASSWORD_SUCCESFUL
        });
        yield setToken(myAccountChangePasswordAPICall?.data?.token);
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: myAccountChangePasswordAPICall?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: myAccountActions.CHANGE_PASSWORD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const myAccountSaga = [
    takeLatest(myAccountActions.START_UPDATE_USER, updateMyAccount),
    takeLatest(myAccountActions.START_CHANGE_PASSWORD, changePassword)
];
