import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { timeValueConverter as convertSecondsToText } from 'erpcore/utils/utils';
import Input from '../Input';

const regexCodes = {
    hoursMinutesMatch: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]?)$/,
    hoursMinutesSecondsMatch: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]?):([0-5][0-9]?)$/,
    minutesToMatch: /^:([0-9][0-9]?[0-9]?[0-9]?)$/,
    numberMatch: /^([0-9][0-9]?[0-9]?[0-9]?)$/,
    customFormat:
        /^(([0-9]|0[0-9]|1[0-9]|2[0-3])(h))?\s?(([0-9][0-9]?[0-9]?)(m))?\s?(([0-9][0-9]?[0-9]?)(s))?$/,
    unlimitedHoursFormat: /^(([0-9]+)(h))?\s?(([0-9][0-9]?[0-9]?)(m))?$/
};

const Timelog = ({ input, meta, fieldProps, fieldAttr, field, onBlur }) => {
    const { value: incomingValue } = input;
    const { max24hours = true, useFullUnitName = false, defaultUnit } = { ...fieldProps };
    const { disabled = false } = { ...fieldAttr };

    const [inputStringValue, setInputStringValue] = useState(
        convertSecondsToText(incomingValue, useFullUnitName, defaultUnit) || ''
    );
    const outgoingValueRef = useRef(null);

    useEffect(() => {
        if (incomingValue !== outgoingValueRef.current) {
            if (typeof incomingValue === 'number' || incomingValue === 0 || incomingValue === '0') {
                setInputStringValue(
                    convertSecondsToText(incomingValue, useFullUnitName, defaultUnit)
                );
            } else if (incomingValue.trim() === '') {
                setInputStringValue('');
            }
        }
        outgoingValueRef.current = null;
    }, [incomingValue]);

    const convertTextToSeconds = (value) => {
        let seconds = '';

        // format HH:MM
        if (value.match(regexCodes.hoursMinutesMatch)) {
            const hours = Number(value.match(regexCodes.hoursMinutesMatch)[1]);
            const minutes = Number(value.match(regexCodes.hoursMinutesMatch)[2]);
            seconds = hours * 3600 + minutes * 60;
        }

        // format HH:MM:SS
        if (value.match(regexCodes.hoursMinutesSecondsMatch)) {
            const hours = Number(value.match(regexCodes.hoursMinutesMatch)[1]);
            const minutes = Number(value.match(regexCodes.hoursMinutesMatch)[2]);
            const secondsMatch = Number(value.match(regexCodes.hoursMinutesMatch)[3]);
            seconds = hours * 3600 + minutes * 60 + secondsMatch;
        }

        // format :MM to HH:MM or :MM
        else if (value.match(regexCodes.minutesToMatch)) {
            const minutes = Number(value.match(regexCodes.minutesToMatch)[1]);
            seconds = minutes * 60;
        }

        // format only number
        else if (value.match(regexCodes.numberMatch)) {
            const numberValue = Number(value.match(regexCodes.numberMatch)[1]);
            seconds = numberValue < 25 ? numberValue * 60 * 60 : numberValue * 60;
        }

        // custom format 1h 30m or 1h or 30m or 25s
        else if (value.match(regexCodes.customFormat)) {
            const hours = Number(value.match(regexCodes.customFormat)[2]) || 0;
            const minutes = Number(value.match(regexCodes.customFormat)[5]) || 0;
            const secondsMatch = Number(value.match(regexCodes.customFormat)[8]) || 0;
            seconds = hours * 3600 + minutes * 60 + secondsMatch;
        }

        // custom format 1-999h 30m
        else if (value.match(regexCodes.unlimitedHoursFormat) && !max24hours) {
            const hours = Number(value.match(regexCodes.unlimitedHoursFormat)[2]) || 0;
            const minutes = Number(value.match(regexCodes.unlimitedHoursFormat)[5]) || 0;
            seconds = hours * 3600 + minutes * 60;
        }

        return seconds ? Number(seconds) : 0;
    };

    const handleValueChange = (value) => {
        setInputStringValue(value);
        const seconds = convertTextToSeconds(value) || 0;
        outgoingValueRef.current = seconds;
        return input.onChange(seconds);
    };

    const handleOnBlur = (value) => {
        const seconds = convertTextToSeconds(value);

        setInputStringValue(convertSecondsToText(seconds, useFullUnitName, defaultUnit));

        if (onBlur) {
            onBlur(seconds);
        }
        return input.onBlur(seconds);
    };

    const handleKeyPress = (ev) => {
        // ENTER keypress
        if (ev.charCode === 13) {
            handleOnBlur(ev.target.value);
        }
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <input
                disabled={disabled}
                id={input.name}
                {...input}
                {...fieldAttr}
                aria-label={`${input.name}`}
                type="text"
                className={`input__field ${fieldAttr?.className ? fieldAttr.className : ''}`}
                value={inputStringValue}
                onChange={(ev) => handleValueChange(ev.target.value)}
                onBlur={(ev) => handleOnBlur(ev.target.value)}
                onKeyPress={handleKeyPress}
            />
        </Input>
    );
};

Timelog.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    onBlur: null
};

Timelog.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    onBlur: PropTypes.func
};

export default Timelog;
