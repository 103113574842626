export const actions = {
    // Listing
    START_FETCHING_DIVISIONS: 'START_FETCHING_DIVISIONS',
    FETCHING_SUCCESSFUL_DIVISIONS: 'FETCHING_SUCCESSFUL_DIVISIONS',
    FETCHING_FAILED_DIVISIONS: 'FETCHING_FAILED_DIVISIONS',
    // Create
    START_CREATE_DIVISION: 'START_CREATE_DIVISION',
    CREATE_DIVISION_SUCCESSFUL: 'CREATE_DIVISION_SUCCESSFUL',
    CREATE_DIVISION_FAILED: 'CREATE_DIVISION_FAILED',
    // Edit
    START_UPDATE_DIVISION: 'START_UPDATE_DIVISION',
    UPDATE_DIVISION_SUCCESSFUL: 'UPDATE_DIVISION_SUCCESSFUL',
    UPDATE_DIVISION_FAILED: 'UPDATE_DIVISION_FAILED',
    // Delete
    START_DELETE_DIVISION: 'START_DELETE_DIVISION',
    DELETE_DIVISION_SUCCESSFUL: 'DELETE_DIVISION_SUCCESSFUL',
    DELETE_DIVISION_FAILED: 'DELETE_DIVISION_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_DIVISIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DIVISIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_DIVISIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
